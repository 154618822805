import React from "react";
import {
    Checkbox,
    Chip,
    FormControlLabel,
    FormHelperText,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {Add} from "@mui/icons-material";
import {useSectorsData} from "../../../../Contexts/DataContext/SectorsDataContext";
import ChipColorPicker from "../../../../Utilities/ChipColorPicker";

export default function ProfileFormSectors({
                                               selectedSectors,
                                               setSelectedSectors,
                                               errorState,
                                               setErrorState,
                                               state
                                           }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: any) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const theme = useTheme();

    const handleSectorToggle = (sectorId: string) => {
        setSelectedSectors(prev => {
            if (prev.includes(sectorId)) {
                return prev.filter(id => id !== sectorId);
            }
            return [...prev, sectorId];
        });
    };

    const handleDelete = (value: string) => {
        setSelectedSectors(prev => prev.filter(id => id !== value));
    };

    const countSelections = () => {
        return selectedSectors.length;
    };

    const {sectors} = useSectorsData();
    const sectorsData = sectors
        .map((item) => ({
            id: item.id,
            data: item.name
        }));

    const elementChosen = (id) => {
        handleSectorToggle(id);
        setErrorState(undefined);
    }

    return (
        <>
            <Stack direction="column" spacing={1}>
                <Stack direction="row" spacing={1} sx={{flexGrow: 1, overflow: 'hidden'}}>
                    <Typography sx={{
                        color: errorState !== undefined ? "#d32f2f" : theme.palette.text.secondary,
                        paddingBottom: "0.3em",
                        alignSelf: {xs: "center", md: state === "view" ? "center" : "end"},
                        gap: 1,
                    }}>אשכולות:</Typography>

                    <Stack
                        direction="row"
                        sx={{
                            flexWrap: 'wrap',
                            alignItems: {xs: 'flex-start', md: 'flex-end'},
                            justifyContent: "center",
                        }}
                    >
                        {selectedSectors.map((id) => {
                            const sector = sectorsData.find(s => s.id === id);
                            const idx = sectorsData.findIndex(s => s.id === id);

                            if (sector === undefined) {
                                return null;
                            }

                            return (
                                <Chip
                                    key={id}
                                    label={sector?.data}
                                    color={ChipColorPicker(idx)}
                                    deleteIcon={state !== 'view' ?
                                        <Tooltip title="מחק אשכול"><DeleteIcon/></Tooltip> : undefined}
                                    onDelete={state !== 'view' ? () => handleDelete(id) : undefined}
                                    disabled={countSelections() === 1}
                                    sx={{
                                        margin: "2px"
                                    }}
                                />
                            );
                        })}
                    </Stack>
                </Stack>
                {errorState !== undefined &&
                    <FormHelperText error sx={{marginLeft: "14px"}}>{errorState}</FormHelperText>
                }
            </Stack>

            {state !== 'view' &&
                <IconButton
                    color="primary"
                    sx={{alignSelf: {xs: "center", md: "end"}, flexShrink: 0}}
                    onClick={handleClick}
                >
                    <Add/>
                </IconButton>
            }

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <Typography
                    sx={{
                        margin: 0,
                        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                        lineHeight: 1.66,
                        letterSpacing: '0.03333em',
                        fontSize: '11px',
                        fontWeight: 600,
                        padding: '8px'
                    }}
                >
                    הוספת אשכולות
                </Typography>

                {sectorsData.map((sector) => (
                    <MenuItem key={sector.id}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectedSectors.includes(sector.id)}
                                    onChange={() => elementChosen(sector.id)}
                                    onClick={(e) => e.stopPropagation()}
                                    disabled={countSelections() === 1 && selectedSectors.includes(sector.id)}
                                />
                            }
                            label={sector.data}
                        />
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

