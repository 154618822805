import {MenuItem, Select} from "@mui/material";
import React from "react";
import {blue, green, red} from "@mui/material/colors";
import {Cached, Done, DriveFileMove, Favorite, FiberNew, SentimentDissatisfied} from "@mui/icons-material";

const SelectInterviewStatus = ({label, value, onChange, hide}) => {
    const statuses = [
        {value: "נדחה", icon: <SentimentDissatisfied/>, color: red[800]},
        {value: "אינו מעוניין במשרה", icon: <SentimentDissatisfied/>, color: red[500]},
        {value: "קו\"ח הועברו למנהלי התוכנית", icon: <DriveFileMove/>, color: blue[500]},
        {value: "העבר למשרה אחרת", icon: <Cached/>, color: blue[900]},
        {value: "התקבל", icon: <Done/>, color: green[500]},
        {value: "עבר ראיון ראשון", icon: <Favorite/>, color: green[300]},
        {value: "עבר ראיון שני", icon: <Favorite/>, color: green[700]},
        {value: "זומן לראיון ראשון", icon: <Favorite/>, color: blue[300]},
        {value: "זומן לראיון שני", icon: <Favorite/>, color: blue[700]},
        {value: "הוגשה מועמדות", icon: <FiberNew/>, color: green[400]}
    ];

    const filteredStatuses = statuses.filter(status => !hide?.includes(status.value));

    return (
        <Select
            value={value}
            onChange={onChange}
            label={label}
            displayEmpty
            sx={{
                '& .MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    color: filteredStatuses.find(s => s.value === value)?.color,
                },
            }}
        >
            {filteredStatuses.map((status) => (
                <MenuItem
                    key={status.value}
                    value={status.value}
                    disabled={status.value === value}
                    sx={{
                        display: 'flex',
                        gap: 1,
                        color: status.color,
                        '& .MuiSvgIcon-root': {
                            color: status.color,
                        },
                    }}
                >
                    {status.icon}
                    {status.value}
                </MenuItem>
            ))}
        </Select>
    );
};

export default SelectInterviewStatus;