import React from 'react';
import {alpha, Box, Divider, IconButton, Popover, Tooltip, Typography, useTheme} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {useAuth} from "../../../Contexts/AuthContext/AuthContext";
import {recruiterService} from "../../../../Firebase/FirebaseFunctions/RecruiterService";
import {useSnackbar} from "../../../../Utilities/Context/Snackbar";
import ProfileFormSectors from "../../../Pages/Recruiters/ModifyCreateRecruiter/Components/ProfileFormSectors";
import {LoadingButton} from "@mui/lab";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import {UserRole} from "../../../../Firebase/enums/UserRole";
import { useThemeContext } from '../../../Contexts/Theme';

const NavbarProfile: React.FC = () => {
    const {currentUser, userDetails, role} = useAuth();
    const theme = useTheme();

    const {showSnackbar} = useSnackbar();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [loadingPasswordReset, setLoadingPasswordReset] = React.useState(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleResetPassword = async () => {
        try {
            setLoadingPasswordReset(true);
            await recruiterService.sendPasswordReset(currentUser.email);
            showSnackbar({message: "קישור לאיפוס סיסמה נשלח למייל", severity: "success"});
            handleClose();
        } catch (error) {
            showSnackbar({message: "איפוס הסיסמה נכשל."});
            console.log(error);
        } finally {
            setLoadingPasswordReset(false);
        }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'profile-popover' : undefined;

    return (
        <>
            <Tooltip title="הפרופיל שלי" arrow componentsProps={{
                tooltip: {
                    sx: {
                        fontSize: '1rem',
                    },
                },
            }}>

                <IconButton
                    aria-describedby={id}
                    onClick={handleClick}
                    size="large"
                    sx={{
                        color: theme.palette.custom.navbar.iconColor,
                        '&:hover': {
                            color: theme.palette.primary.main,
                        }
                    }}
                >
                    <AccountCircleIcon/>
                </IconButton>
            </Tooltip>
        
            <Popover
               id={id}
               open={open}
               anchorEl={anchorEl}
               onClose={handleClose}
               dir="ltr"
   
               anchorOrigin={{
                   vertical: 'bottom',
                   horizontal: 'center',
               }}
               transformOrigin={{
                   vertical: 'top',
                   horizontal: 'center',
               }}
               slotProps={{
                   paper: {
                     sx: {
                       direction: 'rtl',
                       left: '0px', // IconButton padding is 12px (as default) - 4px = (12px - 4px)
                       top: "66px !important",
                       width: 'fit-content',
                        overflow: 'unset',
                         backgroundColor: "transparent !important",
                         boxShadow: 'none !important',
                       position: 'relative',
                     }
                   }
                 }}
            >
                <Box sx={{
                  direction: 'ltr',
                  p: 3,
                  borderRadius: '8px !important',
                  bgcolor: theme.palette.background.paper,
                  minWidth: 250,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  border: `1px solid ${alpha(theme.palette.text.secondary, 0.2)}`,
  
                  // arrow
                  "&::before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      right: 61.4,
                      width: 22,
                      height: 22,
                      bgcolor: theme.palette.background.paper,
                      borderRight: `1px solid ${alpha(theme.palette.text.secondary, 0.2)}`,
                      borderTop: `1px solid ${alpha(theme.palette.text.secondary, 0.2)}`,
                      transform: "translateY(-50%) rotate(45deg)",
  
                    }
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px'
                    }}>
                        <AccountBoxIcon sx={{width: 70, height: 70}}/>
                        <Box>
                            <Typography variant="subtitle1" fontWeight="bold">
                                {currentUser?.displayName}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {currentUser?.email}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {(() => {
                                    switch (role) {
                                        case UserRole.admin:
                                            return "מנהל/ת מערכת";
                                        case UserRole.recruiter:
                                            return "מגייס/ת";
                                        default:
                                            return "";
                                    }
                                })()}
                            </Typography>
                        </Box>
                    </Box>

                    <Divider/>

                    <Box sx={{display: "flex", alignItems: "center", gap: "12px"}}>
                        <ProfileFormSectors
                            selectedSectors={userDetails?.sectors}
                            setSelectedSectors={undefined}
                            errorState={undefined}
                            setErrorState={undefined}
                            state={"view"}/>
                    </Box>

                    <Divider/>

                    <LoadingButton
                        variant="outlined"
                        color="warning"
                        loading={loadingPasswordReset}
                        onClick={handleResetPassword}
                        fullWidth
                    >
                        איפוס סיסמה
                    </LoadingButton>
                </Box>
            </Popover>
        </>
    );
};

export default NavbarProfile;
