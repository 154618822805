import FullScreenDialog, {FullScreenDialogHeader} from "../../../Components/Dialog/FullScreenDialog/FullScreenDialog";
import React from "react";
import CreateSectorHeader from "./Components/CreateSectorHeader";
import CreateSectorForm from "./Components/CreateSectorForm";
import {Box} from "@mui/material";

export default function CreateSector({show, close, sector}) {
    return (
        <>
            <FullScreenDialog show={show} close={close}>
                <FullScreenDialogHeader>
                    <CreateSectorHeader sector={sector}/>
                </FullScreenDialogHeader>

                <Box sx={{paddingTop: 5}}>
                    <CreateSectorForm close={close} sector={sector}/>
                </Box>
            </FullScreenDialog>
        </>
    );
}
