import React, {useState, useEffect} from 'react';
import {Box} from '@mui/material';

const Bubble = ({ size, position }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setIsVisible(true), 50);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Box
            sx={{
                position: 'absolute',
                background: 'rgba(255, 255, 255, 0.2)',
                width: size,
                height: size,
                borderRadius: '50%',
                left: position.x,
                top: position.y,
                zIndex: 0,
                pointerEvents: 'none',
                opacity: 0,
                transform: 'scale(0)',
                animation: isVisible ? 'bubbleAppear 0.5s ease forwards' : 'none',
                '@keyframes bubbleAppear': {
                    '0%': {
                        opacity: 0,
                        transform: 'scale(0)',
                    },
                    '100%': {
                        opacity: 0.5,
                        transform: 'scale(1)',
                    },
                },
            }}
        />
    );
};


export default Bubble;