import {useSectorsData} from "../../../../Contexts/DataContext/SectorsDataContext";
import React from "react";
import InformationalCard from "../../../../Components/Cards/InformationalCard";
import {Box} from "@mui/system";
import PlaceIcon from '@mui/icons-material/Place';
import WorkIcon from '@mui/icons-material/Work';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import NumbersIcon from '@mui/icons-material/Numbers';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import dayjs from "dayjs";

export default function JobDetails({job}) {
    const {sectors} = useSectorsData();
    const sector = sectors?.find(item => item.id === job?.sector);

    return <>
        <Box sx={{
            width: "100%",
            height: "100%",
            paddingX: 2,
            display: 'flex',
            flexWrap: 'wrap',
            gap: 2,
        }}>
            <Box sx={{flex: '1 1 300px'}}>
                <InformationalCard icon={<NumbersIcon sx={{fontSize: 40, color: '#2A5475'}}/>} title={job.jobNumber}
                                   subtitle={"מספר המשרה"}/>
            </Box>

            <Box sx={{flex: '1 1 300px'}}>
                <InformationalCard icon={<ApartmentIcon sx={{fontSize: 40, color: '#009B4D'}}/>}
                                   title={sector?.name}
                                   subtitle={"אשכול"}/>
            </Box>

            <Box sx={{flex: '1 1 300px'}}>
                <InformationalCard icon={<CalendarTodayIcon sx={{fontSize: 40, color: '#E7473C'}}/>}
                                   title={dayjs(job.creationDate).format("DD/MM/YY HH:mm")}
                                   subtitle={"נוצרה בתאריך ושעה"}/>
            </Box>

            <Box sx={{flex: '1 1 300px'}}>
                <InformationalCard icon={<PlaceIcon sx={{fontSize: 40, color: '#8076a3'}}/>} title={job.region}
                                   subtitle={"איזור"}/>
            </Box>

            <Box sx={{flex: '1 1 300px'}}>
                <InformationalCard icon={<WorkIcon sx={{fontSize: 40, color: '#FF921C'}}/>} title={job.title}
                                   subtitle={"כותרת המשרה"}/>
            </Box>

            <Box sx={{flex: '1 1 300px'}}>
                <InformationalCard icon={<EventSeatIcon sx={{fontSize: 40, color: '#178582'}}/>} title={job.role}
                                   subtitle={"תפקיד"}/>
            </Box>
        </Box>
    </>;
}