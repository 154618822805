import {FormHelperText, Stack, TextField, Typography, useTheme} from "@mui/material";
import React from "react";
import BorderedContainer from "../../../../../Components/BorderedContainer";
import {RatingRTL} from "../../../../../Styled/Rating";

export default function InterviewSummary({formik, step}) {
    const summaryField = step === 2 ? 'firstSummary' : 'secondSummary';
    const rating = "rating";
    const theme = useTheme();

    return (<>
        <Stack direction={{xs: "column", sm: "row"}} spacing={2}>
            <TextField
                required
                label="סיכום ראיון"
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                {...formik.getFieldProps(summaryField)}
                error={formik.touched[summaryField] && Boolean(formik.errors[summaryField])}
                helperText={formik.touched[summaryField] && formik.errors[summaryField]}
            />

            <Stack flexDirection="column">
                <BorderedContainer sx={{minHeight: "8rem", maxHeight: "fit-content", flexBasis: "20%"}}
                                   error={formik.touched[rating] && Boolean(formik.errors[rating])}>
                    <Typography sx={{
                        color: formik.touched[rating] && Boolean(formik.errors[rating]) ? "#d32f2f" : theme.palette.custom.form.text,
                        paddingBottom: "0.3em"
                    }}>
                        {step === 4 ? "עדכון " : ""} דרגת התאמה *
                    </Typography>

                    <Stack flexDirection="row" justifyContent="center" paddingBottom="0.6em">
                        <RatingRTL
                            name={"rating"}
                            value={formik.values.rating}
                            onChange={(event, newValue) => {
                                formik.setFieldValue('rating', newValue);
                            }}
                        />
                    </Stack>
                </BorderedContainer>
                {formik.touched[rating] && Boolean(formik.errors[rating]) &&
                    <FormHelperText error sx={{marginLeft: "14px"}}>{formik.errors[rating]}</FormHelperText>
                }
            </Stack>
        </Stack>
    </>)
}