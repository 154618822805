import * as yup from "yup";
import {collection, doc, DocumentData, Firestore, getDoc, getDocs, QueryDocumentSnapshot,} from "firebase/firestore";
import {
    customAddDoc,
    customDeleteDoc,
    customUpdateDoc
} from "./Helpers/CustomFirebaseFunctions";
import {db} from "../FirebaseConfig/firebase";

export const RolesLocalStorage = "roles";

// Role class definition
class Role {
    name: string;
    open: boolean;

    constructor(name: string, open: boolean = true) {
        this.name = name;
        this.open = open;
    }
}

// Validation schema for Role using Yup
const roleSchema = yup.object().shape({
    name: yup.string().required("Role name is required").min(2, "Name must be at least 2 characters"),
    open: yup.boolean().required("Open status is required"),
});

// Firestore data converter for Role
const roleConverter = {
    toFirestore: (role: Role): DocumentData => {
        return {
            name: role.name,
            open: role.open,
        };
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot, options: any): Role => {
        const data = snapshot.data(options);
        return new Role(data.name, data.open);
    },
};

// Role service class for Firestore operations
class RoleService {
    private db: Firestore;
    private collectionPath: string;

    constructor(db: Firestore, collectionPath: string) {
        this.db = db;
        this.collectionPath = collectionPath;
    }

    async saveRole(roleData: DocumentData): Promise<{ message: string; roleId: string } | void> {
        try {
            // Validate data using Yup schema
            const validatedRoleData = await roleSchema.validate(roleData, {abortEarly: false});

            const rolesCollection = collection(this.db, this.collectionPath);
            const rolesQuery = await getDocs(rolesCollection);
            const existingRoles = rolesQuery.docs.find((doc) => doc.data().name === validatedRoleData.name);

            if (existingRoles) {
                throw new Error(`Role with name "${validatedRoleData.name}" already exists.`);
            }

            const roleRef = rolesCollection.withConverter(roleConverter);
            await customAddDoc(roleRef, validatedRoleData, RolesLocalStorage);
            console.log("Role added successfully!");
        } catch (error: any) {
            if (error instanceof yup.ValidationError) {
                console.error("Validation errors:", error.errors);
                throw new Error("Invalid role data. Please check the input fields.");
            } else {
                console.error("Error calling createRole:", error);
                throw error;
            }
        }
    }

    async updateRoleStatus(roleId: string, open: boolean): Promise<void> {
        try {
            const roleRef = doc(this.db, this.collectionPath, roleId);
            await customUpdateDoc(roleRef, {open: open}, RolesLocalStorage);
            console.log(`Role ${open ? "opened" : "closed"} successfully!`);
        } catch (error) {
            console.error(`Error updating role status:`, error);
            throw new Error(`An error occurred while updating the role status.`);
        }
    }

    async updateRoleName(roleId: string, newName: string): Promise<void> {
        try {
            const rolesCollection = collection(this.db, this.collectionPath);
            const rolesQuery = await getDocs(rolesCollection);
            const existingRole = rolesQuery.docs.find(
                (doc) => doc.data().name === newName && doc.id !== roleId
            );

            if (existingRole) {
                throw new Error(`Role with name "${newName}" already exists.`);
            }

            // If name is unique, proceed with update
            const roleRef = doc(this.db, this.collectionPath, roleId);
            await customUpdateDoc(roleRef, {name: newName}, RolesLocalStorage);
            console.log("Role name updated successfully!");
        } catch (error) {
            console.error("Error updating role name:", error);
            throw error;
        }
    }

    async deleteRole(roleId: string): Promise<void> {
        try {
            const roleRef = doc(this.db, this.collectionPath, roleId);
            const roleDoc = await getDoc(roleRef);
            if (!roleDoc.exists()) {
                throw new Error("Role not found");
            }

            await customDeleteDoc(roleRef, RolesLocalStorage);
            console.log("Role deleted successfully!");
        } catch (error: any) {
            console.error("Error deleting role:", error.message);
            throw new Error("An error occurred while deleting the role.");
        }
    }
}

// Initialize Roles service for the "roles" collection
export const roleService = new RoleService(db, "roles");

export {Role, roleConverter, roleSchema};
export default RoleService;
