import TitleLabel from "../../Components/TitleLabel";
import React from "react";
import {Delete, Edit, LockReset, SupervisorAccount} from "@mui/icons-material";
import {Box, Button, Grid} from "@mui/material";
import {TableColumns, TableOptions} from "../../Components/Table/Table.types";
import BetterTable from "../../Components/Table/Table";
import {TableSearch} from "../../Components/Table/TableSearch";
import ModifyNewRecruiter from "./ModifyCreateRecruiter/ModifyNewRecruiter";
import CustomCard from "../../Components/Cards/Card";
import {useRecruiterDelete, useRecruiterResetPassword} from "../../Functions/Recruiter";
import {useAuth} from "../../Contexts/AuthContext/AuthContext";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import SectorChip from "../../Components/Chips/SectorChip";
import {SectorsDataProvider} from "../../Contexts/DataContext/SectorsDataContext";
import {useSearchParams} from "react-router-dom";
import {recruiterService} from "../../../Firebase/FirebaseFunctions/RecruiterService";
import ActivityText from "./Components/Activity";
import {RecruitersDataProvider} from "../../Contexts/DataContext/RecruitersDataContext";

function RecruitersPageContent() {
    const resetPassword = useRecruiterResetPassword();
    const removeRecruiter = useRecruiterDelete();

    const [showRecruiter, setShowRecruiter] = React.useState<any | null>(undefined);

    const columnsData: TableColumns = {
        columns: [
            {name: "שם פרטי", field: "firstName", width: "10%", sortable: true},
            {name: "שם משפחה", field: "lastName", width: "10%", sortable: true},
            {name: "אימייל", field: "email", width: "15%", sortable: true},
            {name: "אשכולות", field: "sectors", sortable: false},
            {name: "נראה לאחרונה", field: "activity", sortable: true}
        ]
    };

    const options: TableOptions = {
        label: "ניהול מגייס",
        items: [
            {label: "ערוך מגייס", icon: <Edit/>, command: row => setShowRecruiter(row.recruiter)},
            {label: "איפוס סיסמה", icon: <LockReset/>, command: row => resetPassword(row.recruiter)},
            {label: "מחק מגייס", icon: <Delete/>, command: row => removeRecruiter(row.recruiter)}
        ]
    };

    // const {recruiters, loadingRecruiters} = useRecruitersData();
    const {currentUser} = useAuth();
    const [recruiters, setRecruiters] = React.useState<any[]>([]);
    const [loading, setLoading] = React.useState(false);

    const mapRecruitersToData = (recruiters) => {
        const temp = recruiters.filter(rec => rec.id !== currentUser.uid);
        return temp.map((recruiter) => ({
            firstName: recruiter.firstName,
            lastName: recruiter.lastName,
            email: recruiter.email,
            sectors: {
                label: "", component: recruiter.sectors.map((sector, index) => (
                    <SectorChip
                        key={index}
                        id={sector}
                        sx={{margin: "2px"}}
                    />
                ))
            },
            activity: {
                label: "",
                component: <ActivityText activity={recruiter.isActive} timestamp={recruiter.lastActive}/>
            },
            recruiter: recruiter
        }))
    }

    React.useEffect(() => {
        setLoading(true);
        const unsubscribe = recruiterService.listenToRecruiterActivity(setRecruiters);
        setLoading(false);
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        }
    }, []);

    const data = React.useMemo(() => mapRecruitersToData(recruiters), [recruiters]);
    const [filteredData, setFilteredData] = React.useState([]);
    React.useEffect(() => {
        setFilteredData(data);
    }, [data]);

    const [searchParams] = useSearchParams();
    const paramEmail = searchParams.get('email');

    React.useEffect(() => {
        if (paramEmail) {
            const recruiter = recruiters.find(rec => rec.email.toString() === paramEmail);
            if (recruiter && recruiter.id !== currentUser.uid) {
                setShowRecruiter(recruiter);
            }
        }
    }, [paramEmail]);

    return <>
        <TitleLabel title="מגייסים" icon={SupervisorAccount}/>

        <CustomCard noPadding={true}>
            <Box display="flex" justifyContent="space-between" padding="1rem">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TableSearch data={data} setFilteredData={setFilteredData} columns={columnsData}/>
                    </Grid>

                    <Grid item xs={12} sm={6} display="flex" justifyContent="end">
                        <Button variant="outlined" sx={{width: {xs: "100%", sm: "fit-content"}}} color="primary"
                                onClick={() => setShowRecruiter(null)}>מגייס/ת
                            חדש/ה</Button>
                    </Grid>
                </Grid>
            </Box>

            <BetterTable columnsData={columnsData} data={filteredData} options={options} loading={loading}
                         paginator={{label: "מגייסים", rowsPerPage: [5, 10, 15]}}/>
        </CustomCard>

        <ModifyNewRecruiter recruiter={showRecruiter} show={showRecruiter !== undefined}
                            close={() => setShowRecruiter(undefined)}/>
    </>
}

export default function RecruitersPage() {
    const {loading} = useAuth();

    if (loading) {
        return <LoadingSpinner/>;
    }

    return <>
        <RecruitersDataProvider>
            <SectorsDataProvider>
                <RecruitersPageContent/>
            </SectorsDataProvider>
        </RecruitersDataProvider>
    </>
}
