import React from 'react';
import {Select, MenuItem} from '@mui/material';
import {ApplicationCandidatesStatuses} from "../../Utilities/Statuses";

const SelectStatus = ({value, onChange, allStatuses = false}) => {
    return (
        <Select value={value || ""} label="סטטוס" onChange={onChange}>
            {allStatuses && (
                <MenuItem value="כל הסטטוסים">
                    כל הסטטוסים
                </MenuItem>
            )}

            {ApplicationCandidatesStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                    {status}
                </MenuItem>
            ))}
        </Select>
    );
};

export default SelectStatus;
