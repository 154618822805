import {Paper, Stack, Typography} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import React from "react";

export default function PreviewBox() {
    return <>
        <Paper
            sx={{
                backgroundColor: 'rgba(255, 152, 0, 0.3)',
                padding: 1,
                textAlign: 'center',
                width: '100%',
            }}
            elevation={0}
        >
            <Stack flexDirection="row" gap={1} justifyContent="center" alignItems="center">
                <InfoIcon fontSize="large" sx={{color: 'warning.dark'}}/>
                <Typography variant="h6" color="warning.dark" sx={{margin: 0}}>
                    עמוד זה לתצוגה מקדימה בלבד
                </Typography>
            </Stack>
        </Paper>
    </>
}