import {IconButton, Tooltip, useTheme} from "@mui/material";
import React from "react";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import {useThemeContext} from "../../../Contexts/Theme";

export default function ThemeChange() {
    const {toggleTheme, isDark} = useThemeContext();
    const theme = useTheme();

    return (
        <Tooltip title="שינוי ערכת צבעים" arrow componentsProps={{
            tooltip: {
                sx: {
                    fontSize: '1rem',
                },
            },
        }}>
            <IconButton
                size="large"
                onClick={toggleTheme}
                sx={{
                    color: theme.palette.custom.navbar.iconColor,
                    '&:hover': {
                        color: theme.palette.primary.main,
                    }
                }}
            >
                {isDark ? <Brightness7Icon/> : <Brightness4Icon/>}
            </IconButton>
        </Tooltip>
    );
}
