import React, {useState} from 'react';
import {Box, Divider, List, ListItem, ListItemIcon, Popover, Typography} from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import {Search} from '../../../Styled/Search/Search';
import {SearchIconWrapper} from "../../../Styled/Search/SearchIconWrapper";
import {StyledInputBase} from "../../../Styled/Search/StyledInputBase";
import {Candidate, CandidateLocalStorage} from "../../../../Firebase/FirebaseFunctions/CandidateService";
import {Job, JobsLocalStorage} from "../../../../Firebase/FirebaseFunctions/JobsService";
import {Recruiter, RecruitersLocalStorage} from "../../../../Firebase/FirebaseFunctions/RecruiterService";
import {Sector, SectorsLocalStorage} from "../../../../Firebase/FirebaseFunctions/SectorService";
import {Role, RolesLocalStorage} from "../../../../Firebase/FirebaseFunctions/RoleService";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../../Contexts/AuthContext/AuthContext";
import {UserRole} from "../../../../Firebase/enums/UserRole";

import WorkIcon from '@mui/icons-material/Work';
import PersonIcon from '@mui/icons-material/Person';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ApartmentIcon from '@mui/icons-material/Apartment';
import EventSeatIcon from '@mui/icons-material/EventSeat';

const MINIMUM_LETTERS = 2;

export default function NavbarSearch() {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const {role} = useAuth();

    const [candidates, setCandidates] = useState<Candidate[]>([]);
    const [jobs, setJobs] = useState<Job[]>([]);
    const [recruiters, setRecruiters] = useState<Recruiter[]>([]);
    const [sectors, setSectors] = useState<Sector[]>([]);
    const [roles, setRoles] = useState<Role[]>([]);

    React.useEffect(() => {
        const loadData = () => {
            const storedCandidates = localStorage.getItem(CandidateLocalStorage);
            if (storedCandidates) {
                setCandidates(JSON.parse(storedCandidates));
            }

            const storedJobs = localStorage.getItem(JobsLocalStorage);
            if (storedJobs) {
                setJobs(JSON.parse(storedJobs));
            }

            const storedRecruiters = localStorage.getItem(RecruitersLocalStorage);
            if (storedRecruiters) {
                setRecruiters(JSON.parse(storedRecruiters));
            }

            const storedSectors = localStorage.getItem(SectorsLocalStorage);
            if (storedSectors) {
                setSectors(JSON.parse(storedSectors));
            }

            const storedRoles = localStorage.getItem(RolesLocalStorage);
            if (storedRoles) {
                setRoles(JSON.parse(storedRoles));
            }
        };

        loadData();

        // Listen for local storage changes
        const handleStorageChange = () => loadData();
        window.addEventListener('storage-changed', handleStorageChange);
        return () => window.removeEventListener('storage-changed', handleStorageChange);
    }, []);

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
        if (!anchorEl) {
            setAnchorEl(event.currentTarget.parentElement);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const highlightText = (text: string, searchTerm: string) => {
        if (!searchTerm || searchTerm.length < MINIMUM_LETTERS) return text;

        const regex = new RegExp(`(${searchTerm})`, 'gi');
        const parts = text.split(regex);

        return parts.map((part, index) =>
            regex.test(part) ?
                <span key={index} style={{backgroundColor: '#fff3b0'}}>{part}</span> :
                part
        );
    };

    const open = Boolean(anchorEl) && searchValue.length >= MINIMUM_LETTERS;
    const id = open ? 'search-popover' : undefined;

    const filteredJobs = jobs.filter(job =>
        job.open &&
        (job.jobNumber.toString().includes(searchValue) ||
            job.title.toLowerCase().includes(searchValue.toLowerCase()) ||
            job.region.toLowerCase().includes(searchValue.toLowerCase()))
    );

    const filteredCandidates = candidates.filter(candidate =>
        `${candidate.firstname.toLowerCase()} ${candidate.lastname.toLowerCase()}`.includes(searchValue.toLowerCase()) ||
        candidate.email.toLowerCase().includes(searchValue.toLowerCase()) ||
        candidate.id.toLowerCase().includes(searchValue.toLowerCase())
    );

    const filteredRecruiters = recruiters.filter(recruiter =>
        `${recruiter.firstName.toLowerCase()} ${recruiter.lastName.toLowerCase()}`.includes(searchValue.toLowerCase()) ||
        recruiter.email.toLowerCase().includes(searchValue.toLowerCase())
    );

    const filteredSectors = sectors.filter(sector => sector.name.toLowerCase().includes(searchValue.toLowerCase()));
    const filteredRoles = roles.filter(role => role.name.toLowerCase().includes(searchValue.toLowerCase()));

    const sections = [
        {
            title: 'משרות',
            icon: <WorkIcon/>,
            click: (index) => {
                const job = filteredJobs[index];
                navigate(`/management/jobs?jobNumber=${job.jobNumber}`);
            },
            items: searchValue.length >= MINIMUM_LETTERS ? filteredJobs.map((job, index) => (
                <Box key={index}>
                    <Typography variant="body1" sx={{fontWeight: 700}}>
                        {highlightText(job.title, searchValue)}
                    </Typography>
                    <Typography variant="body2">
                        {highlightText(job.region, searchValue)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {highlightText(job.jobNumber.toString(), searchValue)}
                    </Typography>
                </Box>
            )) : []
        },
        {
            title: 'מועמדים',
            icon: <PersonIcon/>,
            click: (index) => {
                const candidate = filteredCandidates[index];
                window.open(`/management/candidate/${candidate.id}`, "_self");
            },
            items: searchValue.length >= MINIMUM_LETTERS ? filteredCandidates.map((candidate, index) => (
                <Box key={index}>
                    <Typography variant="body1" sx={{fontWeight: 700}}>
                        {highlightText(`${candidate.firstname} ${candidate.lastname}`, searchValue)}
                    </Typography>
                    <Typography variant="body2">
                        {highlightText(candidate.email, searchValue)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {highlightText(candidate.id, searchValue)}
                    </Typography>
                </Box>
            )) : []
        },
        {
            title: 'מגייסים',
            icon: <SupervisorAccountIcon/>,
            click: (index) => {
                const recruiter = filteredRecruiters[index];
                navigate(`/management/recruiters?email=${recruiter.email}`);
            },
            items: searchValue.length >= MINIMUM_LETTERS && role === UserRole.admin ? filteredRecruiters.map((recruiter, index) => (
                <Box key={index}>
                    <Typography variant="body1" sx={{fontWeight: 700}}>
                        {highlightText(`${recruiter.firstName} ${recruiter.lastName}`, searchValue)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {highlightText(recruiter.email, searchValue)}
                    </Typography>
                </Box>
            )) : []
        },
        {
            title: 'אשכולות',
            icon: <ApartmentIcon/>,
            click: (index) => {
                const sector = filteredSectors[index];
                navigate(`/management/sectors?name=${sector.name}`);
            },
            items: searchValue.length >= MINIMUM_LETTERS && role === UserRole.admin ? filteredSectors.map((sector, index) => (
                <Box key={index}>
                    <Typography variant="body1" sx={{fontWeight: 700}}>
                        {highlightText(sector.name, searchValue)}
                    </Typography>
                </Box>
            )) : []
        },
        {
            title: 'תפקידים',
            icon: <EventSeatIcon/>,
            click: (index) => {
                const role = filteredRoles[index];
                navigate(`/management/roles?name=${role.name}`);
            },
            items: searchValue.length >= MINIMUM_LETTERS && role === UserRole.admin ? filteredRoles.map((role, index) => (
                <Box key={index}>
                    <Typography variant="body1" sx={{fontWeight: 700}}>
                        {highlightText(role.name, searchValue)}
                    </Typography>
                </Box>
            )) : []
        }
    ];

    const sectionsWithResults = searchValue.length >= MINIMUM_LETTERS ?
        sections.filter(section => section.items.length > 0) : [];
    const hasResults = sectionsWithResults.length > 0;

    return (
        <>
            <Search>
                <SearchIconWrapper>
                    <SearchIcon/>
                </SearchIconWrapper>
                <StyledInputBase
                    placeholder="חיפוש בפריטים שנטענו..."
                    inputProps={{'aria-label': 'search'}}
                    onChange={handleSearchChange}
                    value={searchValue}
                    autoComplete="off"
                />
            </Search>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                disableAutoFocus
                disableEnforceFocus
                sx={{
                    '& .MuiPopover-paper': {
                        width: 300,
                        maxHeight: 400,
                    }
                }}
            >
                <Box sx={{p: 2}}>
                    {!hasResults ? (
                        <Typography
                            variant="body1"
                            sx={{
                                color: 'text.secondary',
                                textAlign: 'center',
                                py: 2
                            }}
                        >
                            לא נמצאו תוצאות
                        </Typography>
                    ) : (
                        sectionsWithResults.map((section, index) => (
                            <React.Fragment key={section.title}>
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        fontWeight: 600,
                                        color: 'text.secondary',
                                        mb: 1
                                    }}
                                >
                                    {section.title}
                                </Typography>
                                <List dense>
                                    {section.items.map((item, itemIndex) => (
                                        <ListItem key={itemIndex} button onClick={() => {
                                            handleClose();
                                            if (section.click) {
                                                section.click(itemIndex);
                                            }
                                        }}>
                                            <ListItemIcon>
                                                {section.icon}
                                            </ListItemIcon>
                                            {item}
                                        </ListItem>
                                    ))}
                                </List>
                                {index < sectionsWithResults.length - 1 && <Divider sx={{mb: 2}}/>}
                            </React.Fragment>
                        ))
                    )}
                </Box>
            </Popover>
        </>
    );
}
