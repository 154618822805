import {DocumentData, QueryDocumentSnapshot} from "firebase/firestore";
import * as yup from "yup";
import {convertToDate} from "../../Management/Utilities/DateFormatter";

export const ApplicationsLocalStorage = "applications";

// Define a validation schema for Application using Yup
const applicationSchema = yup.object().shape({
    about: yup.string().optional(),  // about is not required
    arrivalWay: yup.string().required("Arrival way is required"),
    applyDate: yup.date().default(() => new Date()),  // Automatically set to current date
    candidateId: yup.string().optional(),
    interviewDate: yup.date().default(new Date(0)),  // Default interview date
    interviewsSummery: yup
        .array()
        .of(yup.string())
        .length(2, "Interviews summary must contain exactly 2 items")  // Ensure array length is 2
        .default(["", ""]),  // Default to an array of two empty strings
    jobNumber: yup.number().required("Job number is required"),
    sector: yup.string().required("sector is required"),
    lastUpdate: yup.date().default(() => new Date()),  // Set to current date
    matchingRate: yup.number().default(-1),
    rejectCause: yup.string().default(""),
    status: yup.string().default("הוגשה מועמדות")  // Default status
});

class Application {
    id: string;
    about: string;
    arrivalWay: string;
    applyDate?: Date;
    candidateId: string;
    interviewDate?: Date;
    interviewsSummery: string[];
    jobNumber: number;
    sector: string;
    lastUpdate?: Date;
    matchingRate: number;
    rejectCause: string;
    status: string;

    constructor(
        id = "",
        about = "",
        arrivalWay: string,
        applyDate = new Date(),
        candidateId: string,
        interviewDate = new Date(0),
        interviewsSummery = ["", ""],
        jobNumber = 0,
        sector: string,
        lastUpdate = new Date(),
        matchingRate = -1,
        rejectCause = "",
        status = "הוגשה מועמדות"
    ) {
        this.id = id;
        this.about = about;
        this.arrivalWay = arrivalWay;
        this.applyDate = applyDate;
        this.candidateId = candidateId;
        this.interviewDate = interviewDate;
        this.interviewsSummery = interviewsSummery;
        this.jobNumber = jobNumber;
        this.sector = sector;
        this.lastUpdate = lastUpdate;
        this.matchingRate = matchingRate;
        this.rejectCause = rejectCause;
        this.status = status;
    }
}

// Firestore data converter for Application
const applicationConverter = {
    toFirestore: (application: Application): DocumentData => {
        return {
            id: application.id,
            about: application.about,
            arrivalWay: application.arrivalWay,
            applyDate: application.applyDate,
            candidateId: application.candidateId,
            interviewDate: application.interviewDate,
            interviewsSummery: application.interviewsSummery,
            jobNumber: application.jobNumber,
            sector: application.sector,
            lastUpdate: application.lastUpdate,
            matchingRate: application.matchingRate,
            rejectCause: application.rejectCause,
            status: application.status
        };
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot, options: any): Application => {
        const data = snapshot.data(options);
        return new Application(
            snapshot.id,
            data.about,
            data.arrivalWay,
            convertToDate(data.applyDate),
            data.candidateId,
            convertToDate(data.interviewDate),
            data.interviewsSummery,
            data.jobNumber,
            data.sector,
            convertToDate(data.lastUpdate),
            data.matchingRate,
            data.rejectCause,
            data.status
        );
    }
};

export {Application, applicationConverter, applicationSchema};