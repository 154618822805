import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import {SearchIconWrapper} from "../../Styled/Search/SearchIconWrapper";
import {Search} from "../../Styled/Search/Search";
import {StyledInputBase} from "../../Styled/Search/StyledInputBase";
import {isLabeledCell, TableColumns, TableRow} from "./Table.types";

interface TableSearchProps {
    columns: TableColumns;
    data: TableRow[];
    setFilteredData: any;
}

export function TableSearch(props: TableSearchProps) {
    const [searchTerm, setSearchTerm] = React.useState('');

    const handleChange = (event) => {
        const newSearchTerm = event.target.value.toLowerCase();
        setSearchTerm(newSearchTerm);
        filterData(newSearchTerm);
    };

    const filterData = React.useCallback((term: string) => {
        const filteredData = props.data.filter(row =>
            props.columns.columns.some(column => {
                const value = row[column.field];
                if (isLabeledCell(value)) {
                    return value.label.toString().toLowerCase().includes(term);
                } else if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
                    return value.toString().toLowerCase().includes(term);
                }
                return false;
            })
        );
        props.setFilteredData(filteredData);
    }, [props.data, props.columns]);

    React.useEffect(() => {
        filterData(searchTerm);
    }, [props.data]);

    return (
        <Search>
            <SearchIconWrapper>
                <SearchIcon/>
            </SearchIconWrapper>
            <StyledInputBase
                placeholder="חיפוש..."
                inputProps={{'aria-label': 'search'}}
                sx={{width: "100%"}}
                onChange={handleChange}
                value={searchTerm}
            />
        </Search>
    );
}
