import * as yup from "yup";
import {
    arrayUnion,
    collection,
    deleteDoc,
    doc,
    DocumentData,
    Firestore,
    getDoc,
    getDocs,
    QueryDocumentSnapshot
} from "firebase/firestore";
import {Application, applicationConverter, applicationSchema} from "./ApplicationService";
import {
    CRUDType,
    customDeleteDoc,
    customHttpsCall,
    CustomHttpsCallInter,
    customUpdateDoc
} from "./Helpers/CustomFirebaseFunctions";
import {auth, db, functions, storage} from "../FirebaseConfig/firebase";
import {deleteObject, ref, StorageReference, uploadBytes} from "firebase/storage";
import {getFunctions, httpsCallable} from "firebase/functions";
import {convertToDate} from "../../Management/Utilities/DateFormatter";

export const CandidateLocalStorage = "candidates";

// Define the expected structure of the Cloud Function response
interface SaveCandidateResponse {
    message: string;
}

// Candidate class
class Candidate {
    id: string; // phone
    firstname: string;
    lastname: string;
    email: string;
    generalrating?: number;
    note?: string;
    lastModified?: Date;
    sectors?: string[];

    constructor(id: string, firstname: string, lastname: string, email: string, generalrating = -1, note = "", lastModified: Date) {
        this.id = id;
        this.firstname = firstname;
        this.lastname = lastname;
        this.email = email;
        this.generalrating = generalrating || -1;
        this.note = note || "";
        this.lastModified = lastModified || new Date();
        this.sectors = [];
    }

    toString(): string {
        return `${this.firstname} ${this.lastname} (${this.email}) - Rating: ${this.generalrating}`;
    }
}

// Firestore data converter for Candidate
const candidateConverter = {
    toFirestore: (candidate: Candidate): DocumentData => {
        return {
            id: candidate.id,
            firstname: candidate.firstname,
            lastname: candidate.lastname,
            email: candidate.email,
            generalrating: candidate.generalrating,
            note: candidate.note,
            lastModified: candidate?.lastModified!,
            sectors: candidate?.sectors!
        };
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot, options: any): Candidate => {
        const data = snapshot.data(options);
        return new Candidate(data.id, data.firstname, data.lastname, data.email, data.generalrating, data.note, convertToDate(data.lastModified)!);
    }
};

// Define a validation schema using Yup
export const candidateSchema = yup.object().shape({
    id: yup
        .string()
        .matches(/^[0-9]+$/, "Phone number must contain digits only")
        .required("Phone number is required"),
    firstname: yup
        .string()
        .min(2, "First name must be at least 2 characters long")
        .required("First name is required"),
    lastname: yup
        .string()
        .min(2, "Last name must be at least 2 characters long")
        .required("Last name is required"),
    email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required"),
    generalrating: yup.number().default(-1),  // General rating, initialized to -1
    note: yup.string().default(""),  // Notes, initialized to an empty string
    lastModified: yup.date().optional(),
});

class CandidateService {
    private db: Firestore;
    private collectionPath: string;

    constructor(db: Firestore, collectionPath: string) {
        this.db = db;
        this.collectionPath = collectionPath;
    }

    // Fetches candidate data from Firestore by ID
    async getCandidate(id: string): Promise<Candidate | undefined> {
        try {
            const docRef = doc(this.db, this.collectionPath, id.toString()).withConverter(candidateConverter);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                return docSnap.data();
            } else {
                // console.log("No such document!");
                return undefined;
            }
        } catch (error) {
            console.error("Error getting document:", error);
            throw new Error("An error occurred while retrieving the document.");
        }
    }

    // Add candidate data in Firestore with validation
    //     // Example candidate data
    //     const candidateId = "12345263337890";
    //     const candidateData = {
    //         id: candidateId,
    //         firstname: "MA",
    //         lastname: "omer",
    //         email: "johndoe@example.com"
    //     };
    // await candidateService.saveCandidate(candidateData);
    async saveCandidate(data: Record<string, any>): Promise<void> {
        try {
            // Validate data against the schema
            const validatedData = await candidateSchema.validate(data, {abortEarly: false});

            // Call the Cloud Function
            // const saveCandidateFunc = httpsCallable<Record<string, any>, SaveCandidateResponse>(functions, "saveCandidate");
            // const response = await saveCandidateFunc(validatedData);

            const obj: CustomHttpsCallInter = {
                functionName: "saveCandidate",
                httpsData: validatedData,
                type: CRUDType.Create,
                localStorageKey: CandidateLocalStorage,
                storageData: validatedData
            };
            const response = await customHttpsCall(obj);

            // Log the success message
            // @ts-ignore
            console.log(response.data.message);
        } catch (error) {
            if (error instanceof yup.ValidationError) {
                // Log validation errors
                console.error("Validation error(s):", error.errors);
                throw new Error("Validation error: " + error.errors.join(", "));
            } else if (error instanceof Error) {
                // Log general errors
                console.error("Error saving candidate:", error.message);
                throw new Error("An error occurred while saving the candidate.");
            } else {
                console.error("Unknown error:", error);
                throw new Error("An unknown error occurred.");
            }
        }
    }

    // Updates multiple fields of a candidate by ID

    // Update multiple fields
    // ### EXAMPLE ###
    // await candidateService.updateCandidateFields("1234567890", {
    //   generalrating: 5,
    //   note: "Excellent candidate, strong technical skills."
    // });
    async updateCandidateFields(id: string, updatedFields: Partial<Candidate>): Promise<void> {
        try {
            if (await this.getCandidate(id)) {
                const docRef = doc(this.db, this.collectionPath, id.toString());
                await customUpdateDoc(docRef, updatedFields, CandidateLocalStorage);
                console.log("Fields updated successfully!");
            } else {
                console.error("the candidate not found");
            }

        } catch (error) {
            console.error("Error updating fields:", error);
            throw new Error("An error occurred while updating the fields.");
        }
    }

    // Method to delete a candidate and their sub-collection of applications
    async deleteCandidate(id: string): Promise<void> {
        try {
            const candidateRef = doc(this.db, this.collectionPath, id.toString());

            // Delete sub-collection "applications" first
            const applicationsRef = collection(this.db, this.collectionPath, id, "applications");
            const querySnapshot = await getDocs(applicationsRef);

            // Loop through each document in "applications" and delete it
            for (const appDoc of querySnapshot.docs) {
                await deleteDoc(appDoc.ref);
            }

            
            // Delete the candidate document after sub-collection is cleared
            await customDeleteDoc(candidateRef, CandidateLocalStorage);
            console.log("Candidate and associated applications deleted successfully!");

            await this.deleteCandidateCV(id);
        } catch (error) {
            console.error("Error deleting candidate:", error);
            throw error;
        }
    }

    async updateApplication(candidateId: string, applicationId: string, updatedFields: Partial<Application>): Promise<void> {
        try {
            // Get reference to the application document
            const applicationRef = doc(
                this.db,
                this.collectionPath,
                candidateId.toString(),
                "applications",
                applicationId
            );
            // .withConverter(applicationConverter);

            // Validate the updated fields against the schema
            const validatedData = await applicationSchema.validate(
                updatedFields,
                {abortEarly: false, stripUnknown: true}
            );

            // Update the document
            await customUpdateDoc(applicationRef, validatedData, CandidateLocalStorage);

            // If sector is being updated, update the candidate's sectors array
            if (validatedData.sector) {
                const candidateRef = doc(this.db, this.collectionPath, candidateId.toString());
                await customUpdateDoc(candidateRef, {
                    sectors: arrayUnion(validatedData.sector)
                }, CandidateLocalStorage);
            }

            console.log("Application updated successfully!");

        } catch (error) {
            if (error instanceof yup.ValidationError) {
                console.error("Validation error(s):", error.errors);
                throw new Error("Validation error: " + error.errors.join(", "));
            } else {
                console.error("Error updating application:", error);
                throw new Error("An error occurred while updating the application.");
            }
        }
    }

    async addApplication(data: Record<string, any>): Promise<void> {
        try {
            // Validate data against the schema
            const validatedData = await applicationSchema.validate(data, {abortEarly: false});

            // Call the Cloud Function
            const addApplicationFunc = httpsCallable<Record<string, any>, SaveCandidateResponse>(functions, "addApplication");
            const response = await addApplicationFunc(validatedData);

            // Log the success message
            console.log(response.data.message);
        } catch (error) {
            if (error instanceof yup.ValidationError) {
                // Log validation errors
                console.error("Validation error(s):", error.errors);
                throw new Error("Validation error: " + error.errors.join(", "));
            } else if (error instanceof Error) {
                // Log general errors
                console.error("Error adding application:", error.message);
                throw new Error("An error occurred while adding the application.");
            } else {
                console.error("Unknown error:", error);
                throw new Error("An unknown error occurred.");
            }
        }
    }


    async uploadCandidateCV(candidateId: string, file: File): Promise<string> {
        try {
            const tokenResult = await auth?.currentUser?.getIdTokenResult();
            console.log(tokenResult?.claims);
            // Define the file path
            const filePath = `CandidatesFiles/${candidateId.toString()}/CV.pdf`;

            // Create a reference to the storage location
            const fileRef: StorageReference = ref(storage, filePath);

            const metadata = {
                contentType: "application/pdf",
            };

            // Upload the file
            const snapshot = await uploadBytes(fileRef, file, metadata);

            console.log("File uploaded successfully!", snapshot);

            // Return the file path or download URL if needed
            return filePath;
        } catch (error) {
            console.error("Error uploading file:", error);
            throw new Error("An error occurred while uploading the file.");
        }
    }

    private async deleteCandidateCV(candidateId: string): Promise<void> {
        try {
            const filePath = `CandidatesFiles/${candidateId}/CV.pdf`;

            const fileRef = ref(storage, filePath);

            await deleteObject(fileRef);

            console.log("CV deleted successfully!");
        } catch (error) {
            console.error("Error deleting CV:", error);
            throw new Error("An error occurred while deleting the CV.");
        }
    }

    // async getCandidateCV(candidateId: string): Promise<string> {
    //     try {
    //         const filePath = `CandidatesFiles/${candidateId}/CV.pdf`;
    //         const fileRef = ref(storage, filePath);
    //         const downloadURL = await getDownloadURL(fileRef);
    //         return downloadURL;
    //     } catch (error: any) {
    //         console.error("Error getting CV URL:", error);
    //         throw new Error("An error occurred while getting the CV URL.");
    //     }
    // }

    async getCandidateCV(candidateId: string): Promise<void> {
        try {
            const bucketName = process.env.REACT_APP_STORAGE_BUCKET;
            const filePath = `CandidatesFiles/${candidateId}/CV.pdf`;
            const encodedFilePath = encodeURIComponent(filePath);
            const url = `https://firebasestorage.googleapis.com/v0/b/${bucketName}/o/${encodedFilePath}?alt=media`;

            const token = await auth.currentUser?.getIdToken();

            // send request file with Authorization Header
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const blob = await response.blob();
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL, "_blank");

        } catch (error) {
            console.error("Error fetching the file:", error);
            throw new Error("An error occurred while fetching the CV.");
        }
    }

    async getApplications(candidateId: string): Promise<Application[]> {
        try {
            const applicationsCollectionRef = collection(
                db,
                `candidates/${candidateId.toString()}/applications`
            ).withConverter(applicationConverter);

            const snapshot = await getDocs(applicationsCollectionRef);

            const applications = snapshot.docs.map((doc) => doc.data());
            return applications;
        } catch (error) {
            console.error("Error fetching applications:", error);
            throw new Error(`Failed to fetch applications for candidate ID ${candidateId}: ${error instanceof Error ? error.message : String(error)}`);
        }
    }

}

export const setCandidateCustomClaims = async () => {
    const functions = getFunctions();
    const callable = httpsCallable(functions, "setCandidateCustomClaims");

    try {
        const result = await callable();
        console.log("Function response:", result.data);
    } catch (error: any) {
        console.error("Error calling function:", error.message);
    }
};

// Initialize Candidate service for the "candidates" collection
export const candidateService = new CandidateService(db, "candidates");

export {Candidate, candidateConverter};
export default CandidateService;