import {Box, FormHelperText, Stack} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {Description} from "@mui/icons-material";
import React from "react";
import dayjs from "dayjs";

export default function CandidateCVButton({candidate}) {
    const onClick = (event) => {
        event.stopPropagation();
        window.open(`/management/candidate/${candidate.id}/resume`, '_blank');
    }

    return <>
        <Stack direction="column" gap={0.5}>
            <Box sx={{display: "flex", justifyContent: "center"}}>
                <LoadingButton
                    size='small'
                    variant="contained"
                    color="info"
                    startIcon={<Description/>}
                    onClick={onClick}
                >
                    קו"ח
                </LoadingButton>
            </Box>

            {candidate?.lastModified && <FormHelperText sx={{textAlign: 'center'}} security="invalid"
                                                        style={{marginRight: 0, marginTop: 0, fontSize: 11}}>
                הועלה בתאריך: {dayjs(candidate.lastModified).format("DD/MM/YY")}
            </FormHelperText>}
        </Stack>
    </>
}
