import {Button, Stack, Typography} from "@mui/material";
import React, {ReactNode, useContext} from "react";
import SimpleDialog, {SimpleDialogFooter} from "../Components/Dialog/SimpleDialog";

interface DialogContextType {
    showDialog: (dialog: Dialog) => void;
}

const DialogContext = React.createContext<DialogContextType | undefined>(undefined);

export interface Dialog {
    title: string;
    description: string;
    onAccept: () => void;
    onCancel?: () => void;
    acceptColorAsGreen?: boolean;
}

export const useDialog = () => {
    const context = useContext(DialogContext);
    if (!context) {
        throw new Error('useDialog must be used within a DialogProvider');
    }
    return context;
}

export const DialogProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [dialog, setDialog] = React.useState<Dialog | null>(null);
    const showDialog = (value: Dialog) => setDialog(value);
    const handleClose = () => setDialog(null);
    const acceptClicked = () => {
        handleClose();
        dialog?.onAccept();
    }

    const cancelClicked = () => {
        handleClose();
        dialog?.onCancel?.();
    }

    return (
        <DialogContext.Provider value={{showDialog}}>
            {children}

            <SimpleDialog title={dialog?.title} close={cancelClicked} show={dialog !== null}>
                <Typography variant="body1" fontFamily="Rubik">
                    {dialog?.description}
                </Typography>

                <SimpleDialogFooter>
                    <Stack width="100%" flexDirection="row" justifyContent="space-between">
                        <Button color={dialog?.acceptColorAsGreen ? "success" : "error"} onClick={acceptClicked}
                                variant="contained">אשר/י</Button>
                        <Button onClick={cancelClicked}>בטל/י</Button>
                    </Stack>
                </SimpleDialogFooter>
            </SimpleDialog>
        </DialogContext.Provider>
    );
}

