import React from "react";

export interface TableColumn {
    name: string;
    field: string;
    sortable?: boolean;
    width?: string;
}

export interface TableExpandRow {
    expandedRows: Set<number>;
    setExpandedRows: React.Dispatch<React.SetStateAction<Set<number>>>;
    onRowExpansion: (row: any) => React.ReactNode;
}

export interface TableColumns {
    columns: TableColumn[];
    onRowClick?: (row: any) => void;
    onExpandedRows?: TableExpandRow;
}

export type TableCellType = string | number | boolean | Date;
export type TableCellComponent = React.ReactNode;
export type TableCellTypeObj = { label: TableCellType, component?: TableCellComponent };

export interface TableRow {
    [key: string]: TableCellTypeObj | TableCellType;
}

export function isLabeledCell(value: any): value is { label: TableCellType; component?: TableCellComponent } {
    return value && typeof value === 'object' && 'label' in value;
}

export interface TableOptionsItem {
    label: string;
    icon?: React.ReactNode;
    command: (row: any) => void;
    hidden?: boolean;
}

export interface TableOptions {
    label: string;
    items: TableOptionsItem[];
}

export interface TablePaginator {
    label: string;
    rowsPerPage: number | number[];
}

export type TableDensity = "small" | "medium";
export type TableTextAlignment = "start" | "center" | "end";
