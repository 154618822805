import React from "react";
import FullScreenDialog, {FullScreenDialogHeader} from "../../../Components/Dialog/FullScreenDialog/FullScreenDialog";
import CreateJobHeader from "./Components/CreateJobHeader";
import CreateJobForm from "./Components/CreateJobForm";

export default function CreateJob({show, close, job}) {
    return (
        <>
            <FullScreenDialog show={show} close={close} centerContent={true}>
                <FullScreenDialogHeader>
                    <CreateJobHeader job={job}/>
                </FullScreenDialogHeader>

                <CreateJobForm job={job} close={close}/>
            </FullScreenDialog>
        </>
    );
}
