import React, {useCallback, useState} from 'react';
import {Box, IconButton, InputAdornment, TextField, Tooltip, Typography} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

interface EditableLabelProps {
    initialText: string;
    tooltipText: string;
    setOnSave: (value: string) => Promise<boolean>;
}

export const EditableLabel: React.FC<EditableLabelProps> = ({initialText, tooltipText, setOnSave}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [text, setText] = useState<string>(initialText);
    const [tempText, setTempText] = useState<string>(initialText);
    const [isSaving, setIsSaving] = useState(false);

    React.useEffect(() => {
        setText(initialText);
        setTempText(initialText);
    }, [initialText]);

    const handleDoubleClick = () => {
        setIsEditing(true);
        setTempText(text);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTempText(event.target.value);
    };

    const handleSave = useCallback(async (event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLInputElement>) => {
        event.preventDefault();
        setIsSaving(true);

        try {
            const success = await setOnSave(tempText);
            if (success) {
                setText(tempText);
            }
        } finally {
            setIsEditing(false);
            setIsSaving(false);
        }
    }, [tempText, setOnSave]);

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (!event.relatedTarget || event.relatedTarget.tagName !== 'BUTTON') {
            setIsEditing(false);
            setTempText(text);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSave(event as unknown as React.MouseEvent<HTMLButtonElement>);
        }
    };

    if (isEditing) {
        return (
            <Box display="flex" alignItems="center" width="100%">
                <TextField
                    value={tempText}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                    fullWidth
                    variant="standard"
                    onKeyDown={handleKeyDown}
                    InputProps={{
                        sx: {fontSize: 'inherit'},
                        endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip title="שמור שינויים">
                                    <span>
                                        <IconButton
                                            onMouseDown={(e) => e.preventDefault()}
                                            onClick={handleSave}
                                            size="small"
                                            disabled={isSaving}
                                        >
                                            <SaveIcon/>
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </InputAdornment>
                        )
                    }}
                />
            </Box>
        );
    }

    return (
        <Tooltip title={tooltipText}>
            <Typography onDoubleClick={handleDoubleClick}>
                {text}
            </Typography>
        </Tooltip>
    );
};

export default EditableLabel;