import {Box, Stack, Typography} from "@mui/material";
import {Person2} from "@mui/icons-material";
import React from "react";


export default function ModifyNewRecruiterHeader({recruiter}) {

    return <>
        <Box display="flex" justifyContent="center" marginBottom="20px">
            <Stack direction="column">
                <Stack direction="row" justifyContent="center" spacing={1}>
                    <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        <Person2 sx={{color: "#fff"}}/>
                    </Box>
                    <Typography
                        sx={{
                            fontFamily: "'Noto Sans Hebrew', sans-serif",
                            color: "#fff",
                            textAlign: "center",
                        }}
                        variant="h4"
                    >
                        {recruiter ? 'ערוך פרופיל מגייס/ת' : 'יצירת מגייס/ת חדש/ה'}
                    </Typography>
                </Stack>

                <Typography
                    sx={{
                        opacity: 0.6,
                        width: "100%",
                        textAlign: "center",
                        color: "#fff",
                        fontSize: "16px",
                        fontFamily: "'Noto Sans Hebrew', sans-serif",
                        mt: 1,
                    }}
                    variant="subtitle1"
                >
                    {recruiter ? 'לתשומת ליבך: עדכון השינויים יוביל לאובדן הנתונים הקודמים לצמיתות' : 'לתשומת ליבך: פעולה זו תיצור מגייס/ת חדש/ה.'}

                </Typography>

                <Box
                    sx={{
                        background: "linear-gradient(90deg,hsla(0,0%,100%,0),#fff,hsla(0,0%,100%,0))",
                        padding: 0.05,
                        width: "100%",
                        mt: 2,
                    }}
                />

                {recruiter &&
                    <Typography
                        sx={{
                            fontFamily: "'Noto Sans Hebrew', sans-serif",
                            color: "#fff",
                            textAlign: "center",
                        }}
                        variant="h3"
                    >
                        {recruiter.firstName} {recruiter.lastName}
                    </Typography>
                }
            </Stack>
        </Box>
    </>
}
