import React from 'react';
import {Select, MenuItem} from '@mui/material';

const SelectChangedStatus = ({value, onChange}) => {
    const TimeOnStatuses = [
        "בשבוע האחרון",
        "בחודש האחרון",
        "כל המועמדים"
    ];

    return (
        <Select value={value} label="מועמדים שנערכו לאחרונה" onChange={onChange}>
            {TimeOnStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                    {status}
                </MenuItem>
            ))}
        </Select>
    );
};

export default SelectChangedStatus;
