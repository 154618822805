export const containerStyles = {
    padding: 2,
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
};

export const stackStyles = {
    direction: 'column',
    color: 'primary.myBoxShadow',
};

export const logoBoxStyles = {
    display: 'flex',
    justifyContent: 'center',
    paddingRight: 2,
    paddingBottom: 1
};

export const logoImageStyles = {
    height: 120,
    pointerEvents: 'none' as React.CSSProperties['pointerEvents'],
    WebkitUserSelect: 'none' as React.CSSProperties['WebkitUserSelect'],
    MozUserSelect: 'none' as React.CSSProperties['MozUserSelect'],
    msUserSelect: 'none' as React.CSSProperties['msUserSelect'],
    userSelect: 'none' as React.CSSProperties['userSelect'],
    WebkitTouchCallout: 'none' as any,
    WebkitUserDrag: 'none' as any,
};

export const subtitleTypographyStyles = {
    color: 'secondary.drushimTitle',
    textAlign: 'center',
    fontWeight: 'bold',
};