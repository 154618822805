import {useNavigate, useSearchParams} from 'react-router-dom';
import React from "react";
import PreviewBox from "../../../Components/PreviewBox";
import {Job} from "../../../../Firebase/FirebaseFunctions/JobsService";
import JobInformation from "../../../../DrushimMainPage/Pages/JobPage/Components/JobInfo/JobInfo";
import Background from "../../../../DrushimMainPage/Pages/JobPage/Components/Background";
import {page} from "../../../../DrushimMainPage/Pages/JobPage/JobPageStyles";
import {Box} from "@mui/system";

export default function JobPreviewPage() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const parseJobData = () => {
        try {
            const parsed = JSON.parse(searchParams.get('data') || '{}');
            if (typeof parsed !== 'object' || parsed === null) {
                return null;
            }
            return parsed;
        } catch (error) {
            return null;
        }
    };

    const jobData = parseJobData();

    React.useEffect(() => {
        if (!jobData) {
            navigate("/career/not-found");
            return;
        }

        const requiredFields = ['area', 'title', 'role', 'sector', 'start'];
        const missingFields = requiredFields.filter(field => !jobData[field]);

        if (missingFields.length > 0) {
            navigate("/career/not-found");
        }
    }, [jobData, navigate]);

    if (!jobData) {
        return null;
    }

    const job: Job = {
        jobNumber: -1,
        creationDate: new Date(Date.now()),
        open: jobData.open || false,
        region: jobData.area,
        description: jobData.description || "לא צויין",
        title: jobData.title,
        role: jobData.role,
        sector: jobData.sector,
        startOn: jobData.start,
        highPriority: jobData.hot || false,
        requirements: jobData.requirements || "לא צויין",
        scope: jobData.workTime || [0, 100]
    }

    return <>
        <PreviewBox/>
        <Box sx={page()}>
            <Background/>
            <JobInformation job={job} jobApplicationElement={undefined}/>
        </Box>
    </>
}
