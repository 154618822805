import React, {useState} from "react";
import {AuthCard} from "./Components/Card";
import {
    AlertTitle,
    Box,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useSnackbar} from "../../../Utilities/Context/Snackbar";
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {useNavigate} from "react-router-dom";
import {LoadingButton} from "@mui/lab";
import {confirmPasswordReset, signInWithEmailAndPassword, verifyPasswordResetCode} from "firebase/auth";
import {auth} from "../../../Firebase/FirebaseConfig/firebase";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import PasswordStrengthIndicator from "../../Components/PasswordStrengthIndicator";
import Alert from "@mui/material/Alert";

export function ResetPasswordPage() {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [strongPassword, setStrongPassword] = useState(false);
    const [actionCode, setActionCode] = useState<string>();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    const {showSnackbar} = useSnackbar();

    React.useEffect(() => {
        const verifyCode = async () => {
            try {
                const actionCode = new URLSearchParams(window.location.search).get('oobCode');

                if (!actionCode) {
                    throw new Error('Missing reset code');
                }

                const email = await verifyPasswordResetCode(auth, actionCode);
                setEmail(email);
                setActionCode(actionCode);
            } catch (error) {
                setActionCode(undefined);
            } finally {
                setLoading(false);
            }
        };

        verifyCode();
    }, []);

    const validationSchema = Yup.object({
        password: Yup.string()
            .required('יש להזין סיסמה'),
        confirmPassword: Yup.string()
            .required('יש לאשר את הסיסמה')
            .oneOf([Yup.ref('password')], 'הסיסמאות אינן תואמות')
    });

    const handleSubmit = async (values, {setSubmitting, setTouched}) => {
        if (!strongPassword) {
            showSnackbar({message: "הסיסמה חייבת להיות לפחות 6 תווים, אותיות גדולות, סימנים מיוחדים ומספרים."});
            return;
        }

        setSubmitting(true);
        setTouched({
            password: true,
            confirmPassword: true
        });

        try {
            await confirmPasswordReset(auth, actionCode!, values.password);
            if (email) {
                await signInWithEmailAndPassword(auth, email, values.password);
                navigate("/management");
            } else {
                navigate("/auth/login");
            }

            showSnackbar({message: 'הסיסמה אופסה בהצלחה', severity: "success"});
        } catch (error) {
            showSnackbar({message: 'איפוס הסיסמה נכשל. אנא נסה שוב עם קישור חדש.'});
        } finally {
            setSubmitting(false);
        }
    };

    if (loading) {
        return <LoadingSpinner/>
    }

    return (
        <AuthCard title={"איפוס סיסמה"}>
            {actionCode === undefined ?
                (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        gap={1}
                    >
                        <SentimentVeryDissatisfiedIcon
                            sx={{
                                fontSize: 84,
                                color: 'red'
                            }}
                        />
                        <Typography
                            variant="h6"
                            color="red"
                            textAlign="center"
                        >
                            הקישור פג תוקף. אנא בקשו קישור חדש.
                        </Typography>
                    </Box>
                )
                : (
                    <Formik
                        initialValues={{password: '', confirmPassword: ''}}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({
                              values,
                              handleChange,
                              handleBlur,
                              isSubmitting,
                              touched,
                              errors
                          }) => (
                            <Form style={{width: '100%'}}>
                                <FormControl fullWidth margin="normal">
                                    <TextField
                                        label="סיסמה"
                                        type={showPassword ? 'text' : 'password'}
                                        variant="outlined"
                                        name="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!touched.password && !!errors.password}
                                        InputLabelProps={{shrink: true}}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={() => setShowPassword(!showPassword)}
                                                                edge="end">
                                                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <FormHelperText error>
                                        {touched.password && errors.password ? errors.password : ''}
                                    </FormHelperText>
                                </FormControl>

                                <FormControl fullWidth margin="normal">
                                    <TextField
                                        label="אישור סיסמה"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        variant="outlined"
                                        name="confirmPassword"
                                        value={values.confirmPassword}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!touched.confirmPassword && !!errors.confirmPassword}
                                        InputLabelProps={{shrink: true}}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                        edge="end">
                                                        {showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <FormHelperText error>
                                        {touched.confirmPassword && errors.confirmPassword ? errors.confirmPassword : ''}
                                    </FormHelperText>
                                </FormControl>

                                <PasswordStrengthIndicator password={values.password}
                                                           setStrongPassword={setStrongPassword}/>

                                <LoadingButton
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        py: 1.5,
                                    }}
                                >
                                    אפס/י סיסמה
                                </LoadingButton>
                            </Form>
                        )}
                    </Formik>
                )
            }

            {actionCode &&
                <Alert severity="warning">
                    <AlertTitle sx={{fontWeight: "bold", color: "#856404"}}>שימו לב:</AlertTitle>
                    הסיסמה חייבת להיות לפחות 6 תווים, אותיות גדולות, סימנים מיוחדים ומספרים.
                </Alert>
            }
        </AuthCard>
    );
}
