import XLSX from 'xlsx-js-style';

export const exportToExcel = (
    filename: string,
    sheetTitle: string,
    title: string,
    columns: any[],
    data: any[]
): void => {
    const workbook = XLSX.utils.book_new();
    if (!workbook.Workbook) workbook.Workbook = {};
    if (!workbook.Workbook.Views) workbook.Workbook.Views = [];
    workbook.Workbook.Views[0] = {RTL: true};

    const worksheet = XLSX.utils.aoa_to_sheet([
        [title],
        columns.map(col => col.header),
        ...data.map(row =>
            columns.map(col => row[col.field])
        )
    ]);

    worksheet['!merges'] = [{
        s: {r: 0, c: 0},
        e: {r: 0, c: columns.length - 1}
    }];


    // Calculate maximum width for each column based on content
    const maxWidths = Array(columns.length).fill(15);
    const range = XLSX.utils.decode_range(worksheet['!ref'] || 'A1:A1');

    for (let c = 0; c <= range.e.c; c++) {
        for (let r = 0; r <= range.e.r; r++) {
            const cellRef = XLSX.utils.encode_cell({r, c});
            const cell = worksheet[cellRef];
            if (cell && cell.v) {
                const cellLength = String(cell.v).length;
                maxWidths[c] = Math.max(maxWidths[c],
                    Math.min(cellLength * 1.2, 50));
            }
        }
    }

    worksheet['!cols'] = maxWidths.map(width => ({wch: width}));

    // Add autoFilter
    worksheet['!autofilter'] = {
        ref: XLSX.utils.encode_range(
            {r: 1, c: 0},
            {r: range.e.r, c: range.e.c}
        )
    };

    // Apply styles to each cell in the first row
    for (let i = 0; i < columns.length; i++) {
        const cellRef = XLSX.utils.encode_cell({r: 0, c: i});
        worksheet[cellRef] = {
            v: i === 0 ? title : '',
            t: 's',
            s: {
                font: {
                    sz: 16,
                    bold: true,
                    name: 'Arial',
                    color: {rgb: '2B7485'}
                },
                alignment: {
                    horizontal: 'center',
                    vertical: 'center',
                    wrapText: true
                },
                fill: {
                    patternType: 'solid',
                    fgColor: {rgb: 'DAEEF3'}
                }
            }
        };
    }

    // Apply styles to each cell in the second row
    for (let i = 0; i < columns.length; i++) {
        const cellRef = XLSX.utils.encode_cell({r: 1, c: i});
        const originalCell = worksheet[cellRef] || {};
        worksheet[cellRef] = {
            v: originalCell.v || columns[i].header,
            t: 's',
            s: {
                font: {
                    sz: 12,
                    bold: true,
                    name: 'Arial',
                    color: {rgb: '2B7485'}
                },
                alignment: {
                    horizontal: 'center',
                    vertical: 'center',
                    wrapText: true
                },
                fill: {
                    patternType: 'solid',
                    fgColor: {rgb: 'DAEEF3'}
                },
                border: {
                    bottom: {
                        style: 'medium',
                        color: {rgb: '2B7485'}
                    }
                }
            }
        };
    }

    // Apply wrap text and all borders to data cells (from third row)
    for (let r = 2; r <= range.e.r; r++) {
        const rowData = data[r - 2];
        const bgColor = rowData?.RowColor || "FFFDF3";

        for (let c = 0; c <= range.e.c; c++) {
            const cellRef = XLSX.utils.encode_cell({r, c});
            const originalCell = worksheet[cellRef] || {};
            worksheet[cellRef] = {
                v: originalCell.v,
                t: originalCell.t || 's',
                s: {
                    font: {
                        name: 'Arial',
                        color: {rgb: "5C3200"},
                    },
                    alignment: {
                        wrapText: true,
                        vertical: 'center'
                    },
                    border: {
                        top: {style: 'thin', color: {rgb: '000000'}},
                        bottom: {style: 'thin', color: {rgb: '000000'}},
                        left: {style: 'thin', color: {rgb: '000000'}},
                        right: {style: 'thin', color: {rgb: '000000'}}
                    },
                    fill: {
                        fgColor: {rgb: bgColor},
                        patternType: 'solid',
                    }
                }
            };
        }
    }

    worksheet['!rows'] = [{hpt: 45}, {hpt: 30}];

    XLSX.utils.book_append_sheet(workbook, worksheet, sheetTitle);
    XLSX.writeFile(workbook, `${filename}.xlsx`);
};
