import * as yup from "yup";
import {
    collection,
    doc,
    DocumentData,
    Firestore,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    Query,
    QueryDocumentSnapshot,
    where,
} from "firebase/firestore";
import {customDeleteDoc, customSetDoc, customUpdateDoc} from "./Helpers/CustomFirebaseFunctions";
import {db} from "../FirebaseConfig/firebase";
import {convertToDate} from "../../Management/Utilities/DateFormatter";

export const JobsLocalStorage = "jobs";

// Job class definition
class Job {
    jobNumber: number;
    title: string;
    role: string;
    scope: number[];
    region: string;
    sector: string;
    description: string;
    requirements: string;
    open: boolean;
    highPriority: boolean;
    creationDate: Date;
    startOn: string;

    constructor(
        jobNumber: number,
        title: string,
        role: string,
        scope: number[],
        region: string,
        sector: string,
        description: string,
        requirements: string,
        open = true,
        highPriority = false,
        creationDate = new Date(),
        startOn = "מיידי",
    ) {
        this.jobNumber = jobNumber;
        this.title = title;
        this.role = role;
        this.scope = scope;
        this.region = region;
        this.sector = sector;
        this.description = description;
        this.requirements = requirements;
        this.open = open;
        this.highPriority = highPriority;
        this.creationDate = creationDate;
        this.startOn = startOn;
    }
}

// Validation schema for Job using Yup
const jobSchema = yup.object().shape({
    jobNumber: yup.number().required("Job number is required").positive().integer(),
    title: yup.string().required("Title is required").min(2, "Title must be at least 2 characters"),
    role: yup.string().required("Role is required").min(2, "Role must be at least 2 characters"),
    scope: yup.array().of(yup.number().required("Scope must be a number")).required("Scope is required"),
    region: yup.string().required("Region is required"),
    sector: yup.string().required("Sector is required"),
    description: yup.string().default("").optional(),
    requirements: yup.string().default("").optional(),
    open: yup.boolean().default(true).optional(),
    highPriority: yup.boolean().default(false).optional(),
    creationDate: yup.date().required("Creation date is required"),
    startOn: yup.string().required("Start date is required"),
});

// Firestore data converter for Job
const jobConverter = {
    toFirestore: (job: Job): DocumentData => {
        return {
            jobNumber: job.jobNumber,
            title: job.title,
            role: job.role,
            scope: job.scope,
            region: job.region,
            sector: job.sector,
            description: job.description || "",
            requirements: job.requirements || "",
            open: job.open ?? true,
            highPriority: job.highPriority ?? false,
            creationDate: job.creationDate || new Date(),
            startOn: job.startOn || "מיידי",
        };
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot, options: any): Job => {
        const data = snapshot.data(options);
        return new Job(
            data.jobNumber,
            data.title,
            data.role,
            data.scope,
            data.region,
            data.sector,
            data.description || "",
            data.requirements || "",
            data.open ?? true,
            data.highPriority ?? false,
            convertToDate(data.creationDate),
            data.startOn || "מיידי",
        );
    },
};


// Job service class for Firestore operations
class JobService {
    private db: Firestore;
    private collectionPath: string;

    constructor(db: Firestore, collectionPath: string) {
        this.db = db;
        this.collectionPath = collectionPath;
    }

    // Fetches job data from Firestore by ID
    async getJob(id: string): Promise<Job | null> {
        try {
            const docRef = doc(this.db, this.collectionPath, id).withConverter(jobConverter);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                return docSnap.data();
            } else {
                // console.log("No such document!");
                return null;
            }
        } catch (error) {
            console.error("Error getting job document:", error);
            throw new Error("An error occurred while retrieving the job document.");
        }
    }

    async generateUniqueJobNumber(): Promise<number> {
        try {
            // Query the last job number
            const lastJobNumberQuery = query(
                collection(db, this.collectionPath).withConverter(jobConverter),
                orderBy("jobNumber", "desc"),
                limit(1)
            );
            const querySnapshot = await getDocs(lastJobNumberQuery);

            // Ensure there is a document in the collection
            if (querySnapshot.empty) {
                return NaN;
            }
            const lastJobNumber = querySnapshot.docs[0].data()?.jobNumber;

            // Generate a new unique job number
            // The randomness will reduce the risk that multiple recruiters
            // are adding a new job at the same exact moment
            const randomIncrement = Math.floor(Math.random() * 5) + 1;
            return lastJobNumber + randomIncrement;
        } catch (error) {
            console.error("Error generating unique job number:", error);
            throw new Error("Failed to generate a unique job number.");
        }
    }

    // Function to save/add a new job with validation
    async saveJob(id: string, jobData: Partial<Job>): Promise<void> {
        try {
            // Validate jobData against the schema
            const validatedData = await jobSchema.validate(jobData, {abortEarly: false});

            // Create a Job instance
            const job = new Job(
                validatedData.jobNumber,
                validatedData.title,
                validatedData.role,
                validatedData.scope,
                validatedData.region,
                validatedData.sector,
                validatedData.description || "",
                validatedData.requirements || "",
                validatedData.open ?? true,
                validatedData.highPriority ?? false,
                validatedData.creationDate || new Date(),
                validatedData.startOn || "מיידי"
            );

            // Create a reference to the document with a specific ID
            const jobRef = doc(this.db, this.collectionPath, id).withConverter(jobConverter);

            // Save the job document with the specified ID
            await customSetDoc(jobRef, job, JobsLocalStorage, "jobNumber");
            console.log("Job added successfully with custom ID!");
        } catch (error) {
            if (error instanceof yup.ValidationError) {
                console.error("Validation error(s):", error.errors);
                throw new Error("Validation error: " + error.errors.join(", "));
            } else {
                console.error("Error adding job:", error);
                throw new Error("An error occurred while saving the job.");
            }
        }
    }


    // Updates multiple fields of a job by ID

    // ### EXAMPLE ###
    // await jobService.updateJobFields("job12345", {
    //   title: "Senior Developer",
    //   description: ["Strong knowledge of TypeScript", "Experience with Firebase"],
    //   highPriority: true
    // });
    async updateJobFields(id: string, updatedFields: Partial<Job>): Promise<void> {
        try {
            const jobRef = doc(this.db, this.collectionPath, id);
            const jobDoc = await getDoc(jobRef);

            if (!jobDoc.exists()) {
                console.error("Job not found");
                throw new Error("Job not found");
            }

            // Update only the specified fields in the job document
            await customUpdateDoc(jobRef, updatedFields, JobsLocalStorage, "jobNumber");
            console.log("Job fields updated successfully!");
        } catch (error) {
            console.error("Error updating job fields:", error);
            throw new Error("An error occurred while updating the job fields.");
        }
    }

    async findJobsByFilters(filters: Partial<Job>): Promise<Job[]> {
        try {
            let jobsQuery: Query<Job> = collection(this.db, this.collectionPath).withConverter(jobConverter);

            if (filters.open !== undefined) {
                jobsQuery = query(jobsQuery, where("open", "==", filters.open));
            }

            if (filters.sector) {
                jobsQuery = query(jobsQuery, where("sector", "==", filters.sector));
            }

            if (filters.region) {
                jobsQuery = query(jobsQuery, where("region", "==", filters.region));
            }

            const snapshot = await getDocs(jobsQuery);
            return snapshot.docs.map((doc) => doc.data());
        } catch (error) {
            console.error("Error fetching jobs by filters:", error);
            throw new Error("An error occurred while fetching jobs by filters.");
        }
    }

    // Function to delete a job
    async deleteJob(jobId: string): Promise<void> {
        try {
            const jobRef = doc(this.db, this.collectionPath, jobId);
            await customDeleteDoc(jobRef, JobsLocalStorage, "jobNumber");
            console.log("Job deleted successfully!");
        } catch (error) {
            console.error("Error deleting job:", error);
            throw new Error("An error occurred while deleting the job.");
        }
    }

    // Function to close or open a job
    async setJobStatus(jobId: string, status: boolean): Promise<void> {
        try {
            const jobRef = doc(this.db, this.collectionPath, jobId);
            await customUpdateDoc(jobRef, {open: status}, JobsLocalStorage, "jobNumber");
            console.log(`Job set to ${status} successfully!`);
        } catch (error) {
            console.error(`Error setting job to ${status}:`, error);
            throw new Error(`An error occurred while setting the job to ${status}.`);
        }
    }
}

// Initialize Jobs service for the "jobs" collection
export const jobService = new JobService(db, "jobs");

export {Job, jobConverter, jobSchema};
export default JobService;
