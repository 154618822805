import {Button, Typography} from "@mui/material";
import {Description} from "@mui/icons-material";
import React from "react";
import SimpleDialog from "../../../../Components/Dialog/SimpleDialog";


export default function CandidateSelfInfo({about}) {
    const [open, setOpen] = React.useState(false);

    return <>
        <React.Fragment>
            <Button
                size='small'
                variant="contained"
                color="info"
                startIcon={<Description/>}
                onClick={() => setOpen(true)}
                disabled={about === null || about.trim() === ''}
            >
                ספר עלייך
            </Button>

            <SimpleDialog title="ספר/י לנו עלייך" show={open} close={() => setOpen(false)}>
                <Typography
                    variant="body1"
                    component="div"
                    dir="rtl"
                    sx={{
                        fontSize: '1rem',
                        lineHeight: 1.5,
                        whiteSpace: 'pre-wrap',
                        fontFamily: "Rubik",
                        paddingBottom: 2,
                        paddingX: 1,
                        paddingTop: 1
                    }}
                >
                    {about}
                </Typography>
            </SimpleDialog>
        </React.Fragment>
    </>
}
