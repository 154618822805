import {useEffect, useState} from "react";

export default function FadeIn({children, duration = 250}) {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, duration);

        return () => clearTimeout(timer);
    }, []);

    return <>
        <div className={`fade-in ${isVisible ? 'visible' : ''}`}>
            {children}
        </div>
    </>
}