import {AppBar, IconButton, Toolbar, Typography, useTheme} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Bubble from "../../Bubble/Bubble";
import {Box} from "@mui/system";

export default function DialogNavbar({title = 'Dialog Title', onClose, children}) {
    const theme = useTheme();
    const bubbles = [
        {size: 60, position: {x: '10%', y: '20%'}},
        {size: 20, position: {x: '80%', y: '60%'}},
        {size: 40, position: {x: '30%', y: '80%'}},
        {size: 80, position: {x: '70%', y: '10%'}},
        {size: 14, position: {x: '50%', y: '40%'}},
    ];

    return (
        <AppBar position="static" sx={{
            background: theme.palette.custom.background.default,
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
        }}>
            <Toolbar sx={{justifyContent: 'center', position: 'relative'}}>
                <Typography variant="h6" component="div"
                            sx={{position: 'absolute', left: '50%', transform: 'translateX(-50%)'}}>
                    {title}
                </Typography>
                {onClose && (
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                        sx={{position: 'absolute', right: 16}}
                    >
                        <CloseIcon/>
                    </IconButton>
                )}
            </Toolbar>
            <Box position="relative" height="100%" display={{xs: "none", sm: "block"}}>
                {bubbles.map((bubble, index) => (
                    <Bubble key={index} size={bubble.size} position={bubble.position}/>
                ))}
            </Box>

            {children}
        </AppBar>
    );
}