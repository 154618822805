import {Navigate} from "react-router-dom";
import {useAuth} from "./Contexts/AuthContext/AuthContext";
import {UserRole} from "../Firebase/enums/UserRole";

export const ProtectedRoute = ({children}: { children: React.ReactNode }) => {
    const {role, loading, currentUser} = useAuth();

    if (!currentUser && role !== UserRole.candidate && !loading) {
        return <Navigate to="/auth/login" replace/>;
    }

    return <>{children}</>;
};

export const ProtectedRouteAdmin = ({children}: { children: React.ReactNode }) => {
    const {role, loading} = useAuth();

    if ((role !== UserRole.admin) && !loading) {
        return <Navigate to="/management/not-found" replace/>;
    }

    return <>{children}</>;
}
