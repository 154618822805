import {FormControl, Grid, InputLabel} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import {LoadingButton} from "@mui/lab";
import DateRangePicker from "../../../Components/DateRangePicker";
import {useSnackbar} from "../../../../Utilities/Context/Snackbar";
import SelectSector from "../../../Components/Selects/SelectSector";
import SelectRole from "../../../Components/Selects/SelectRole";
import SelectStatus from "../../../Components/Selects/SelectStatus";
import SelectChangedStatus from "../../../Components/Selects/SelectChangedStatus";
import CustomCard from "../../../Components/Cards/Card";
import {Field, Form, Formik} from "formik";
import {collectionGroup, getDocs, query, Timestamp, where} from "firebase/firestore";
import {db} from "../../../../Firebase/FirebaseConfig/firebase";
import {applicationConverter} from "../../../../Firebase/FirebaseFunctions/ApplicationService";
import {useCandidatesData} from "../../../Contexts/DataContext/CandidatesDataContext";
import {useSectorsData} from "../../../Contexts/DataContext/SectorsDataContext";
import {useJobsData} from "../../../Contexts/DataContext/JobsDataContext";
import {ApplicationCandidatesStatuses, getStatusHexColor} from "../../../Utilities/Statuses";
import {exportToExcel} from "../../../Utilities/ExcelExport";
import SelectJob from "../../../Components/Selects/SelectJob";

export default function ReportsByCandidates() {
    const {showSnackbar} = useSnackbar();
    const {candidates} = useCandidatesData();
    const {sectors} = useSectorsData();
    const {jobs} = useJobsData();

    const initialValues = {
        status: "כל הסטטוסים",
        timeOnStatus: "כל המועמדים",
        selectedRole: "כל התפקידים",
        selectedSector: "כל האשכולות",
        selectedJob: "כל המשרות",
        startDate: dayjs().subtract(7, "day"),
        endDate: dayjs(),
    };

    const handleSubmit = async (values, {setSubmitting}) => {
        setSubmitting(true);

        if (values.endDate.isBefore(values.startDate)) {
            showSnackbar({message: 'התאריכים בדו"ח מועמדים שגויים.'});
            setSubmitting(false);
            return;
        }

        try {
            // Sectors
            const filteredSectors = values.selectedSector === "כל האשכולות"
                ? sectors.map(sec => sec.id)
                : [sectors.find(sec => sec.name === values.selectedSector)!.id];

            // Roles
            const filteredRoles = values.selectedRole === "כל התפקידים"
                ? jobs.map(job => job.jobNumber)
                : jobs.filter(job => job.role.trim() === values.selectedRole.trim()).map(job => job.jobNumber);

            // Jobs
            const filteredJobs = values.selectedJob === "כל המשרות" ?
                jobs.map(job => job.jobNumber)
                : jobs.filter(job => job.jobNumber === values.selectedJob).map(job => job.jobNumber);

            if (filteredRoles.length !== jobs.length && filteredJobs.length === 1) {
                throw new Error(`לא ניתן להנפיק דו"ח כאשר יש ערך במשרה ובתפקיד. יש לשנות את אחד מהם ל"כל התפקידים" או "כל המשרות".`);
            }

            // Statuses
            const filteredStatuses = values.status === "כל הסטטוסים"
                ? ApplicationCandidatesStatuses.map(status => status)
                : [values.status];

            // Application Date
            const applyStartTimestamp = Timestamp.fromDate(new Date(values.startDate));
            const applyEndTimestamp = Timestamp.fromDate(new Date(values.endDate));

            let applicationsQuery = query(
                collectionGroup(db, "applications").withConverter(applicationConverter),
                where("sector", "in", filteredSectors),
                where("applyDate", ">=", applyStartTimestamp),
                where("applyDate", "<=", applyEndTimestamp)
            );

            if (filteredStatuses.length === 1) {
                applicationsQuery = query(applicationsQuery, where("status", "==", filteredStatuses[0]));
            }

            const querySnapshot = await getDocs(applicationsQuery);
            let results = querySnapshot.docs
                .map(doc => doc.data())
                .filter(data => filteredRoles.includes(data.jobNumber))
                .filter(data => filteredStatuses.includes(data.status))
                .filter(data => filteredJobs.includes(data.jobNumber));

            // Candidates that were edited lately
            if (values.timeOnStatus !== "כל המועמדים") {
                const now = dayjs();
                results = results.filter(data =>
                    values.timeOnStatus === "בשבוע האחרון"
                        ? dayjs(data.lastUpdate).isAfter(now.subtract(7, 'days'))
                        : dayjs(data.lastUpdate).isAfter(now.subtract(1, 'month'))
                );
            }

            const columns = [
                {field: 'jobNumber', header: "מספר משרה"},
                {field: 'role', header: "תפקיד"},
                {field: 'sector', header: "אשכול"},
                {field: 'firstname', header: "שם פרטי"},
                {field: "lastname", header: "שם משפחה"},
                {field: "email", header: "מייל"},
                {field: "phone", header: "פלאפון"},
                {field: "location", header: "איזור המשרה"},
                {field: "arriveWay", header: "איך הגעת אלינו?"},
                {field: "rate", header: "ציון המועמד"},
                {field: "status", header: "סטטוס"},
                {field: "lastUpdated", header: "תאריך עדכון"},
                {field: "applyDate", header: "תאריך הגשת מועמדות"}
            ];

            const data = results.map((result) => {
                const job = jobs.find(job => job.jobNumber === result.jobNumber);
                const candidate = candidates.find(cand => cand.id === result.candidateId);
                const sector = sectors.find(sec => sec.id === job?.sector);

                return {
                    jobNumber: result.jobNumber,
                    role: job?.role || "לא נמצא",
                    sector: sector?.name || "לא נמצא",
                    firstname: candidate?.firstname || 'לא נמצא',
                    lastname: candidate?.lastname || 'לא נמצא',
                    email: candidate?.email || 'לא נמצא',
                    phone: candidate?.id || 'לא נמצא',
                    location: job?.region || 'לא נמצא',
                    arriveWay: result.arrivalWay,
                    rate: result.matchingRate === -1 ? "לא צויין" : result.matchingRate,
                    status: result.status,
                    lastUpdated: dayjs(result.lastUpdate).format('DD/MM/YYYY HH:mm').toString(),
                    applyDate: dayjs(result.applyDate).format('DD/MM/YYYY HH:mm').toString(),
                    RowColor: getStatusHexColor(result.status)
                };
            }).sort((a, b) => a.status.localeCompare(b.status));

            exportToExcel(`candidates-report`, 'דו"ח', 'דו"ח מועמדיות', columns, data);
        } catch (error) {
            showSnackbar({message: `אירעה שגיאה: ${error}`})
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <CustomCard title='דו"ח מועמדיות'>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit} // Removed validationSchema
                >
                    {({values, setFieldValue, isSubmitting}) => (
                        <Form>
                            <Grid container spacing={2}>
                                {/* Select Status */}
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>סטטוס</InputLabel>
                                        <Field
                                            name="status"
                                            as={SelectStatus}
                                            value={values.status}
                                            onChange={(e) =>
                                                setFieldValue("status", e.target.value)
                                            }
                                            allStatuses={true}
                                        />
                                    </FormControl>
                                </Grid>

                                {/* Select Changed Status */}
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>מועמדים שנערכו לאחרונה</InputLabel>
                                        <Field
                                            name="timeOnStatus"
                                            as={SelectChangedStatus}
                                            value={values.timeOnStatus}
                                            onChange={(e) =>
                                                setFieldValue("timeOnStatus", e.target.value)
                                            }
                                        />
                                    </FormControl>
                                </Grid>

                                {/* Select Sector */}
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="sector-label">אשכול</InputLabel>
                                        <Field name="selectedSector">
                                            {({field, form}) => (
                                                <SelectSector
                                                    value={field.value}
                                                    allSectors={true}
                                                    onChange={(value) =>
                                                        form.setFieldValue(
                                                            "selectedSector",
                                                            value
                                                        )
                                                    }
                                                    labelId="sector-label"
                                                />
                                            )}
                                        </Field>
                                    </FormControl>
                                </Grid>

                                {/* Select Role */}
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="role-label">תפקיד</InputLabel>
                                        <Field name="selectedRole">
                                            {({field, form}) => (
                                                <SelectRole
                                                    value={field.value}
                                                    allRoles={true}
                                                    onChange={(value) =>
                                                        form.setFieldValue(
                                                            "selectedRole",
                                                            value
                                                        )
                                                    }
                                                    labelId="role-label"
                                                />
                                            )}
                                        </Field>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="role-label">משרה</InputLabel>
                                        <Field name="selectedJob">
                                            {({field, form}) => (
                                                <SelectJob
                                                    value={field.value}
                                                    allJobsTitle={true}
                                                    onChange={(value) =>
                                                        form.setFieldValue(
                                                            "selectedJob",
                                                            value
                                                        )
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </FormControl>
                                </Grid>

                                {/* Date Range Picker */}
                                <Grid item xs={12} md={6}>
                                    <DateRangePicker
                                        startDate={values.startDate}
                                        endDate={values.endDate}
                                        onStartDateChange={(date) =>
                                            setFieldValue("startDate", date)
                                        }
                                        onEndDateChange={(date) =>
                                            setFieldValue("endDate", date)
                                        }
                                    />
                                </Grid>

                                {/* Submit Button */}
                                <Grid
                                    item
                                    xs={12}
                                    display="flex"
                                    justifyContent={{xs: "center", md: "flex-end"}}
                                >
                                    <LoadingButton
                                        sx={{
                                            width: {
                                                xs: "100%",
                                                md: "auto",
                                            },
                                        }}
                                        color="info"
                                        variant="contained"
                                        type="submit"
                                        loading={isSubmitting}
                                    >
                                        הנפק דו"ח
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </CustomCard>
        </>
    );
}
