export const containerStyles = () => ({
    paddingTop: {sm: '14px', md: '30px', lg: '22px', xl: '30px'},
    marginTop: {md: 2},
    marginBottom: 2,
    padding: 0
});

export const gridStyles = () => ({
    padding: {xs: 2, sm: 4, md: 2, lg: 1},
    paddingRight: {lg: 4, xl: 4},
    ml: 'auto',
});

export const dividerStyles = () => ({
    backgroundColor: 'primary.divider',
    height: '2px',
    width: 'calc(100% - 31px)',
    mt: 2,
    ml: 'auto',
    borderRadius: 1
});

export const loadingStylesContainer = () => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    mt: 5,
    mb: 5
});

export const loadingStyles = () => ({
    width: {xs: '185px', md: '330px'},
    height: '40px',
    fontWeight: 500,
    backgroundColor: 'primary.filterButton',
    color: 'primary.JobTitle2',
    opacity: 1,
    borderRadius: '30px',
    ':hover': {
        backgroundColor: 'secondary.filterButton',
    },
    '& .MuiLoadingButton-loadingIndicator': {
        color: 'primary.JobTitle2'
    }
});