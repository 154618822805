import {Box} from "@mui/material";
import navbarImage from '../../../../Assets/navbarImage.png';
import React from "react";

interface AuthBackgroundProps {
    children: React.ReactNode;
}

export function AuthBackground({children}: AuthBackgroundProps) {
    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative', // Ensure the ::before overlay positions correctly
                backgroundImage: `url(${navbarImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            {/* Overlay for blur and tint effect */}
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    backdropFilter: 'blur(10px)',
                    zIndex: 0,
                }}
            />
            {/* Component container */}
            <Box sx={{position: 'relative', zIndex: 1}}>
                {children}
            </Box>
        </Box>
    );
}

