import {useTheme} from '@mui/material/styles';
import CircleIcon from '@mui/icons-material/Circle';
import dayjs from 'dayjs';
import React from "react";

export default function ActivityText({activity, timestamp}) {
    const theme = useTheme();

    const getStatusText = () => {
        if (activity) {
            return "מחובר/ת כעת";
        }

        if (timestamp === null) {
            return "לא מחובר/ת";
        }

        return `לא מחובר/ת מ- ${dayjs(timestamp).format('DD/MM/YY HH:mm')}`;
    };

    return (
        <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
            <CircleIcon sx={{color: activity ? theme.palette.success.main : theme.palette.error.main}}/>
            <span>{getStatusText()}</span>
        </div>
    );
}
