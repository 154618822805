import {createTheme, ThemeOptions} from '@mui/material/styles';
import {alpha} from "@mui/material";

declare module '@mui/material/styles' {
    interface Palette {
        custom: {
            sidebar: {
                hoverBackgroundColor: string;
            };
            navbar: {
                iconColor: string;
            };
            search: {
                iconColor: string;
                backgroundColor: string;
                hoverBackgroundColor: string;
            };
            background: {
                default: string;
            },
            form: {
                text: "rgba(0, 0, 0, 0.60)"
            },
            borderedContainer: {
                borderColor: string;
                hoverBorderColor: string;
            },
            table: {
                chipBorderColor: string;
                firstTableRow: string;
                secondTableRow: string;
                hoverRow: string;
                borderColor: string;
            },
            card: {
                backgroundColor: string;
                hoverBackgroundColor: string;
            }
        }
    }
}

export const getDesignTokens = (mode: 'light' | 'dark'): ThemeOptions => ({
    palette: {
        mode,
        ...(mode === 'light' ? {
            custom: {
                sidebar: {
                    hoverBackgroundColor: "rgba(0, 0, 0, 0.04)"
                },
                navbar: {
                    iconColor: "rgba(0, 0, 0, 0.54)"
                },
                search: {
                    iconColor: "rgba(0, 0, 0, 0.54)",
                    backgroundColor: alpha("#000000", 0.05),
                    hoverBackgroundColor: alpha("#000000", 0.10)
                },
                background: {
                    default: "linear-gradient(-45deg, #2F4677, #007ACC, #54c5f8)"
                },
                form: {
                    text: "rgba(0, 0, 0, 0.60)"
                },
                borderedContainer: {
                    borderColor: "rgba(0, 0, 0, 0.22)",
                    hoverBorderColor: "rgba(0, 0, 0, 0.87)"
                },
                table: {
                    chipBorderColor: "#bdbdbd",
                    firstTableRow: "rgba(240, 240, 240, 0.8)",
                    secondTableRow: "white",
                    hoverRow: "rgba(25, 118, 210, 0.2)",
                    borderColor: "rgba(224, 224, 224, 1)"
                },
                card: {
                    backgroundColor: "rgba(0, 0, 0, 0.03)",
                    hoverBackgroundColor: "rgba(0, 0, 0, 0)"
                }
            }
        } : {
            primary: {
                main: '#4e91fd',
                light: '#bac2ff',
                dark: '#2c2cff',
                contrastText: '#FFFFFF',
            },
            secondary: {
                main: '#545462',
                light: '#333349',
                dark: '#1E1E2E',
                contrastText: '#FFFFFF'
            },
            background: {
                default: '#1E1E2E',
                paper: '#2A2A3C',
            },
            text: {
                primary: '#FFFFFF',
                secondary: '#B8B8C3',
                disabled: '#6C6C7C'
            },
            custom: {
                sidebar: {
                    hoverBackgroundColor: "rgba(255, 255, 255, 0.08)"
                },
                navbar: {
                    iconColor: "#FFFFFF"
                },
                search: {
                    iconColor: "#FFFFFF",
                    backgroundColor: alpha("#FFFFFF", 0.25),
                    hoverBackgroundColor: alpha("#FFFFFF", 0.30)
                },
                background: {
                    default: "linear-gradient(-45deg, #192442, #19345C, #075a8f, #0b84c4)"
                },
                form: {
                    text: "#B8B8C3"
                },
                borderedContainer: {
                    borderColor: alpha("#B8B8C3", 0.35),
                    hoverBorderColor: "#FFFFFF"
                },
                table: {
                    chipBorderColor: alpha("#bdbdbd", 0.35),
                    firstTableRow: '#1E1E2E',
                    secondTableRow: '#2D2D40',
                    hoverRow: alpha("#4e91fd", 0.2),
                    borderColor: alpha("#B8B8C3", 0.1)
                },
                card: {
                    backgroundColor: 'rgba(0,0,0,0.2)',
                    hoverBackgroundColor: "rgba(0, 0, 0, 0.1)"
                }
            }
        })
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: ({theme}) => ({
                    backgroundColor: theme.palette.mode === 'dark'
                        ? '#2A2A3C'
                        : theme.palette.background.paper,
                    backgroundImage: 'none'
                })
            }
        }
    }
});

export const theme = (mode: 'light' | 'dark') => createTheme(getDesignTokens(mode));