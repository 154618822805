import {styled} from "@mui/material";
import {renderTimeViewClock, TimePicker} from "@mui/x-date-pickers";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

export const TimePickerRTL = styled(TimePicker)({
    '& .MuiDatePickerToolbar-root': {},
});

TimePickerRTL.defaultProps = {
    slots: {
        leftArrowIcon: KeyboardArrowRightIcon,
        rightArrowIcon: KeyboardArrowLeftIcon,
    },
    viewRenderers: {
        hours: renderTimeViewClock,
        minutes: renderTimeViewClock,
        seconds: renderTimeViewClock,
    }
};