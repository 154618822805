import DrushimThemeProvider from "../../../DrushimMainPage/Theme/ThemeProvider";
import {Box} from "@mui/material";
import PageNotFound from "../../../DrushimMainPage/Pages/NotFoundPage/NotFoundPage";

export default function NotFoundPage() {
    return (
        <Box
            sx={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", height: "100%"}}>
            <DrushimThemeProvider>
                     <PageNotFound/>
            </DrushimThemeProvider>
        </Box>
    );
}
