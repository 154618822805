import React from 'react';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {Stack, Typography} from '@mui/material';
import 'dayjs/locale/he';
import {DatePickerRTL} from "../Styled/DatePicker";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

const DateRangePicker = ({startDate, endDate, onStartDateChange, onEndDateChange, sx = {}}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='he'>
            <Stack spacing={2} direction={'row'} justifyContent={{xs: 'center', md: 'flex-start'}} sx={{...sx}}>
                <DatePickerRTL
                    slotProps={{textField: {size: 'small'}}}
                    sx={{width: '50%'}}
                    label="מתאריך"
                    value={startDate}
                    onChange={onStartDateChange}
                />
                <Typography alignSelf='center' sx={{
                    fontSize: 15,
                    fontFamily: 'Rubik',
                    fontWeight: 600,
                    letterSpacing: '0px',
                    opacity: 0.8,
                }}>
                    -
                </Typography>
                <DatePickerRTL
                    slotProps={{textField: {size: 'small'}}}
                    sx={{width: '50%'}}
                    label="עד תאריך"
                    minDate={startDate}
                    value={endDate}
                    onChange={onEndDateChange}
                />
            </Stack>
        </LocalizationProvider>
    );
};

export default DateRangePicker;