import React from "react";
import HighchartCard from "../../../Components/Cards/HighchartCard";
import {useJobsData} from "../../../Contexts/DataContext/JobsDataContext";

export default function ReportsByCity() {
    const {jobs} = useJobsData();

    // Prepare the chart data based on the filtered jobs
    const chartData = React.useMemo(() => {
        const jobsByRegion: { [region: string]: number } = {};

        for (let i = 0; i < jobs.length; i++) {
            const job = jobs[i];
            const region = job.region;

            if (job.open === true) {
                if (jobsByRegion[region]) {
                    jobsByRegion[region]++;
                } else {
                    jobsByRegion[region] = 1;
                }
            }
        }

        const regions = Object.keys(jobsByRegion);
        const quantities = Object.values(jobsByRegion);

        return {
            categories: regions,
            data: quantities,
        };
    }, [jobs]);

    return (
        <HighchartCard
            title="משרות לפי ערים (משרות פתוחות בלבד)"
            axisTitle="ערים"
            chartData={chartData}
        />
    );
}
