import {BrowserRouter, Navigate, Outlet, Route, Routes} from "react-router-dom";
import {Box, CssBaseline} from "@mui/material";
import React from "react";
import DrushimThemeProvider from "./DrushimMainPage/Theme/ThemeProvider";
import Navbar from "./DrushimMainPage/Layout/Navbar/Navbar";
import Accessibility from "./DrushimMainPage/Components/Accessibility/Accessibility";
import Footer from "./DrushimMainPage/Layout/Footer/Footer";
import ThanksPage from "./DrushimMainPage/Pages/AlertPages/ThanksPage";
import PageNotFound from "./DrushimMainPage/Pages/NotFoundPage/NotFoundPage";
import MainPage from "./DrushimMainPage/Pages/MainPage/MainPage";
import JobPage from "./DrushimMainPage/Pages/JobPage/JobPage";
import {AuthBackground} from "./Management/Pages/AuthenticationPages/Components/Background";
import DashboardPage from "./Management/Pages/Dashboard/DashboardPage";
import ReportsPage from "./Management/Pages/Reports/ReportsPage";
import JobsPage from "./Management/Pages/Jobs/JobsPage";
import CandidatesPage from "./Management/Pages/Candidates/CandidatesPage";
import {SnackbarProvider} from "./Utilities/Context/Snackbar";
import CandidatePage from "./Management/Pages/Candidates/Candidate/CandidatePage";
import NotFoundPage from "./Management/Pages/NotFound/NotFoundPage";
import {LoginPage} from "./Management/Pages/AuthenticationPages/LoginPage";
import RecruitersPage from "./Management/Pages/Recruiters/RecruitersPage";
import SectorsPage from "./Management/Pages/Sectors/SectorsPage";
import RolesPage from "./Management/Pages/Roles/RolesPage";
import {DialogProvider} from "./Management/Contexts/Dialog";
import CandidateInterviewPage from "./Management/Pages/Candidates/Candidate/Interviews/CandidateInterview";
import CandidateView from "./Management/Pages/Candidates/Candidate/View/CandidateJobs";
import {ManagementLayout} from "./Management/ManagementLayout";
import {ProtectedRoute, ProtectedRouteAdmin} from "./Management/ProtectedRoutes";
import {AuthProvider} from "./Management/Contexts/AuthContext/AuthContext";
import {DrushimJobsDataProvider} from "./DrushimMainPage/Contexts/JobsDrushimContext";
import EmailResetPasswordPage from "./Management/Pages/AuthenticationPages/EmailResetPasswordPage";
import {ResetPasswordPage} from "./Management/Pages/AuthenticationPages/ResetPasswordPage";
import ScrollToTopPageRoute from "./Utilities/ScrollToTopPageRoute";
import CandidateResume from "./Management/Pages/Candidates/Candidate/Resume/CandidateResume";
import JobPreviewPage from "./Management/Pages/Jobs/JobPreview/JobPreviewPage";
import AlreadyAppliedPage from "./DrushimMainPage/Pages/AlertPages/AlreadyAppliedPage";
import MaintenancePage from "./DrushimMainPage/Pages/AlertPages/MaintenacePage";
import {ThemeContextProvider} from "./Management/Contexts/Theme";

const Maintenance = false;

export const CareerRoutes = () => (
    <Route path="/career" element={
        <DrushimThemeProvider>
            <CssBaseline/>
            <Navbar/>
            <Box flex={1} height={'calc(100dvh - 64px)'}>
                <Accessibility/>
                <DrushimJobsDataProvider>
                    <SnackbarProvider>
                        <Outlet/>
                    </SnackbarProvider>
                </DrushimJobsDataProvider>
                <Footer/>
            </Box>
        </DrushimThemeProvider>
    }>
        <Route index element={<Navigate to="/career/jobs" replace/>}/>
        <Route path="jobs" element={<MainPage/>}/>
        <Route path="jobs/:id" element={<JobPage/>}/>
        <Route path="not-found" element={<PageNotFound/>}/>

        <Route path="thanks" element={<ThanksPage/>}/>
        <Route path="already-applied" element={<AlreadyAppliedPage/>}/>

        {/*Only visible to logged-in users*/}
        <Route path="job/preview" element={
            <ProtectedRoute>
                <JobPreviewPage/>
            </ProtectedRoute>
        }/>
    </Route>
);

export const AuthRoutes = () => (
    <Route path="/auth" element={
        <SnackbarProvider>
            <AuthBackground>
                <Outlet/>
            </AuthBackground>
        </SnackbarProvider>
    }>
        <Route path="login" element={<LoginPage/>}/>
        <Route path="resetPassword" element={<EmailResetPasswordPage/>}/>
        <Route path="action" element={<ResetPasswordPage/>}/>
        <Route path="*" element={<Navigate to="/auth/login" replace/>}/>
        <Route index element={<Navigate to="/auth/login" replace/>}/>
    </Route>
);

export const ManagementRoutes = () => {
    return (
        <Route path="/management" element={
            <ProtectedRoute>
                <ThemeContextProvider>
                    <ManagementLayout>
                        <Outlet/>
                    </ManagementLayout>
                </ThemeContextProvider>
            </ProtectedRoute>
        }>
            <Route path="dashboard" element={<DashboardPage/>}/>
            <Route path="reports" element={<ReportsPage/>}/>
            <Route path="jobs" element={<JobsPage/>}/>
            <Route path="candidates" element={<CandidatesPage/>}/>
            <Route path="candidate" element={<Navigate to="/management/candidates"/>}/>
            <Route path="candidate/:id" element={<CandidatePage/>}>
                <Route index element={<Navigate to="view" replace/>}/>
                <Route path="interviews" element={<CandidateInterviewPage/>}/>
                <Route path="view" element={<CandidateView/>}/>
                <Route path="resume" element={<CandidateResume/>}/>
            </Route>

            {/* Admin-only routes */}
            <Route element={
                <ProtectedRouteAdmin>
                    <Outlet/>
                </ProtectedRouteAdmin>
            }>
                <Route path="recruiters" element={<RecruitersPage/>}/>
                <Route path="sectors" element={<SectorsPage/>}/>
                <Route path="roles" element={<RolesPage/>}/>
            </Route>

            {/* Fallback and default routes */}
            <Route path="not-found" element={<NotFoundPage/>}/>
            <Route path="*" element={<Navigate to="/management/not-found" replace/>}/>
            <Route index element={<Navigate to="/management/dashboard" replace/>}/>
        </Route>
    );
}

export const App = () => {
    if (Maintenance) {
        return <>
            <BrowserRouter>
                <Routes>
                    <Route path="*" element={
                        <DrushimThemeProvider>
                            <CssBaseline/>
                            <MaintenancePage/>
                        </DrushimThemeProvider>
                    }/>
                </Routes>
            </BrowserRouter>
        </>
    }

    return (
        <BrowserRouter>
            <ScrollToTopPageRoute/>
            <AuthProvider>
                <DialogProvider>
                    <SnackbarProvider>
                        <Routes>
                            {CareerRoutes()}
                            {AuthRoutes()}
                            {ManagementRoutes()}

                            {/* Default and fallback routes */}
                            <Route path="" element={<Navigate to="/career/jobs" replace/>}/>
                            <Route path="*" element={<Navigate to="/career/not-found" replace/>}/>
                        </Routes>
                    </SnackbarProvider>
                </DialogProvider>
            </AuthProvider>
        </BrowserRouter>
    );
};
