import {useDialog} from "../Contexts/Dialog";
import {useSnackbar} from "../../Utilities/Context/Snackbar";
import {useCandidatesData} from "../Contexts/DataContext/CandidatesDataContext";
import {Candidate, candidateService} from "../../Firebase/FirebaseFunctions/CandidateService";
import {Application} from "../../Firebase/FirebaseFunctions/ApplicationService";

export function useCandidateDelete(closeHandler?: () => void) {
    const {showDialog} = useDialog();
    const {showSnackbar} = useSnackbar();
    const {refreshCandidates} = useCandidatesData();

    return (candidate) => {
        showDialog({
            title: `מחיקת מועמד`,
            description: `האם למחוק את המועמד/ת "${candidate.firstname} ${candidate.lastname}"?`,
            onAccept: async () => {
                try {
                    const id = candidate.id.toString();
                    await candidateService.deleteCandidate(id);
                    await refreshCandidates();

                    showSnackbar({
                        message: `המועמד נמחק בהצלחה.`,
                        severity: "success"
                    });

                    if (closeHandler) {
                        closeHandler();
                    }
                } catch (error) {
                    const errorStr = (error as any).message as string;
                    if (errorStr.includes("CV")) {
                        await refreshCandidates();

                        showSnackbar({
                            message: `המועמד נמחק בהצלחה.`,
                            severity: "success"
                        });

                        if (closeHandler) {
                            closeHandler();
                        }
                    } else {
                        showSnackbar({message: `מחיקת המועמד נכשלה, השגיאה: ${errorStr}`});
                    }

                }
            }
        });
    };
}

export function useCandidateUpdate(closeHandler?: () => void, noDialog = false) {
    const {showDialog} = useDialog();
    const {showSnackbar} = useSnackbar();
    const {refreshCandidates} = useCandidatesData();

    return (candidate, fieldsToUpdate, cv: File | null = null) => {
        return new Promise<boolean>((resolve) => {
            const updateProcess = async () => {
                try {
                    const id = candidate.id;
                    await candidateService.updateCandidateFields(id, fieldsToUpdate);
                    if (cv) {
                        await candidateService.uploadCandidateCV(id, cv);
                    }
                    await refreshCandidates();

                    showSnackbar({
                        message: `המועמד עודכן בהצלחה.`,
                        severity: "success"
                    });

                    if (closeHandler) {
                        closeHandler();
                    }
                    resolve(true);
                } catch (error) {
                    showSnackbar({message: `עדכון המועמד נכשל. שגיאה: ${error}`})
                    resolve(false);
                }
            };

            if (noDialog) {
                updateProcess();
            } else {
                showDialog({
                    title: `עדכון מועמד`,
                    description: `האם לעדכן את המועמד "${candidate.firstname} ${candidate.lastname}"?`,
                    onAccept: updateProcess,
                    onCancel: () => resolve(false)
                });
            }
        });
    };
}

export function useCandidateCreate(closeHandler?: () => void) {
    const {showSnackbar} = useSnackbar();
    const {refreshCandidates} = useCandidatesData();

    return async (candidate: Partial<Candidate>, application: Partial<Application>, cv: File | null) => {
        try {
            const id = candidate.id!;
            const found = await candidateService.getCandidate(id);

            if (!found) {
                await candidateService.saveCandidate(candidate);
            }

            const apps = await candidateService.getApplications(id);
            if (apps.filter(app => app.jobNumber === application.jobNumber).length > 0) {
                throw new Error("לא ניתן להגיש מועמדות בעקבות שיש כבר את המועמדות הזאת למועמד.");
            }

            await candidateService.addApplication(application);
            if (cv) {
                await candidateService.uploadCandidateCV(id, cv);
            }
            await refreshCandidates();

            showSnackbar({
                message: `המועמד נוצר בהצלחה.`,
                severity: "success"
            });

            if (closeHandler) {
                closeHandler();
            }
        } catch (error) {
            showSnackbar({message: `יצירת המועמד נכשל. שגיאה: ${(error as any).message}`})
        }
    }
}

export function useCandidateUpdateApplication() {
    const {showSnackbar} = useSnackbar();

    return async (candidate, application, fieldToUpdate) => {
        try {
            await candidateService.updateApplication(candidate.id, application.id, fieldToUpdate);

            showSnackbar({
                message: `המועמדות עודכנה בהצלחה.`,
                severity: "success"
            });
        } catch (error) {
            showSnackbar({message: `עדכון המועמדות נכשלה. שגיאה: ${error}`})
            throw error;
        }
    };
}

export function useCandidateCreateApplication() {
    return async (application) => {
        await candidateService.addApplication(application);
    };
}
