import {Typography, useTheme} from "@mui/material";
import React from "react";
import {useAuth} from "../../../Contexts/AuthContext/AuthContext";

export default function Welcome() {
    const {currentUser} = useAuth();
    const theme = useTheme();
    const [displayName, setDisplayName] = React.useState<string | null>(() => {
        if (typeof window !== 'undefined') {
            return localStorage.getItem('userDisplayName');
        }
        return null;
    });

    React.useEffect(() => {
        if (!displayName && currentUser?.displayName) {
            setDisplayName(currentUser.displayName);
            localStorage.setItem('userDisplayName', currentUser.displayName);
        }
    }, [currentUser, displayName]);

    return (
        <Typography variant="h6" sx={{
            flexGrow: 1,
            textAlign: 'center',
            fontWeight: 400,
            color: theme.palette.text.primary,
            fontFamily: "Roboto, Helvetica, Arial, sans-serif"
        }}>
            שלום <Typography variant="h6" component="span"
                             sx={{fontWeight: 'bold', fontFamily: "Rubik", color: theme.palette.text.primary}}>
            {displayName || "טוען..."}
        </Typography>, ברוך/ה הבא/ה!
        </Typography>
    );
}
