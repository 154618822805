import React from "react";
import {
    Box,
    Drawer,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Switch,
    Typography,
    useTheme,
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {useLocation, useNavigate} from "react-router-dom";
import logo from "../../../Assets/GVANIM_LOGO_NEW2.png";

interface MenuItem {
    text: string;
    icon: React.ReactElement;
    route: string;
    hidden?: boolean;
}

interface Section {
    label?: string;
    items: MenuItem[];
}

interface SidebarProps {
    menuItems: Section[];
    isMobileView: boolean;
}

const MainSidebar: React.FC<SidebarProps> = ({menuItems, isMobileView}) => {
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const getSidebarValue = () => {
        const saved = localStorage.getItem('sidebarLocked');
        return saved ? JSON.parse(saved) : true;
    }
    const [isExpanded, setIsExpanded] = React.useState(getSidebarValue());
    const [isLocked, setIsLocked] = React.useState(getSidebarValue());


    const navigate = useNavigate();
    const location = useLocation();

    const sidebarWidth = isExpanded ? 240 : 64;

    const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

    const itemClicked = (item) => {
        if (location.pathname === item.route) {
            return;
        }

        if (isMobileView) {
            handleDrawerToggle();
        }

        navigate(item.route);
    }

    const handleMouseEnter = () => {
        if (!isLocked) {
            setIsExpanded(true);
        }
    };

    const handleMouseLeave = () => {
        if (!isLocked && !isMobileView) {
            setIsExpanded(false);
        }
    };

    React.useEffect(() => {
        localStorage.setItem('sidebarLocked', JSON.stringify(isLocked));
        if (isLocked) {
            setIsExpanded(true);
        }
    }, [isLocked]);

    React.useEffect(() => {
        if (isMobileView) {
            setIsExpanded(true);
        }
    }, [isMobileView]);

    const lockSwitch = (
        <Box sx={{
            pb: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <FormControlLabel
                control={
                    <Switch
                        checked={isLocked}
                        onChange={(e) => {
                            setIsLocked(e.target.checked);
                            if (e.target.checked) {
                                setIsExpanded(true);
                            }
                        }}
                        size="small"
                    />
                }
                label={
                    <Typography
                        variant="caption"
                        sx={{
                            color: theme.palette.text.secondary,
                            fontSize: '0.75rem',
                            fontFamily: '"Rubik", sans-serif',
                        }}
                    >
                        נעילת סרגל צד
                    </Typography>
                }
            />
        </Box>
    );

    const drawer = (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: sidebarWidth,
            transition: 'width 0.3s ease',
            overflow: 'hidden',
        }}
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}
        >
            <img
                src={logo}
                alt="Logo"
                style={{
                    //  filter: isExpanded && 'drop-shadow(0 0px 1px rgba(255, 255, 255, 0.1)) drop-shadow(0 2px 2px rgba(255, 255, 255, 0.1))',
                    overflow: 'hidden',
                    marginRight: isExpanded ? 0 : 10,
                    marginTop: isExpanded ? 40 : 15,
                    marginBottom: 20,
                    width: isExpanded ? 150 : 55,
                    height: "auto",
                    alignSelf: "center",
                    userSelect: "none",
                    pointerEvents: "none",
                    transition: 'width 0.3s ease, filter 1s ease'
                }}
            />
            <List sx={{overflowY: "auto", overflowX: "hidden"}}>
                {menuItems.map((section, index) => (
                    <div key={index}>
                        {section.label && isExpanded && (
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    padding: "8px 16px",
                                    color: theme.palette.text.secondary,
                                    textTransform: "uppercase",
                                    fontSize: "0.75rem",
                                    fontWeight: 500,
                                    lineHeight: 1.66
                                }}
                            >
                                {section.label}
                            </Typography>
                        )}
                        {section.items.map((item, itemIndex) => (
                            !item.hidden &&
                            <ListItem disablePadding key={itemIndex} sx={{margin: 0}}>
                                <ListItemButton
                                    onClick={() => itemClicked(item)}
                                    selected={location.pathname === item.route}
                                    disableRipple
                                    sx={{
                                        justifyContent: isExpanded ? "initial" : "center",
                                        fontFamily: '"Rubik", sans-serif',
                                        px: 2.5,
                                        borderRadius: '8px',
                                        margin: '4px 8px',
                                        '&:hover': {
                                            backgroundColor: theme.palette.custom.sidebar.hoverBackgroundColor,
                                            '& .MuiListItemIcon-root': {
                                                color: theme.palette.primary.main,
                                            },
                                            '& .MuiListItemText-primary': {
                                                color: theme.palette.primary.main,
                                            },
                                        },
                                        transition: 'all 0.3s ease',
                                        color: location.pathname === item.route ? theme.palette.primary.main : 'inherit',
                                        cursor: location.pathname === item.route ? "auto" : "pointer",
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            color: location.pathname === item.route ? theme.palette.primary.main : 'inherit',
                                            minWidth: 0,
                                            mr: isExpanded ? 2 : "auto",
                                            justifyContent: "center"
                                        }}>{item.icon}</ListItemIcon>
                                    {isExpanded && <ListItemText primary={item.text}/>}
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </div>
                ))}
            </List>
            {isExpanded && <Box sx={{
                marginTop: 'auto',
                p: 2,
                borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                textAlign: 'center'
            }}>
                {!isMobileView && lockSwitch}
                <Typography
                    variant="caption"
                    sx={{
                        color: theme.palette.text.secondary,
                        fontSize: '0.75rem',
                        fontFamily: '"Rubik", sans-serif',
                    }}
                >
                    המערכת פותחה על ידי צוות המחשוב של גוונים
                </Typography>
            </Box>}
        </Box>
    );

    if (isMobileView) {
        return (
            <>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{
                        mr: 2,
                        marginRight: '0 !important',
                        marginLeft: '0 !important',
                        color: theme.palette.custom.navbar.iconColor,
                    }}
                >
                    <MenuIcon/>
                </IconButton>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    anchor="right"
                    sx={{
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: 240,
                            boxShadow: '5px 0 5px -2px rgba(0,0,0,0.2)',
                            left: 0,
                            right: 'auto',
                            transform: 'translateX(100%)',
                            transition: theme.transitions.create('transform', {
                                easing: theme.transitions.easing.easeOut,
                                duration: theme.transitions.duration.enteringScreen,
                            }),
                            '&.MuiDrawer-paperAnchorRight.MuiDrawer-paperOpen': {
                                transform: 'translateX(0%)',
                            },
                        },
                    }}
                >
                    {drawer}
                </Drawer>
            </>
        );
    }

    return (
        <Box
            component="nav"
            sx={{
                width: sidebarWidth,
                flexShrink: 0,
                transition: "width 0.3s ease"
            }}
            aria-label="mailbox folders"
        >
            <Drawer
                variant="permanent"
                sx={{
                    '& .MuiDrawer-paper': {
                        width: sidebarWidth,
                        transition: 'width 0.3s ease',
                        boxSizing: 'border-box',
                        borderLeft: "none",
                        borderRight: "none",
                        boxShadow: 'none',
                    },
                }}
                open
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

export default MainSidebar;