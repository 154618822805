import {Box, Icon, Typography} from "@mui/material";
import React from "react";

import {ReactComponent as JobLocationSVG} from "../../../../Assets/JobLocation.svg";
import {ReactComponent as JobStartSVG} from "../../../../Assets/JobStart.svg";
import {ReactComponent as JobRoleSVG} from "../../../../Assets/JobRole.svg";
import {ReactComponent as JobScopeSVG} from "../../../../Assets/JobScope.svg";
import {ReactComponent as PinkEllipseSVG} from "../../../../Assets/PinkEllipse.svg";
import {ReactComponent as YellowEllipseSVG} from "../../../../Assets/YellowEllipse.svg";
import {UseScreenSelectors} from "../../../../../Utilities/ScreenSize";
import {UseStyles} from "./JobInfoSummaryStyles";

export default function JobInfoSummary(props: { job }) {
    const {job} = props;
    const [floatingTop, setFloatingTop] = React.useState<number>(0);

    const styles = UseStyles();
    const screenSize = UseScreenSelectors();

    React.useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            if (scrollTop >= floatingTop && scrollTop <= 100) {
                setFloatingTop(scrollTop);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [floatingTop]);

    return (
        <Box sx={styles.container}>
            {/* Ellipses */}
            <Box sx={styles.ellipsesContainer}
            >
                <Icon sx={styles.yellowEllipse} component={YellowEllipseSVG}/>
                <Icon sx={styles.pinkEllipse} component={PinkEllipseSVG}/>
            </Box>
            <Box sx={styles.jobDetailsBox}>
                <Box sx={styles.jobDetailsHeader}>
                    <Typography variant={screenSize === "xs" ? "subtitle1" : 'h2'} sx={styles.jobDetailsHeaderText}
                    >
                        פרטי משרה:
                    </Typography>
                </Box>

                {/* job role */}
                < Box sx={styles.jobDetailRow}>
                    {/* Icon */}
                    <Box sx={styles.jobDetailIcon}>
                        <Icon sx={styles.jobDetailIconSvg} component={JobRoleSVG}/>
                    </Box>
                    <Typography variant={screenSize === "xs" ? "subtitle2" : 'h4'} sx={styles.jobDetailLabel}>
                        תפקיד:
                    </Typography>
                    <Typography sx={styles.jobDetailValue} variant={screenSize === "xs" ? "subtitle2" : 'h4'}>
                        {job?.role}
                    </Typography>
                </Box>

                {/* job scope */}
                <Box sx={styles.jobDetailRow}>
                    {/* Icon */}
                    <Box sx={styles.jobDetailIcon}>
                        <Icon sx={styles.jobDetailIconSvg} component={JobScopeSVG}/>
                    </Box>
                    <Typography variant={screenSize === "xs" ? "subtitle2" : 'h4'}
                                sx={{...styles.jobDetailLabel, ...styles.jobScopeLabel}}>
                        היקף משרה:
                    </Typography>
                    <Typography
                        sx={styles.jobDetailValue}
                        variant={screenSize === "xs" ? "subtitle2" : 'h4'}
                    >
                        {job?.scope[0] !== job?.scope[1] ? job?.scope.slice(0).reverse().map((num, index) => (index !== job.scope.length - 1) ? num + "%-" : num + "%") : job?.scope[0] + "%"}
                    </Typography>
                </Box>

                {/* job location */}
                < Box sx={styles.jobDetailRow}
                >
                    {/* Icon */}
                    <Box sx={styles.jobDetailIcon}>
                        <Icon sx={styles.jobDetailIconSvg} component={JobLocationSVG}/>
                    </Box>

                    <Typography variant={screenSize === "xs" ? "subtitle2" : 'h4'} sx={styles.jobDetailLabel}>
                        מיקום:
                    </Typography>
                    <Typography
                        sx={styles.jobDetailValue}
                        variant={screenSize === "xs" ? "subtitle2" : 'h4'}
                    >
                        {job?.region}
                    </Typography>
                </Box>

                {/* job Start */}
                < Box
                    sx={styles.jobDetailRow}
                >
                    {/* Icon */}
                    <Box sx={styles.jobDetailIcon}>
                        <Icon sx={styles.jobDetailIconSvg} component={JobStartSVG}/>
                    </Box>

                    <Typography variant={screenSize === "xs" ? "subtitle2" : 'h4'}
                                sx={{...styles.jobDetailLabel, ...styles.jobScopeLabel}}>
                        תחילת עבודה:
                    </Typography>
                    <Typography
                        sx={styles.jobDetailValue}
                        variant={screenSize === "xs" ? "subtitle2" : 'h4'}
                    >
                        {job?.startOn}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};
