import React from "react";
import {alpha, Box, Button, IconButton, Popover, Tooltip, Typography, useTheme} from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import PhoneIcon from '@mui/icons-material/Phone';

export default function Help() {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const theme = useTheme();
    const open = Boolean(anchorEl);
    const id = open ? 'help-popover' : undefined;

    return (<>
        <Tooltip title="עזרה" arrow componentsProps={{
            tooltip: {
                sx: {
                    fontSize: '1rem',
                },
            },
        }}>
            <IconButton
                aria-describedby={id}
                onClick={handleClick}
                size="large"
                sx={{
                    // height: '100%',
                    color: theme.palette.custom.navbar.iconColor,
                    '&:hover': {
                        color: theme.palette.primary.main,
                    }
                }}
            >
                <HelpIcon/>
            </IconButton>
        </Tooltip>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            dir="ltr"

            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            slotProps={{
                paper: {
                    sx: {
                        direction: 'rtl',
                        left: '0px', // IconButton padding is 12px (as default) - 4px = (12px - 4px)
                        top: "66px !important",
                        width: 'fit-content',
                        overflow: 'unset',
                        backgroundColor: "transparent !important",
                        boxShadow: 'none !important',
                        position: 'relative',
                    }
                }
            }}
        >
            <Box sx={{
                direction: 'ltr',
                p: 3,
                maxWidth: 335,
                borderRadius: '8px !important',
                bgcolor: theme.palette.background.paper,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                fontFamily: "Rubik",
                border: `1px solid ${alpha(theme.palette.text.secondary, 0.2)}`,

                // arrow
                "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 157,
                    // right: "auto",
                    width: 22,
                    height: 22,
                    bgcolor: theme.palette.background.paper,
                    borderRight: `1px solid ${alpha(theme.palette.text.secondary, 0.2)}`,
                    borderTop: `1px solid ${alpha(theme.palette.text.secondary, 0.2)}`,
                    transform: "translateY(-50%) rotate(45deg)",
                }

            }}>
                <Typography variant="h6" component="h3"
                            sx={{mb: 2, fontWeight: 600, textAlign: 'center', fontFamily: "Rubik"}}>
                    נתקלתם בתקלה?
                </Typography>
                <Typography variant="body1" sx={{mb: 2, textAlign: 'center'}}>
                    אנא פתחו פנייה במערכת התמיכה או צרו קשר עם צוות המחשוב של גוונים.
                </Typography>
                <Box sx={{display: 'flex', gap: 2}}>
                    <Button
                        variant="contained"
                        color="info"
                        sx={{
                            mt: 1,
                            fontWeight: 'medium'
                        }}
                        onClick={() => window.open("https://gvanim.atlassian.net/servicedesk/customer/portal/1", "_blank")}
                    >
                        פתיחת פנייה
                    </Button>
                    <Button
                        variant="outlined"
                        color="info"
                        startIcon={<PhoneIcon/>}
                        sx={{
                            mt: 1,
                            fontWeight: 'medium'
                        }}
                        onClick={() => window.location.href = 'tel:+9720546689032'}
                    >
                        התקשרו אלינו
                    </Button>
                </Box>
            </Box>
        </Popover>
    </>);
}
