import {Box, useTheme} from "@mui/material";

export default function BorderedContainer({children, error = false, sx = {}}) {
    const theme = useTheme();
    const borderStyle = error
        ? {
            border: '1px solid #d32f2f',
            '&:hover': {
                border: '1px solid #d32f2f'
            }
        }
        : {
            border: '1px solid rgba(0, 0, 0, 0.22)',
            borderColor: theme.palette.custom.borderedContainer.borderColor,
            '&:hover': {
                borderColor: theme.palette.custom.borderedContainer.hoverBorderColor
            }
        };

    return (
        <Box
            boxSizing="border-box"
            width="100%"
            borderRadius="4px"
            padding="16.5px 14px"
            sx={{
                ...borderStyle,
                ...sx
            }}
        >
            {children}
        </Box>
    );
}

