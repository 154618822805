import React from 'react';
import {Box, Chip, MenuItem, Pagination, Select, Stack, useTheme} from '@mui/material';

interface PaginatorProps {
    dataLength: number;
    rowsPerPage: number;
    setRowsPerPage: (value: number) => void;
    rowsPerPageList?: number[];
    labelText: string;
    onPageChange: (newPage: number) => void;
}

const Paginator: React.FC<PaginatorProps> = ({
                                                 dataLength,
                                                 rowsPerPage,
                                                 setRowsPerPage,
                                                 rowsPerPageList,
                                                 labelText,
                                                 onPageChange
                                             }) => {
    const theme = useTheme();
    const [page, setPage] = React.useState(1);

    const pageCount = React.useMemo(() =>
            Math.max(1, Math.ceil(dataLength / rowsPerPage)),
        [dataLength, rowsPerPage]
    );

    React.useEffect(() => {
        setPage(1);
        onPageChange(1);
    }, [dataLength, rowsPerPage]);

    React.useEffect(() => {
        if (page > pageCount) {
            setPage(pageCount);
            onPageChange(pageCount);
        }
    }, [page, pageCount]);

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        const newPage = Math.min(Math.max(1, value), pageCount);
        setPage(newPage);
        onPageChange(newPage);
    };

    const handleRowsPerPageChange = (event: any) => setRowsPerPage(event.target.value);

    return (
        <Stack
            direction={{xs: 'column', md: 'row-reverse'}}
            justifyContent='space-between'
            alignItems='center'
            padding={1}
            sx={{direction: 'rtl'}}
        >
            <Box display={{xs: 'none', md: 'flex'}} gap={1}>
                {rowsPerPageList && rowsPerPageList.length > 0 && (
                    <Select
                        value={rowsPerPage}
                        onChange={handleRowsPerPageChange}
                        sx={{
                            borderRadius: '0.5rem',
                            height: '32px',
                            border: "1px solid #bdbdbd",
                            backgroundColor: "transparent",
                            fontSize: "0.8125rem",
                            fontWeight: 'bold',
                            borderColor: theme.palette.custom.table.chipBorderColor,
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            },
                        }}
                    >
                        {rowsPerPageList.map((value) => (
                            <MenuItem key={value} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                )}

                <Chip
                    label={`${dataLength} :${labelText}`}
                    sx={{fontWeight: 'bold', borderRadius: '0.5rem'}}
                    variant="outlined"
                />
            </Box>
            <Box>
                <Pagination
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    page={page}
                    count={pageCount}
                    onChange={handlePageChange}
                    sx={{
                        '& .MuiPaginationItem-root': {
                            borderRadius: '35%',
                        },
                    }}
                />
            </Box>
        </Stack>
    );
};

export default Paginator;
