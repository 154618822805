import React from "react";

export const container = (theme) => ({
    position: "sticky",
    overflow: 'hidden',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    top: 0,
    boxShadow: "0px 3px 10px",
    color: theme.gray,
    zIndex: 20,
});

export const container2nd = (theme) => ({
    height: '73px',
    backgroundColor: theme.white,
    paddingLeft: 2,
    paddingRight: 4,
    borderTop: '5px solid',
    borderColor: theme.main,
});

export const container3rd = () => ({
    mt: '5px',
    width: 'fit-content'
});


export const logoImageMob = (theme) => ({
    pointerEvents: 'none' as React.CSSProperties['pointerEvents'],
    WebkitUserSelect: 'none' as React.CSSProperties['WebkitUserSelect'],
    MozUserSelect: 'none' as React.CSSProperties['MozUserSelect'],
    msUserSelect: 'none' as React.CSSProperties['msUserSelect'],
    userSelect: 'none' as React.CSSProperties['userSelect'],
    WebkitTouchCallout: 'none' as any,
    WebkitUserDrag: 'none' as any,
    padding: '15px',
    height: '70px',
    WebkitFilter: theme.webkit_filter,

    // filter: 'drop-shadow(0 4px 8px rgba(255, 255, 255, 0.3)) drop-shadow(0 0 16px rgba(0, 122, 204, 0.3))'
});

export const logoImageDesk = (theme) => ({
    pointerEvents: 'none' as React.CSSProperties['pointerEvents'],
    WebkitUserSelect: 'none' as React.CSSProperties['WebkitUserSelect'],
    MozUserSelect: 'none' as React.CSSProperties['MozUserSelect'],
    msUserSelect: 'none' as React.CSSProperties['msUserSelect'],
    userSelect: 'none' as React.CSSProperties['userSelect'],
    WebkitTouchCallout: 'none' as any,
    WebkitUserDrag: 'none' as any,
    padding: '10px',
    height: '70px',
    WebkitFilter: theme.webkit_filter,
    // filter: 'drop-shadow(0 4px 8px rgba(255, 255, 255, 0.3)) drop-shadow(0 0 16px rgba(0, 122, 204, 0.4))'
});

// Desktop navigation
export const desktopNav = {
    height: '100%',
    filter: theme => theme.brightness
};

export const desktopNavItem = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    height: '100%'
};

export const desktopNavBox = {
    height: '100%'
};

export const desktopNavLink: React.CSSProperties = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textDecoration: 'none'
};

export const navLinkContent = {
    mt: 'auto',
    mb: 'auto',
    ":hover + #borderBottom": {
        display: 'flex',
    }
};

export const navLinkText = (theme) => ({
    color: theme.body,
    opacity: 1,
    letterSpacing: '0px',
    mb: 0
});

export const navLinkBorder = (theme) => ({
    display: 'none',
    mt: 0,
    mr: 'auto',
    ml: 'auto',
    background: theme.body,
    height: '4px',
    width: '100%',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
});

// Contact section
export const helpText = (theme) => ({
    textDecorationLine: 'underline',
    color: theme.underline,
    filter: theme.brightness,
    letterSpacing: 0,
    opacity: 1,
});

export const phoneNumber = (theme) => ({
    color: theme.caption,
    letterSpacing: 0,
    opacity: 1,
    filter: theme.brightness
});

// Mobile navigation
export const mobileNav = {
    mt: '5px',
    width: '100%',
};

export const mobileMenuButton = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
};

export const mobileLogoContainer = {
    display: 'flex',
    flexDirection: 'row',
    height: '58px',
    overflow: 'hidden'
};

export const mobilePhoneContainer = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
};

// Hamburger menu
export const hamburgerStack = {
    direction: 'column',
    spacing: '7px',
    justifyContent: 'space-between',
    minHeight: '17px'
};

export const hamburgerLine = (open, theme) => ({
    display: 'block',
    position: 'relative',
    width: '22px',
    height: '1px',
    borderRadius: '1px',
    background: theme.body,
    transition: 'all .2s',
    margin: '0 auto'
});

export const hamburgerLineTop = (open, theme) => ({
    ...hamburgerLine(open, theme),
    transform: open ? 'rotate(45deg)' : 'none',
    transformOrigin: open ? '10% 10%' : 'unset',
});

export const hamburgerLineMiddle = (open, theme) => ({
    ...hamburgerLine(open, theme),
    display: open ? 'none' : 'block'
});

export const hamburgerLineBottom = (open, theme) => ({
    ...hamburgerLine(open, theme),
    transform: open ? 'rotate(-45deg)' : 'none',
    transformOrigin: open ? '-10% -10%' : 'unset',
});

// Mobile menu dropdown
export const mobileMenuContainer = (theme) => ({
    display: { xs: 'flex', md: 'none' },
    backgroundColor:
        theme.mode === 'light' ? '#FFFFFF' :
            theme.mode === 'dark-contrast' ? '#000000' :
                theme.mode === 'bright-contrast' ? '#FFFFFF' : '#FFFFFF'
});

export const mobileMenuStack = {
    direction: 'column',
    justifyContent: 'center',
    marginTop: 4,
    paddingBottom: 2,
    spacing: 2,
    width: '100%',
    height: 'fit-content'
};

export const mobileMenuLink: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    textDecoration: 'none'
};

export const mobileMenuText = (theme) => ({
    color: theme.body,
    font: 'normal normal normal 20px Rubik',
    opacity: 1,
    letterSpacing: '0px',
    mb: 0
});

export const mobileMenuBorder = (theme) => ({
    position: 'relative',
    background: 'transparent',
    transition: 'all 0.3s ease-in-out',
    '&::before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '0%',
        height: '2px',
        background: theme.body,
        borderRadius: '1px',
        transition: 'width 0.3s ease-in-out'
    },
    '&:hover::before': {
        width: '100%'
    }
});