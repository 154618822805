import FullScreenDialog, {FullScreenDialogHeader} from "../../../Components/Dialog/FullScreenDialog/FullScreenDialog";
import CreateCandidateHeader from "./Components/CreateJobHeader";
import CreateCandidateForm from "./Components/CreateCandidateForm";

export default function CreateCandidate({show, close, candidate}) {
    return <>
        <FullScreenDialog show={show} close={close} centerContent={true}>
            <FullScreenDialogHeader>
                <CreateCandidateHeader candidate={candidate}/>
            </FullScreenDialogHeader>

            <CreateCandidateForm candidate={candidate} close={close}/>
        </FullScreenDialog>
    </>
}
