import {Box, Button, Collapse, Divider, Stack, Typography} from "@mui/material";
import React from "react";
import {Close, FilterListRounded, Place, SupervisorAccount, WatchLater} from "@mui/icons-material";
import {ColorModeContext} from "../../../../Theme/Theme";
import SearchBar from "./SearchBar/SearchBar";
import LocationMultiSelect from "./MultiSelects/LocationMultiSelect";
import {LoadingButton} from "@mui/lab";
import RolesMultiSelect from "./MultiSelects/RolesMultiSelect";
import {OptionType} from "../../../../Components/CustomMultiSelect/Option.type";

type DisplayType = 'desktop' | 'mobile' | 'none';
export default function FilterPanel(props: {
    display: DisplayType,
    setSearch,
    setLocationFilter,
    setRoleFilter,
    setScope
}) {
    const {display, setSearch, setLocationFilter, setRoleFilter, setScope} = props;

    const [text, setText] = React.useState('');
    const [buttonLoading] = React.useState(false);
    const [locationSelected, setLocationSelected] = React.useState<OptionType[] | null>();
    const [roleSelected, setRoleSelected] = React.useState<OptionType[] | null>();
    const [filterMobile, setFilterMobile] = React.useState(false);
    const [scopeSelected, setScopeSelected] = React.useState<null | number>(null);

    const colorMode = React.useContext(ColorModeContext);

    const handleFilter = () => {
        setSearch(text);
        setLocationFilter(locationSelected);
        setRoleFilter(roleSelected);
        setScope(scopeSelected);

        setFilterMobile(false);
    };

    const handleScope = (btn: "full" | "half") => {
        if (scopeSelected === 50 && btn === "half") {
            setScopeSelected(null);
            return;
        }

        if (scopeSelected === 100 && btn === "full") {
            setScopeSelected(null);
            return;
        }

        setScopeSelected(btn === 'full' ? 100 : 50);
    }

    const renderFilterDesktop = () => (
        <Box id='filterContainerDesktop' padding={{xl: 2}} display={{xs: 'none', lg: 'block'}} width={'26%'}>

            <Box>
                <Box width="26%">
                    <Stack direction='row' spacing={1} justifyContent='start' sx={{paddingLeft: 2}}>

                        <FilterListRounded
                            sx={{color: colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2'}}/>


                        <Stack direction='row' spacing={1}>
                            <Typography variant='h2' sx={{
                                color: colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2',
                            }}>סינון</Typography>

                        </Stack>
                    </Stack>


                </Box>
                {/* #2b2c25 */}
                <Box sx={{
                    backgroundColor: 'primary.divider',
                    height: '2px',
                    width: '92%',
                    mt: 2,
                    mb: 2,
                    mr: 'auto',
                    ml: 'auto',
                    borderRadius: 1
                }}/>
                {/* #191919 */}
                <Box sx={{borderRadius: '10px', backgroundColor: 'primary.filterBar', paddingBottom: 2}}>
                    <Box sx={{paddingLeft: 4, paddingTop: 2}}>
                        <Typography variant='subtitle2' sx={{
                            color: colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2',
                        }}>חיפוש לפי מילה חופשית:</Typography>
                        <SearchBar text={text} setText={setText} handleFilter={handleFilter}/>
                    </Box>
                    <Divider sx={{mt: 3.5, mb: 3, backgroundColor: 'primary.filterDivider', borderRadius: 1}}/>

                    {/* ################# Location FILTER #################### */}
                    <Box>
                        <Stack direction='row' spacing={2} justifyContent='start' sx={{paddingLeft: 4}}>


                            <Place fontSize='small' sx={{
                                color: colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2',
                                mb: 0.6
                            }}/>

                            <Stack direction='row' spacing={1} alignItems='end'>
                                <Typography variant='subtitle2' sx={{
                                    color: colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2',
                                }}>חיפוש לפי מיקום:</Typography>

                            </Stack>
                        </Stack>
                        <Box sx={{mt: 2, paddingLeft: 4, paddingRight: 4}}>
                            <LocationMultiSelect optionSelected={locationSelected} setSelected={setLocationSelected}/>
                        </Box>
                    </Box>
                    {/* ############################################## */}

                    <Divider sx={{mt: 3, mb: 3, backgroundColor: 'primary.filterDivider'}}/>

                    {/* ################# Scope FILTER #################### */}
                    <Box>
                        <Stack direction='row' spacing={2} justifyContent='start' sx={{paddingLeft: 4}}>

                            <WatchLater fontSize='small' sx={{
                                color: colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2',
                                padding: 0.20,
                                mt: -0.4
                            }}/>
                            <Stack direction='row' spacing={1.2}>
                                <Typography variant='subtitle2' alignItems='end'
                                            sx={{color: colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2'}}>
                                    בחרו היקף משרה:
                                </Typography>

                            </Stack>
                        </Stack>
                        <Stack direction='row' spacing={2.5} sx={{mt: 2.5, paddingLeft: 4.5, paddingRight: 4.5}}>
                            <Button
                                onClick={() => handleScope('full')}
                                sx={{
                                    width: '100%',
                                    fontSize: 'medium',
                                    padding: 1.2,
                                    backgroundColor: scopeSelected === 100 ? (colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2') : 'primary.jobScopeButton',
                                    color: (scopeSelected === 100 ? (colorMode?.getActualMode()! === 'bright-contrast' ? '#FFFFFF' : 'primary.JobTitle2') : (colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2')),
                                    borderRadius: '4px',
                                    opacity: 1,
                                    ':hover': {
                                        backgroundColor: scopeSelected === 100 ? (colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2') : 'secondary.jobScopeButton'
                                    },
                                }}
                            >
                                משרה מלאה
                            </Button>
                            <Button
                                onClick={() => handleScope('half')}
                                sx={{
                                    width: '100%',
                                    fontSize: 'medium',
                                    padding: 1.2,
                                    backgroundColor: scopeSelected !== null && scopeSelected !== 100 ? (colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2') : 'primary.jobScopeButton',
                                    color: (scopeSelected !== null && scopeSelected !== 100 ? (colorMode?.getActualMode()! === 'bright-contrast' ? '#FFFFFF' : 'primary.JobTitle2') : (colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2')),
                                    borderRadius: '4px',
                                    opacity: 1,
                                    ':hover': {
                                        backgroundColor: scopeSelected !== null && scopeSelected !== 100 ? (colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2') : 'secondary.jobScopeButton'
                                    },
                                }}
                            >
                                חצי משרה
                            </Button>
                        </Stack>
                    </Box>
                    {/* ############################################## */}

                    <Divider sx={{mt: 3, mb: 3, backgroundColor: 'primary.filterDivider'}}/>

                    {/* ################# Role FILTER #################### */}
                    <Box>
                        <Stack direction='row' spacing={2} justifyContent='start' sx={{paddingLeft: 4}}>

                            <SupervisorAccount
                                sx={{color: colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2'}}/>

                            <Stack direction='row' spacing={1} alignItems='end'>
                                <Typography variant='subtitle2'
                                            sx={{color: colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2'}}>חיפוש
                                    לפי תפקיד:</Typography>

                            </Stack>
                        </Stack>
                        <Box sx={{mt: 2, paddingLeft: 4, paddingRight: 4}}>
                            <RolesMultiSelect optionSelected={roleSelected} setSelected={setRoleSelected}/>
                        </Box>
                    </Box>
                    {/* ############################################## */}
                    <Box sx={{display: 'flex', justifyContent: 'center', mt: 5, mb: 2}}>
                        <LoadingButton
                            loading={buttonLoading}
                            loadingIndicator="טוען.."
                            onClick={handleFilter} sx={{
                            width: '185px',
                            height: '40px',
                            fontWeight: 500,
                            backgroundColor: 'primary.filterButton',
                            color: 'primary.JobTitle2',
                            opacity: 1,
                            borderRadius: '30px',
                            ':hover': {
                                backgroundColor: 'secondary.filterButton',
                            },
                        }}>
                            החל סינון
                        </LoadingButton>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
    const renderFilterMobile = () => (
        <Box width='100%' display={{xs: 'flex', lg: 'none'}} paddingLeft={{xs: 0, md: 4, lg: 0}} justifyContent='center'
             sx={{paddingTop: 1.5, paddingBottom: 1.5}}>
            <Box display='flex' flexDirection='column' justifyContent='space-between' width='100%'>
                <Box display={{xs: 'flex', lg: 'none'}} sx={{
                    backgroundColor: 'primary.divider',
                    height: '2px',
                    width: '100%',
                    mt: 2,
                    mb: 2,
                    ml: 'auto',
                    borderRadius: 1
                }}/>
                <Stack direction='column' justifyContent='center'
                       sx={{
                           backgroundColor: 'primary.filterBar',
                           borderRadius: '10px',
                           minHeight: '60px',
                           width: '100%'

                       }}>
                    <Button disableRipple sx={{
                        display: 'flex',
                        justifyContent: 'start',
                        ":hover": {backgroundColor: 'primary.filterBar'}
                    }} onClick={() => setFilterMobile(!filterMobile)}>
                        <Stack direction='row' spacing={filterMobile ? 1.5 : 2} justifyContent='start'
                               sx={{paddingLeft: filterMobile ? 0 : 2}}>
                            <Box sx={{
                                display: filterMobile ? 'none' : 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center'
                            }}>
                                <FilterListRounded
                                    sx={{color: colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2'}}/>
                            </Box>

                            <Box sx={{
                                display: filterMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                paddingTop: filterMobile ? 1.5 : 0
                            }}>
                                <Close
                                    sx={{color: colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2'}}/>
                            </Box>

                            <Stack direction='row' spacing={1} sx={{paddingTop: filterMobile ? 1.5 : 0}}>
                                <Typography sx={{
                                    textAlign: 'left',
                                    fontWeight: 'bold',
                                    fontFamily: 'Rubik',
                                    letterSpacing: '0px',
                                    color: colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2',
                                    opacity: 1,
                                }}>סינון</Typography>

                            </Stack>
                        </Stack>
                    </Button>

                    <Collapse in={filterMobile}>
                        <Box sx={{width: '100%', height: 'fit-content'}}>

                            <Box sx={{
                                backgroundColor: 'primary.divider',
                                height: '1px',
                                width: '100%',
                                mt: 1.5,
                                mb: 2,
                                mr: 'auto',
                                ml: 'auto'
                            }}/>

                            <Box sx={{borderRadius: '10px', backgroundColor: 'primary.filterBar', paddingBottom: 2}}>

                                <Box sx={{paddingLeft: 4, paddingTop: 2}}>
                                    <Typography variant='subtitle2' sx={{
                                        color: colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2',
                                    }}>חיפוש לפי מילה חופשית:</Typography>
                                    <SearchBar text={text} setText={setText} handleFilter={handleFilter}/>
                                </Box>
                                <Divider
                                    sx={{mt: 3.5, mb: 3, backgroundColor: 'primary.filterDivider', borderRadius: 1}}/>

                                {/* ################# Location FILTER #################### */}
                                <Box>
                                    <Stack direction='row' spacing={2} justifyContent='start'
                                           sx={{mt: 4, paddingLeft: 4}}>


                                        <Place fontSize='small' sx={{
                                            color: colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2',
                                            mb: 0.6
                                        }}/>

                                        <Stack direction='row' spacing={1} alignItems='end'>
                                            <Typography variant='subtitle2' sx={{
                                                color: colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2',
                                            }}>חיפוש לפי מיקום:</Typography>

                                        </Stack>
                                    </Stack>
                                    <Box sx={{mt: 2, paddingLeft: 4, paddingRight: 4}}>
                                        <LocationMultiSelect optionSelected={locationSelected}
                                                             setSelected={setLocationSelected}/>
                                    </Box>
                                </Box>
                                {/* ############################################## */}

                                <Divider sx={{mt: 3, mb: 3, backgroundColor: 'primary.filterDivider'}}/>

                                {/* ################# Scope FILTER #################### */}
                                <Box>
                                    <Stack direction='row' spacing={2} justifyContent='start'
                                           sx={{mt: 4, paddingLeft: 4}}>

                                        <WatchLater fontSize='small' sx={{
                                            color: colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2',
                                            padding: 0.20,
                                            mt: -0.4
                                        }}/>
                                        <Stack direction='row' spacing={1.2}>
                                            <Typography variant='subtitle2' alignItems='end'
                                                        sx={{color: colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2'}}>
                                                בחרו היקף משרה:
                                            </Typography>

                                        </Stack>
                                    </Stack>
                                    <Stack direction='row' spacing={2.5}
                                           sx={{mt: 2.5, paddingLeft: 4.5, paddingRight: 4.5}}>
                                        <Button
                                            onClick={() => handleScope('full')}
                                            sx={{
                                                width: '100%',
                                                fontSize: 'medium',
                                                padding: 1.2,
                                                backgroundColor: scopeSelected === 100 ? (colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2') : 'primary.jobScopeButton',
                                                color: (scopeSelected === 100 ? (colorMode?.getActualMode()! === 'bright-contrast' ? '#FFFFFF' : 'primary.JobTitle2') : (colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2')),
                                                borderRadius: '4px',
                                                opacity: 1,
                                                ':hover': {
                                                    backgroundColor: scopeSelected === 100 ? (colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2') : 'secondary.jobScopeButton'
                                                },
                                            }}
                                        >
                                            משרה מלאה
                                        </Button>
                                        <Button
                                            onClick={() => handleScope('half')}
                                            sx={{
                                                width: '100%',
                                                fontSize: 'medium',
                                                padding: 1.2,
                                                backgroundColor: scopeSelected !== null && scopeSelected !== 100 ? (colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2') : 'primary.jobScopeButton',
                                                color: (scopeSelected !== null && scopeSelected !== 100 ? (colorMode?.getActualMode()! === 'bright-contrast' ? '#FFFFFF' : 'primary.JobTitle2') : (colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2')),
                                                borderRadius: '4px',
                                                opacity: 1,
                                                ':hover': {
                                                    backgroundColor: scopeSelected !== null && scopeSelected !== 100 ? (colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2') : 'secondary.jobScopeButton'
                                                },
                                            }}
                                        >
                                            חצי משרה
                                        </Button>
                                    </Stack>
                                </Box>
                                {/* ############################################## */}

                                <Divider sx={{mt: 3, mb: 3, backgroundColor: 'primary.filterDivider'}}/>

                                {/* ################# Role FILTER #################### */}
                                <Box>
                                    <Stack direction='row' spacing={2} justifyContent='start'
                                           sx={{mt: 4, paddingLeft: 4}}>

                                        <SupervisorAccount
                                            sx={{color: colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2'}}/>

                                        <Stack direction='row' spacing={1} alignItems='end'>
                                            <Typography variant='subtitle2'
                                                        sx={{color: colorMode?.getActualMode()! === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2'}}>חיפוש
                                                לפי תפקיד:</Typography>

                                        </Stack>
                                    </Stack>
                                    <Box sx={{mt: 2, paddingLeft: 4, paddingRight: 4}}>
                                        <RolesMultiSelect optionSelected={roleSelected} setSelected={setRoleSelected}/>
                                    </Box>
                                </Box>
                                {/* ############################################## */}
                                <Box sx={{display: 'flex', justifyContent: 'center', mt: 5, mb: 2}}>
                                    <LoadingButton
                                        loading={buttonLoading}
                                        loadingIndicator="טוען.."
                                        onClick={handleFilter}
                                        sx={{
                                            width: '185px',
                                            height: '40px',
                                            fontWeight: 500,
                                            backgroundColor: 'primary.filterButton',
                                            color: 'primary.JobTitle2',
                                            opacity: 1,
                                            borderRadius: '30px',
                                            ':hover': {
                                                backgroundColor: 'secondary.filterButton',
                                            },
                                        }}>
                                        החל סינון
                                    </LoadingButton>
                                </Box>
                            </Box>

                        </Box>
                    </Collapse>
                </Stack>
                <Box display={{xs: 'flex', lg: 'none'}} sx={{
                    backgroundColor: 'primary.divider',
                    height: '2px',
                    width: '100%',
                    mt: 2,
                    mb: 2,
                    ml: 'auto',
                    borderRadius: 1
                }}/>
            </Box>
        </Box>
    );

    const renderer = () => {
        if (display === 'none') {
            return (<></>);
        }

        return (<>
            {display === 'desktop' ? renderFilterDesktop() : renderFilterMobile()}
        </>);
    }

    return (<>{renderer()}</>);
}
