import {createContext, useContext, useEffect, useState, ReactNode} from 'react';
import {collection, getDocs} from 'firebase/firestore';
import {db} from '../../../Firebase/FirebaseConfig/firebase';
import {getLocalStorage, setLocalStorage} from "../../../Firebase/FirebaseFunctions/Helpers/LocalStorage";
import {RolesLocalStorage} from "../../../Firebase/FirebaseFunctions/RoleService";

interface RolesContextType {
    roles: Array<any>;
    loadingRoles: boolean;
    refreshRoles: () => Promise<void>;
}

const RolesContext = createContext<RolesContextType | undefined>(undefined);

export function RolesDataProvider({children}: { children: ReactNode }) {
    const [roles, setRoles] = useState<Array<any>>(getLocalStorage(RolesLocalStorage));
    const [loadingRoles, setLoadingRoles] = useState<boolean>(true);

    const fetchRoles = async () => {
        setLoadingRoles(true);
        try {
            const snapshot = await getDocs(collection(db, 'roles'));
            const newData = snapshot.docs.map(doc => ({
                id: doc.id,
                name: doc.data().name,
                open: doc.data().open
            }));
            console.log('[ROLES] Fetched roles (from server) length:', newData.length);

            setRoles(newData);
            setLocalStorage(RolesLocalStorage, newData);
            localStorage.setItem('rolesLastFetchTime', new Date().getTime().toString());
        } catch (error) {
            console.error("[ROLES] Error fetching roles: ", error);
            const cachedData = getLocalStorage(RolesLocalStorage);
            setRoles(cachedData);
        } finally {
            setLoadingRoles(false);
        }
    };

    const refreshRoles = async () => {
        setLoadingRoles(true);
        console.log("[ROLES] data refreshed (from local).");
        setRoles(getLocalStorage(RolesLocalStorage));
        setLoadingRoles(false);
    };

    useEffect(() => {
        const lastFetch = localStorage.getItem('rolesLastFetchTime');
        const currentTime = new Date().getTime();
        const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes cache

        if (lastFetch && currentTime - parseInt(lastFetch) < CACHE_DURATION) {
            const cachedData = getLocalStorage(RolesLocalStorage);
            console.log('[ROLES] Fetched roles (from local) length:', cachedData.length);
            setRoles(cachedData);
            setLoadingRoles(false);
            return;
        }

        fetchRoles();
    }, []);

    return (
        <RolesContext.Provider value={{roles, loadingRoles, refreshRoles}}>
            {children}
        </RolesContext.Provider>
    );
}

export function useRolesData(): RolesContextType {
    const context = useContext(RolesContext);
    if (!context) {
        throw new Error("useRolesData must be used within a RolesDataProvider");
    }
    return context;
}
