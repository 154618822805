import {Box, FormControl, Grid, InputLabel, TextField} from "@mui/material";
import React from "react";
import SelectOpenClosed from "../../../../Components/Selects/SelectOpenClosed";
import * as Yup from "yup";
import {useFormik} from "formik";
import CustomCard from "../../../../Components/Cards/Card";
import {useSectorCreate, useSectorUpdate} from "../../../../Functions/Sector";
import {LoadingButton} from "@mui/lab";

export default function CreateSectorForm({close, sector}) {
    const addSector = useSectorCreate(close);
    const updateSector = useSectorUpdate(close);

    const validationSchema = Yup.object().shape({
        sectorName: Yup.string().required('שדה זה הוא חובה'),
    });

    const formik = useFormik({
        initialValues: {
            sectorName: sector?.name as string || '',
            sectorType: sector === null ? "פתוח" : (sector?.open ? "פתוח" : "סגור"),
        },
        validationSchema: validationSchema,
        onSubmit: async (values, {setSubmitting}) => {
            setSubmitting(true);

            if (sector) {
                const name = sector.name === values.sectorName ? null : values.sectorName;
                const open = sector.open === (values.sectorType === "פתוח") ? null : (values.sectorType === "פתוח");
                await updateSector(sector, name, open);
            } else {
                await addSector(values.sectorName, values.sectorType === "פתוח");
            }

            setSubmitting(false);
        },
    });

    return (
        <Box
            sx={{
                position: "relative",
                display: "flex",
                justifySelf: "center",
                width: {md: "50%", sm: "70%", xs: "100%"},
            }}
        >
            <CustomCard>
                <Box component="form" noValidate onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <FormControl
                                fullWidth
                                size="small"
                                error={formik.touched.sectorName && Boolean(formik.errors.sectorName)}
                            >
                                <TextField
                                    label="שם האשכול"
                                    name="sectorName"
                                    fullWidth
                                    variant="standard"
                                    value={formik.values.sectorName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    helperText={formik.touched.sectorName && (
                                        <span style={{color: 'red'}}>{formik.errors.sectorName}</span>
                                    )}
                                    error={formik.touched.sectorName && Boolean(formik.errors.sectorName)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth variant="standard" size="small">
                                <InputLabel>אשכול</InputLabel>
                                <SelectOpenClosed
                                    name="sectorType"
                                    value={formik.values.sectorType}
                                    onChange={formik.handleChange}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                loading={formik.isSubmitting}
                                disabled={(sector && formik.values.sectorName === sector.name && (formik.values.sectorType === "פתוח") === sector.open) || formik.isSubmitting}
                            >
                                {sector ? "ערוך" : "הוסף"}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </CustomCard>
        </Box>
    );
}