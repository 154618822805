import React from "react";
import {Box, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel,} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {Field, Form, Formik} from "formik";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import DateRangePicker from "../../../Components/DateRangePicker";
import {useSnackbar} from "../../../../Utilities/Context/Snackbar";
import SelectSector from "../../../Components/Selects/SelectSector";
import SelectRole from "../../../Components/Selects/SelectRole";
import CustomCard from "../../../Components/Cards/Card";
import {useSectorsData} from "../../../Contexts/DataContext/SectorsDataContext";
import {useJobsData} from "../../../Contexts/DataContext/JobsDataContext";
import {exportToExcel} from "../../../Utilities/ExcelExport";

export default function ReportsByJobs() {
    dayjs.extend(isBetween);
    const {showSnackbar} = useSnackbar();

    const {sectors} = useSectorsData();
    const {jobs} = useJobsData();

    const initialValues = {
        selectedRole: "כל התפקידים",
        selectedSector: "כל האשכולות",
        openJobs: true,
        highPriority: false,
        startDate: dayjs().subtract(7, "day"),
        endDate: dayjs(),
    };

    const handleSubmit = async (values, {setSubmitting}) => {
        setSubmitting(true);

        if (values.endDate.isBefore(values.startDate)) {
            showSnackbar({message: "התאריכים בדו'ח משרות שגויים."});
            setSubmitting(false);
            return;
        }

        try {
            // Sectors
            const filteredSectors = values.selectedSector === "כל האשכולות"
                ? sectors.map(sec => sec.id)
                : [sectors.find(sec => sec.name === values.selectedSector)!.id];

            let results = jobs.filter(job => filteredSectors.includes(job.sector));

            // Roles
            if (values.selectedRole !== "כל התפקידים") {
                results = results.filter(job => job.role === values.selectedRole);
            }

            if (values.openJobs) {
                results = results.filter(job => job.open);
            }

            if (values.highPriority) {
                results = results.filter(job => job.highPriority);
            }

            results = results.filter(data =>
                dayjs(data.creationDate).isBetween(values.startDate, values.endDate, 'day', '[]')
            );

            const columns = [
                {field: 'jobNumber', header: "מספר משרה"},
                {field: 'role', header: "תפקיד"},
                {field: "sector", header: "אשכול"},
                {field: "location", header: "איזור המשרה"},
                {field: "scope", header: "אחוז משרה"},
                {field: "priority", header: "משרה בעדיפות גבוהה"},
                {field: "open", header: "משרה פתוחה/סגורה"},
                {field: "creationDate", header: "תאריך יצירת משרה"}
            ];

            const data = results.map((result) => {
                const sector = sectors.find((sector) => sector.id === result.sector);

                return {
                    jobNumber: result.jobNumber,
                    role: result.role,
                    sector: sector?.name || "לא נמצא",
                    location: result.region,
                    scope: [...(result.scope || [])].map(n => `${n}%`).join(" - "),
                    priority: result.highPriority ? "כן" : "לא",
                    open: result.open ? "פתוחה" : "סגורה",
                    creationDate: dayjs(result.creationDate).format("DD/MM/YYYY HH:mm"),
                    RowColor: result.open ? "E2EFDA" : "FFE6E6"
                }
            }).sort((a, b) => b.open.localeCompare(a.open));

            exportToExcel(`jobs-report`, 'דו"ח', 'דו"ח משרות', columns, data);
        } catch (error) {
            showSnackbar({message: `אירעה שגיאה. יש לפתוח פנייה. שגיאה: ${error}`})
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <CustomCard title='דו"ח משרות'>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {({values, setFieldValue, isSubmitting}) => (
                    <Form>
                        <Grid container spacing={2}>
                            {/* Checkboxes */}
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                        marginY: "1rem",
                                    }}
                                >
                                    {/* Open Jobs Checkbox */}
                                    <Box>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.openJobs}
                                                    onChange={(e) =>
                                                        setFieldValue("openJobs", e.target.checked)
                                                    }
                                                />
                                            }
                                            label="כלול רק את המשרות הפתוחות"
                                        />
                                        <FormHelperText>
                                            במידה ושדה זה לא יסומן, הדו"ח יכלול גם את המשרות הסגורות
                                        </FormHelperText>
                                    </Box>

                                    {/* Divider */}
                                    <Box sx={{marginX: "1rem"}}>
                                        <Divider orientation="vertical"/>
                                    </Box>

                                    {/* High Priority Checkbox */}
                                    <Box>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.highPriority}
                                                    onChange={(e) =>
                                                        setFieldValue("highPriority", e.target.checked)
                                                    }
                                                />
                                            }
                                            label="כלול רק את המשרות בעדיפות גבוהה"
                                        />
                                        <FormHelperText>
                                            במידה ושדה זה יסומן, הדו"ח יכלול רק את המשרות אשר מסווגות
                                            ב-"עדיפות גבוהה"
                                        </FormHelperText>
                                    </Box>
                                </Box>
                            </Grid>

                            {/* Select Sector */}
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth size="small">
                                    <InputLabel>אשכול</InputLabel>
                                    <Field name="selectedSector">
                                        {({field, form}) => (
                                            <SelectSector
                                                value={field.value}
                                                allSectors={true}
                                                labelId="jobs-label"
                                                onChange={(value) =>
                                                    form.setFieldValue("selectedSector", value)
                                                }
                                            />
                                        )}
                                    </Field>
                                    <FormHelperText>משרות תחת אשכול ספציפי</FormHelperText>
                                </FormControl>
                            </Grid>

                            {/* Select Role */}
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="role-label">תפקיד</InputLabel>
                                    <Field name="selectedRole">
                                        {({field, form}) => (
                                            <SelectRole
                                                value={field.value}
                                                allRoles={true}
                                                onChange={(value) =>
                                                    form.setFieldValue(
                                                        "selectedRole",
                                                        value
                                                    )
                                                }
                                                labelId="role-label"
                                            />
                                        )}
                                    </Field>
                                    <FormHelperText>משרות תחת תפקיד ספציפי</FormHelperText>
                                </FormControl>
                            </Grid>

                            {/* Date Range Picker */}
                            <Grid item xs={12} md={6}>
                                <DateRangePicker
                                    startDate={values.startDate}
                                    endDate={values.endDate}
                                    onStartDateChange={(date) =>
                                        setFieldValue("startDate", date)
                                    }
                                    onEndDateChange={(date) =>
                                        setFieldValue("endDate", date)
                                    }
                                />
                            </Grid>

                            {/* Submit Button */}
                            <Grid
                                item
                                xs={12}
                                md={6}
                                display="flex"
                                justifyContent={{xs: "center", md: "flex-end"}}
                            >
                                <LoadingButton
                                    sx={{
                                        width: {
                                            xs: "100%",
                                            md: "auto",
                                        },
                                    }}
                                    color="info"
                                    variant="contained"
                                    type="submit"
                                    loading={isSubmitting}
                                >
                                    הנפק דו"ח
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </CustomCard>
    );
}
