import React, {useState, useEffect, useRef} from "react";
import Confetti from "react-confetti-boom";

export default function ShowConfetti({show, setShow, duration = 5000}) {
    const [fadeOut, setFadeOut] = useState(false);
    const confettiRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (show) {
            setShow(true);
            setTimeout(() => {
                setFadeOut(true);
                setTimeout(() => {
                    setShow(false);
                    setFadeOut(false);
                }, 1000);
            }, duration);
        }
    }, [show, duration]);

    useEffect(() => {
        if (confettiRef.current) {
            confettiRef.current.style.opacity = fadeOut ? '0' : '1';
            confettiRef.current.style.transition = 'opacity 1s ease';
        }
    }, [fadeOut]);

    return (
        <>
            {show && (
                <div ref={confettiRef}>
                    <Confetti
                        mode="fall"
                        particleCount={85}
                        shapeSize={25}
                        // colors={['#063b7af8', '#41c2f0f1', '#43c4f241', '#053a7a52']}
                        colors={['#a864fd', '#41c2f0f1', '#78ff44', '#ff718d', '#fdff6a']}
                    />
                </div>
            )}
        </>
    );
}