import isMobile from "./isMobile";

interface WhatsAppMessageProps {
    phoneNumber: string;
    message: string;
}

// strip down a number like 0501231234 to 972501231234
const sanitisePhoneNumber = (phoneNumber: string): string => {
    // Remove all non-numeric characters first
    let cleaned = phoneNumber.replace(/\D/g, '');

    // Remove leading '+972' if present
    if (cleaned.startsWith('972')) {
        cleaned = cleaned.slice(3);
    }

    // Remove leading '0' if present
    if (cleaned.startsWith('0')) {
        cleaned = cleaned.slice(1);
    }

    return cleaned;
};

export const sendWhatsAppMessage = ({phoneNumber, message}: WhatsAppMessageProps): void => {
    const cleanNumber = `972${sanitisePhoneNumber(phoneNumber)}`;
    const url = isMobile()
        ? `https://wa.me/${cleanNumber}?text=${encodeURIComponent(message)}`
        : `https://web.whatsapp.com/send?phone=${cleanNumber}&text=${encodeURIComponent(message)}&app_absent=0`;

    window.open(url);
};