import {useDialog} from "../Contexts/Dialog";
import {useSnackbar} from "../../Utilities/Context/Snackbar";
import {useJobsData} from "../Contexts/DataContext/JobsDataContext";
import {jobService} from "../../Firebase/FirebaseFunctions/JobsService";

export function useJobDelete(closeHandler?: () => void) {
    const {showDialog} = useDialog();
    const {showSnackbar} = useSnackbar();
    const {refreshJobs} = useJobsData();

    return (job) => {
        showDialog({
            title: `מחיקת משרה מס' ${job.jobNumber}`,
            description: `האם למחוק את המשרה "${job.title}"?`,
            onAccept: async () => {
                try {
                    const id = job.jobNumber.toString();
                    await jobService.deleteJob(id);
                    await refreshJobs();

                    showSnackbar({
                        message: `המשרה נמחקה בהצלחה.`,
                        severity: "success"
                    });

                    if (closeHandler) {
                        closeHandler();
                    }
                } catch (error) {
                    showSnackbar({message: `מחיקת משרה נכשל. שגיאה: ${error}`})
                }
            }
        });
    };
}

export function useJobUpdate(closeHandler?: () => void) {
    const {showDialog} = useDialog();
    const {showSnackbar} = useSnackbar();
    const {refreshJobs} = useJobsData();

    return (job, fieldsToUpdate) => {
        return new Promise((resolve) => {
            showDialog({
                title: `עדכון משרה מס' ${job.jobNumber}`,
                description: `האם לעדכן את המשרה "${job.title}"?`,
                onAccept: async () => {
                    try {
                        const id = job.jobNumber.toString();
                        await jobService.updateJobFields(id, fieldsToUpdate);
                        await refreshJobs();

                        showSnackbar({
                            message: `המשרה עודכנה בהצלחה.`,
                            severity: "success"
                        });

                        if (closeHandler) {
                            closeHandler();
                        }
                        resolve(true);
                    } catch (error) {
                        showSnackbar({message: `עדכון משרה נכשל. שגיאה: ${error}`})
                        resolve(false);
                    }
                },
                onCancel: () => resolve(false)
            });
        })

    };
}

export function useJobAdd(closeHandler?: () => void) {
    const {showDialog} = useDialog();
    const {showSnackbar} = useSnackbar();
    const {refreshJobs} = useJobsData();

    return (fieldsToAdd) => {
        return new Promise((resolve) => {
            showDialog({
                title: `יצירת משרה חדשה`,
                description: `האם ליצור את המשרה המבוקשת?`,
                onAccept: async () => {
                    try {
                        const temp = {
                            ...fieldsToAdd,
                            creationDate: new Date(Date.now()),
                            jobNumber: await jobService.generateUniqueJobNumber()
                        }

                        await jobService.saveJob(temp.jobNumber.toString(), temp);
                        await refreshJobs();

                        showSnackbar({
                            message: `המשרה נוצרה בהצלחה.`,
                            severity: "success"
                        });

                        if (closeHandler) {
                            closeHandler();
                        }
                        resolve(true);
                    } catch (error) {
                        showSnackbar({message: `יצירת משרה נכשל. שגיאה: ${error}`})
                        resolve(false);
                    }
                },
                onCancel: () => resolve(false)
            });
        })

    };
}

export function useJobChangeOpenClosed() {
    const {showDialog} = useDialog();
    const {showSnackbar} = useSnackbar();
    const {refreshJobs} = useJobsData();

    return (job) => {
        return new Promise((resolve) => {
            showDialog({
                title: "שינוי סטטוס",
                description: `האם לשנות ל'${job.open ? 'סגור' : 'פתוח'}'?`,
                onAccept: async () => {
                    try {
                        const id = job.jobNumber.toString();
                        await jobService.setJobStatus(id, !job.open);
                        await refreshJobs();

                        showSnackbar({message: `הסטטוס השתנה!`, severity: "success"});
                        resolve(true);
                    } catch (error) {
                        showSnackbar({message: `שינוי סטטוס משרה נכשל. שגיאה: ${error}`})
                        resolve(false);
                    }
                },
                onCancel: () => resolve(false)
            });
        });
    };
}
