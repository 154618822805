import {Box, Typography} from "@mui/material";
import React from "react";

export default function TitleLabel({title, icon: IconComponent}) {
    return (
        <Box display="flex" gap={1} paddingBottom="2rem">
            {IconComponent && (
                <IconComponent sx={{color: "#fff", alignSelf: "center"}}/>
            )}
            <Typography
                variant="h4"
                sx={{
                    color: "#fff",
                    fontFamily: "'Noto Sans Hebrew', sans-serif",
                    fontWeight: 500,
                    justifySelf: "center",
                }}
            >
                {title}
            </Typography>
        </Box>
    );
}