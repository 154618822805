import {background, backgroundIcon} from "../JobPageStyles";
import MobileBackground from "../../../Assets/MobileBackground.png";
import {Box, Icon} from "@mui/material";
import React from "react";
import {UseScreenSelectors} from "../../../../Utilities/ScreenSize";
import {ReactComponent as BackgroundSVG} from "../../../Assets/Background.svg";

export default function Background() {
    const screenSize = UseScreenSelectors();

    return <>
        <Box sx={background()}>
            {screenSize === "xs" ? (
                <img src={MobileBackground} height="408px" width="100%" alt=""/>
            ) : (
                <Icon sx={backgroundIcon()} component={BackgroundSVG}/>
            )}
        </Box>
    </>
}