import AlertPage from "./AlertPage";

const AlreadyAppliedPage = () => {
    return (
        <AlertPage
            title={
                <>
                    תודה על התעניינותך במשרות עמותת גוונים.
                    <br/>
                    לצערנו, לא ניתן להגיש מועמדות נוספת כרגע מאחר והגשת כבר שתי מועמדויות.
                </>
            }
        />
    );
};

export default AlreadyAppliedPage;
