import React, {ReactNode} from "react";
import {Box, CssBaseline, keyframes, ThemeProvider, useTheme} from "@mui/material";
import {Sidebar} from "./Layout/Sidebar/Sidebar";
import BubbleContainer from "./Components/Bubble/BubbleContainer";
import {ManagementNavbar} from "./Layout/Navbar/Navbar";
import {useLocation} from "react-router-dom";
import {theme} from "./Theme";
import {useThemeContext} from "./Contexts/Theme";

interface ManagementLayoutProps {
    children: ReactNode;
}

const gradientShift = keyframes({
    "0%": {backgroundPosition: "0% 50%"},
    "50%": {backgroundPosition: "100% 50%"},
    "100%": {backgroundPosition: "0% 50%"}
});

function ManagementLayoutContent({children}: ManagementLayoutProps) {
    const location = useLocation();
    const isResumeUrl = location.pathname.endsWith('resume');
    const theme = useTheme();

    return (
        <Box sx={{
            display: 'flex',
            height: "100dvh",
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper
        }}>
            <Box sx={{display: {xs: "none", md: "flex"}}}>
                <Sidebar/>
            </Box>
            <Box sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                overflow: 'hidden',
            }}>
                <Box sx={{width: "100%"}}>
                    <ManagementNavbar/>
                </Box>

                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        pt: {xs: isResumeUrl ? 0 : 2, md: isResumeUrl ? 0 : 5},
                        pb: {xs: isResumeUrl ? 0 : 2, md: isResumeUrl ? 0 : 5},
                        px: {xs: isResumeUrl ? 0 : 1, md: isResumeUrl ? 0 : 5},
                        marginTop: isResumeUrl ? 0 : "2px",
                        background: theme.palette.custom.background.default,
                        backgroundSize: "400% 400%",

                        animation: `${gradientShift} 15s ease infinite`,
                        borderTopLeftRadius: {sm: 0, md: "30px"},
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        boxSizing: "border-box",
                        width: '100%',
                    }}
                >
                    <BubbleContainer numberOfBubbles={14}/>
                    {children}
                </Box>
            </Box>
        </Box>
    );
}

export function ManagementLayout({children}) {
    const {isDark} = useThemeContext();

    return <>
        <ThemeProvider theme={theme(isDark ? "dark" : "light")}>
            <CssBaseline/>
            <ManagementLayoutContent>
                {children}
            </ManagementLayoutContent>
        </ThemeProvider>
    </>
}