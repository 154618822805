import {ChevronLeft} from "@mui/icons-material";
import {Button} from "@mui/material";
import React from "react";

export default function ToButton({text, onClick}) {
    return <>
        <Button
            variant='text'
            disableRipple
            style={{backgroundColor: 'transparent'}}
            endIcon={<ChevronLeft className="icon-hover"/>}
            onClick={onClick}
            sx={{
                '& .MuiButton-endIcon': {
                    transition: 'transform 0.3s ease',
                },
                '&:hover .MuiButton-endIcon': {
                    transform: 'translateX(5px)',
                },
            }}
        >
            {text}
        </Button>
    </>
}