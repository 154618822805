import FullScreenDialog, {FullScreenDialogHeader} from "../../../Components/Dialog/FullScreenDialog/FullScreenDialog";
import React from "react";
import CreateRoleHeader from "./Components/CreateRoleHeader";
import CreateRoleForm from "./Components/CreateRoleForm";
import {Box} from "@mui/material";

export default function CreateRole({show, close, role}) {
    return (
        <>
            <FullScreenDialog show={show} close={close}>
                <FullScreenDialogHeader>
                    <CreateRoleHeader role={role}/>
                </FullScreenDialogHeader>

                <Box sx={{paddingTop: 5}}>
                    <CreateRoleForm close={close} role={role}/>
                </Box>
            </FullScreenDialog>
        </>
    );
}
