import React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton, styled} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


export default function SimpleDialog({title = "", show, close, children}) {
    const childrenArray = React.Children.toArray(children);
    const footer = childrenArray.find(child =>
        React.isValidElement(child) && child.type === SimpleDialogFooter
    );

    const otherChildren = childrenArray.filter(child =>
        React.isValidElement(child) && child.type !== SimpleDialogFooter
    );

    return (
        <BootstrapDialog
            onClose={close}
            aria-labelledby="customized-dialog-title"
            open={show}
            fullWidth={true}
            disableEscapeKeyDown={true}
        >
            <DialogTitle sx={{m: 0, p: 2}} id="customized-dialog-title" fontFamily="Rubik">
                {title}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={close}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                })}
            >
                <CloseIcon/>
            </IconButton>
            <DialogContent dividers>
                {otherChildren}
            </DialogContent>

            {footer && <DialogActions>
                {footer}
            </DialogActions>}
        </BootstrapDialog>
    );
}

export function SimpleDialogFooter({children}) {
    return (<>{children}</>)
}