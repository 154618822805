import React from "react";
import {TableColumns, TableOptions} from "../../Components/Table/Table.types";
import {Apartment, Edit} from "@mui/icons-material";
import TitleLabel from "../../Components/TitleLabel";
import {Box, Button, Grid} from "@mui/material";
import {TableSearch} from "../../Components/Table/TableSearch";
import BetterTable from "../../Components/Table/Table";
import CreateSector from "./CreateSector/CreateSector";
import OpenClosedChip from "../../Components/Chips/OpenClosedChip";
import CustomCard from "../../Components/Cards/Card";
import {useAuth} from "../../Contexts/AuthContext/AuthContext";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import {SectorsDataProvider, useSectorsData} from "../../Contexts/DataContext/SectorsDataContext";
import {useSectorChangeOpenClosed} from "../../Functions/Sector";
import {useSearchParams} from "react-router-dom";

function SectorsPageContent() {
    const [createSector, setCreateSector] = React.useState<any>(undefined);

    const sectorChangeOpenClosed = useSectorChangeOpenClosed();

    const columnsData: TableColumns = {
        columns: [
            {name: "שם האשכול", field: "sectorName", width: "50%", sortable: true},
            {name: "סטטוס", field: "sectorStatus", width: "45%", sortable: true}
        ]
    };

    const options: TableOptions = {
        label: "אפשרויות",
        items: [
            {label: "ערוך אשכול", icon: <Edit/>, command: row => setCreateSector(row.sector)},
        ]
    };

    const {sectors, loadingSectors} = useSectorsData();

    const mapSectorsToData = (sectors) => {
        return sectors.map((sector) => ({
            sectorName: sector.name,
            sectorStatus: {
                label: sector.open,
                component: <OpenClosedChip initialOpen={sector.open}
                                           onApprovedClick={() => sectorChangeOpenClosed(sector)}/>
            },
            sector: sector
        }))
    }

    const data = React.useMemo(() => !loadingSectors ? mapSectorsToData(sectors) : [], [sectors, loadingSectors]);

    const [filteredData, setFilteredData] = React.useState(data);

    React.useEffect(() => {
        setFilteredData(data);
    }, [data]);

    const [searchParams] = useSearchParams();
    const paramName = searchParams.get('name');
    React.useEffect(() => {
        if (paramName && !loadingSectors) {
            const sector = sectors.find(s => s.name.toString() === paramName);
            if (sector) {
                setCreateSector(sector);
            }
        }
    }, [paramName, loadingSectors]);

    return <>
        <TitleLabel title="אשכולות" icon={Apartment}/>

        <CustomCard noPadding={true}>
            <Box display="flex" justifyContent="space-between" padding="1rem">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TableSearch data={data} setFilteredData={setFilteredData} columns={columnsData}/>
                    </Grid>

                    <Grid item xs={12} sm={6} display="flex" justifyContent="end">
                        <Button variant="outlined" sx={{width: {xs: "100%", sm: "fit-content"}}} color="primary"
                                onClick={() => setCreateSector(null)}>אשכול
                            חדש</Button>
                    </Grid>
                </Grid>
            </Box>

            <BetterTable columnsData={columnsData} data={filteredData} loading={loadingSectors} options={options}
                         paginator={{label: "אשכולות", rowsPerPage: [5, 10, 15]}}/>
        </CustomCard>

        <CreateSector show={createSector !== undefined} close={() => setCreateSector(undefined)} sector={createSector}/>
    </>
}

export default function SectorsPage() {
    const {loading} = useAuth();

    if (loading) {
        return <LoadingSpinner/>;
    }

    return <>
        <SectorsDataProvider>
            <SectorsPageContent/>
        </SectorsDataProvider>
    </>
}