import TitleLabel from "../../Components/TitleLabel";
import {Badge, Delete, Description, Edit} from "@mui/icons-material";
import {Box, Button, Grid} from "@mui/material";
import {TableSearch} from "../../Components/Table/TableSearch";
import BetterTable from "../../Components/Table/Table";
import React from "react";
import CandidateJobs from "./Components/CandidateJobs";
import CandidateCVButton from "./Components/CandidateCVButton";
import CreateCandidate from "./CreateCandidate/CreateCandidate";
import {TableColumns, TableOptions} from "../../Components/Table/Table.types";
import {useNavigate} from "react-router-dom";
import CustomCard from "../../Components/Cards/Card";
import {useAuth} from "../../Contexts/AuthContext/AuthContext";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import {CandidatesDataProvider, useCandidatesData} from "../../Contexts/DataContext/CandidatesDataContext";
import {JobsDataProvider} from "../../Contexts/DataContext/JobsDataContext";
import {useCandidateDelete} from "../../Functions/Candidate";
import ToButton from "../../Components/Buttons/ToButton";
import {ApplicationsDataProvider} from "../../Contexts/DataContext/ApplicationsDataContext";
import {RatingRTL} from "../../Styled/Rating";
import {UserRole} from "../../../Firebase/enums/UserRole";

function CandidatesPageContent() {
    const [createModifyCandidate, setCreateModifyCandidate] = React.useState<any | undefined>(undefined);
    const navigate = useNavigate();

    const removeCandidate = useCandidateDelete();

    const showCandidateProfile = (candidate) => navigate(`/management/candidate/${candidate.id}`);

    const [expandedRows, setExpandedRows] = React.useState<Set<number>>(new Set());
    const columnsData: TableColumns = {
        columns: [
            {name: "שם מלא", field: "candidateName", width: "20%", sortable: true},
            {name: "קורות חיים", field: "candidateCV", width: "20%"},
            {name: "פרופילים", field: "candidateProfile", width: "20%"},
        ],
        onExpandedRows: {
            expandedRows,
            setExpandedRows,
            onRowExpansion: (row) => (<CandidateJobs candidate={row.candidate}/>)
        }
    }

    const {role} = useAuth();
    const {candidates, loadingCandidates} = useCandidatesData();

    const mapCandidatesToData = (temp) => {
        return temp.map((candidate) => ({
            candidateName: `${candidate.firstname} ${candidate.lastname}`,
            candidateCV: {
                label: "", component: <CandidateCVButton candidate={candidate}/>
            },
            candidateProfile: {
                label: "",
                component:
                    <ToButton text="לפרופיל המועמד/ת" onClick={(event) => {
                        event.stopPropagation();
                        showCandidateProfile(candidate)
                    }}/>
            },
            candidate: candidate
        }))
    };

    const data = React.useMemo(() => !loadingCandidates ? mapCandidatesToData(candidates) : [], [candidates, loadingCandidates]);
    const [filteredData, setFilteredData] = React.useState([]);
    React.useEffect(() => setFilteredData(data), [data]);

    const options: TableOptions = {
        label: "אפשרויות",
        items:
            [
                {
                    label: "קורות חיים", icon: <Description/>, command: (row) => {
                        window.open(`/management/candidate/${row.candidate.id}/resume`, '_blank');
                    }
                },
                {
                    label: "עריכת פרטי מועמד",
                    icon: <Edit/>,
                    command: (row) => setCreateModifyCandidate(row.candidate)
                },
                {label: "הצג מועמד", icon: <Badge/>, command: (row) => showCandidateProfile(row.candidate)},
                {
                    label: "מחק מועמד",
                    icon: <Delete/>,
                    hidden: role !== UserRole.admin,
                    command: row => removeCandidate(row.candidate)
                }
            ]
    };

    return <>
        <TitleLabel title="ניהול מועמדים" icon={Badge}/>

        <CustomCard noPadding={true}>
            <Box display="flex" justifyContent="space-between" padding="1rem">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TableSearch data={data} setFilteredData={setFilteredData} columns={columnsData}/>
                    </Grid>

                    <Grid item xs={12} sm={6} display="flex" justifyContent="end">
                        <Button variant="outlined" sx={{width: {xs: "100%", sm: "fit-content"}}} color="primary"
                                onClick={() => setCreateModifyCandidate(null)}>מועמד/ת
                            חדש/ה</Button>
                    </Grid>
                </Grid>
            </Box>

            <BetterTable columnsData={columnsData} data={filteredData}
                         paginator={{label: "מועמדים", rowsPerPage: [5, 10, 15]}}
                         loading={loadingCandidates} options={options} sxHeaderCell={{zIndex: 10}}/>
        </CustomCard>

        <CreateCandidate show={createModifyCandidate !== undefined}
                         close={() => setCreateModifyCandidate(undefined)}
                         candidate={createModifyCandidate}/>
    </>
}

export default function CandidatesPage() {
    const {loading} = useAuth();

    if (loading) {
        return <LoadingSpinner/>;
    }

    return (
        <JobsDataProvider>
            <CandidatesDataProvider>
                <ApplicationsDataProvider>
                    <CandidatesPageContent/>
                </ApplicationsDataProvider>
            </CandidatesDataProvider>
        </JobsDataProvider>
    )
}