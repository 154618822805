import React from "react";
import Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import CustomCard from "./Card";
import {useThemeContext} from "../../Contexts/Theme";

interface HighchartCardProps {
    title: string;
    chartData: { categories: string[]; data: number[] };
    axisTitle: string;
}

const HighchartCard: React.FC<HighchartCardProps> = ({title, chartData, axisTitle}) => {
    const chartRef = React.useRef<HTMLDivElement>(null);
    const chartInstance = React.useRef<Highcharts.Chart | null>(null);
    const {isDark} = useThemeContext();

    React.useEffect(() => {
        HighchartsExporting(Highcharts);
        HighchartsExportData(Highcharts);
        HighchartsAccessibility(Highcharts);
    }, []);

    const destroyChart = () => {
        if (chartInstance.current) {
            chartInstance.current.destroy();
            chartInstance.current = null;
        }
    }

    React.useEffect(() => {
        destroyChart();

        if (chartRef.current) {
            Highcharts.setOptions({
                credits: {
                    enabled: false,
                },
                legend: {
                    enabled: false,
                    itemStyle: {
                        color: isDark ? '#ffffff' : '#666666'
                    }
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            legend: {
                                enabled: false
                            },
                            yAxis: {
                                title: {
                                    text: null
                                }
                            }
                        }
                    }]
                },
                exporting: { enabled: false },
            });

            chartInstance.current = Highcharts.chart(chartRef.current, {
                chart: {
                    type: "column",
                    backgroundColor: isDark ? '#2a2a2a' : '#ffffff',
                    style: {
                        color: isDark ? '#ffffff' : '#666666'
                    }
                },
                credits: {
                    enabled: false,
                },
                title: {
                    text: "",
                    style: {
                        color: isDark ? '#ffffff' : '#666666'
                    }
                },
                xAxis: {
                    categories: chartData.categories,
                    title: {
                        text: "",
                        style: {
                            color: isDark ? '#ffffff' : '#666666'
                        }
                    },
                    labels: {
                        style: {
                            color: isDark ? '#ffffff' : '#666666'
                        }
                    }
                },
                yAxis: {
                    title: {
                        text: "",
                        style: {
                            color: isDark ? '#ffffff' : '#666666'
                        }
                    },
                    labels: {
                        style: {
                            color: isDark ? '#ffffff' : '#666666'
                        }
                    }
                },
                series: [
                    {
                        type: "column",
                        name: "מספר",
                        data: chartData.data,
                        color: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 1,
                                y2: 0
                            },
                            stops: [
                                [0, '#4A65A0'],
                                [1, '#3DA5C4']
                            ]
                        }
                    }
                ]

            });
        }

        return destroyChart;
    }, [chartData, isDark, axisTitle]);


    return (
        <CustomCard title={title}>
            <div ref={chartRef} style={{width: "100%", height: "400px", transition: 'width 300ms ease-in-out'}}></div>
        </CustomCard>
    );
};

export default HighchartCard;
