import { MainNavbar } from "./MainNavbar";
import React from "react";
import NavbarDisconnect from "./Components/Disconnect";
import Welcome from "./Components/Welcome";
import NavbarProfile from "./Components/Profile";
import { SectorsDataProvider } from "../../Contexts/DataContext/SectorsDataContext";
import Help from "./Components/Help";
import { Box } from "@mui/material";
import NavbarSearch from "./Components/Search";
import ThemeChange from "./Components/Theme";
import NavbarNotifications from "./Components/Notifications/Notifications";
import { AuditLogsProvider } from "../../Contexts/DataContext/AuditLogsContext";
import { RecruitersDataProvider } from "../../Contexts/DataContext/RecruitersDataContext";

export function ManagementNavbar() {
    const startItems = (<>
        <Box sx={{
            display: { xs: "none", sm: "flex" }
        }}>
            <NavbarSearch />
        </Box>
    </>
    );

    const centerItems = (
        <Box sx={{ display: { xs: "none", sm: "flex" } }}>
            <Welcome />
        </Box>
    );

    const endItems = (
        <>
            {/*<RecruitersDataProvider>*/}
            {/*    <AuditLogsProvider>*/}
            {/*        <NavbarNotifications />*/}
            {/*    </AuditLogsProvider>*/}
            {/*</RecruitersDataProvider>*/}
            <Help />
            <ThemeChange />
            <NavbarProfile />
            <NavbarDisconnect />


        </>
    );

    return <>
        <SectorsDataProvider>
            <MainNavbar startItems={startItems} centerItems={centerItems} endItems={endItems} />
        </SectorsDataProvider>
    </>
}