import React from 'react';
import {MenuItem, Select} from '@mui/material';
import {useRolesData} from "../../Contexts/DataContext/RolesDataContext";
import {useSnackbar} from "../../../Utilities/Context/Snackbar";

const SelectRole = ({value, onChange, labelId, allRoles = false}) => {
    const {roles} = useRolesData();
    const {showSnackbar} = useSnackbar();

    const filteredRoles = allRoles ? roles : roles.filter(role => role.open);
    React.useEffect(() => {
        if (value && value.trim() !== "" && value !== "כל התפקידים" && !filteredRoles.some(role => role.name === value)) {
            showSnackbar({message: "התפקיד שנבחר לא קיים, ייתכן ונמחק."});
        }
    }, [value]);

    return (
        <Select
            value={value || ""}
            label="תפקיד"
            onChange={(event) => onChange(event.target.value)}
            labelId={labelId}
        >
            {allRoles && (
                <MenuItem value="כל התפקידים">
                    כל התפקידים
                </MenuItem>
            )}

            {filteredRoles?.map(role => (
                <MenuItem key={role.id} value={role.name}>
                    {role.name}
                </MenuItem>
            ))}
        </Select>
    );
};

export default SelectRole;
