import {styled} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

export const DatePickerRTL = styled(DatePicker)({
    '& .MuiDatePickerToolbar-root': {},
});

DatePickerRTL.defaultProps = {
    slots: {
        leftArrowIcon: KeyboardArrowRightIcon,
        rightArrowIcon: KeyboardArrowLeftIcon,
    },
};