import {Box, Button, FormControl, Grid, InputAdornment, TextField, Typography,} from "@mui/material";
import React from "react";
import ProfileFormSectors from "./ProfileFormSectors";
import {Delete, LockReset} from "@mui/icons-material";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import CustomCard from "../../../../Components/Cards/Card";
import {
    useRecruiterCreate,
    useRecruiterDelete,
    useRecruiterResetPassword,
    useRecruiterUpdate
} from "../../../../Functions/Recruiter";
import {LoadingButton} from "@mui/lab";

interface ProfileFormProps {
    recruiter: any;
    state: 'modify' | 'new';
    close?: () => void;
}

const ProfileForm: React.FC<ProfileFormProps> = ({
                                                     recruiter, state, close = () => {
    }
                                                 }) => {
    const resetPassword = useRecruiterResetPassword();
    const updateRecruiter = useRecruiterUpdate(close);
    const removeRecruiter = useRecruiterDelete(close);
    const createRecruiter = useRecruiterCreate(close);

    const validationSchema = Yup.object().shape({
        privateName: Yup.string().required('שדה זה הוא חובה'),
        privateLastName: Yup.string().required('שדה זה הוא חובה'),
        privateEmail: Yup.string().email().test("email", "שדה זה הוא חובה", value =>
            (value || '').replace(/@gvanim\.org\.il$/, '').trim() !== ""
        )
    });

    const [selectedSectors, setSelectedSectors] = React.useState(recruiter?.sectors ?? []);
    const [selectedSectorsError, setSelectedSectorsError] = React.useState<string | undefined>(undefined);
    const formik = useFormik({
        initialValues: {
            privateName: recruiter?.firstName ?? "",
            privateLastName: recruiter?.lastName ?? "",
            privateEmail: recruiter?.email ?? ""
        },
        validationSchema: validationSchema,
        onSubmit: async (values, {setSubmitting}) => {
            setSubmitting(true);

            if (state === 'modify') {
                let firstName = undefined;
                let lastName = undefined;

                if (recruiter.firstName! !== values.privateName || recruiter.lastName! !== values.privateLastName) {
                    firstName = values.privateName;
                    lastName = values.privateLastName;
                }

                await updateRecruiter(recruiter.id, firstName, lastName, undefined, selectedSectors);
            } else {
                const newRecruiter = {
                    firstName: values.privateName,
                    lastName: values.privateLastName,
                    email: values.privateEmail,
                    sectors: selectedSectors
                };

                await createRecruiter(newRecruiter);
            }

            setSubmitting(false);
        },
    });

    const onSubmit = async () => {
        await formik.setTouched(
            Object.keys(formik.initialValues).reduce((acc, key) => {
                acc[key] = true;
                return acc;
            }, {} as { [key: string]: boolean }),
            true
        );

        setSelectedSectorsError(undefined);
        const errors = await formik.validateForm();
        const sectorsExists = selectedSectors.length > 0;
        if (Object.keys(errors).length > 0 || !sectorsExists) {
            if (!sectorsExists) {
                setSelectedSectorsError("שדה זה הוא חובה");
            }
            return;
        }

        formik.handleSubmit();
    }

    return (
        <CustomCard>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth size="small"
                                 error={formik.touched.privateName && !!formik.errors.privateName}>
                        <TextField
                            label="שם פרטי"
                            name="privateName"
                            fullWidth
                            variant="standard"
                            value={formik.values.privateName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.privateName && !!formik.errors.privateName}
                            helperText={formik.touched.privateName && formik.errors.privateName && (typeof formik.errors.privateName === 'string' ? formik.errors.privateName : formik.errors.privateName[0])}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormControl fullWidth size="small"
                                 error={formik.touched.privateLastName && !!formik.errors.privateLastName}>
                        <TextField
                            label="שם משפחה"
                            name="privateLastName"
                            fullWidth
                            variant="standard"
                            value={formik.values.privateLastName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.privateLastName && !!formik.errors.privateLastName}
                            helperText={formik.touched.privateLastName && formik.errors.privateLastName && (typeof formik.errors.privateLastName === 'string' ? formik.errors.privateLastName : formik.errors.privateLastName[0])}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormControl fullWidth size="small"
                                 error={formik.touched.privateEmail && !!formik.errors.privateEmail}>
                        <TextField
                            label="אימייל"
                            name="privateEmail"
                            fullWidth
                            variant="standard"
                            disabled={state !== 'new'}
                            value={state === "new" ? (formik.values.privateEmail || '').replace(/@gvanim\.org\.il$/, '') : formik.values.privateEmail}
                            onChange={(e) => {
                                const newValue = e.target.value.replace(/@/g, '');
                                formik.setFieldValue('privateEmail', `${newValue}@gvanim.org.il`);
                            }}
                            onBlur={formik.handleBlur}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="end">
                                        {state === "new" && <Typography
                                            color="textSecondary"
                                            style={{userSelect: 'none'}}
                                        >
                                            gvanim.org.il@
                                        </Typography>}
                                    </InputAdornment>
                                )
                            }}
                            error={formik.touched.privateEmail && !!formik.errors.privateEmail}
                            helperText={formik.touched.privateEmail && formik.errors.privateEmail &&
                                (typeof formik.errors.privateEmail === 'string' ?
                                    formik.errors.privateEmail : formik.errors.privateEmail[0])}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6} display="flex" justifyContent="space-between" sx={{
                    alignContent: {xs: "start", md: "end"}
                }}>
                    <ProfileFormSectors
                        selectedSectors={selectedSectors}
                        setSelectedSectors={(newValue) => {
                            setSelectedSectors(newValue);
                            formik.setFieldValue('selectedSectors', selectedSectors);
                        }}
                        errorState={selectedSectorsError}
                        setErrorState={setSelectedSectorsError}
                        state={state}
                    />
                </Grid>

                <Grid item xs={12} display="flex" gap={1} mt={2} sx={{
                    justifyContent: {md: state === "modify" ? "space-between" : "end"},
                    flexDirection: {xs: "column", md: "row"}
                }}>
                    {state === 'modify' &&
                        <Button
                            variant="contained"
                            color="error"
                            startIcon={<Delete/>}
                            onClick={() => removeRecruiter(recruiter)}
                        >
                            מחק מגייס
                        </Button>
                    }

                    <Box sx={{
                        display: "flex",
                        flexDirection: {xs: "column", md: "row"},
                        width: {xs: "100%", md: "fit-content"},
                        gap: 1
                    }}>
                        {state !== 'new' &&
                            <Button
                                variant="contained"
                                color="warning"
                                startIcon={<LockReset/>}
                                onClick={() => resetPassword(recruiter)}
                            >
                                אפס/י סיסמה
                            </Button>
                        }

                        <LoadingButton
                            variant="contained"
                            color="primary"
                            disabled={
                                state !== 'new' && (
                                    (recruiter?.firstName === formik.values.privateName || (!recruiter && formik.values.privateName === '')) &&
                                    (recruiter?.lastName === formik.values.privateLastName || (!recruiter && formik.values.privateLastName === '')) &&
                                    (recruiter?.email === formik.values.privateEmail || (!recruiter && formik.values.privateEmail === '')) &&
                                    JSON.stringify(recruiter?.sectors ?? []) === JSON.stringify(selectedSectors)
                                )
                            }
                            loading={formik.isSubmitting}
                            onClick={onSubmit}
                        >
                            {state === "new" ? "צור מגייס" : "שמור עריכת פרופיל מגייס"}
                        </LoadingButton>
                    </Box>

                </Grid>
            </Grid>
        </CustomCard>
    );
}

export default ProfileForm;