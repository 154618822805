import {styled} from "@mui/system";
import {TextField} from "@mui/material";

export const StyledTextField = styled(TextField)(({theme, error}) => ({
    width: "100%",
    "& .MuiInputBase-input": {
        color: theme.palette.secondary.descAndReqText,
    },
    "& .MuiInputLabel-root": {
        color: theme.palette.secondary.descAndReqText,
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: error ? theme.palette.error.main : theme.palette.primary.descAndReqTitle,
            borderRadius: "4px",
        },
        "&:hover fieldset": {
            borderColor: error ? theme.palette.error.main : theme.palette.primary.descAndReqTitle,
            borderRadius: "4px",
        },
        "&.Mui-focused fieldset": {
            borderColor: error ? theme.palette.error.main : theme.palette.primary.descAndReqTitle,
            borderRadius: "4px",
        }
    }
}));