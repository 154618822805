import React from "react";
import HighchartCard from "../../../Components/Cards/HighchartCard";
import {useJobsData} from "../../../Contexts/DataContext/JobsDataContext";
import {useSectorsData} from "../../../Contexts/DataContext/SectorsDataContext";

export default function ReportsBySectors() {
    const {jobs} = useJobsData();
    const {sectors} = useSectorsData();

    const chartData = React.useMemo(() => {
        const jobsBySector: { [sectorName: string]: number } = {};

        for (let i = 0; i < jobs.length; i++) {
            const job = jobs[i];
            const sectorId = job.sector;

            if (job.open) {
                const sector = sectors.find((s) => s.id === sectorId);
                const sectorName = sector ? sector.name : "Unknown Sector";

                if (jobsBySector[sectorName]) {
                    jobsBySector[sectorName]++;
                } else {
                    jobsBySector[sectorName] = 1;
                }
            }
        }

        const categories = Object.keys(jobsBySector); // Sector names
        const data = Object.values(jobsBySector); // Job counts

        return {
            categories,
            data,
        };
    }, [jobs, sectors]);

    return (
        <HighchartCard
            title="משרות לפי אשכולות (משרות פתוחות בלבד)"
            axisTitle="אשכולות"
            chartData={chartData}
        />
    );
}
