import {addDoc, CollectionReference, deleteDoc, setDoc, updateDoc} from "firebase/firestore";
import {DocumentReference, UpdateData, WithFieldValue} from "@firebase/firestore";
import {httpsCallable} from "firebase/functions";
import {auth, functions} from "../../FirebaseConfig/firebase";
import {getLocalStorage, setLocalStorage} from "./LocalStorage";

export async function customAddDoc<T>(
    collectionRef: CollectionReference<T>,
    data: T,
    localStorageKey: string,
    id: string = "id"
): Promise<void> {
    try {
        const docRef = await addDoc(collectionRef, data);
        const buffer: Array<any> = getLocalStorage(localStorageKey);
        const updatedBuffer = [...buffer, {[id]: docRef.id, ...data}];
        setLocalStorage(localStorageKey, updatedBuffer);
    } catch (error) {
        console.error(`Error adding document: ${error}`);
        throw error;
    }
}


export async function customUpdateDoc<T>(reference: DocumentReference<T>, data: UpdateData<T>, localStorageKey: string, id: string = "id"): Promise<void> {
    try {
        await setDoc(reference as DocumentReference<{ lastModifiedBy?: string }>, { lastModifiedBy: auth?.currentUser?.uid || auth?.currentUser?.phoneNumber || "Unknown" }, { merge: true });
        await updateDoc(reference, data);
        console.log(data);

        const buffer: Array<any> = getLocalStorage(localStorageKey);
        const updatedBuffer = buffer.map(item => {
            if (String(item[id]) === String(reference.id)) {
                return {...item, ...(data as Record<any, any>)};
            }
            return item;
        });
        setLocalStorage(localStorageKey, updatedBuffer);
    } catch (error) {
        console.error(`Error updating document: ${error}`);
        throw error;
    }
}

export async function customDeleteDoc<T>(reference: DocumentReference<T>, localStorageKey: string, id: string = "id"): Promise<void> {
    try {
        await setDoc(reference as DocumentReference<{ deletedBy?: string }>, { deletedBy: auth?.currentUser?.uid || "Unknown" }, { merge: true });
        await deleteDoc(reference);

        const buffer: Array<any> = getLocalStorage(localStorageKey);
        const updatedBuffer = buffer.filter((item) => item[id].toString() !== reference.id.toString());
        setLocalStorage(localStorageKey, updatedBuffer);
    } catch (error) {
        console.error(`Error deleting document: ${error}`);
        throw error;
    }
}

export async function customSetDoc<T>(reference: DocumentReference<T>, data: WithFieldValue<T>, localStorageKey: string, id: string = "id"): Promise<void> {
    try {
        await setDoc(reference, data);

        const buffer: Array<any> = getLocalStorage(localStorageKey);
        const updatedBuffer = [...buffer, {[id]: reference.id, ...(data as Record<any, any>)}];
        setLocalStorage(localStorageKey, updatedBuffer);
    } catch (error) {
        console.error(`Error setting job: ${error}`);
        throw error;
    }
}

export enum CRUDType {
    Create,
    Update,
    Delete,
}

export interface CustomHttpsCallInter {
    functionName: string;
    httpsData: any;
    localStorageKey: string;
    storageId?: string;
    storageData?: any;
    type: CRUDType;
}

export async function customHttpsCall(object: CustomHttpsCallInter, id: string = "id") {
    try {
        if ((object.type === CRUDType.Create || object.type === CRUDType.Update) && !object.storageData) {
            throw new Error("Storage data is required for creating and updating.");
        }

        const func = httpsCallable(functions, object.functionName);
        const result = await func(object.httpsData);

        const buffer: Array<any> = getLocalStorage(object.localStorageKey);
        let updatedBuffer: any;
        switch (object.type) {
            case CRUDType.Delete:
                updatedBuffer = buffer.filter((item) => item[id] !== object.storageId);
                break;

            case CRUDType.Create:
                const referenceId = object.storageId || Object.entries(result.data as any).find(([key, _]) => key.toLowerCase().includes("id"))?.[1];
                updatedBuffer = [...buffer, {[id]: referenceId, ...object.storageData}];
                break;

            case CRUDType.Update:
                updatedBuffer = buffer.map(item => {
                    if (String(item[id]) === String(object.storageId)) {
                        return {...item, ...object.storageData};
                    }
                    return item;
                });
                break;
        }

        setLocalStorage(object.localStorageKey, updatedBuffer);
        return result;
    } catch (error) {
        console.error(`Error calling function: ${error}`);
        throw error;
    }
}