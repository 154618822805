import React from "react";
import {TableColumns} from "../../../Components/Table/Table.types";
import BetterTable from "../../../Components/Table/Table";
import {useJobsData} from "../../../Contexts/DataContext/JobsDataContext";
import ChipColorPicker from "../../../Utilities/ChipColorPicker";
import {Chip} from "@mui/material";
import {ApplicationCandidatesStatuses, statusesToMoveToBottom} from "../../../Utilities/Statuses";
import {useApplicationsData} from "../../../Contexts/DataContext/ApplicationsDataContext";
import {RatingRTL} from "../../../Styled/Rating";

export default function CandidateJobs({candidate}) {
    const columnsData: TableColumns = {
        columns: [
            {name: "כותרת המשרה", field: "candidateJobTitle", width: "60%"},
            {name: "התאמה", field: "candidateFit", width: "20%"},
            {name: "סטטוס", field: "candidateJobStatus", width: "20%"}
        ]
    };

    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState<any[]>([]);
    const [candidateApplications, setCandidateApplications] = React.useState<any[]>([]);
    const [fold, setFold] = React.useState<number | undefined>(undefined);

    const {jobs, loadingJobs} = useJobsData();
    const {loadingApplications, fetchApplicationsByCandidateId} = useApplicationsData();

    const mapApplicationsToData = (applications) => {
        if (!applications) return [];

        const data = applications
            .map(app => {
                const job = jobs.find(job => job.jobNumber === app.jobNumber);
                if (!job) return null;

                return {
                    candidateJobTitle: {
                        label: job?.title ?? "המשרה לא קיימת",
                        component: !job?.title
                            ? <p>המשרה לא קיימת</p>
                            : job.open
                                ? <a href={`/career/jobs/${job.jobNumber}`} target="_blank">{job.title}</a>
                                : <p>{job.title} (משרה מס': {app.jobNumber})</p>
                    },
                    candidateFit: {
                        label: app.matchingRate,
                        component: <RatingRTL value={app.matchingRate} readOnly/>
                    },
                    candidateJobStatus: {
                        label: app.status,
                        component: <Chip label={app.status}
                                         color={ChipColorPicker(ApplicationCandidatesStatuses.indexOf(app.status))}/>
                    },
                    candidate: candidate
                };
            }).filter(app => app != null)
            .sort((a, b) => {
                const aStatus = statusesToMoveToBottom.has(a.candidateJobStatus.label);
                const bStatus = statusesToMoveToBottom.has(b.candidateJobStatus.label);
                return aStatus === bStatus ? 0 : aStatus ? 1 : -1;
            });

        const startIndex = data.findIndex(app => statusesToMoveToBottom.has(app.candidateJobStatus.label));
        const hasNonBottomItems = data.some(app => !statusesToMoveToBottom.has(app.candidateJobStatus.label));

        if (hasNonBottomItems) {
            setFold(startIndex === -1 ? undefined : startIndex);
        } else {
            setFold(undefined);
        }

        return data;
    };

    React.useEffect(() => {
        if (candidate?.id) {
            setLoading(true);
            fetchApplicationsByCandidateId(candidate.id)
                .then(results => setCandidateApplications(results))
                .finally(() => setLoading(false));
        }
    }, [candidate?.id]);

    React.useEffect(() => {
        if (!loadingJobs && !loadingApplications && candidateApplications) {
            const mappedData = mapApplicationsToData(candidateApplications);
            setData(mappedData);
        }
    }, [jobs, loadingJobs, candidateApplications]);

    return <>
        <BetterTable
            columnsData={columnsData}
            data={data}
            loading={loading}
            density={'small'}
            plain={true}
            alignText="start"
            sxHeaderCell={{zIndex: 5}}
            foldAfter={fold}
        />
    </>;
}

