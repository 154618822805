import {useFormik} from "formik";
import * as Yup from "yup";
import {Button, Stack, TextField} from "@mui/material";
import SimpleDialog, {SimpleDialogFooter} from "../../../../../Components/Dialog/SimpleDialog";
import {LoadingButton} from "@mui/lab";
import React from "react";

export default function RejectDialog({open, setOpen, onClick}) {
    const [loading, setLoading] = React.useState(false);

    const formik = useFormik({
        initialValues: {
            rejectReason: '',
        },
        validationSchema: Yup.object({
            rejectReason: Yup.string().required('נא להזין נימוק לדחייה'),
        }),
        onSubmit: (values) => {
            onClick(values.rejectReason, setLoading);
            setOpen(false);
            formik.resetForm();
        },
    });

    const onClose = () => {
        setOpen(false);
        formik.resetForm();
    }

    return <>
        <SimpleDialog
            title="נימוק לדחיית המועמד"
            show={open}
            close={onClose}
        >
            <TextField
                fullWidth
                name="rejectReason"
                placeholder="נימוק"
                value={formik.values.rejectReason}
                onChange={formik.handleChange}
                error={formik.touched.rejectReason && Boolean(formik.errors.rejectReason)}
                helperText={formik.touched.rejectReason && formik.errors.rejectReason}
                multiline
                rows={4}
            />

            <SimpleDialogFooter>
                <Stack width="100%" flexDirection="row" justifyContent="space-between">
                    <LoadingButton
                        color="error"
                        variant="contained"
                        onClick={() => formik.handleSubmit()}
                        disabled={!formik.isValid || !formik.dirty}
                        loading={loading}
                    >
                        דחה
                    </LoadingButton>
                    <Button onClick={onClose}>
                        בטל/י
                    </Button>
                </Stack>
            </SimpleDialogFooter>
        </SimpleDialog>
    </>
}