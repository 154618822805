import React from "react";
import {OptionType} from "../../../../../Components/CustomMultiSelect/Option.type";
import MultiSelect from "../../../../../Components/CustomMultiSelect/CustomMultiSelect";
import {useDrushimJobsData} from "../../../../../Contexts/JobsDrushimContext";

export default function LocationMultiSelect(props: { optionSelected: any, setSelected: any }) {
    const {optionSelected, setSelected} = props;
    const [locations, setLocations] = React.useState<OptionType[]>([]);
    const {jobs, loadingJobs} = useDrushimJobsData();

    const handleChange = (selected: OptionType[]) => setSelected(selected);
    const fetchLocations = async () => {
        const uniqueLocations = new Set<string>();

        jobs.forEach(job => {
            if (job.region) {
                const locations = job.region
                    .split(/,\s*|-\s*/)

                    // further split tokens if a word starts with the Hebrew "ו"
                    .flatMap(token =>
                        token.split(/\s+(?=ו)/)
                    )
                    // trim, remove a leading "ו" if present, and remove "והסביבה" at the end
                    .map(loc =>
                        loc
                            .trim()
                            .replace(/^ו/, "")
                            .replace(/\s*והסביבה$/, "")
                            .trim()
                    )
                    .filter(Boolean);
                locations.forEach(location => uniqueLocations.add(location));
            }
        });

        return Array.from(uniqueLocations)
            .sort()
            .map((location, index) => ({value: index, label: location}));
    }

    React.useEffect(() => {
        if (!loadingJobs) {
            fetchLocations().then((locs) => setLocations(locs));
        }
    }, [jobs, loadingJobs]);

    return (
        <>
            <MultiSelect
                options={locations}
                onChange={handleChange}
                value={optionSelected}
                isSelectAll={true}
                menuPlacement={"bottom"}
            />
        </>
    );
}
