import React from "react";
import {
    Box,
    IconButton,
    LinearProgress,
    Menu,
    MenuItem,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Paginator from "./Paginator";
import "./Table.css";
import {
    isLabeledCell,
    TableCellType,
    TableColumns,
    TableDensity,
    TableOptions,
    TablePaginator,
    TableRow as MyTableRow,
    TableTextAlignment
} from "./Table.types";
import ExpandButton from "../Buttons/ExpandButtons";

interface TableProps {
    columnsData: TableColumns;
    data: MyTableRow[];
    // onLoadMore?: () => void;
    paginator?: TablePaginator;
    loading: boolean;
    options?: TableOptions;
    density?: TableDensity;
    plain?: boolean;
    alignText?: TableTextAlignment;
    foldAfter?: number;
    sxHeaderCell?: any;
}

export default function BetterTable(props: TableProps) {
    const theme = useTheme();

    // Sorting
    const [orderDirection, setOrderDirection] = React.useState<'asc' | 'desc' | null>(null);
    const [orderBy, setOrderBy] = React.useState<string | null>(null);

    const handleSortRequest = (columnId: string) => {
        if (orderBy === columnId) {
            if (orderDirection === 'asc') {
                setOrderDirection('desc');
            } else if (orderDirection === 'desc') {
                setOrderDirection(null);
                setOrderBy(null);
            } else {
                setOrderDirection('asc');
            }
        } else {
            setOrderBy(columnId);
            setOrderDirection('asc');
        }
    };

    const sortedData = React.useMemo(() => {
        if (orderBy && orderDirection) {
            return [...props.data].sort((a, b) => {
                const getValue = (row: any, field: string): string | number => {
                    const cell = row[field];

                    if (isLabeledCell(cell)) {
                        const label = cell.label;
                        if (label instanceof Date) {
                            return label.getTime();
                        }
                        // Return numbers directly
                        if (typeof label === 'number') {
                            return label;
                        }
                        return label.toString();
                    }


                    if (cell && typeof cell === 'object' && 'label' in cell) {
                        const label = (cell as { label: TableCellType }).label;
                        if (label instanceof Date) {
                            return label.getTime();
                        }
                        return label.toString();
                    }

                    if (typeof cell === 'boolean') {
                        return cell ? 1 : 0;
                    }

                    if (cell instanceof Date) {
                        return cell.getTime();
                    }

                    return (cell ?? "").toString();
                };

                const aValue = getValue(a, orderBy);
                const bValue = getValue(b, orderBy);

                if (aValue === bValue) return 0;

                // Date or number
                const isNumeric = (n) => !isNaN(parseFloat(n)) && isFinite(n);
                if (isNumeric(aValue) || isNumeric(bValue)) {
                    return orderDirection === 'asc'
                        ? (aValue as number) - (bValue as number)
                        : (bValue as number) - (aValue as number);
                }

                // string comparison
                return orderDirection === 'asc'
                    ? (aValue as string).localeCompare(bValue as string)
                    : (bValue as string).localeCompare(aValue as string);
            });
        }
        return props.data;
    }, [props.data, orderBy, orderDirection]);

    // Expansion
    const [isExpanded, setIsExpanded] = React.useState(false);

    // Paginator
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(1);
    const currentRows = React.useMemo(() => {
        const rows = sortedData.slice((page - 1) * rowsPerPage, page * rowsPerPage);
        return (props.foldAfter && !isExpanded) ? rows.slice(0, props.foldAfter) : rows;
    }, [sortedData, page, rowsPerPage, props.foldAfter, isExpanded]);

    React.useEffect(() => {
        if (!props.paginator) {
            setRowsPerPage(props.data.length);
            return;
        }

        const rows = props.paginator.rowsPerPage;
        if (typeof rows === "number") {
            setRowsPerPage(rows);
        } else {
            setRowsPerPage(rows[0]);
        }
    }, [props.data]);

    const onPageChange = (newPage: number) => {
        setPage(newPage);
        props.columnsData.onExpandedRows?.setExpandedRows(new Set());
    }

    // Menu Items
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedRow, setSelectedRow] = React.useState<any>(null);
    const handleClose = () => setAnchorEl(null);
    const handleCommand = (command: (row: any) => void) => {
        if (command && selectedRow) {
            command(selectedRow);
        }
        handleClose();
    };
    const handleClick = (event, row: any) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSelectedRow(row);
    };

    // Row expansion
    const toggleRowExpansion = (index: number) => {
        if (!props.columnsData.onExpandedRows) return;

        const newExpandedRows = new Set(props.columnsData.onExpandedRows.expandedRows);
        if (newExpandedRows.has(index)) {
            newExpandedRows.delete(index);
        } else {
            newExpandedRows.add(index);
        }
        props.columnsData.onExpandedRows.setExpandedRows(newExpandedRows);
    };

    React.useEffect(() => {
        props.columnsData.onExpandedRows?.setExpandedRows(new Set());
    }, [props.data])

    return (
        <Box sx={{width: "100%"}} display="flex" alignItems="start">
            {props.foldAfter &&
                <Box sx={{width: "48px"}}>
                    {sortedData.length > props.foldAfter && (
                        <ExpandButton
                            expanded={isExpanded}
                            onClick={(event) => {
                                event.stopPropagation();
                                setIsExpanded(!isExpanded);
                            }}
                        />
                    )}
                </Box>
            }

            <Box sx={{width: "100%", overflow: "hidden"}} component={props.plain ? 'div' : Paper}>
                <TableContainer>
                    <Table stickyHeader size={props.density || "medium"}>
                        <TableHead sx={{boxShadow: props.plain ? undefined : "0px 2px 4px rgba(0, 0, 0, 0.35)"}}>
                            {props.loading &&
                                <TableRow>
                                    <TableCell colSpan={props.columnsData.columns.length + (props.options ? 1 : 0)}>
                                        <LinearProgress/>
                                    </TableCell>
                                </TableRow>
                            }

                            <TableRow>
                                {props.options && (
                                    <TableCell sx={{
                                        textAlign: "center",
                                        borderRight: props.plain ? undefined : "1px solid",
                                        borderRightColor: props.plain ? undefined : theme.palette.custom.table.borderColor,
                                        width: "4%"
                                    }}/>
                                )}

                                {props.columnsData.columns.map((column, index) => (
                                    <TableCell key={index} sx={{
                                        textAlign: props.alignText || "center",
                                        fontWeight: 600,
                                        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                        borderRight: props.plain ? undefined : "1px solid",
                                        borderRightColor: props.plain ? undefined : theme.palette.custom.table.borderColor,
                                        width: column.width || "auto",
                                        backgroundColor: props.plain ? "transparent" : undefined,
                                        ...props.sxHeaderCell
                                    }}>
                                        {column.sortable ? (
                                            <TableSortLabel active={orderBy === column.field}
                                                            direction={orderBy === column.field ? orderDirection || "asc" : "asc"}
                                                            onClick={() => handleSortRequest(column.field)}>
                                                {column.name}
                                            </TableSortLabel>
                                        ) : (
                                            column.name
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {currentRows.map((data, index) => (
                                <React.Fragment key={index}>
                                    <TableRow
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            if (props.columnsData.onExpandedRows) {
                                                toggleRowExpansion(index);
                                            } else if (props.columnsData.onRowClick) {
                                                props.columnsData.onRowClick(data);
                                            }
                                        }}
                                        sx={{
                                            backgroundColor: props.plain ? undefined : (index % 2 === 0 ? theme.palette.custom.table.firstTableRow : theme.palette.custom.table.secondTableRow),
                                            "&:hover": {
                                                backgroundColor: props.plain ? undefined : theme.palette.custom.table.hoverRow,
                                                transition: props.plain ? undefined : "background-color 0.3s ease",
                                                cursor: (props.columnsData.onRowClick || props.columnsData.onExpandedRows) ? 'pointer' : 'auto'
                                            }
                                        }}>

                                        {props.options ? (
                                            <TableCell sx={{
                                                textAlign: "center",
                                                borderRight: props.plain ? undefined : "1px solid",
                                                borderRightColor: props.plain ? undefined : theme.palette.custom.table.borderColor
                                            }}>
                                                {props.loading ? <Skeleton variant="text"/> : (
                                                    <Tooltip title={props.options.label} arrow>
                                                        <IconButton size="small"
                                                                    onClick={(event) => handleClick(event, data)}>
                                                            <MoreVertIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </TableCell>
                                        ) : undefined}

                                        {props.columnsData.columns.map((column) => {
                                            const value = data[column.field];

                                            // Check if the cell contains a simple label or a more complex object
                                            const isLabelCell = isLabeledCell(value);
                                            const valueLabel = isLabelCell ? value.label : value;
                                            const valueComponent = isLabelCell ? value.component : undefined;

                                            return (
                                                <TableCell
                                                    key={column.field}
                                                    sx={{
                                                        textAlign: props.alignText || "center",
                                                        borderRight: props.plain ? undefined : "1px solid",
                                                        borderRightColor: props.plain ? undefined : theme.palette.custom.table.borderColor
                                                    }}
                                                >
                                                    {props.loading ? (
                                                        <Skeleton variant="text"/>
                                                    ) : (
                                                        valueComponent ? (
                                                            valueComponent
                                                        ) : (
                                                            valueLabel && typeof valueLabel !== 'object' ? valueLabel : null
                                                        )
                                                    )}
                                                </TableCell>
                                            );
                                        })}


                                    </TableRow>
                                    {props.columnsData.onExpandedRows?.expandedRows.has(index) && (
                                        <TableRow>
                                            <TableCell
                                                colSpan={props.columnsData.columns.length + (props.options ? 1 : 0)}
                                                sx={{
                                                    padding: 2,
                                                    backgroundColor: props.plain ? undefined : "rgba(240, 240, 240, 0.4)"
                                                }}
                                            >
                                                {props.columnsData.onExpandedRows.onRowExpansion?.(data)}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {props.paginator && !props.foldAfter && !props.loading && (
                    <Paginator
                        labelText={props.paginator.label}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        rowsPerPageList={typeof props.paginator.rowsPerPage !== "number" ? props.paginator.rowsPerPage : undefined}
                        dataLength={props.data.length}
                        onPageChange={(page) => onPageChange(page)}
                    />
                )}

                {props.options &&
                    <>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <Typography
                                sx={{
                                    margin: 0,
                                    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                                    lineHeight: 1.66,
                                    letterSpacing: '0.03333em',
                                    fontSize: '11px',
                                    fontWeight: 600,
                                    padding: '8px'
                                }}
                            >
                                {props.options.label}
                            </Typography>

                            {props.options.items.map((option, index) => (
                                (!option.hidden && <MenuItem
                                    key={index}
                                    onClick={() => handleCommand(option.command)}
                                    sx={{
                                        margin: 0,
                                        fontWeight: 400,
                                        lineHeight: 1.5,
                                        letterSpacing: '0.00938em',
                                        color: 'rgb(62, 80, 96)',
                                        fontSize: 'small',
                                        fontFamily: '"IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                        marginBottom: '2px',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.08)'
                                        }
                                    }}
                                >
                                    <Box
                                        display="flex"
                                        gap={1}
                                        sx={{
                                            color: theme => theme.palette.text.primary,
                                            '&:hover': {
                                                gap: 2,
                                            },
                                            transition: "all 0.3s"
                                        }}>
                                        {option?.icon}
                                        {option.label}
                                    </Box>
                                </MenuItem>)
                            ))}
                        </Menu>
                    </>
                }
            </Box>
        </Box>
    );
}
