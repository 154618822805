import {useLocation, useNavigate} from "react-router-dom";
import React from "react";
import {ColorModeContext} from "../../../../Theme/Theme";
import {Box, Stack, Typography} from "@mui/material";
import {NavigateBefore, NewReleases, Place, WatchLater} from "@mui/icons-material";
import {jobItemStyles} from "./JobItemStyles";
import {Job} from "../../../../../Firebase/FirebaseFunctions/JobsService";

export default function JobItem(props: { job: Job }) {
    const {job} = props;

    const navigate = useNavigate();
    const location = useLocation();
    const colorMode = React.useContext(ColorModeContext);
    const styles = jobItemStyles({} as any, colorMode);

    return (
        <Stack direction='row-reverse'>
            <Box borderTop={`5px solid`} id='JobCard' sx={styles.jobCard}>
                <Stack direction='row' justifyContent='end' display={{xs: job?.highPriority ? 'flex' : 'none'}}>
                    <Box paddingTop={{xs: 2, sm: 2.5}} paddingRight={1} sx={{position: 'absolute'}}>
                        <Stack spacing={0.5} direction='row' justifyContent='center' alignItems='center'
                               sx={styles.priorityBadge}>
                            <Typography fontSize={{xs: 11, sm: 12}} sx={styles.priorityText}>
                                משרה חמה
                            </Typography>
                            <NewReleases sx={{fontSize: 14, ...styles.priorityText}}/>
                        </Stack>
                    </Box>
                </Stack>

                <Box sx={styles.titleBox}>
                    <Typography variant='body1' sx={styles.titleText}>
                        תפקיד: {job?.role}
                    </Typography>
                </Box>

                <Box sx={styles.descriptionContainer}>
                    <Typography variant='h5' display='block' sx={styles.descriptionText} whiteSpace="pre-wrap">
                        {job?.description}
                        <br/>
                    </Typography>

                    <Stack direction='row' spacing={1.5} justifyContent='start' sx={{mt: 4}}>
                        <Box display='flex' flexDirection='column' justifyContent='center'>
                            <Place fontSize='inherit' sx={styles.detailsIcon}/>
                        </Box>
                        <Stack direction='row' spacing={1} alignItems='center'>
                            <Typography variant='caption' sx={styles.detailsLabel}>מיקום:</Typography>
                            <Typography variant='caption' sx={styles.detailsValue}>{job?.region}</Typography>
                        </Stack>
                    </Stack>

                    <Stack direction='row' spacing={1.5} justifyContent='start' sx={{mt: 1.5}}>
                        <Box display='flex' flexDirection='column' justifyContent='center'>
                            <WatchLater fontSize='inherit' sx={{padding: 0.15, ...styles.detailsIcon}}/>
                        </Box>
                        <Stack direction='row' spacing={1} alignItems='center'>
                            <Typography variant='caption' sx={styles.detailsLabel}>היקף משרה:</Typography>
                            <Typography variant='caption' sx={styles.detailsValue}>
                                {job?.scope[0] !== job?.scope[1] ? `${job?.scope[1]}% -  ${job?.scope[0]}%` : `${job?.scope[0]}%`}
                            </Typography>
                        </Stack>
                    </Stack>

                    <Stack direction='row' className='mylink' spacing={1.8} justifyContent='start' sx={{mt: 5}}>
                        <Box
                            component="button"
                            onClick={() => {
                                navigate(`/career/jobs/${job?.jobNumber}`, {state: {homePath: location?.pathname}});
                            }}
                            sx={{
                                ...styles.jobLink,
                                background: 'none',
                                border: 'none',
                                padding: 0,
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer'
                            }}
                        >
                            <Typography variant='caption' id="element1" sx={styles.linkText}>
                                צפייה במשרה והגשת מועמדות
                            </Typography>
                            <NavigateBefore id="arrow" sx={styles.arrowIcon}/>
                        </Box>
                    </Stack>
                </Box>

                <Stack direction='row' justifyContent={{xs: 'start', md: 'end'}} spacing={1}
                       sx={{mt: {xs: 5, md: 2}, pb: 2, px: 2.5, pt: 2.5}}>
                    <Typography variant='caption' sx={styles.detailsLabel}>משרה מספר:</Typography>
                    <Typography variant='caption' sx={styles.detailsValue}>{job?.jobNumber}</Typography>
                </Stack>
            </Box>
        </Stack>
    );
}