import {Article, Delete, Edit, Work} from "@mui/icons-material";
import TitleLabel from "../../Components/TitleLabel";
import React from "react";
import {Box, Button, FormControl, FormControlLabel, Grid, Stack, Switch, Typography} from "@mui/material";
import {TableSearch} from "../../Components/Table/TableSearch";
import CreateJob from "./CreateJob/CreateJob";
import {TableColumns, TableOptions} from "../../Components/Table/Table.types";
import BetterTable from "../../Components/Table/Table";
import CustomCard from "../../Components/Cards/Card";
import {useAuth} from "../../Contexts/AuthContext/AuthContext";
import {JobsDataProvider, useJobsData} from "../../Contexts/DataContext/JobsDataContext";
import {SectorsDataProvider, useSectorsData} from "../../Contexts/DataContext/SectorsDataContext";
import OpenClosedChip from "../../Components/Chips/OpenClosedChip";
import SectorChip from "../../Components/Chips/SectorChip";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import {useJobChangeOpenClosed, useJobDelete} from "../../Functions/Job";
import {RolesDataProvider} from "../../Contexts/DataContext/RolesDataContext";
import {ApplicationsDataProvider} from "../../Contexts/DataContext/ApplicationsDataContext";
import {CandidatesDataProvider} from "../../Contexts/DataContext/CandidatesDataContext";
import {UserRole} from "../../../Firebase/enums/UserRole";
import JobCandidatesTable from "./Components/JobCandidatesTable";
import dayjs from "dayjs";
import ToButton from "../../Components/Buttons/ToButton";
import JobCandidates from "./JobCandidates/JobCandidates";
import {useSearchParams} from "react-router-dom";

function JobsPageContent() {
    const deleteJob = useJobDelete();
    const changeOpenJob = useJobChangeOpenClosed();
    const {role} = useAuth();
    const {sectors} = useSectorsData();

    const [createModifyJob, setCreateModifyJob] = React.useState<any | undefined>(undefined);
    const [jobCandidatesDialog, setJobCandidatesDialog] = React.useState<any | undefined>(undefined);
    const [switchText, setSwitchText] = React.useState("מציג משרות פתוחות");
    const [expandedRows, setExpandedRows] = React.useState<Set<number>>(new Set());

    const columnsData: TableColumns = {
        columns: [
            {name: "מס' משרה", field: "jobNumber", width: "8%", sortable: true},
            {name: "סטטוס", field: "jobStatus", width: "8%", sortable: false},
            {name: "איזור", field: "jobArea", width: "8%", sortable: true},
            {name: "תפקיד", field: "jobRole", width: "8%", sortable: true},
            {name: "כותרת המשרה", field: "jobTitle", width: "25%", sortable: true},
            {name: "אשכול", field: "jobSector", width: "8%", sortable: true},
            {name: "אחוז משרה", field: "jobTime", width: "10%", sortable: true},
            {name: "תאריך יצירת משרה", field: "jobCreatedDate", width: "10%", sortable: true},
            {name: "מועמדים שניגשו", field: "jobCandidates", width: "13%", sortable: false}
        ],
        onExpandedRows: {
            expandedRows,
            setExpandedRows,
            onRowExpansion: (row) => (<JobCandidatesTable job={row.job}/>)
        }
    }

    const mapJobsToData = (jobs) => {
        return jobs.map(job => ({
            jobNumber: job.jobNumber,
            jobStatus: {
                label: job.open,
                component: <OpenClosedChip initialOpen={job.open} disabled={role !== UserRole.admin}
                                           onApprovedClick={() => changeOpenJob(job)}/>
            },
            jobArea: job.region,
            jobRole: job.role,
            jobTitle: job.title,
            jobSector: {
                label: sectors.find(item => item.id === job.sector)?.name,
                component: <SectorChip id={job.sector}/>
            },
            jobTime: [...(job.scope || [])].reverse().map(n => `${n}%`).join(" - "),
            jobCreatedDate: {
                label: job.creationDate,
                component: <Typography variant="body2">
                    {dayjs(job.creationDate).format("DD/MM/YY HH:mm")}
                </Typography>
            },
            jobCandidates: {
                label: 0,
                component: <ToButton text="לרשימת המועמדים המלאה" onClick={(e) => {
                    e.stopPropagation();
                    setJobCandidatesDialog(job);
                }}/>
            },
            job: job
        }));
    };

    const {loadingJobs, jobs} = useJobsData();
    const [openJobs, setOpenJobs] = React.useState(true);
    const data = React.useMemo(() => !loadingJobs ? mapJobsToData(jobs) : [], [jobs, loadingJobs]);
    const [searchData, setSearchData] = React.useState([]);
    const [filteredData, setFilteredData] = React.useState([]);

    React.useEffect(() => {
        const mappedOpenData = data.filter((job: any) => Boolean(job.jobStatus.label) === openJobs);
        setFilteredData(mappedOpenData);
        setSearchData(mappedOpenData);
    }, [data]);

    const handleSwitchChange = () => {
        setOpenJobs(prev => !prev);
        const mappedOpenData = data.filter((job: any) => Boolean(job.jobStatus.label) !== openJobs);
        setFilteredData(mappedOpenData);
        setSearchData(mappedOpenData);

        if (switchText.includes("פתוחות")) {
            setSwitchText("מציג משרות סגורות");
        } else {
            setSwitchText("מציג משרות פתוחות");
        }
    };

    const options: TableOptions = {
        label: "אפשרויות",
        items: [
            {
                label: `לדף המשרה`,
                icon: <Article/>,
                hidden: !openJobs,
                command: (row) => window.open(`/career/jobs/${row.jobNumber}`, '_blank')
            },
            {
                label: "ערוך משרה",
                icon: <Edit/>,
                command: (row) => setCreateModifyJob(row.job),
                hidden: role !== UserRole.admin
            },
            {label: "מחק משרה", icon: <Delete/>, command: row => deleteJob(row.job), hidden: role !== UserRole.admin}
        ]
    };

    const [searchParams] = useSearchParams();
    const paramJobNumber = searchParams.get('jobNumber');

    React.useEffect(() => {
        if (paramJobNumber && !loadingJobs) {
            const job = jobs.find(j => j.jobNumber.toString() === paramJobNumber);
            if (job) {
                setJobCandidatesDialog(job);
            }
        }
    }, [paramJobNumber, loadingJobs]);

    return (
        <Stack>
            <TitleLabel title="ניהול משרות" icon={Work}/>

            <CustomCard noPadding={true} sx={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                <Box display="flex" justifyContent="space-between" padding="1rem">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TableSearch data={searchData} setFilteredData={setFilteredData} columns={columnsData}/>
                        </Grid>

                        <Grid item xs={12} sm={6} display="flex"
                              sx={{justifyContent: {xs: role === UserRole.admin ? "space-between" : "center", sm: "end"}}}>
                            <FormControl component="fieldset">
                                <FormControlLabel
                                    value="bottom"
                                    control={<Switch color="primary" defaultChecked={true}
                                                     onChange={handleSwitchChange}/>}
                                    label={switchText}
                                    labelPlacement="end"
                                />
                            </FormControl>
                            {role === UserRole.admin &&
                                <Button variant="outlined" color="primary" onClick={() => setCreateModifyJob(null)}>
                                    משרה חדשה
                                </Button>}
                        </Grid>
                    </Grid>
                </Box>

                <Box display="flex" flexDirection="column" flexGrow={1}>
                    <BetterTable
                        columnsData={columnsData}
                        data={filteredData}
                        paginator={{label: openJobs ? 'משרות פתוחות' : 'משרות סגורות', rowsPerPage: [5, 10, 15]}}
                        options={role !== UserRole.admin && !openJobs ? undefined : options}
                        loading={loadingJobs}
                    />
                </Box>
            </CustomCard>

            <CreateJob show={createModifyJob !== undefined} close={() => setCreateModifyJob(undefined)}
                       job={createModifyJob}/>

            <JobCandidates show={jobCandidatesDialog !== undefined} close={() => setJobCandidatesDialog(undefined)}
                           job={jobCandidatesDialog || {}}/>
        </Stack>
    )
}

export default function JobsPage() {
    const {loading} = useAuth();

    if (loading) {
        return <LoadingSpinner/>;
    }

    return (
        <JobsDataProvider>
            <SectorsDataProvider>
                <RolesDataProvider>
                    <ApplicationsDataProvider>
                        <CandidatesDataProvider>
                            <JobsPageContent/>
                        </CandidatesDataProvider>
                    </ApplicationsDataProvider>
                </RolesDataProvider>
            </SectorsDataProvider>
        </JobsDataProvider>
    )
}