import React, {useState, useEffect} from 'react';
import {Typography} from "@mui/material";

interface AnimatedCounterProps {
    target: number;
    duration: number; // ms
    sx?: any;
}

const AnimatedCounter = ({target, duration, sx}: AnimatedCounterProps) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        let startTime: number | null = null;

        const animate = (timestamp: number) => {
            if (!startTime) startTime = timestamp;
            const elapsed = timestamp - startTime;

            // Calculate the current count based on elapsed time
            const progress = Math.min(elapsed / duration, 1);
            const currentCount = Math.round(progress * target);
            setCount(currentCount);

            if (progress < 1) {
                requestAnimationFrame(animate);
            }
        };

        requestAnimationFrame(animate);

        return () => {
            setCount(0);
        };
    }, [target, duration]);

    return (<Typography variant="h6" sx={sx}>{count}</Typography>);
};

export default AnimatedCounter;