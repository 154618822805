import CustomCard from "../../../Components/Cards/Card";
import {Divider, Stack, Typography} from "@mui/material";
import AnimatedCounter from "../../../Components/AnimatedCounter";
import React from "react";
import {UseIsScreenXS} from "../../../../Utilities/ScreenSize";

export default function DividerCard({data}) {
    const screenXS = UseIsScreenXS();

    return <>
        <CustomCard>
            <Stack display="flex" spacing={2} justifyContent="space-around" sx={{
                flexDirection: {xs: "column", sm: "row"},
            }}>
                {data.map((item, index) => (
                    <React.Fragment key={index}>
                        <Stack spacing={2} alignItems="center" sx={{
                            alignSelf: {xs: "center", md: "end"}
                        }}>
                            <Typography
                                variant="h5"
                                sx={{
                                    fontFamily: 'Rubik',
                                    fontWeight: 600,
                                    opacity: 0.8
                                }}
                            >
                                {item.title}
                            </Typography>

                            <AnimatedCounter target={item.value} duration={1500} sx={{
                                fontFamily: 'Rubik',
                                fontWeight: 600,
                                fontSize: 50,
                                background: 'linear-gradient(to left, #4A65A0, #3970A1, #2F87AB, #3DA5C4  )', 
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                            }}/>
                        </Stack>
                        {index < data.length - 1 &&
                            <Divider orientation={screenXS ? 'horizontal' : 'vertical'} flexItem/>}
                    </React.Fragment>
                ))}
            </Stack>
        </CustomCard>
    </>
}
