import React from "react";
import {Slider, SliderThumb, styled} from "@mui/material";

const AirbnbSlider = styled(Slider)(({ theme }) => ({
    color: '#555ABF',
    height: 3,
    padding: '13px 0',
    '& .MuiSlider-thumb': {
        height: 27,
        width: 27,
        backgroundColor: '#fff',
        border: '1px solid currentColor',
        '&:hover': {
            boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
        },
        '& .airbnb-bar': {
            height: 9,
            width: 1,
            backgroundColor: 'currentColor',
            marginLeft: 1,
            marginRight: 1,
        },
    },
}));

interface AirbnbThumbComponentProps extends React.HTMLAttributes<unknown> {}

function AirbnbThumbComponent(props: AirbnbThumbComponentProps) {
    const { children, ...other } = props;
    return (
        <SliderThumb {...other}>
            {children}
            <span className="airbnb-bar" />
            <span className="airbnb-bar" />
            <span className="airbnb-bar" />
        </SliderThumb>
    );
}


export default function CustomSlider({
                                         value, // Default range
                                         onChange,
                                         steps = 5,
                                         min = 0,
                                         max = 100,
                                         skip = 25,
                                     }) {
    const marks = [
        {value: min, label: `${min}%`},
        ...Array.from({length: (max - min) / skip - 1}, (_, i) => ({
            value: (i + 1) * skip,
            label: `${(i + 1) * skip}%`,
        })),
        {value: max, label: `${max}%`},
    ];

    return (
        <>
            <AirbnbSlider
                disableSwap
                value={value}
                onChange={onChange}
                min={min}
                max={max}
                step={steps}
                valueLabelDisplay="auto"
                getAriaLabel={(index) =>
                    index === 0 ? "Minimum value" : "Maximum value"
                }
                getAriaValueText={(value) => `${value}%`}
                marks={marks}
                sx={{
                    ".MuiSlider-thumb": {
                        transform: "translateX(50%) translateY(-50%)",
                    },
                    ".MuiSlider-mark": {
                        transform: "translateX(50%) translateY(-50%)",
                    },
                    ".MuiSlider-markLabel": {
                        transform: "translateX(50%) translateY(0)",
                    },
                }}
                slots={{ thumb: AirbnbThumbComponent }}
            />
        </>
    );
}
