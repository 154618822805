import * as yup from "yup";
import {
    arrayRemove,
    arrayUnion,
    collection,
    doc,
    DocumentData,
    Firestore,
    getDoc,
    getDocs,
    QueryDocumentSnapshot,
} from "firebase/firestore";
import {customAddDoc, customDeleteDoc, customUpdateDoc} from "./Helpers/CustomFirebaseFunctions";
import {db} from "../FirebaseConfig/firebase";

export const SectorsLocalStorage = "sectors";

// Sector class definition
class Sector {
    name: string;
    open: boolean;
    recruiter: string[];

    constructor(name: string, open: boolean = true, recruiter: string[] = []) {
        this.name = name;
        this.open = open;
        this.recruiter = recruiter;
    }
}

// Validation schema for Sector using Yup
const sectorSchema = yup.object().shape({
    name: yup.string().required("Sector name is required").min(2, "Name must be at least 2 characters"),
    open: yup.boolean().required("Open status is required"),
    recruiter: yup.array().of(yup.string().required("Recruiter must be a string")).default([]).optional(),
});

// Firestore data converter for Sector
const sectorConverter = {
    toFirestore: (sector: Sector): DocumentData => {
        return {
            name: sector.name,
            open: sector.open,
            recruiter: sector.recruiter,
        };
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot, options: any): Sector => {
        const data = snapshot.data(options);
        return new Sector(data.name, data.open, data.recruiter);
    },
};

// Sector service class for Firestore operations
class SectorService {
    private db: Firestore;
    private collectionPath: string;

    constructor(db: Firestore, collectionPath: string) {
        this.db = db;
        this.collectionPath = collectionPath;
    }

    // Function to save/add a new sector with validation and uniqueness check
    async saveSector(sectorData: DocumentData): Promise<void> {
        try {
            // Validate sectorData against the schema
            const validatedData = await sectorSchema.validate(sectorData, {abortEarly: false});

            const sectorsCollection = collection(this.db, this.collectionPath);
            const sectorQuery = await getDocs(sectorsCollection);
            const existingSector = sectorQuery.docs.find(doc => doc.data().name === validatedData.name);

            if (existingSector) {
                throw new Error(`Sector with name "${validatedData.name}" already exists.`);
            }

            const sectorRef = sectorsCollection.withConverter(sectorConverter);
            await customAddDoc(sectorRef, validatedData, SectorsLocalStorage);
            console.log("Sector added successfully!");
        } catch (error) {
            if (error instanceof yup.ValidationError) {
                console.error("Validation error(s):", error.errors);
                throw new Error("Validation error: " + error.errors.join(", "));
            } else {
                console.error("Error adding sector:", error);
                throw error;
            }
        }
    }

    // Function to update a sector's open status
    async updateSectorStatus(sectorId: string, open: boolean): Promise<void> {
        try {
            const sectorRef = doc(this.db, this.collectionPath, sectorId);
            await customUpdateDoc(sectorRef, {open: open}, SectorsLocalStorage);
            console.log(`Sector ${open ? "opened" : "closed"} successfully!`);
        } catch (error) {
            console.error(`Error updating sector status:`, error);
            throw new Error(`An error occurred while updating the sector status.`);
        }
    }

    async addRecruiter(sectorId: string, recruiterEmail: string): Promise<void> {
        try {
            const sectorRef = doc(this.db, this.collectionPath, sectorId);
            await customUpdateDoc(sectorRef, {
                recruiter: arrayUnion(recruiterEmail)
            }, SectorsLocalStorage);
            console.log("Recruiter added successfully!");
        } catch (error) {
            console.error("Error adding recruiter:", error);
            throw new Error("An error occurred while adding the recruiter.");
        }
    }

    async removeRecruiter(sectorId: string, recruiterEmail: string): Promise<void> {
        try {
            const sectorRef = doc(this.db, this.collectionPath, sectorId);
            await customUpdateDoc(sectorRef, {
                recruiter: arrayRemove(recruiterEmail)
            }, SectorsLocalStorage);
            console.log("Recruiter removed successfully!");
        } catch (error) {
            console.error("Error removing recruiter:", error);
            throw new Error("An error occurred while removing the recruiter.");
        }
    }

    async updateSectorName(sectorId: string, newName: string): Promise<void> {
        try {
            // First check if the new name already exists
            const sectorsCollection = collection(this.db, this.collectionPath);
            const sectorsQuery = await getDocs(sectorsCollection);
            const existingSector = sectorsQuery.docs.find(
                (doc) => doc.data().name === newName && doc.id !== sectorId
            );

            if (existingSector) {
                throw new Error(`Sector with name "${newName}" already exists.`);
            }

            // If name is unique, proceed with update
            const sectorRef = doc(this.db, this.collectionPath, sectorId);
            await customUpdateDoc(sectorRef, {name: newName}, SectorsLocalStorage);
            console.log("Sector name updated successfully!");
        } catch (error) {
            console.error("Error updating sector name:", error);
            throw error;
        }
    }

    async getSectorRecruiters(sectorId: string): Promise<string[]> {
        try {
            const sectorRef = doc(this.db, this.collectionPath, sectorId);
            const sectorDoc = await getDoc(sectorRef);

            if (sectorDoc.exists()) {
                const sectorData = sectorDoc.data();
                const recruiters = sectorData?.recruiters as string[];
                return recruiters;
            } else {
                throw new Error("Sector not found");
            }
        } catch (error) {
            throw new Error("An error occurred while fetching the recruiters.");
        }
    }

    getSectorName(sectorId: string, sectors: any[]): string | null {
        const sector = sectors.find((s) => s.id === sectorId);
        return sector ? sector.name : null;
    }

    getSectorId(sectorName: string, sectors: any[]): string | null {
        const sector = sectors.find((s) => s.name === sectorName);
        return sector ? sector.id : null;
    }

    async deleteSector(sectorId: string): Promise<void> {
        try {
            // Get the sector reference
            const sectorRef = doc(this.db, this.collectionPath, sectorId);

            // Check if sector exists
            const sectorDoc = await getDoc(sectorRef);
            if (!sectorDoc.exists()) {
                throw new Error("Sector not found");
            }

            // Check if sector has recruiters
            const sectorData = sectorDoc.data();
            if (sectorData.recruiters && sectorData.recruiters.length > 0) {
                throw new Error("Cannot delete sector with assigned recruiters");
            }

            // Delete the sector
            await customDeleteDoc(sectorRef, SectorsLocalStorage);
            console.log("Sector deleted successfully!");
        } catch (error) {
            console.error("Error deleting sector:", error);
            throw new Error(error instanceof Error ? error.message : "An error occurred while deleting the sector");
        }
    }
}

// Initialize Sectors service for the "sectors" collection
export const sectorService = new SectorService(db, "sectors");


// const { sectors, loading } = useSectorsData();

// export const getSectorName = (sectorId: string) => {
//     const sector = sectors.find((s) => s.id === sectorId);
//     return sector ? sector.name : "Unknown";
//   };

// export const getSectorIdByName = (sectorName: string): string | null => {
//     const { sectors } = useSectorsData(); // גישה למערך הסקטורים דרך הקונטקסט

//     const sector = sectors.find((s) => s.name === sectorName);
//     return sector ? sector.id : null; // מחזיר את ה-ID אם נמצא, אחרת null
//   }
export {Sector, sectorConverter, sectorSchema};
export default SectorService;
