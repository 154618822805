import {IconButton, Tooltip, useTheme} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import {useDialog} from "../../../Contexts/Dialog";
import {useAuth} from "../../../Contexts/AuthContext/AuthContext";

export default function NavbarDisconnect() {
    const theme = useTheme();
    const {showDialog} = useDialog();
    const {handleLogout} = useAuth();

    const handleDisconnect = async () => {
        showDialog({
            title: "התנתקות מהמערכת", description: "האם את/ה בטוח/ה שברצונך להתנתק מהמערכת?", onAccept: async () => {
                try {
                    handleLogout();
                } catch (error) {
                    console.error("Error signing out:", error);
                }
            }
        })
    }

    return (
        <Tooltip title="התנתק" arrow componentsProps={{
            tooltip: {
                sx: {
                    fontSize: '1rem',
                },
            },
        }}>
            <IconButton
                size="large"
                onClick={handleDisconnect}
                sx={{
                    color: theme.palette.custom.navbar.iconColor,
                    '&:hover': {
                        color: theme.palette.error.main,
                    },
                    transition: 'all 0.3s ease',
                }}
            >
                <LogoutIcon/>
            </IconButton>
        </Tooltip>
    );
}