import {FormControl, InputLabel} from "@mui/material";
import SelectInterviewStatus from "../../../../../../Components/Selects/SelectInterviewStatus";
import React from "react";
import MoveJobDialog from "../MoveJobDialog";
import {Application} from "../../../../../../../Firebase/FirebaseFunctions/ApplicationService";
import RejectDialog from "../RejectDialog";

interface MoreOptionsButtonProps {
    application: Application;
    validate?: () => boolean;
    onAccepted?: any;
    onMoveJob?: any;
    onNotInterested?: any;
    onDeclined?: any;
    onSentCVToManager?: any;
    onPassFirstInterview?: any;
    onPassSecondInterview?: any;
    onInviteFirstInterview?: any;
    onInviteSecondInterview?: any;
    onAppliedInterview?: any;
}

export default function MoreOptionsButton({
                                              application,
                                              validate = () => true,
                                              onAccepted,
                                              onMoveJob,
                                              onNotInterested,
                                              onDeclined,
                                              onSentCVToManager,
                                              onPassFirstInterview,
                                              onPassSecondInterview,
                                              onInviteFirstInterview,
                                              onInviteSecondInterview,
                                              onAppliedInterview
                                          }: MoreOptionsButtonProps) {
    const [moveJob, setMoveJob] = React.useState(false);
    const [declineJob, setDeclineJob] = React.useState(false);

    const onChange = (e) => {
        const value = e.target.value;

        if (value === "העבר למשרה אחרת" && onMoveJob) {
            setMoveJob(true);
        } else if (validate()) {
            if (value === "אינו מעוניין במשרה" && onNotInterested) {
                onNotInterested();
            } else if (value === 'קו"ח הועברו למנהלי התוכנית' && onSentCVToManager) {
                onSentCVToManager();
            } else if (value === "התקבל" && onAccepted) {
                onAccepted();
            } else if (value === "נדחה" && onDeclined) {
                setDeclineJob(true);
            } else if (value === "עבר ראיון ראשון" && onPassFirstInterview) {
                onPassFirstInterview();
            } else if (value === "עבר ראיון שני" && onPassSecondInterview) {
                onPassSecondInterview();
            } else if (value === "זומן לראיון ראשון" && onInviteFirstInterview) {
                onInviteFirstInterview();
            } else if (value === "זומן לראיון שני" && onInviteSecondInterview) {
                onInviteSecondInterview();
            } else if (value === "הוגשה מועמדות" && onAppliedInterview) {
                onAppliedInterview();
            }
        }
    }

    const status = application.status;

    const statusMap = {
        "אינו מעוניין במשרה": onNotInterested,
        'קו"ח הועברו למנהלי התוכנית': onSentCVToManager,
        "התקבל": onAccepted,
        "העבר למשרה אחרת": onMoveJob,
        "נדחה": onDeclined,
        "עבר ראיון ראשון": onPassFirstInterview,
        "עבר ראיון שני": onPassSecondInterview,
        "זומן לראיון ראשון": onInviteFirstInterview,
        "זומן לראיון שני": onInviteSecondInterview,
        "הוגשה מועמדות": onAppliedInterview
    };

    const validStatuses = Object.keys(statusMap)
        .map(key => statusMap[key] ? key : false)
        .filter(Boolean);

    const displayValue = validStatuses.filter(Boolean).includes(status) ? status : "";

    return <>
        <FormControl variant="outlined" size="small" sx={{width: {xs: "100%", md: 260}}}>
            <InputLabel>אפשרויות נוספות</InputLabel>
            <SelectInterviewStatus
                value={displayValue}
                onChange={onChange}
                hide={Object.keys(statusMap)
                    .map(key => !statusMap[key] ? key : false)
                    .filter(Boolean)}
                label="אפשרויות נוספות"
            />
        </FormControl>

        <MoveJobDialog
            application={application}
            open={moveJob}
            close={() => setMoveJob(false)}
            onAccept={onMoveJob}
        />

        <RejectDialog open={declineJob} setOpen={setDeclineJob} onClick={onDeclined}/>
    </>
}