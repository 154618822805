import React from "react";
import MainSidebar from "./MainSidebar";
import {Apartment, Assessment, Badge, BarChart, EventSeat, Person, SupervisorAccount, Work} from "@mui/icons-material";
import {UseScreenSelectors} from "../../../Utilities/ScreenSize";
import {useAuth} from "../../Contexts/AuthContext/AuthContext";
import {UserRole} from "../../../Firebase/enums/UserRole";
import {useLocation} from "react-router-dom";

export function Sidebar() {
    const {role} = useAuth();
    const {pathname} = useLocation();

    let candidateName = "";
    if (pathname.includes("view") || pathname.includes("resume") || pathname.includes("interviews")) {
        const [_1, _2, _3, id] = pathname.split("/");
        const item = localStorage.getItem(`candidates`);
        if (item) {
            const obj = JSON.parse(item);
            const candidate = obj.find(cand => cand.id === id)!;
            if (candidate) {
                candidateName = `${candidate.firstname} ${candidate.lastname}`;
            }
        }
    }

    let menuItems = [
        {
            label: "סקירה",
            items: [
                {text: "סקירה כללית", icon: <BarChart/>, route: "/management/dashboard"},
                {text: "דוחות", icon: <Assessment/>, route: "/management/reports"},
            ],
        },
        {
            label: "ניהול",
            items: [
                {text: "משרות", icon: <Work/>, route: "/management/jobs"},
                {text: "מועמדים", icon: <Badge/>, route: "/management/candidates"},
                {text: candidateName, icon: <Person/>, route: pathname, hidden: candidateName === ""}
            ]
        },
    ];

    if (role === UserRole.admin) {
        menuItems = [...menuItems, {
            label: "ניהול מערכת",
            items: [
                {text: "מגייסים", icon: <SupervisorAccount/>, route: "/management/recruiters"},
                {text: "אשכולות", icon: <Apartment/>, route: "/management/sectors"},
                {text: "תפקידים", icon: <EventSeat/>, route: "/management/roles"},
            ],
        }];
    }

    const screenSize = UseScreenSelectors();

    return (
        <MainSidebar menuItems={menuItems} isMobileView={screenSize === 'sm' || screenSize === 'xs'}/>
    )
}
