import {createContext, useContext, useEffect, useState, ReactNode} from 'react';
import {collection, getDocs} from 'firebase/firestore';
import {db} from '../../../Firebase/FirebaseConfig/firebase';
import {getLocalStorage, setLocalStorage} from "../../../Firebase/FirebaseFunctions/Helpers/LocalStorage";
import {RecruitersLocalStorage} from "../../../Firebase/FirebaseFunctions/RecruiterService";

interface RecruitersContextType {
    recruiters: Array<any>;
    loadingRecruiters: boolean;
    refreshRecruiters: () => Promise<void>;
}

const RecruitersContext = createContext<RecruitersContextType | undefined>(undefined);

export function RecruitersDataProvider({children}: { children: ReactNode }) {
    const [recruiters, setRecruiters] = useState<Array<any>>(getLocalStorage(RecruitersLocalStorage));
    const [loadingRecruiters, setLoadingRecruiters] = useState<boolean>(true);

    const fetchRecruiters = async () => {
        setLoadingRecruiters(true);
        try {
            const snapshot = await getDocs(collection(db, 'recruiters'));
            const newData = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
            console.log('[RECRUITERS] Fetched recruiters (from server) length:', newData.length);

            setRecruiters(newData);
            setLocalStorage(RecruitersLocalStorage, newData);
            localStorage.setItem('recruitersLastFetchTime', new Date().getTime().toString());
        } catch (error) {
            console.error("[RECRUITERS] Error fetching recruiters: ", error);
            const cachedData = getLocalStorage(RecruitersLocalStorage);
            setRecruiters(cachedData);
        } finally {
            setLoadingRecruiters(false);
        }
    };

    const refreshRecruiters = async () => {
        setLoadingRecruiters(true);
        console.log("[RECRUITERS] data refreshed (from local).");
        setRecruiters(getLocalStorage(RecruitersLocalStorage));
        setLoadingRecruiters(false);
    };

    useEffect(() => {
        const lastFetch = localStorage.getItem('recruitersLastFetchTime');
        const currentTime = new Date().getTime();
        const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes cache

        if (lastFetch && currentTime - parseInt(lastFetch) < CACHE_DURATION) {
            const cachedData = getLocalStorage(RecruitersLocalStorage);
            console.log('[RECRUITERS] Found cached recruiters:', cachedData.length);
            setRecruiters(cachedData);
            setLoadingRecruiters(false);
            return;
        }

        fetchRecruiters();
    }, []);

    return (
        <RecruitersContext.Provider value={{recruiters, loadingRecruiters, refreshRecruiters}}>
            {children}
        </RecruitersContext.Provider>
    );
}


export function useRecruitersData(): RecruitersContextType {
    const context = useContext(RecruitersContext);
    if (!context) {
        throw new Error("useRecruitersData must be used within a RecruitersDataProvider");
    }
    return context;
}
