import React, {createContext, ReactNode, useContext, useState} from 'react';
import {collectionGroup, getDocs, query, where} from 'firebase/firestore';
import {Application, applicationConverter} from '../../../Firebase/FirebaseFunctions/ApplicationService';
import {db} from '../../../Firebase/FirebaseConfig/firebase';
import {candidateService} from "../../../Firebase/FirebaseFunctions/CandidateService";

interface ApplicationsContextType {
    applications: Array<Application>;
    loadingApplications: boolean;
    fetchApplicationsByCandidateId: (candidateId: string) => Promise<Application[]>;
    fetchApplicationsByJobNumber: (jobNumber: number) => Promise<Application[]>;
}

const ApplicationsContext = createContext<ApplicationsContextType | undefined>(undefined);

export function ApplicationsDataProvider({children}: { children: ReactNode }) {
    const [applications, setApplications] = useState<Array<Application>>([]);
    const [loadingApplications, setLoadingApplications] = useState<boolean>(false);

    const fetchApplicationsByCandidateId = async (candidateId: string) => {
        setLoadingApplications(true)

        try {
            console.log('[APPLICATIONS] Fetching from DB for candidate:', candidateId)
            const results = await candidateService.getApplications(candidateId)
            setApplications(results)
            return results
        } catch (error) {
            console.error('[APPLICATIONS] Error:', error)
            setApplications([])
            return []
        } finally {
            setLoadingApplications(false)
        }
    }

    const fetchApplicationsByJobNumber = async (jobNumber: number) => {
        setLoadingApplications(true)
        try {
            console.log('[APPLICATIONS] Fetching from DB for job:', jobNumber)
            const applicationsQuery = query(
                collectionGroup(db, 'applications').withConverter(applicationConverter),
                where('jobNumber', '==', jobNumber)
            )
            const snapshot = await getDocs(applicationsQuery)
            const results = snapshot.docs.map(doc => doc.data() as Application)

            setApplications(results)
            return results
        } catch (error) {
            console.error('[APPLICATIONS] Error:', error)
            setApplications([])
            return []
        } finally {
            setLoadingApplications(false)
        }
    }
    return (
        <ApplicationsContext.Provider
            value={{
                applications,
                loadingApplications,
                fetchApplicationsByCandidateId,
                fetchApplicationsByJobNumber
            }}
        >
            {children}
        </ApplicationsContext.Provider>
    );
}

export function useApplicationsData(): ApplicationsContextType {
    const context = useContext(ApplicationsContext);
    if (!context) {
        throw new Error("useApplicationsData must be used within an ApplicationsDataProvider");
    }
    return context;
}
