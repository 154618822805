import { Box } from "@mui/material";
import React from "react";
import Logo from "../../../Assets/GVANIM_LOGO_NEW1.png";
import styles from "./LoadingSpinner.module.css";

const LoadingSpinner: React.FC = () => {
    return (
        <Box className={styles.loaderContainer} sx={{height: "100%"}}>

            <Box className={styles.rotatingRing} />

            <Box className={styles.logoWrapper}>
                
        <img
          src={Logo}
          alt="Logo"
          width={317} 
          height={224}
          className={styles.logoImage}
        />
            </Box>
        </Box>
    );
};

export default LoadingSpinner;