import {Alert, Box, Chip, Typography} from "@mui/material";
import React from "react";
import {TableSearch} from "../../../../Components/Table/TableSearch";
import {TableColumns} from "../../../../Components/Table/Table.types";
import BetterTable from "../../../../Components/Table/Table";
import {useCandidatesData} from "../../../../Contexts/DataContext/CandidatesDataContext";
import {ApplicationCandidatesStatuses, statusesToMoveToBottom} from "../../../../Utilities/Statuses";
import ChipColorPicker from "../../../../Utilities/ChipColorPicker";
import {useSnackbar} from "../../../../../Utilities/Context/Snackbar";
import {useApplicationsData} from "../../../../Contexts/DataContext/ApplicationsDataContext";
import {RatingRTL} from "../../../../Styled/Rating";
import dayjs from "dayjs";

export default function JobCandidatesTable({job}) {
    const {showSnackbar} = useSnackbar();

    const columnsData: TableColumns = {
        columns: [
            {name: "שם המועמד", field: "candidateName", width: "30%", sortable: true},
            {name: "אימייל", field: "candidateEmail", width: "30%", sortable: true},
            {name: "תאריך הגשת מועמדות", field: "candidateCreationDate", width: "10%", sortable: true},
            {name: "תאריך שינוי סטטוס", field: "candidateStatusModifyDate", width: "10%", sortable: true},
            {name: "סטטוס", field: "candidateStatus", width: "20%", sortable: true},
            {name: "התאמה", field: "candidateFit", sortable: true}
        ],
        onRowClick: (row) => {
            const id = row?.candidate?.id;
            if (id) {
                window.open(`/management/candidate/${id}`, "_blank");
            } else {
                showSnackbar({message: "המועמד לא נמצא. אנו מתנצלים."});
            }
        }
    };

    const [loading, setLoading] = React.useState(true);
    const {candidates, loadingCandidates} = useCandidatesData();
    const {applications, loadingApplications, fetchApplicationsByJobNumber} = useApplicationsData();

    const [data, setData] = React.useState<any[]>([]);
    const [filteredData, setFilteredData] = React.useState<any[]>([]);

    const mapApplicationsToData = (results) => {
        return results.map((app) => {
            const candidate = candidates.find(cand => cand.id === app.candidateId);

            if (candidate === undefined) {
                return null;
            }

            let label = "";
            if (candidate?.firstname === undefined && candidate?.lastname === undefined) {
                label = "שגיאה";
            } else {
                if (candidate?.firstname !== undefined) {
                    label += `${candidate!.firstname} `;
                }
                if (candidate?.lastname !== undefined) {
                    label += `${candidate!.lastname} `;
                }
            }

            return ({
                candidateName: {label: label},
                candidateEmail: {label: candidate?.email || "שגיאה"},
                candidateStatusModifyDate: {
                    label: app.lastUpdate,
                    component: (
                        <Typography variant="body2">
                            {dayjs(app?.lastUpdate).isValid() ? dayjs(app.lastUpdate).format("DD/MM/YY") : "אין תאריך"}
                        </Typography>
                    )
                },
                candidateStatus: {
                    label: app.status,
                    component: <Chip label={app.status}
                                     color={ChipColorPicker(ApplicationCandidatesStatuses.indexOf(app.status))}/>
                },
                candidateCreationDate: {
                    label: app.applyDate,
                    component: <Typography variant="body2">
                        {dayjs(app?.applyDate).isValid() ? dayjs(app.applyDate).format("DD/MM/YY") : "אין תאריך"}
                    </Typography>
                },
                candidateFit: {
                    label: app.matchingRate,
                    component: <RatingRTL value={app.matchingRate} readOnly/>
                },
                candidate: candidate,
                application: app
            })
        }).filter((app) => app !== null && (app.candidateName.label !== "שגיאה" || app.candidateEmail.label !== "שגיאה"))
            .sort((a, b) => {
                const aStatus = statusesToMoveToBottom.has(a.application.status);
                const bStatus = statusesToMoveToBottom.has(b.application.status);
                return aStatus === bStatus ? 0 : aStatus ? 1 : -1;
            })
            .sort((a, b) => {
                if (a.application.status === 'הוגשה מועמדות' && b.application.status !== 'הוגשה מועמדות') {
                    return -1;
                }
                if (a.application.status !== 'הוגשה מועמדות' && b.application.status === 'הוגשה מועמדות') {
                    return 1;
                }
                return 0;
            })
    }

    React.useEffect(() => {
        if (job.jobNumber !== undefined) {
            fetchApplicationsByJobNumber(job.jobNumber);
        }
    }, [job]);

    React.useEffect(() => {
        if (!loadingApplications && !loadingCandidates) {
            const mappedData = mapApplicationsToData(applications);
            setData(mappedData);
            setFilteredData(mappedData);
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [applications, loadingApplications, candidates, loadingCandidates]);

    if (!loading && data.length <= 0) {
        return <>
            <Box sx={{width: "100%", padding: 2}}>
                <Alert
                    severity="info"
                    variant="outlined"
                    sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        padding: 2,
                        borderRadius: 1
                    }}
                >
                    לא נמצאו מועמדים
                </Alert>
            </Box>
        </>
    }

    return <>
        <Box width={{xs: "100%", md: "40%"}} paddingX={2} paddingY="1rem" justifySelf="center">
            <TableSearch data={data} setFilteredData={setFilteredData} columns={columnsData}/>
        </Box>

        <BetterTable columnsData={columnsData} data={filteredData}
                     loading={loading} paginator={{label: "מועמדים", rowsPerPage: 5}}/>
    </>
}