import { useNavigate, useOutletContext } from "react-router-dom";
import { Candidate } from "../../../../../Firebase/FirebaseFunctions/CandidateService";
import LoadingSpinner from "../../../../Components/LoadingSpinner/LoadingSpinner";
import React, { useEffect, useState } from "react";
import isMobile from "../../../../Utilities/isMobile";
import { getAuth } from "firebase/auth";

export default function CandidateResume() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [url, setUrl] = useState<string | null>(null);

    const { candidate } = useOutletContext<{ candidate: Candidate }>();

    useEffect(() => {
        async function fetchCandidateCV() {
            try {
                const auth = getAuth();
                if (!auth.currentUser) {
                    throw new Error("User is not authenticated");
                }

                const token = await auth.currentUser.getIdToken();
                const bucketName = process.env.REACT_APP_STORAGE_BUCKET;
                if (!bucketName) {
                    throw new Error("Firebase Storage bucket is not defined.");
                }

                const filePath = `CandidatesFiles/${candidate.id}/CV.pdf`;
                const encodedFilePath = encodeURIComponent(filePath);
                const url = `https://firebasestorage.googleapis.com/v0/b/${bucketName}/o/${encodedFilePath}?alt=media`;

                const response = await fetch(url, {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const blob = await response.blob();
                const blobUrl = URL.createObjectURL(blob);
                setUrl(blobUrl);
                setLoading(false);


                if (isMobile()) {
                    window.open(blobUrl, "_blank");
                    window.close();
                }

            } catch (error) {
                console.error("Error fetching the file:", error);
                navigate('/management/not-found');
            }
        }

        fetchCandidateCV();
    }, [candidate.id, navigate]);

    if (url === null) {
        return <LoadingSpinner />;
    }

    return (
        <>
            {loading && <LoadingSpinner />}
            <embed
                src={url}
                width="100%"
                height="100%"
                onLoad={() => setLoading(false)}
            />
        </>
    );
}
