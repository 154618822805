import {ChipProps} from "@mui/material";

export default function ChipColorPicker(index: number) {
    const colors: ChipProps["color"][] = [
        "primary",
        "secondary",
        "success",
        "info",
        "warning",
        "error"
    ];

    const colorIndex = index % colors.length;
    return colors[colorIndex];
}