import React from 'react';
import {IconButton, TableCell, TableRow} from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

interface ExpandButtonProps {
    expanded: boolean;
    onClick: (event) => void;
}

const ExpandButton: React.FC<ExpandButtonProps> = ({expanded, onClick}) => {
    const [_expanded, _setExpanded] = React.useState(expanded);

    return (
        <IconButton
            onClick={(event) => {
                _setExpanded(!_expanded);
                onClick(event);
            }}
            sx={{
                transform: _expanded ? 'rotate(-90deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s',
            }}
        >
            <KeyboardArrowLeftIcon/>
        </IconButton>
    );
};

export default ExpandButton;