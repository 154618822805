import {InputBase, styled} from "@mui/material";

export const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: theme.palette.text.primary,
    border: `2px solid transparent`,
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.short,
        }),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    '&:focus-within': {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: theme.shape.borderRadius,
        boxShadow: `0 0 3px ${theme.palette.primary.main}`,
    },
    transition: 'border-color 0.3s ease, box-shadow 0.3s ease',
}));