import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import React from "react";
import {Chip, Tooltip} from "@mui/material";

export default function OpenClosedChip({initialOpen, disabled = false, onApprovedClick}) {
    const onClick = async (e) => {
        e.stopPropagation();
        await onApprovedClick();
    }

    const chipComponent = <Chip
        label={initialOpen ? "פתוח" : "סגור"}
        color={initialOpen ? "success" : "error"}
        icon={initialOpen ? <LockOpenIcon/> : <LockIcon/>}
        onClick={disabled ? undefined : onClick}
        sx={{cursor: "pointer"}}
        variant="outlined"
    />;

    if (disabled) {
        return chipComponent;
    }

    return (
        <Tooltip title={initialOpen ? 'סגור' : 'פתח'}>
            {chipComponent}
        </Tooltip>
    );
}