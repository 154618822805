import React from "react";
import InfoTable from "../../../../Components/Table/InfoTable";
import {Avatar, Box, Button, Paper, Tooltip} from "@mui/material";
import EditableLabel from "../../../../Components/Labels/EditableLabel";
import {useLocation, useNavigate} from "react-router-dom";
import CreateCandidate from "../../CreateCandidate/CreateCandidate";
import {useCandidateDelete, useCandidateUpdate} from "../../../../Functions/Candidate";
import {useAuth} from "../../../../Contexts/AuthContext/AuthContext";
import {UserRole} from "../../../../../Firebase/enums/UserRole";
import {useSnackbar} from "../../../../../Utilities/Context/Snackbar";
import * as Yup from "yup";
import dayjs from "dayjs";

export default function CandidateInfo({candidate}) {
    const navigate = useNavigate();
    const location = useLocation();
    const removeCandidate = useCandidateDelete(() => navigate("/management/candidates"));
    const updateCandidate = useCandidateUpdate(undefined, true);

    const {role} = useAuth();
    const [modifyCandidate, setModifyCandidate] = React.useState<boolean>(false);
    const {showSnackbar} = useSnackbar();

    const columns = [
        {label: "שם מלא", field: "candidateName"},
        {label: "אימייל", field: "candidateEmail"},
        {label: "מס' טלפון", field: "candidatePhone"},
        {label: "הערות", field: "candidateNotes"},
    ];

    const data = [
        {
            candidateName: (
                <EditableLabel initialText={`${candidate.firstname} ${candidate.lastname}`}
                               tooltipText="לחץ פעמיים עבור עריכה" setOnSave={async (text) => {
                    const parseFullName = (fullName: string) => {
                        const parts = fullName.split(/\s(.+)/);
                        if (parts[parts.length - 1] === "") {
                            parts.pop();
                        }
                        return {
                            parts,
                            firstname: parts[0],
                            lastname: parts[1] || "",
                            isValid: parts.length === 2 && parts[1].trim() !== ""
                        };
                    };

                    const fullNameSchema = Yup.string()
                        .required('חובה שם מלא')
                        .test('full-name', 'יש להזין שם משפחה', value => {
                            return parseFullName(value).isValid;
                        });

                    try {
                        await fullNameSchema.validate(text);

                        const {firstname, lastname} = parseFullName(text);
                        const obj = {firstname, lastname};

                        const success = await updateCandidate(candidate, obj);
                        if (success) {
                            candidate.firstname = obj.firstname;
                            candidate.lastname = obj.lastname;
                        }
                        return success;
                    } catch (error) {
                        showSnackbar({message: (error as any).message});
                        return false;
                    }
                }}/>
            ),
            candidateEmail: (
                <EditableLabel initialText={candidate.email} tooltipText="לחץ פעמיים עבור עריכה"
                               setOnSave={async (text) => {
                                   const emailSchema = Yup.string().email("האימייל שגוי").required("חובה להזין אימייל");
                                   try {
                                       await emailSchema.validate(text);
                                   } catch (error) {
                                       showSnackbar({message: (error as any).message});
                                       return false;
                                   }

                                   const success = await updateCandidate(candidate, {email: text})
                                   if (success) {
                                       candidate.email = text;
                                   }
                                   return success;
                               }}/>
            ),
            candidatePhone: candidate.id,
            candidateNotes: (
                <EditableLabel initialText={candidate.note} tooltipText="לחץ פעמיים עבור עריכה"
                               setOnSave={async (text) => {
                                   const success = await updateCandidate(candidate, {note: text});
                                   if (success) {
                                       candidate.note = text;
                                   }
                                   return success;
                               }}/>
            ),
        },
    ];

    return <>
        <Paper sx={{
            display: "flex",
            alignItems: "stretch",
            gap: 2,
            padding: "1rem",
            boxShadow: "rgba(85, 90, 191, 1) 0px 2px 5px, rgba(85, 90, 191, 1) 0px 4px 10px",
            width: {xs: "100%", sm: "85%"},
            flexDirection: {xs: "column-reverse", sm: "row"},
            margin: "auto",
            marginBottom: "1rem",
        }}>
            <Box display="flex" sx={{
                flexDirection: {xs: "row", sm: "column"}
            }}>
                <Avatar
                    src="/broken-image.jpg"
                    variant="rounded"
                    sx={{
                        width: {xs: "150px", sm: '95px'},
                        height: {xs: "150px", sm: '85px'},
                        marginBottom: "10px",
                        marginRight: {xs: "10px", sm: "0"}
                    }}
                />

                <Box display="flex" justifyContent="space-evenly" flexDirection="column" height="100%" width="100%">
                    {location.pathname.includes("interviews") ?
                        <Button variant="text" onClick={() => navigate(`view`)}>משרות</Button> :
                        <Button variant="text" onClick={() => navigate(`interviews`)}>ראיונות</Button>}
                    <Tooltip
                        title={candidate.lastModified ? `הועלה בתאריך: ${dayjs(candidate.lastModified).format("DD/MM/YY")}` : undefined}>
                        <Button variant="text"
                                onClick={() => window.open(`/management/candidate/${candidate.id}/resume`, '_blank')}>קו"ח</Button>
                    </Tooltip>
                    <Button variant="text" onClick={() => setModifyCandidate(true)}>עריכת פרטים</Button>
                    {role === UserRole.admin &&
                        <Button variant="text" color="error" onClick={() => removeCandidate(candidate)}>מחק
                            מועמד</Button>}
                </Box>
            </Box>

            <InfoTable
                columns={columns}
                data={data}
            />

        </Paper>

        <CreateCandidate show={modifyCandidate} close={() => setModifyCandidate(false)}
                         candidate={candidate}/>
    </>
}