import {sendWhatsAppMessage} from "../../../../../../Utilities/WhatsAppMessage";
import {Button} from "@mui/material";
import React from "react";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {useDialog} from "../../../../../../Contexts/Dialog";

export default function WhatsAppButton({
                                           message, interviewType, phoneNumber, clicked = () => {
    }
                                       }) {
    const {showDialog} = useDialog();

    const onClick = () => {

        showDialog({
            title: "שליחת הודעת ווצאפ",
            description: "האם ברצונך לשלוח הודעה לזימון ראיון בווצאפ?",
            onAccept: () => sendWhatsAppMessage({phoneNumber, message}),
            acceptColorAsGreen: true
        })
        clicked();
    };

    return <>
        <Button startIcon={<WhatsAppIcon/>} onClick={onClick} variant="outlined"
                color="success">{`זמן לראיון ${interviewType}`}</Button>
    </>
}
