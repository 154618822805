export const ApplicationCandidatesStatuses = [
    "הוגשה מועמדות",
    "זומן לראיון ראשון",
    "עבר ראיון ראשון",
    "זומן לראיון שני",
    "עבר ראיון שני",
    "נדחה",
    "התקבל",
    "הועבר למשרה אחרת",
    "אינו מעוניין במשרה",
    'קו"ח הועברו למנהלי התוכנית'
]

export const statusesToMoveToBottom = new Set([
    "נדחה",
    "הועבר למשרה אחרת",
    "אינו מעוניין במשרה",
    'קו"ח הועברו למנהלי התוכנית'
]);

export const getStatusHexColor = (status: string): string | null => {
    const statusColors: { [key: string]: string } = {
        "התקבל": "E2EFDA",           // light green
        "נדחה": "FFE6E6",            // light red
        "הוגשה מועמדות": "FFF2CC",   // light yellow
        "זומן לראיון ראשון": "DEEBF7", // light blue
        "עבר ראיון ראשון": "C6E7C6",  // different light green
        "זומן לראיון שני": "BDD7F0",  // darker light blue
        "עבר ראיון שני": "A8D3A8",    // darker light green
        "הועבר למשרה אחרת": "F5E6FF", // light purple
        "אינו מעוניין במשרה": "E0E0E0", // medium gray
        'קו"ח הועברו למנהלי התוכנית': "FFE4B5" // light orange/peach
    };

    return statusColors[status] || null;
};