import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {collection, getDocs, orderBy, query, where,} from 'firebase/firestore';
import {
    Candidate,
    candidateConverter,
    CandidateLocalStorage
} from '../../../Firebase/FirebaseFunctions/CandidateService';
import {db} from '../../../Firebase/FirebaseConfig/firebase';
import {getLocalStorage, setLocalStorage} from "../../../Firebase/FirebaseFunctions/Helpers/LocalStorage";
import {UserRole} from '../../../Firebase/enums/UserRole';
import {useAuth} from "../AuthContext/AuthContext";

// Define the structure of the data context
interface DataContextType {
    candidates: Array<Candidate>;
    loadingCandidates: boolean;
    refreshCandidates: () => Promise<void>; // from localstorage
    reloadCandidates: () => Promise<void>; // from db
}

// Create the data context
export const DataContext = createContext<DataContextType | undefined>(undefined);

// Provider component for managing candidates data
export function CandidatesDataProvider({ children }: { children: ReactNode; }) {
    const { role, userDetails } = useAuth();
    const [candidates, setCandidates] = useState<Array<Candidate>>(getLocalStorage(CandidateLocalStorage));
    const [loadingCandidates, setLoadingCandidates] = useState<boolean>(true);

    const fetchCandidates = async () => {
        setLoadingCandidates(true);
        try {
            if (userDetails?.sectors.length <= 0) {
                setCandidates([]);
                return;
            }

            const getQuery = () => {
                if (role === UserRole.admin) {
                    return query(
                        collection(db, 'candidates').withConverter(candidateConverter),
                        orderBy('lastModified', 'desc')
                    );
                }

                return query(
                    collection(db, 'candidates').withConverter(candidateConverter),
                    orderBy('lastModified', 'desc'),
                    where('sectors', 'array-contains-any', userDetails?.sectors)
                );
            };

            const snapshot = await getDocs(getQuery());
            const fetchedCandidates = snapshot.docs.map((doc) => doc.data() as Candidate);
            console.log('[CANDIDATES] Fetched candidates (from server) length:', fetchedCandidates.length);

            setCandidates(fetchedCandidates);
            setLocalStorage(CandidateLocalStorage, fetchedCandidates);
            localStorage.setItem('candidatesLastFetchTime', new Date().getTime().toString());
        } catch (error) {
            console.error("[CANDIDATES] Error fetching candidates: ", error);
            const cachedData = getLocalStorage(CandidateLocalStorage);
            setCandidates(cachedData);
        } finally {
            setLoadingCandidates(false);
        }
    };

    const refreshCandidates = async () => {
        setLoadingCandidates(true);
        console.log("[CANDIDATES] data refreshed (from local).");
        setCandidates(getLocalStorage(CandidateLocalStorage));
        setLoadingCandidates(false);
    };

    const reloadCandidates = async () => {
        console.log("[CANDIDATES] reloading candidates from database.");
        await fetchCandidates();
    };

    useEffect(() => {
        const lastFetch = localStorage.getItem('candidatesLastFetchTime');
        const currentTime = new Date().getTime();
        const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes cache
        if (lastFetch && currentTime - parseInt(lastFetch) < CACHE_DURATION) {
            const cachedData = getLocalStorage(CandidateLocalStorage);
            console.log('[CANDIDATES] Fetched candidates (from local) length:', cachedData.length);
            setCandidates(cachedData);
            setLoadingCandidates(false);
            return;
        }
        fetchCandidates();
    }, [userDetails?.sectors, role]);

    return (
        <DataContext.Provider value={{ candidates, loadingCandidates, refreshCandidates, reloadCandidates }}>
            {children}
        </DataContext.Provider>
    );
}

// Hook for accessing the candidates data context
export function useCandidatesData(): DataContextType {
    const context = useContext(DataContext);
    if (!context) {
        throw new Error('useCandidatesData must be used within a CandidatesDataProvider');
    }
    return context;
}
