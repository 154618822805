import React from "react";
import {TableColumns, TableOptions} from "../../Components/Table/Table.types";
import {Delete, Edit, EventSeat} from "@mui/icons-material";
import TitleLabel from "../../Components/TitleLabel";
import {Box, Button, Grid} from "@mui/material";
import {TableSearch} from "../../Components/Table/TableSearch";
import BetterTable from "../../Components/Table/Table";
import CreateRole from "./CreateRoles/CreateRole";
import OpenClosedChip from "../../Components/Chips/OpenClosedChip";
import CustomCard from "../../Components/Cards/Card";
import {useAuth} from "../../Contexts/AuthContext/AuthContext";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import {RolesDataProvider, useRolesData} from "../../Contexts/DataContext/RolesDataContext";
import {useRoleChangeOpenClosed, useRoleDelete} from "../../Functions/Role";
import {useSearchParams} from "react-router-dom";

function RolesPageContent() {
    const [createRole, setCreateRole] = React.useState<any>(undefined);

    const deleteRole = useRoleDelete();
    const roleChangeOpenClosed = useRoleChangeOpenClosed();

    const columnsData: TableColumns = {
        columns: [
            {name: "שם התפקיד", field: "roleName", width: "50%", sortable: true},
            {name: "סטטוס", field: "roleStatus", width: "45%", sortable: true},
        ]
    }

    const options: TableOptions = {
        label: "אפשרויות",
        items: [
            {label: "ערוך תפקיד", icon: <Edit/>, command: row => setCreateRole(row.role)},
            {label: "מחק תפקיד", icon: <Delete/>, command: row => deleteRole(row.role)}
        ]
    };

    const {roles, loadingRoles} = useRolesData();
    const mapRolesToData = (roles) => {
        return roles.map((role) => ({
            roleName: role.name,
            roleStatus: {
                label: role.open,
                component: <OpenClosedChip initialOpen={role.open}
                                           onApprovedClick={() => roleChangeOpenClosed(role)}/>
            },
            role: role
        }))
    }

    const data = React.useMemo(() => !loadingRoles ? mapRolesToData(roles) : [], [roles, loadingRoles]);
    const [filteredData, setFilteredData] = React.useState([]);
    React.useEffect(() => {
        setFilteredData(data);
    }, [data]);

    const [searchParams] = useSearchParams();
    const paramName = searchParams.get('name');

    React.useEffect(() => {
        if (paramName && !loadingRoles) {
            const role = roles.find(r => r.name.toString() === paramName);
            if (role) {
                setCreateRole(role);
            }
        }
    }, [paramName, loadingRoles]);

    return <>
        <TitleLabel title="תפקידים" icon={EventSeat}/>

        <CustomCard noPadding={true}>
            <Box display="flex" justifyContent="space-between" padding="1rem">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TableSearch data={data} setFilteredData={setFilteredData} columns={columnsData}/>
                    </Grid>

                    <Grid item xs={12} sm={6} display="flex" justifyContent="end">
                        <Button variant="outlined" sx={{width: {xs: "100%", sm: "fit-content"}}} color="primary"
                                onClick={() => setCreateRole(null)}>תפקיד
                            חדש</Button>
                    </Grid>
                </Grid>
            </Box>

            <BetterTable columnsData={columnsData} data={filteredData} loading={loadingRoles} options={options}
                         paginator={{label: "תפקידים", rowsPerPage: [5, 10, 15]}}/>
        </CustomCard>

        <CreateRole show={createRole !== undefined} close={() => setCreateRole(undefined)} role={createRole}/>
    </>
}

export default function RolesPage() {
    const {loading} = useAuth();

    if (loading) {
        return <LoadingSpinner/>
    }

    return <>
        <RolesDataProvider>
            <RolesPageContent/>
        </RolesDataProvider>
    </>
}