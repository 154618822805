import {Box, Card, CardContent, Typography, useTheme} from '@mui/material';

const InformationalCard = ({icon, title, subtitle}) => {
    const theme = useTheme();

    return (
        <Card sx={{
            height: '100%',
            transition: 'all 0.3s ease-in-out',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            border: '1px solid rgba(0,0,0,0.05)',
            backgroundColor: theme.palette.custom.card.backgroundColor,
            '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                border: '1px solid rgba(0,0,0,0.1)',
                backgroundColor: theme.palette.custom.card.hoverBackgroundColor
            }
        }} className="informational-card">
            <CardContent sx={{
                padding: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center'
            }}>
                <Box sx={{
                    marginBottom: 2.5,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 64,
                    height: 64,
                    borderRadius: '50%',
                    backgroundColor: 'rgba(0,0,0,0.04)'
                }}>
                    {icon}
                </Box>
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 600,
                        marginBottom: 1,
                        fontSize: '1.1rem'
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                        fontSize: '0.875rem',
                        lineHeight: 1.5
                    }}
                >
                    {subtitle}
                </Typography>
            </CardContent>
        </Card>
    );
};


export default InformationalCard;
