import React from 'react';
import {Typography, Box, useTheme} from '@mui/material';

const InfoTable = ({ data, columns, sx = {} }) => {
    const theme = useTheme();
    return (
        <table
            style={{
                borderCollapse: 'collapse',
                width: '100%',
                backgroundColor: theme.palette.custom.table.secondTableRow,
                ...sx
            }}
        >
            <tbody>
            {columns.map((column, index) => (
                <tr key={`column-${index}`}>
                    <td
                        style={{
                            minWidth: '120px',
                            padding: '10px 15px',
                            borderBottom: '1px solid #e0e0e0',
                            backgroundColor: theme.palette.custom.table.secondTableRow,
                            fontWeight: 'bold',
                            color: '#333',
                            textAlign: 'right',
                        }}
                    >
                        <Typography variant="body1" color="textSecondary">
                            {column.label}
                        </Typography>
                    </td>
                    <td
                        style={{
                            padding: '10px 15px',
                            borderBottom: '1px solid #e0e0e0',
                        }}
                    >
                        {data.map((row, rowIndex) => (
                            <Box
                                key={`row-${rowIndex}-${index}`}
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'center',
                                    wordBreak: 'break-word',
                                    color: theme.palette.text.primary
                                }}
                            >
                                {React.isValidElement(row[column.field]) ? (
                                    row[column.field]
                                ) : (
                                    <Typography variant="body1">
                                        {row[column.field]}
                                    </Typography>
                                )}
                            </Box>
                        ))}
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    );
};

export default InfoTable;