import React from "react";
import {AppBar, Box, Button, Collapse, Container, IconButton, Stack, Toolbar, Typography,} from "@mui/material";
import Logo from "../../../Assets/GVANIM_LOGO_NEW2.png";
import colors from "../../Theme/LayoutTheme";
import * as styles from "./NavbarStyles";
import {ColorModeContext} from "../../Theme/Theme";

interface Page {
    label: string;
    url: string;
}

const pages: Page[] = [
    {label: "ראשי", url: "https://www.gvanim.org.il/"},
    {label: "משרות", url: "/career/jobs"},
    {label: "אודות", url: "https://www.gvanim.org.il/index.php/he/node/4"},
];

const ResponsiveAppBar: React.FC = () => {
    const [open, setOpen] = React.useState<boolean>(false);
    // const [activePage, setActivePage] = React.useState<string>("משרות");

    const colorMode = React.useContext(ColorModeContext);
    const theme = colors(colorMode?.getActualMode());

    const toggleMenu = () => {
        setOpen((prev) => !prev);
    };

    const handleClick = (pageLabel: string) => {
        // setActivePage(pageLabel);
        setOpen(false);
    };

    return (
        <AppBar position="sticky" sx={{
            backgroundColor:
                colorMode?.getActualMode()! === 'light'
                    ? '#FFFFFF'
                    : colorMode?.getActualMode()! === 'dark-contrast'
                        ? '#000000'
                        : colorMode?.getActualMode()! === 'bright-contrast'
                            ? '#FFFFFF'
                            : '#FFFFFF', // black & white

            color: theme.main,
            borderTop: '4px solid',
            borderColor: theme.main,
        }}>
            <Container maxWidth="xl"
                       sx={{
                           padding: '0px !important',
                       }}
            >
                <Toolbar sx={{justifyContent: "space-between"}}>
                    {/* icon humburger - mobile*/}
                    <Box sx={{display: {xs: "block", md: "none"}}}>
                        <IconButton
                            color="inherit"
                            sx={{p: 0}}
                            onClick={toggleMenu}
                            disableRipple
                            disableFocusRipple
                        >
                            <Box sx={{position: "relative", width: "30px", height: "24px"}}>
                                {/* פס עליון */}
                                <Box
                                    sx={{
                                        position: "absolute",
                                        width: "100%",
                                        height: "2px",
                                        backgroundColor:
                                            colorMode?.getActualMode()! === 'bright-contrast' ? "#053B7A" : theme.main,
                                        borderRadius: "1px",
                                        transition: "transform 0.3s ease, top 0.3s ease",
                                        top: open ? "12px" : "6px",
                                        transform: open ? "rotate(45deg)" : "rotate(0deg)",
                                    }}
                                />
                                {/* פס אמצעי */}
                                <Box
                                    sx={{
                                        position: "absolute",
                                        width: "100%",
                                        height: "2px",
                                        backgroundColor:
                                            colorMode?.getActualMode()! === 'bright-contrast' ? "#053B7A" : theme.main,
                                        borderRadius: "1px",
                                        transition: "opacity 0.3s ease",
                                        top: "12px",
                                        opacity: open ? 0 : 1,
                                    }}
                                />
                                {/* פס תחתון */}
                                <Box
                                    sx={{
                                        position: "absolute",
                                        width: "100%",
                                        height: "2px",
                                        backgroundColor:
                                            colorMode?.getActualMode()! === 'bright-contrast' ? "#053B7A" : theme.main,
                                        borderRadius: "1px",
                                        transition: "transform 0.3s ease, top 0.3s ease",
                                        top: open ? "12px" : "18px",
                                        transform: open ? "rotate(-45deg)" : "rotate(0deg)",
                                    }}
                                />
                            </Box>
                        </IconButton>
                    </Box>

                    <Box id="logo_desktop" display={{xs: "none", md: "flex"}} flexDirection={'row'}>
                        <img src={Logo} alt="LogoDesk" style={styles.logoImageDesk(theme)}/>
                    </Box>

                    <Box id="logo_Mobile" display={{xs: "flex", md: "none"}} flexDirection={'row'}>
                        <img src={Logo} alt="LogoMob" style={styles.logoImageMob(theme)}/>
                    </Box>


                    <Typography display={{xs: "flex", md: "none"}} variant="caption" sx={{
                        fontWeight: "bold",
                        color:
                            colorMode?.getActualMode()! === 'light'
                                ? '#5BA1AA'
                                : colorMode?.getActualMode()! === 'dark-contrast'
                                    ? '#b2d0ec'
                                    : colorMode?.getActualMode()! === 'bright-contrast'
                                        ? '#5BA1AA'
                                        : '#000000', // black & white

                        letterSpacing: 0,
                        opacity: 1,
                        filter:
                            colorMode?.getActualMode()! === 'bright-contrast'
                                ? 'brightness(0.7)'
                                : 'brightness(1)',
                    }}>
                        9913*
                    </Typography>

                    {/* links - desktop*/}
                    <Stack
                        direction="row"
                        spacing={3}
                        sx={{display: {xs: "none", md: "flex"}}}
                    >
                        {pages.map((page) => (
                            <Button
                                key={page.label}
                                href={page.url}
                                onClick={() => handleClick(page.label)}
                                disableRipple
                                disableFocusRipple
                                sx={{
                                    color:
                                        colorMode?.getActualMode()! === 'light'
                                            ? '#053B7A'
                                            : colorMode?.getActualMode()! === 'dark-contrast'
                                                ? '#b2d0ec'
                                                : colorMode?.getActualMode()! === 'bright-contrast'
                                                    ? '#053B7A'
                                                    : '#000000', // black & white

                                    fontWeight: "bold",
                                    // borderBottom:
                                    //     activePage === page.label ? "2px solid #000" : "none",
                                    "&:hover": {
                                        backgroundColor: "transparent",
                                        color: colorMode?.getActualMode()! !== 'light' && colorMode?.getActualMode()! !== 'dark-contrast' && colorMode?.getActualMode()! !== 'bright-contrast'
                                            ? 'darkgray' : colorMode?.getActualMode()! === 'dark-contrast' ? '#5ba1aa9c' : '#5BA1AA'
                                    },
                                }}
                            >
                                {page.label}
                            </Button>
                        ))}
                    </Stack>

                    {/* test - desktop ("צריכים עזרה?")*/}
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        sx={{display: {xs: "none", md: "flex"}}}
                    >
                        <Typography
                            variant="caption"
                            sx={{
                                color:
                                    colorMode?.getActualMode()! === 'light'
                                        ? '#91A749'
                                        : colorMode?.getActualMode()! === 'dark-contrast'
                                            ? '#c3c6aa'
                                            : colorMode?.getActualMode()! === 'bright-contrast'
                                                ? '#91A749'
                                                : 'darkgray', // black & white
                                filter:
                                    colorMode?.getActualMode()! === 'bright-contrast'
                                        ? 'brightness(0.7)'
                                        : 'brightness(1)'
                            }}
                        >צריכים עזרה? דברו איתנו</Typography>
                        <Typography variant="caption" sx={{
                            fontWeight: "bold",
                            color:
                                colorMode?.getActualMode()! === 'light'
                                    ? '#5BA1AA'
                                    : colorMode?.getActualMode()! === 'dark-contrast'
                                        ? '#b2d0ec'
                                        : colorMode?.getActualMode()! === 'bright-contrast'
                                            ? '#5BA1AA'
                                            : '#000000', // black & white

                            letterSpacing: 0,
                            opacity: 1,
                            filter:
                                colorMode?.getActualMode()! === 'bright-contrast'
                                    ? 'brightness(0.7)'
                                    : 'brightness(1)',
                        }}>
                            9913*
                        </Typography>
                    </Stack>


                </Toolbar>
            </Container>

            {/* menu - mobile*/}
            <Collapse in={open} sx={{display: {xs: "block", md: "none"}}}>
                <Stack
                    spacing={2}
                    sx={{
                        backgroundColor: colorMode?.getActualMode()! === 'dark-contrast' ? "#1d1d1d" : "#f9f9f9"
                    }}
                    p={2}
                    alignItems="center"
                >
                    {pages.map((page) => (
                        <Button
                            key={page.label}
                            href={page.url}
                            onClick={() => handleClick(page.label)}
                            disableRipple
                            disableFocusRipple
                            sx={{
                                color: theme.main,
                                fontWeight: "bold",
                                // borderBottom:
                                //     activePage === page.label ? "2px solid #000" : "none",
                            }}
                        >
                            {page.label}
                        </Button>
                    ))}
                    {/* text - mobile*/}
                    <Box textAlign="center" mt={1}>
                        <Typography variant="caption">צריכים עזרה? דברו איתנו</Typography>
                        <br/>
                        <Typography variant="caption" sx={{fontWeight: "bold"}}>
                            9913*
                        </Typography>
                    </Box>
                </Stack>
            </Collapse>
        </AppBar>
    );
};

export default ResponsiveAppBar;
