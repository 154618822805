import {useSectorsData} from "../../Contexts/DataContext/SectorsDataContext";
import {Chip} from "@mui/material";
import ChipColorPicker from "../../Utilities/ChipColorPicker";
import React from "react";

export default function SectorChip({id, sx = {}}) {
    const {sectors} = useSectorsData();
    if(!sectors) {
        return null;
    }

    const index = sectors.findIndex(item => item.id === id);

    if (index === -1) {
        return null;
    }

    return <Chip
        label={sectors[index].name}
        color={ChipColorPicker(index)}
        sx={{...sx}}
    />
}
