import * as Yup from "yup";
import {AuthCard} from "./Components/Card";
import {Form, Formik} from "formik";
import {FormControl, FormHelperText, TextField, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {recruiterService} from "../../../Firebase/FirebaseFunctions/RecruiterService";
import {useSnackbar} from "../../../Utilities/Context/Snackbar";
import {useNavigate} from "react-router-dom";


export default function EmailResetPasswordPage() {
    const {showSnackbar} = useSnackbar();
    const navigate = useNavigate();

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('יש להזין אימייל נכון')
            .required('יש להזין כתובת אימייל'),
    });

    const handleSubmit = async (values, {setSubmitting, setTouched}) => {
        setSubmitting(true);
        setTouched({
            email: true,
        });

        try {
            await recruiterService.sendPasswordReset(values.email);
            navigate("/auth/login")
            showSnackbar({message: `נשלח איפוס סיסמה למייל.`, severity: "success"});
        } catch (error) {
            showSnackbar({message: `שגיאה בשליחת מייל לאיפוס סיסמה. שגיאה: ${error}`})
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <AuthCard title={"איפוס סיסמה"}>
            <Formik
                initialValues={{email: ''}}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({
                      values,
                      handleChange,
                      handleBlur,
                      isSubmitting,
                      touched,
                      errors
                  }) => (
                    <Form style={{width: '100%'}}>
                        <FormControl fullWidth margin="normal">
                            <TextField
                                fullWidth
                                size="small"
                                label="כתובת אימייל"
                                variant="outlined"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={!!touched.email && !!errors.email}
                                InputLabelProps={{shrink: true}}
                            />
                            <FormHelperText error>
                                {touched.email && errors.email ? errors.email : ''}
                            </FormHelperText>
                        </FormControl>

                        <LoadingButton
                            type="submit"
                            size="small"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            sx={{
                                background: '#053B7A',
                                '&:hover': {
                                    background: '#2863a8',
                                },
                                mt: 1,
                                py: 1,
                            }}
                        >
                            שלח מייל לאיפוס סיסמה
                        </LoadingButton>

                        <Typography variant="body2" sx={{
                            mt: 3,
                            textAlign: 'center',
                            cursor: "pointer",

                        }}
                                    onClick={() => navigate('/auth/login')}>
                            חזרה לכניסת משתמש
                        </Typography>
                    </Form>
                )}
            </Formik>
        </AuthCard>
    );
}
