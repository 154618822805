import {
    collection,
    doc,
    DocumentData,
    Firestore,
    getDoc,
    getDocs,
    onSnapshot,
    query,
    QueryDocumentSnapshot,
    where
} from "firebase/firestore";
import {Auth, sendPasswordResetEmail} from 'firebase/auth';
import * as yup from "yup";
import {auth, db, functions} from "../FirebaseConfig/firebase";
import {CRUDType, customHttpsCall, CustomHttpsCallInter, customUpdateDoc} from "./Helpers/CustomFirebaseFunctions";
import {httpsCallable} from "firebase/functions";
import {UserRole} from "../enums/UserRole";
import {convertToDate} from "../../Management/Utilities/DateFormatter";

export const RecruitersLocalStorage = "recruiters";

interface setRecruiterActivityResponse {
    message: string;
    success: boolean;
}

interface CreateRecruiterResponse {
    message: string;
    uid: string;
    success: boolean;
}

class Recruiter {
    firstName: string;
    lastName: string;
    email: string;
    sectors: string[];

    constructor(firstName: string, lastName: string, email: string, sectors: string[] = []) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.sectors = sectors;
    }
}

const recruiterSchema = yup.object().shape({
    firstName: yup.string().required("First name is required").min(2, "First name must be at least 2 characters"),
    lastName: yup.string().required("Last name is required").min(2, "Last name must be at least 2 characters"),
    email: yup.string().email("Invalid email").required("Email is required"),
    sectors: yup.array().of(yup.string()).default([]).optional(),
});

const recruiterConverter = {
    toFirestore: (recruiter: Recruiter): DocumentData => {
        return {
            firstName: recruiter.firstName,
            lastName: recruiter.lastName,
            email: recruiter.email,
            sectors: recruiter.sectors,
        };
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot, options: any): Recruiter => {
        const data = snapshot.data(options);
        return new Recruiter(data.firstName, data.lastName, data.email, data.sectors);
    },
};

class RecruiterService {
    private db: Firestore;
    private auth: Auth;
    private collectionPath: string;

    constructor(db: Firestore, auth: Auth, collectionPath: string) {
        this.db = db;
        this.auth = auth;
        this.collectionPath = collectionPath;
    }

    async saveRecruiter(recruiterdata: DocumentData): Promise<CreateRecruiterResponse | void> {
        try {
            // Validate data using Yup schema
            const recruiterData = await recruiterSchema.validate(recruiterdata, {abortEarly: false});

            // Check if a recruiter with the provided email already exists
            const isExists = await this.recruiterExists(recruiterData.email);
            if (isExists) {
                throw new Error(`A recruiter with email ${recruiterData.email} already exists.`);
            }

            // Call the createRecruiter function if the email does not exist
            const obj: CustomHttpsCallInter = {
                functionName: "createRecruiter",
                httpsData: {recruiterData},
                localStorageKey: RecruitersLocalStorage,
                type: CRUDType.Create,
                storageData: recruiterData,
            }
            const result = await customHttpsCall(obj);

            console.log("Recruiter created:", result?.data);

            await this.sendPasswordReset(recruiterData.email);

            return result?.data as CreateRecruiterResponse;


        } catch (error: any) {
            if (error instanceof yup.ValidationError) {
                console.error("Validation errors:", error.errors);
                throw new Error("Invalid recruiter data. Please check the input fields.");
            } else {
                throw new Error(error);
            }
        }
    }

    async updateRecruiterEmail(recruiterId: string, newEmail: string): Promise<void> {
        // await this.ensureAdmin();
        try {
            const recruiterRef = doc(this.db, this.collectionPath, recruiterId);
            await customUpdateDoc(recruiterRef, {email: newEmail}, RecruitersLocalStorage);
            console.log("Recruiter email updated successfully!");
        } catch (error) {
            console.error("Error updating recruiter email:", error);
            throw new Error("An error occurred while updating the recruiter email.");
        }
    }

    async updateRecruiterSectors(recruiterId: string, sectors: string[]): Promise<void> {
        try {
            const recruiterRef = doc(this.db, this.collectionPath, recruiterId);
            await customUpdateDoc(recruiterRef, {
                sectors: sectors
            }, RecruitersLocalStorage);
            console.log("Sectors updated successfully!");
        } catch (error) {
            console.error("Error updating sectors:", error);
            throw new Error("An error occurred while updating sectors.");
        }
    }

    async updateRecruiterFullName(recruiterId: string, firstName: string, lastName: string): Promise<void> {
        try {
            const recruiterRef = doc(this.db, this.collectionPath, recruiterId);
            await customUpdateDoc(recruiterRef, {
                firstName: firstName,
                lastName: lastName
            }, RecruitersLocalStorage);
            console.log("Full name updated to recruiter successfully!");
        } catch (error) {
            console.error("Error updating full name to recruiter:", error);
            throw new Error("An error occurred while updating the full name.");
        }
    }

    async sendPasswordReset(email: string): Promise<void> {
        try {
            const tokenResult = await this.auth?.currentUser?.getIdTokenResult();
            const userRole = tokenResult?.claims.role as UserRole;

            // Check if a recruiter with the provided email already exists
            if (userRole === UserRole.admin) {
                const isExists = await this.recruiterExists(email);
                if (!isExists) {
                    console.warn(`A recruiter with email ${email} does not exists.`);
                    return;
                }
            }
            // else if(userRole !== UserRole.recruiter) {
            //     console.error(`You have no permission to perform this action`);
            //     return;
            // }

            await sendPasswordResetEmail(this.auth, email);
            console.log(`Password reset email sent to ${email}.`);
        } catch (error: any) {
            if (error.code === 'auth/invalid-email') {
                console.error("Invalid email address format.");
                throw new Error("Invalid email address format.");
            } else if (error.code === 'auth/user-not-found') {
                console.error(`No user found with email ${email}.`);
                throw new Error(`No user found with email ${email}.`);
            } else {
                console.error("Error sending password reset email:", error.message);
                throw new Error("Error sending password reset email.");
            }
        }
    }

    private async recruiterExists(email: string): Promise<boolean> {
        try {
            const recruitersCollection = collection(this.db, this.collectionPath);
            const emailQuery = query(recruitersCollection, where("email", "==", email));
            const querySnapshot = await getDocs(emailQuery);

            return !querySnapshot.empty;
        } catch (error) {
            console.error("Error checking if recruiter exists:", error);
            throw new Error("An error occurred while checking if recruiter exists.");
        }
    }

    // Fetch a recruiter by ID
    async getRecruiterByUID(recruiterId: string): Promise<Recruiter | null> {
        try {
            const recruiterRef = doc(this.db, this.collectionPath, recruiterId).withConverter(recruiterConverter);
            const recruiterDoc = await getDoc(recruiterRef);

            if (!recruiterDoc.exists()) {
                console.warn(`Recruiter with ID ${recruiterId} does not exist.`);
                return null;
            }

            return recruiterDoc.data() as Recruiter;
        } catch (error) {
            console.error("Error fetching recruiter:", error);
            throw new Error("An error occurred while fetching the recruiter.");
        }
    }


    // Helper function to delete a recruiter from both Firestore and Firebase Authentication
    async deleteRecruiter(recruiterUid: string): Promise<void> {
        try {

            const existingRecruiter = await this.getRecruiterByUID(recruiterUid);
            if (!existingRecruiter) return;


            // console.log("Calling deleteRecruiter function in Firebase Functions with UID:", recruiterUid);

            const obj: CustomHttpsCallInter = {
                functionName: "deleteRecruiter",
                httpsData: {recruiterUid: recruiterUid},
                localStorageKey: RecruitersLocalStorage,
                type: CRUDType.Delete,
                storageId: recruiterUid
            }
            const result = await customHttpsCall(obj);

            console.log("Recruiter deleted successfully:", result?.data);
        } catch (error: any) {
            console.error("Error calling deleteRecruiter:", error);

            if (error.code === "permission-denied") {
                alert("Only admins can delete recruiters.");
            } else if (error.message) {
                alert(`An error occurred: ${error.message}`);
            } else {
                alert("An unexpected error occurred while deleting the recruiter.");
            }
            throw new Error("An error occurred while deleting the recruiter.");
        }
    }

    async setRecruiterActivity(isActive: boolean) {
        try {
            const recruiterId = this.auth?.currentUser?.uid!;
            const setRecruiterActivity = httpsCallable(functions, "setRecruiterActivity");
            const result = await setRecruiterActivity({recruiterId, isActive});

            const data = result?.data as setRecruiterActivityResponse;
            console.log(data.message);
        } catch (error) {
            console.error("Error updating recruiter activity:", error);
        }
    }

    listenToRecruiterActivity(callback) {
        try {
            const recruitersCollectionRef = collection(db, "recruiters");
            return onSnapshot(query(recruitersCollectionRef), (snapshot) => {
                const allRecruiters = snapshot.docs.map(doc => {
                    const data = doc.data();

                    const convertedData = {
                        id: doc.id,
                        isActive: data.isActive || false,
                        lastActive: data.lastActive ? convertToDate(data.lastActive) : null
                    };

                    return {
                        ...data,
                        ...convertedData
                    };
                });
                callback(allRecruiters);
            });
        } catch (error) {
            console.error("Error listening to recruiter activity:", error);
        }
    }
}

// Initialize recruiter service for the "recruiters" collection
export const recruiterService = new RecruiterService(db, auth, "recruiters");
export {Recruiter};
