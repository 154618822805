import React from "react";
import {
    containerStyles,
    logoBoxStyles,
    logoImageStyles,
    stackStyles, subtitleTypographyStyles
} from "./NotFoundPageStyles";
import {Box, Container, Stack, Typography} from "@mui/material";
import Logo from "../../../Assets/GVANIM logos_2.png";

export default function PageNotFound() {
    return (
        <Container maxWidth="md" sx={containerStyles}>
            <Box display="flex" justifyContent="center">
                <Stack sx={stackStyles}>
                    <Box sx={logoBoxStyles}>
                        <img src={Logo} alt="Logo" style={logoImageStyles}/>
                    </Box>
                        <Typography variant="h5" sx={subtitleTypographyStyles}>
                            אנו מתנצלים, העמוד אינו נמצא...
                        </Typography>
                </Stack>
            </Box>
        </Container>
    );
}