import React from 'react';
import { Box } from '@mui/material';
import Bubble from './Bubble';
import './BubbleContainer.css';

const getRandomSize = () => Math.random() * (200 - 50) + 50;
const getRandomPosition = (size: number) => {
    const x = Math.random() * (window.innerWidth - size);
    const y = Math.random() * (window.innerHeight - size);
    return { x, y };
};

const getRandomDuration = () => Math.random() * (15 - 10) + 10;
const getRandomDelay = () => Math.random() * 2;

interface BubbleContainerProps {
    numberOfBubbles: number;
}

const BubbleContainer: React.FC<BubbleContainerProps> = ({ numberOfBubbles }) => {
    const bubbles = Array.from({ length: numberOfBubbles }).map((_, index) => {
        const size = getRandomSize();
        const position = getRandomPosition(size);
        return (
            <Box
                key={index}
                className="bubble-float"
                sx={{
                    '--duration': `${getRandomDuration()}s`,
                    '--delay': `${getRandomDelay()}s`
                }}
            >
                <Bubble size={size} position={position} />
            </Box>
        );
    });

    return (
        <Box className="bubble-wrapper">
            {bubbles}
        </Box>
    );
};

export default BubbleContainer;
