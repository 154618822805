import React from "react";
import { OptionType } from "../../../../../Components/CustomMultiSelect/Option.type";
import MultiSelect from "../../../../../Components/CustomMultiSelect/CustomMultiSelect";
import { Job } from "../../../../../../Firebase/FirebaseFunctions/JobsService";
import {useDrushimJobsData} from "../../../../../Contexts/JobsDrushimContext";

export default function RolesMultiSelect(props: { optionSelected: any, setSelected: any }) {
    const { optionSelected, setSelected } = props;
    const [roles, setRoles] = React.useState<OptionType[]>([]);
    const { jobs } = useDrushimJobsData();

    const handleChange = (selected: OptionType[]) => setSelected(selected);

    const fetchRoles = async () => {
        setRoles(jobs?.map((job: Job, index) => ({ value: index, label: job?.role })));
    }

    React.useEffect(() => {
        fetchRoles();
    }, []);

    return (
        <MultiSelect
            options={roles}
            onChange={handleChange}
            value={optionSelected}
            isSelectAll={true}
            menuPlacement={"bottom"}
        />
    );
}