import React, {useMemo} from 'react';
import {MenuItem, Select} from '@mui/material';
import {useJobsData} from "../../Contexts/DataContext/JobsDataContext";

// type can be "closed" or "open" or undefined, which is both.
const SelectJob = ({value, onChange, filterJobs = [] as number[], type = "", all = false, allJobsTitle = false}) => {
    const {jobs: rawJobs, fullJobs: fullRawJobs} = useJobsData();
    const jobs = useMemo(() => all ? fullRawJobs : rawJobs, [rawJobs]);

    const filteredJobs = useMemo(() => {
        let filtered = jobs;

        if (filterJobs.length > 0) {
            filtered = filtered.filter((job) => filterJobs.includes(job.jobNumber));
        }

        if (type === "open") {
            filtered = filtered.filter((job) => job.open);
        } else if (type === "closed") {
            filtered = filtered.filter((job) => !job.open);
        }

        return filtered;
    }, [jobs, filterJobs, type]);

    // Ensure a valid value or fallback to an empty string
    const selectedValue = jobs.find((job) => job.jobNumber === value)?.title || (allJobsTitle ? value : "");

    return (
        <Select
            name="job"
            value={selectedValue}
            label="משרה"
            onChange={(e) => {
                if (e.target.value === "כל המשרות") {
                    onChange("כל המשרות");
                    return;
                }

                const selectedJob = filteredJobs.find((job) => job.title === e.target.value);
                onChange(selectedJob ? selectedJob.jobNumber : "");
            }}
        >
            {allJobsTitle && (
                <MenuItem value="כל המשרות">
                    כל המשרות
                </MenuItem>
            )}
            {filteredJobs.map((role) => (
                <MenuItem key={role.jobNumber} value={role.title}>
                    {`${role.title} (מס' ${role.jobNumber})`}
                </MenuItem>
            ))}
        </Select>
    );
};

export default SelectJob;