import {styled} from "@mui/material";

export const Search = styled('div')(({theme}) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.custom.search.backgroundColor,
    '&:hover': {
        backgroundColor: theme.palette.custom.search.hoverBackgroundColor,
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    transition: "all 0.3s"
}));
