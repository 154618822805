import React from 'react';
import {Box, Grid} from "@mui/material";
import ReportsBySectors from "../Reports/Components/ReportsBySectors";
import ReportsByCity from "../Reports/Components/ReportsByCity";
import {BarChart} from "@mui/icons-material";
import './Dashboard.css';
import TitleLabel from "../../Components/TitleLabel";
import DividerCard from "./Components/DividerCard";
import FadeIn from "../../Components/Animations/FadeIn";
import {JobsDataProvider, useJobsData} from "../../Contexts/DataContext/JobsDataContext";
import {SectorsDataProvider} from "../../Contexts/DataContext/SectorsDataContext";
import {useAuth} from "../../Contexts/AuthContext/AuthContext";
import {collection, getCountFromServer, query, where} from "firebase/firestore";
import {db} from "../../../Firebase/FirebaseConfig/firebase";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import {UserRole} from '../../../Firebase/enums/UserRole';
import {useSnackbar} from "../../../Utilities/Context/Snackbar";

function DashboardPageContent() {
    const {role, userDetails} = useAuth();
    const {showSnackbar} = useSnackbar();
    const [loading, setLoading] = React.useState(true);
    const {jobs, loadingJobs} = useJobsData();
    const [totalCandidatesCount, setTotalCandidatesCount] = React.useState<number>(0);

    const fetchTotalCandidatesCount = async () => {
        if (userDetails) {
            const baseQuery = query(collection(db, 'candidates'));

            const finalQuery = (role === UserRole.recruiter && userDetails?.sectors)
                ? query(baseQuery, where('sectors', 'array-contains-any', userDetails.sectors))
                : baseQuery;

            const countSnapshot = await getCountFromServer(finalQuery);
            return countSnapshot.data().count;
        }

        showSnackbar({message: "אירעה שגיאה במשיכת הנתונים."});
        return 0;
    };

    React.useEffect(() => {
        setLoading(true);
        fetchTotalCandidatesCount()
            .then(val => setTotalCandidatesCount(val))
            .catch((error) => console.error("Error occurred while fetching data:", error))
            .finally(() => setLoading(false));
    }, [userDetails, role]);

    const data = [
        {title: "משרות פתוחות", value: jobs.filter(job => job.open).length},
        {title: "כל המועמדים", value: totalCandidatesCount},
        {title: "משרות סגורות", value: jobs.filter(job => !job.open).length}
    ];

    if (loading || loadingJobs) {
        return <LoadingSpinner/>
    }

    return (
        <Box width="100%">
            <TitleLabel title="סקירה כללית" icon={BarChart}/>

            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={10}>
                    <FadeIn>
                        <DividerCard data={data}/>
                    </FadeIn>
                </Grid>

                <Grid item xs={12} md={6}>
                    <ReportsBySectors/>
                </Grid>

                <Grid item xs={12} md={6}>
                    <ReportsByCity/>
                </Grid>
            </Grid>
        </Box>)
}

export default function DashboardPage() {
    const {loading} = useAuth();

    if (loading) {
        return <LoadingSpinner/>;
    }

    return <>
        <JobsDataProvider>
            <SectorsDataProvider>
                <DashboardPageContent/>
            </SectorsDataProvider>
        </JobsDataProvider>
    </>
}