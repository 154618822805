export const containerStyles = (theme) => ({
    WebkitFilter: theme.grayscale,
    filter: theme.grayscale
});

export const logoContainerStyles = (theme) => ({
    filter: theme.bottom_image,
    width: "100%",
    display: "flex",
    justifyContent: "center"
});

export const logoStyles = () => ({
    pointerEvents: 'none' as React.CSSProperties['pointerEvents'],
    WebkitUserSelect: 'none' as React.CSSProperties['WebkitUserSelect'],
    MozUserSelect: 'none' as React.CSSProperties['MozUserSelect'],
    msUserSelect: 'none' as React.CSSProperties['msUserSelect'],
    userSelect: 'none' as React.CSSProperties['userSelect'],
    WebkitTouchCallout: 'none' as any,
    WebkitUserDrag: 'none' as any,
});

export const contactInfoStyles = () => ({
    height: "fit-content"
});