import {useDialog} from "../Contexts/Dialog";
import {useSnackbar} from "../../Utilities/Context/Snackbar";
import {useSectorsData} from "../Contexts/DataContext/SectorsDataContext";
import {sectorService} from "../../Firebase/FirebaseFunctions/SectorService";

export function useSectorCreate(closeHandler?: () => void) {
    const {showDialog} = useDialog();
    const {showSnackbar} = useSnackbar();
    const {refreshSectors} = useSectorsData();

    return (sectorName, sectorStatus) => {
        return new Promise((resolve) => {
            showDialog({
                title: "יצירת אשכול",
                description: `האם להוסיף את האשכול: "${sectorName}"?`,
                onAccept: async () => {
                    try {
                        const sector = {
                            name: sectorName,
                            open: sectorStatus
                        }

                        await sectorService.saveSector(sector);
                        await refreshSectors();

                        showSnackbar({
                            message: `האשכול נוסף בהצלחה.`,
                            severity: "success"
                        });

                        if (closeHandler) {
                            closeHandler();
                        }
                        resolve(true);
                    } catch (error) {
                        const errorMessage = (error as any).toString();
                        if (errorMessage.includes("already exists")) {
                            showSnackbar({message: "הוספת האשכול נכשלה בגלל שכבר קיים אשכול כזה."});
                        } else {
                            showSnackbar({message: ` הוספת האשכול נכשלה. שגיאה: ${error}`});
                        }
                        resolve(false);
                    }
                },
                onCancel: () => resolve(false)
            })
        });
    }
}

export function useSectorChangeOpenClosed() {
    const {showDialog} = useDialog();
    const {showSnackbar} = useSnackbar();
    const {refreshSectors} = useSectorsData();

    return (sector) => {
        return new Promise((resolve) => {
            showDialog({
                title: "שינוי סטטוס",
                description: `האם לשנות ל'${sector.open ? 'סגור' : 'פתוח'}'?`,
                onAccept: async () => {
                    try {
                        await sectorService.updateSectorStatus(sector.id, !sector.open);
                        await refreshSectors();

                        showSnackbar({message: `הסטטוס השתנה!`, severity: "success"});
                        resolve(true);
                    } catch (error) {
                        showSnackbar({message: `שינוי סטטוס אשכול נכשל. שגיאה: ${error}`})
                        resolve(false);
                    }
                },
                onCancel: async () => {
                    resolve(false);
                }
            });
        });
    };
}

export function useSectorUpdate(closeHandler) {
    const {showSnackbar} = useSnackbar();
    const {showDialog} = useDialog();
    const {refreshSectors} = useSectorsData();

    return (sector, name: string | null, status: boolean | null) => {
        return new Promise((resolve) => {
            showDialog({
                title: "עדכון אשכול",
                description: `האם לערוך את האשכול "${sector.name}"?`,
                onAccept: async () => {
                    try {
                        if (name !== null) {
                            await sectorService.updateSectorName(sector.id, name);
                        }

                        if (status !== null) {
                            await sectorService.updateSectorStatus(sector.id, status);
                        }

                        await refreshSectors();

                        showSnackbar({
                            message: `האשכול עודכן בהצלחה.`,
                            severity: "success"
                        });

                        if (closeHandler) {
                            closeHandler();
                        }
                        resolve(true);
                    } catch (error) {
                        const errorMessage = (error as any).toString();
                        if (errorMessage.includes("already exists")) {
                            showSnackbar({message: "עדכון האשכול נכשל בגלל שכבר קיים אשכול כזה."});
                        } else {
                            showSnackbar({message: ` עדכון האשכול נכשל. שגיאה: ${error}`});
                        }
                        resolve(false);
                    }
                },
                onCancel: async () => resolve(false)
            })
        });
    }
}