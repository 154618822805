import {useDialog} from "../Contexts/Dialog";
import {useSnackbar} from "../../Utilities/Context/Snackbar";
import {recruiterService} from "../../Firebase/FirebaseFunctions/RecruiterService";
import {useRecruitersData} from "../Contexts/DataContext/RecruitersDataContext";

export function useRecruiterResetPassword() {
    const {showDialog} = useDialog();
    const {showSnackbar} = useSnackbar();

    return (recruiter) => {
        showDialog({
            title: "איפוס סיסמה",
            description: `האם לאפס סיסמה למשתמש בשם: "${recruiter.firstName} ${recruiter.lastName}"?`,
            onAccept: async () => {
                try {
                    await recruiterService.sendPasswordReset(recruiter.email);
                    showSnackbar({
                        message: `נשלח קישור לאיפוס סיסמה במייל`,
                        severity: "success"
                    });
                } catch (error) {
                    showSnackbar({message: `איפוס הסיסמה נכשל. שגיאה: ${error}`})
                }
            }
        });
    };
}

export function useRecruiterDelete(closeHandler?: () => void) {
    const {showDialog} = useDialog();
    const {showSnackbar} = useSnackbar();
    const {refreshRecruiters} = useRecruitersData();

    return (recruiter) => {
        showDialog({
            title: "מחיקת מגייס",
            description: `האם למחוק את המגייס: "${recruiter.firstName} ${recruiter.lastName}"?`,
            onAccept: async () => {
                try {
                    await recruiterService.deleteRecruiter(recruiter.id);
                    await refreshRecruiters();
                    showSnackbar({
                        message: `המגייס נמחק בהצלחה.`,
                        severity: "success"
                    });

                    if (closeHandler) {
                        closeHandler();
                    }
                } catch (error) {
                    showSnackbar({message: `מחיקת המגייס נכשלה. שגיאה: ${error}`})
                }
            }
        });
    };
}

export function useRecruiterCreate(closeHandler?: () => void) {
    const {showDialog} = useDialog();
    const {showSnackbar} = useSnackbar();
    const {refreshRecruiters} = useRecruitersData();

    return (recruiter) => {
        return new Promise((resolve) => {
            showDialog({
                title: "יצירת מגייס",
                description: `האם להוסיף את המגייס: "${recruiter.firstName} ${recruiter.lastName}"?`,
                onAccept: async () => {
                    try {
                        await recruiterService.saveRecruiter(recruiter);
                        await refreshRecruiters();

                        showSnackbar({
                            message: `המגייס נוסף בהצלחה.`,
                            severity: "success"
                        });

                        if (closeHandler) {
                            closeHandler();
                        }
                        resolve(true);
                    } catch (error) {
                        const errorMessage = (error as any).toString();
                        if (errorMessage.includes("already exists")) {
                            showSnackbar({message: "הוספת מגייס נכשלה בגלל שכבר קיים מגייס עם מייל כזה."});
                        } else {
                            showSnackbar({message: ` הוספת מגייס נכשל. שגיאה: ${error}`})
                        }
                        resolve(false);
                    }
                },
                onCancel: async () => resolve(false)
            })
        })
    }
}

export function useRecruiterUpdate(closeHandler?: () => void) {
    const {showDialog} = useDialog();
    const {showSnackbar} = useSnackbar();
    const {refreshRecruiters} = useRecruitersData();

    return (recruiterId, firstName, lastName, email, sectors = []) => {
        return new Promise((resolve) => {
            showDialog({
                title: "עדכון שינויים",
                description: "האם לעדכן את השינויים?",
                onAccept: async () => {
                    try {
                        if (firstName && lastName) {
                            await recruiterService.updateRecruiterFullName(recruiterId, firstName, lastName);
                        }

                        if (email) {
                            await recruiterService.updateRecruiterEmail(recruiterId, email);
                        }

                        if (sectors.length > 0) {
                            await recruiterService.updateRecruiterSectors(recruiterId, sectors);
                        }

                        await refreshRecruiters();

                        showSnackbar({
                            message: `השינויים עודכנו בהצלחה.`,
                            severity: "success"
                        });

                        if (closeHandler) {
                            closeHandler();
                        }
                        resolve(true);
                    } catch (error) {
                        showSnackbar({message: `עדכון השינויים נכשל. שגיאה: ${error}`});
                        resolve(false);
                    }
                },
                onCancel: () => resolve(false)
            });
        });
    };
}

