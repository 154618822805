import React from 'react';
import {Box, Dialog} from '@mui/material';
import DialogNavbar from './DialogNavbar';
import {Transition} from "./SlideUp";

export default function FullScreenDialog({title = "", show, close, centerContent = false, children}) {
    const childrenArray = React.Children.toArray(children);
    const header = childrenArray.find(child =>
        React.isValidElement(child) && child.type === FullScreenDialogHeader
    );

    const otherChildren = childrenArray.filter(child =>
        React.isValidElement(child) && child.type !== FullScreenDialogHeader
    );

    return (
        <Dialog
            fullScreen
            open={show}
            onClose={close}
            TransitionComponent={Transition}
            PaperProps={{style: {backgroundColor: 'transparent'}}}
        >
            <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backdropFilter: 'blur(25px)',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
            }}/>
            <Box sx={{
                position: 'relative',
                zIndex: 1,
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                overflowY: 'auto',
                width: "100%"
            }}>
                <DialogNavbar title={title} onClose={close}>
                    {header}
                </DialogNavbar>

                <Box sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: centerContent ? 'center' : 'flex-start',
                    alignItems: centerContent ? 'center' : 'stretch',
                    p: 2,
                }}>
                    {otherChildren}
                </Box>
            </Box>
        </Dialog>
    )
}

export function FullScreenDialogHeader({children}) {
    return (<>{children}</>)
}