import {Box, Button, FormControl, Grid, InputLabel, TextField} from "@mui/material";
import React from "react";
import SelectOpenClosed from "../../../../Components/Selects/SelectOpenClosed";
import {useFormik} from "formik";
import * as Yup from 'yup';
import CustomCard from "../../../../Components/Cards/Card";
import {useRoleCreate, useRoleDelete, useRoleUpdate} from "../../../../Functions/Role";
import {LoadingButton} from "@mui/lab";

export default function CreateRoleForm({close, role}) {
    const addRole = useRoleCreate(close);
    const updateRole = useRoleUpdate(close);
    const removeRole = useRoleDelete(close);

    const validationSchema = Yup.object().shape({
        roleName: Yup.string().required('שדה זה הוא חובה'),
    });

    const formik = useFormik({
        initialValues: {
            roleName: role?.name as string || '',
            roleType: role === null ? 'פתוח' : (role?.open ? "פתוח" : "סגור"),
        },
        validationSchema: validationSchema,
        onSubmit: async (values, {setSubmitting}) => {
            setSubmitting(true);
            if (role) {
                const name = role.name === values.roleName ? null : values.roleName;
                const status = role.status === (values.roleType === "פתוח") ? null : (values.roleType === "פתוח");
                await updateRole(role, name, status);
            } else {
                await addRole(values.roleName, values.roleType === "פתוח");
            }
            setSubmitting(false);
        },
    });

    return (
        <Box
            sx={{
                position: "relative",
                display: "flex",
                justifySelf: "center",
                width: {md: "50%", sm: "70%", xs: "100%"},
            }}
        >
            <CustomCard>
                <Box component="form" noValidate onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <FormControl
                                fullWidth
                                size="small"
                                error={formik.touched.roleName && Boolean(formik.errors.roleName)}
                            >
                                <TextField
                                    label="שם התפקיד"
                                    name="roleName"
                                    fullWidth
                                    variant="standard"
                                    value={formik.values.roleName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    helperText={
                                        formik.touched.roleName && (
                                            <span style={{color: 'red'}}>{formik.errors.roleName}</span>
                                        )
                                    }
                                    error={formik.touched.roleName && Boolean(formik.errors.roleName)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth variant="standard" size="small">
                                <InputLabel>תפקיד</InputLabel>
                                <SelectOpenClosed
                                    value={formik.values.roleType}
                                    onChange={formik.handleChange}
                                    name="roleType"
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={role ? 9 : 12}>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                loading={formik.isSubmitting}
                                disabled={(role && formik.values.roleName === role.name && (formik.values.roleType === "פתוח") === role.open) || formik.isSubmitting}
                            >
                                {role ? "ערוך" : "הוסף"}
                            </LoadingButton>
                        </Grid>

                        {role &&
                            <Grid item xs={3}>
                                <Button variant="contained" color="error" fullWidth onClick={() => removeRole(role)}>
                                    מחק
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </CustomCard>
        </Box>
    );
}
