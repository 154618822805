import SimpleDialog, {SimpleDialogFooter} from "../../../../../Components/Dialog/SimpleDialog";
import {Button, FormControl, InputLabel, Stack} from "@mui/material";
import React from "react";
import SelectJob from "../../../../../Components/Selects/SelectJob";
import {useJobsData} from "../../../../../Contexts/DataContext/JobsDataContext";
import {LoadingButton} from "@mui/lab";

export default function MoveJobDialog({application, open, close, onAccept}) {
    const [value, setValue] = React.useState<any>();
    const [loading, setLoading] = React.useState(false);
    const {fullJobs} = useJobsData();

    React.useEffect(() => {
        setValue(application.jobNumber);
    }, [open, application.jobNumber]);

    const onClickChange = () => {
        setLoading(true);
        const id = fullJobs.find(job => job.jobNumber === value)!.jobNumber;
        if (onAccept(id, setLoading)) {
            close();
        }
    }

    return <>
        <SimpleDialog title={"העברת משרה"} show={open} close={close}>
            <Stack flexDirection="row" justifyContent="center">
                <FormControl fullWidth variant="standard" size="small" sx={{marginTop: "3px"}}>
                    <InputLabel>משרה חדשה</InputLabel>
                    <SelectJob value={value} onChange={(e) => setValue(e)} type={"open"} all={true}/>
                </FormControl>
            </Stack>

            <SimpleDialogFooter>
                <Stack width="100%" flexDirection="row" justifyContent="space-between">
                    <LoadingButton
                        color="error"
                        variant="contained"
                        disabled={application.jobNumber === value}
                        onClick={onClickChange}
                        loading={loading}
                    >
                        העבר/י
                    </LoadingButton>
                    <Button onClick={close}>
                        בטל/י
                    </Button>
                </Stack>
            </SimpleDialogFooter>
        </SimpleDialog>
    </>
}
