import AlertPage from "./AlertPage";

const ThanksPage = () => {
    return (
        <AlertPage
            title={
                <>
                    מועמדותך נקלטה בהצלחה!
                </>
            }
        />
    );
};

export default ThanksPage;
