import React, {useState} from "react";
import {AuthCard} from "./Components/Card";
import {
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useSnackbar} from "../../../Utilities/Context/Snackbar";
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {useNavigate} from "react-router-dom";
import {signInWithEmailAndPassword} from "firebase/auth";
import {auth} from "../../../Firebase/FirebaseConfig/firebase";
import {useAuth} from "../../Contexts/AuthContext/AuthContext";
import {UserRole} from "../../../Firebase/enums/UserRole";
import {useDialog} from "../../Contexts/Dialog";

export function LoginPage() {
    const {role} = useAuth();
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const {showSnackbar} = useSnackbar();
    const {showDialog} = useDialog();

    const login = () => {
        const path = localStorage.getItem("lastPath");
        navigate("/management");

        if (path) {
            showDialog({
                title: "חזרה לעמוד האחרון",
                description: "האם ברצונך לחזור לעמוד האחרון שהיית בו?",
                onAccept: () => navigate(path),
                acceptColorAsGreen: true
            });
        }
    };

    React.useEffect(() => {
        if (role === UserRole.recruiter || role === UserRole.admin) {
            login();
        }
    }, [role]);

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('יש להזין אימייל נכון')
            .required('יש להזין כתובת אימייל'),
        password: Yup.string()
            .required('יש להזין סיסמה'),
    });

    const handleSubmit = async (values, {setSubmitting, setTouched}) => {
        setSubmitting(true);
        setTouched({
            email: true,
            password: true
        });

        try {
            await signInWithEmailAndPassword(auth, values.email, values.password);
            login();

        } catch (error) {
            showSnackbar({message: 'ההתחברות נכשלה. אנא נסה שוב.'});
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <AuthCard title={"כניסה למערכת"}>
            <Formik
                initialValues={{email: '', password: ''}}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({
                      values,
                      handleChange,
                      handleBlur,
                      isSubmitting,
                      touched,
                      errors
                  }) => (
                    <Form style={{width: '100%'}}>
                        <FormControl fullWidth margin="normal">
                            <TextField
                                fullWidth
                                label="כתובת אימייל"
                                variant="outlined"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={!!touched.email && !!errors.email} // Show error if touched and invalid
                                InputLabelProps={{shrink: true}}
                                autoComplete={"on"}
                            />
                            <FormHelperText error>
                                {touched.email && errors.email ? errors.email : ''}
                            </FormHelperText>
                        </FormControl>

                        <FormControl fullWidth margin="normal" style={{marginBottom: 0}}>
                            <TextField
                                label="סיסמה"
                                type={showPassword ? 'text' : 'password'}
                                variant="outlined"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={!!touched.password && !!errors.password}
                                InputLabelProps={{shrink: true}}
                                autoComplete={"on"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <FormHelperText error>
                                {touched.password && errors.password ? errors.password : ''}
                            </FormHelperText>
                        </FormControl>

                        <Typography variant="caption" sx={{textAlign: 'center', cursor: "pointer"}}
                                    onClick={() => navigate('/auth/resetPassword')}>
                            שכחת את הסיסמה?
                        </Typography>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            sx={{
                                background: '#053B7A',
                                mt: 2,
                                py: 1.5,
                                transition: 'all 0.3s ease-in-out',
                                '&:hover': {
                                    background: '#2863a8',
                                },
                                fontFamily: 'Rubik',
                            }}
                        >
                            {isSubmitting ? <CircularProgress size={20} color="inherit"/> : 'התחבר'}
                        </Button>
                    </Form>
                )}
            </Formik>
        </AuthCard>
    );
}
