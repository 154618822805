import {Card, CardContent, Typography} from "@mui/material";
import logo from "../../../../Assets/GVANIM_LOGO_NEW2.png";
import React from "react";
import {useNavigate} from "react-router-dom";

interface AuthCardProps {
    title: string;
    children: React.ReactNode;
    sx?: any;
}

export function AuthCard({title, children, sx}: AuthCardProps) {
    const navigate = useNavigate();

    return (
        <Card
            sx={{
                width: {xs: 350, sm: 400},
                position: 'relative',
                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
                transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)',
                borderColor: 'rgb(230, 235, 241)',
                color: 'rgb(38, 38, 38)',
                ...sx
            }}
        >
            <CardContent
                sx={{
                    padding: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <img
                    src={logo}
                    alt="Logo"
                    style={{
                        width: 190,
                        height: 'auto',
                        marginBottom: 50,
                        pointerEvents: 'none' as React.CSSProperties['pointerEvents'],
                        WebkitUserSelect: 'none' as React.CSSProperties['WebkitUserSelect'],
                        MozUserSelect: 'none' as React.CSSProperties['MozUserSelect'],
                        msUserSelect: 'none' as React.CSSProperties['msUserSelect'],
                        userSelect: 'none' as React.CSSProperties['userSelect'],
                        WebkitTouchCallout: 'none' as any,
                    }}
                    onClick={() => navigate("/auth/login")}
                />
                <Typography variant="body2" sx={{ textAlign: 'center', fontFamily: "Rubik", fontWeight: 'bold'}}>
                    {title}
                </Typography>

                {children}
            </CardContent>
        </Card>
    );
}
