import {Box, Stack, Typography} from "@mui/material";
import {Apartment} from "@mui/icons-material";
import React from "react";

export default function CreateSectorHeader({sector}) {
    return (
        <Box display="flex" justifyContent="center" marginBottom="20px">
            <Stack direction="column">
                <Stack direction="row" justifyContent="center" spacing={1}>
                    <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        <Apartment sx={{color: "#fff"}}/>
                    </Box>
                    <Typography
                        sx={{
                            fontFamily: "'Noto Sans Hebrew', sans-serif",
                            color: "#fff",
                            textAlign: "center",
                        }}
                        variant="h4"
                    >
                        {sector ? "עריכת אשכול" : "אשכול חדש"}
                    </Typography>
                </Stack>

                <Typography
                    sx={{
                        opacity: 0.6,
                        width: "100%",
                        textAlign: "center",
                        color: "#fff",
                        fontSize: "16px",
                        fontFamily: "'Noto Sans Hebrew', sans-serif",
                        mt: 1,
                    }}
                    variant="subtitle1"
                >
                    {sector ? "לתשומת ליבך: פעולה זו תעדכן את האשכול הקיים." : "לתשומת ליבך: פעולה זו תיצור אשכול חדש."}
                </Typography>

                <Box
                    sx={{
                        background: "linear-gradient(90deg,hsla(0,0%,100%,0),#fff,hsla(0,0%,100%,0))",
                        padding: 0.05,
                        width: "100%",
                        mt: 2,
                    }}
                />
            </Stack>
        </Box>
    );
}
