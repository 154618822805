import React from "react";
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import CustomSlider from "../../../../Components/Slider";
import BorderedContainer from "../../../../Components/BorderedContainer";
import SelectRole from "../../../../Components/Selects/SelectRole";
import SelectSector from "../../../../Components/Selects/SelectSector";
import SelectArea from "../../../../Components/Selects/SelectArea";
import {useFormik} from "formik";
import * as Yup from "yup";
import CustomCard from "../../../../Components/Cards/Card";
import {useSectorsData} from "../../../../Contexts/DataContext/SectorsDataContext";
import {useJobAdd, useJobDelete, useJobUpdate} from "../../../../Functions/Job";
import {LoadingButton} from "@mui/lab";
import {useAuth} from "../../../../Contexts/AuthContext/AuthContext";
import {UserRole} from "../../../../../Firebase/enums/UserRole";

export default function CreateJobForm({job, close}) {
    const {role} = useAuth();
    const {sectors} = useSectorsData();
    const theme = useTheme();

    const updateJob = useJobUpdate(close);
    const deleteJob = useJobDelete(close);
    const addJob = useJobAdd(close);

    const validationSchema = Yup.object({
        title: Yup.string().required("שדה זה הוא חובה"),
        area: Yup.string().required("שדה זה הוא חובה"),
        role: Yup.string().required("שדה זה הוא חובה"),
        sector: Yup.string().required("שדה זה הוא חובה"),
        start: Yup.string().required("שדה זה הוא חובה"),
    });

    const formik = useFormik({
        initialValues: {
            hot: job?.highPriority ?? true,
            open: job?.open ?? true,
            title: job?.title ?? "",
            area: job?.region ?? "",
            role: job?.role ?? "",
            sector: job ? sectors[sectors.findIndex(item => item.id === job?.sector)].name : "",
            start: job?.startOn ?? "",
            description: job?.description ?? "",
            requirements: job?.requirements ?? "",
            workTime: job?.scope ?? [0, 50],
        },
        validationSchema,
        onSubmit: (values, {setSubmitting}) => {
            setSubmitting(true);
            const handleAsync = async () => {
                const temp = {
                    highPriority: values.hot,
                    open: values.open,
                    title: values.title,
                    region: values.area,
                    role: values.role,
                    sector: sectors.find(item => item.name === values.sector)?.id ?? job.sector,
                    startOn: values.start,
                    description: values.description,
                    requirements: values.requirements,
                    scope: values.workTime,
                };

                if (job) {
                    await updateJob(job, temp);
                } else {
                    await addJob(temp);
                }
                setSubmitting(false);
            }
            handleAsync();
        },
    });

    const previewJob = () => {
        formik.validateForm().then((errors) => {
            // Set all fields as touched
            formik.setTouched(
                Object.keys(formik.values).reduce((acc, key) => {
                    acc[key] = true;
                    return acc;
                }, {})
            );

            // Check if there are no errors
            if (Object.keys(errors).length === 0) {
                const json = JSON.stringify(formik.values);
                const encoded = encodeURIComponent(json);
                window.open(`/career/job/preview?data=${encoded}`, "_blank");
            }
        });
    };

    return (
        <Box
            sx={{
                position: "relative",
                display: "flex",
                justifySelf: "center",
                width: {md: "85%", sm: "90%", xs: "100%"},
            }}
        >
            <CustomCard>
                <Box component="form" noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{display: "flex", justifyContent: "space-around", marginY: "1rem"}}>
                                <Box>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formik.values.hot}
                                                onChange={(e) => formik.setFieldValue("hot", e.target.checked)}
                                            />
                                        }
                                        label="הגדר כ-'משרה חמה'"
                                    />
                                    <FormHelperText>בחירה באפשרות זו תקפיץ את המשרה לראש האתר.</FormHelperText>
                                </Box>
                                <Box sx={{marginX: "1rem"}}>
                                    <Divider orientation="vertical"/>
                                </Box>
                                <Box>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formik.values.open}
                                                onChange={(e) => formik.setFieldValue("open", e.target.checked)}
                                            />
                                        }
                                        label="הגדר כ-'משרה פתוחה'"
                                    />
                                    <FormHelperText>
                                        אפשרות זו תגדיר האם המשרה תופיע / לא תופיע באתר.
                                    </FormHelperText>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                label="כותרת המשרה"
                                name="title"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                error={formik.touched.title && Boolean(formik.errors.title)}
                                helperText={formik.touched.title && formik.errors.title ? String(formik.errors.title) : null}
                                fullWidth
                                variant="standard"
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth variant="standard" size="small" required={true}
                                         error={formik.touched.area && Boolean(formik.errors.area)}>
                                <SelectArea
                                    value={formik.values.area}
                                    onChange={(event) => formik.setFieldValue("area", event)}
                                    formik={formik}
                                    required={true}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth variant="standard" size="small" required
                                         error={formik.touched.role && Boolean(formik.errors.role)}>
                                <InputLabel>תפקיד</InputLabel>
                                <SelectRole
                                    value={formik.values.role}
                                    onChange={(value) => formik.setFieldValue("role", value)}
                                    labelId="select-role-job"
                                />
                                <FormHelperText>{formik.touched.role && formik.errors.role ? String(formik.errors.role) : null}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth variant="standard" size="small" required
                                         error={formik.touched.sector && Boolean(formik.errors.sector)}>
                                <InputLabel>אשכול</InputLabel>
                                <SelectSector
                                    value={formik.values.sector}
                                    onChange={(value) => formik.setFieldValue("sector", value)}
                                    labelId="select-sector-job"
                                />
                                <FormHelperText>{formik.touched.sector && formik.errors.sector ? String(formik.errors.sector) : null}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                required
                                label="תחילת עבודה"
                                name="start"
                                value={formik.values.start}
                                onChange={formik.handleChange}
                                fullWidth
                                variant="standard"
                                error={formik.touched.start && Boolean(formik.errors.start)}
                                helperText={formik.touched.start && formik.errors.start ? String(formik.errors.start) : null}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                label="תיאור"
                                name="description"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                multiline
                                minRows={4}
                                maxRows={15}
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                label="דרישות"
                                name="requirements"
                                value={formik.values.requirements}
                                onChange={formik.handleChange}
                                multiline
                                minRows={4}
                                maxRows={15}
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <BorderedContainer>
                                <Typography sx={{color: theme.palette.custom.form.text}}>היקף משרה</Typography>
                                <Box paddingX="1rem" paddingTop="0.5rem">
                                    <CustomSlider value={formik.values.workTime}
                                                  onChange={(e, newValue) => formik.setFieldValue("workTime", newValue)}/>
                                </Box>
                            </BorderedContainer>
                        </Grid>

                        <Grid item xs={12} md={job ? 8 : 10}>
                            <LoadingButton type="submit" variant="contained"
                                           color="primary" loading={formik.isSubmitting} fullWidth disabled={
                                job ? (job.highPriority === formik.values.hot &&
                                    job.open === formik.values.open &&
                                    job.title === formik.values.title &&
                                    job.region === formik.values.area &&
                                    job.role === formik.values.role &&
                                    job.sector === (sectors.find(item => item.name === formik.values.sector)?.id ?? job.sector) &&
                                    job.startOn === formik.values.start &&
                                    job.description === formik.values.description &&
                                    job.requirements === formik.values.requirements &&
                                    [...job.scope].every((value, index) => value === formik.values.workTime[index])) : false
                            }>
                                {job ? "עדכן" : "פרסם"}
                            </LoadingButton>
                        </Grid>

                        <Grid item xs={12} md={!job ? 2 : (role === UserRole.admin ? 2 : 4)}>
                            <Button
                                type="button"
                                variant="contained"
                                color="warning"
                                onClick={previewJob}
                                fullWidth
                            >
                                תצוגה מקדימה
                            </Button>
                        </Grid>

                        {job && role === UserRole.admin && (
                            <Grid item xs={12} md={2}>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => deleteJob(job)}
                                    fullWidth
                                >
                                    הסר משרה
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </CustomCard>
        </Box>
    );
}
