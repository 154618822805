import React from "react";
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import BorderedContainer from "../../../../Components/BorderedContainer";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {LoadingButton} from "@mui/lab";
import SelectJob from "../../../../Components/Selects/SelectJob";
import {useLocation, useNavigate} from "react-router-dom";
import * as Yup from "yup";
import {useFormik} from "formik";
import CustomCard from "../../../../Components/Cards/Card";
import {useCandidateCreate, useCandidateDelete, useCandidateUpdate} from "../../../../Functions/Candidate";
import {useAuth} from "../../../../Contexts/AuthContext/AuthContext";
import {UserRole} from "../../../../../Firebase/enums/UserRole";
import {useJobsData} from "../../../../Contexts/DataContext/JobsDataContext";
import {useCandidatesData} from "../../../../Contexts/DataContext/CandidatesDataContext";

export default function CreateCandidateForm({candidate, close}) {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const {role} = useAuth();
    const {jobs} = useJobsData();
    const {candidates} = useCandidatesData();

    const updateCandidate = useCandidateUpdate(close);
    const removeCandidate = useCandidateDelete(() => {
        close();
        if (!location.pathname.includes("/management/candidates")) {
            navigate("/management/candidates");
        }
    });
    const createCandidate = useCandidateCreate(close);

    const getValidationSchema = (candidate) => Yup.object({
        firstName: Yup.string().required("שדה זה הוא חובה"),
        lastName: Yup.string().required("שדה זה הוא חובה"),
        phone: Yup.string().required("שדה זה הוא חובה"),
        email: Yup.string().email("אימייל לא תקין").required("שדה זה הוא חובה"),
        job: Yup.number().when([], {
            is: () => !candidate,  // If there's no candidate, field is required
            then: (schema) => schema.required("שדה זה הוא חובה"),
            otherwise: (schema) => schema
        }),
        reachUs: Yup.string().when([], {
            is: () => !candidate,  // If there's no candidate, field is required
            then: (schema) => schema.required("שדה זה הוא חובה"),
            otherwise: (schema) => schema
        }),
        comments: Yup.string(),
        rate: Yup.number(),
        cv: Yup.mixed()
            .nullable()
            .test("fileFormat", "יש לבחור קובץ PDF בלבד.", (value) => {
                if (!value) return true;
                return value instanceof File && value.type === "application/pdf";
            })
            .test("fileSize", "גודל הקובץ חייב להיות קטן מ-5MB.", (value) => {
                if (!value) return true;
                return value instanceof File && value.size < 5 * 1024 * 1024;
            })
    });

    const formik = useFormik({
        initialValues: {
            firstName: candidate?.firstname ?? "",
            lastName: candidate?.lastname ?? "",
            phone: candidate?.id ?? "",
            email: candidate?.email ?? "",
            comments: candidate?.note ?? "",
            cv: null,
            job: "",
            reachUs: "",
            aboutYourself: ""
        },
        validationSchema: getValidationSchema(candidate),
        onSubmit: async (values, {setSubmitting}) => {
            setSubmitting(true);

            if (candidate) {
                const obj = {
                    firstname: values.firstName,
                    lastname: values.lastName,
                    email: values.email,
                    note: values.comments,
                };

                const result = await updateCandidate(candidate, obj, values.cv);
                if (result) {
                    candidate.firstname = obj.firstname;
                    candidate.lastname = obj.lastname;
                    candidate.email = obj.email;
                    candidate.note = obj.note;
                }
            } else {
                const jobInfo = jobs.find(j => j.jobNumber === +values.job);
                await createCandidate({
                        firstname: values.firstName,
                        lastname: values.lastName,
                        id: values.phone,
                        note: values.comments,
                        email: values.email,
                    }, {
                        arrivalWay: values.reachUs,
                        jobNumber: +values.job,
                        candidateId: values.phone,
                        about: values.aboutYourself,
                        sector: jobInfo!.sector
                    },
                    values.cv);
            }

            setSubmitting(false);
        }
    });

    const onChangePhone = (e) => {
        if (e.target.value.length >= 9 && formik.values.firstName.trim() === "" && formik.values.lastName.trim() === "" && formik.values.email.trim() === "" && formik.values.comments.trim() === "") {
            const cand = candidates.find(cand => cand.id === e.target.value);
            if (cand) {
                formik.values.firstName = cand.firstname;
                formik.values.lastName = cand.lastname;
                formik.values.email = cand.email;
                formik.values.comments = cand.note;
            } else {
                formik.values.firstName = "";
                formik.values.lastName = "";
                formik.values.email = "";
                formik.values.comments = "";
            }
        }

        formik.handleChange(e);
    }

    return (
        <Box
            sx={{
                position: "relative",
                display: "flex",
                justifySelf: "center",
                width: {md: "85%", sm: "90%", xs: "100%"},
            }}
        >
            <CustomCard>
                <Box component="form" noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth size="small">
                                <TextField
                                    required
                                    label="שם פרטי"
                                    name="firstName"
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                    helperText={formik.touched.firstName && formik.errors.firstName ? String(formik.errors.firstName) : null}
                                    fullWidth
                                    variant="standard"
                                />
                                <FormHelperText
                                    sx={{color: formik.touched.firstName && Boolean(formik.errors.firstName) ? "#d32f2f" : undefined}}>יופיע
                                    בתור שם המועמד.</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                label="שם משפחה"
                                name="lastName"
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                helperText={formik.touched.lastName && formik.errors.lastName ? String(formik.errors.lastName) : null}
                                fullWidth
                                variant="standard"
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                label="טלפון"
                                name="phone"
                                value={formik.values.phone}
                                onChange={onChangePhone}
                                error={formik.touched.phone && Boolean(formik.errors.phone)}
                                helperText={formik.touched.phone && formik.errors.phone ? String(formik.errors.phone) : null}
                                disabled={Boolean(candidate)}
                                fullWidth
                                variant="standard"
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                label="אימייל"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email ? String(formik.errors.email) : null}
                                fullWidth
                                variant="standard"
                            />
                        </Grid>

                        {!candidate && <Grid item xs={12} md={6}>
                            <FormControl fullWidth variant="standard" size="small" sx={{marginTop: "3px"}} required
                                         error={formik.touched.job && Boolean(formik.errors.job)}>
                                <InputLabel>משרה</InputLabel>
                                <SelectJob value={formik.values.job}
                                           onChange={(value) => formik.setFieldValue("job", value)} type={"open"}/>
                                <FormHelperText>{formik.touched.job && formik.errors.job}</FormHelperText>
                            </FormControl>
                        </Grid>}

                        {!candidate && <Grid item xs={12} md={6}>
                            <TextField
                                required
                                label="איך הגיע אלינו"
                                name="reachUs"
                                value={formik.values.reachUs}
                                onChange={formik.handleChange}
                                error={formik.touched.reachUs && Boolean(formik.errors.reachUs)}
                                helperText={formik.touched.reachUs && formik.errors.reachUs}
                                fullWidth
                                variant="standard"
                            />
                        </Grid>}

                        <Grid item xs={12}>
                            <FormControl fullWidth size="small">
                                <TextField
                                    label="הערות"
                                    name="comments"
                                    value={formik.values.comments}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    maxRows={15}
                                    variant="outlined"
                                />
                                <FormHelperText>הערות כלליות על המועמד (בשביל המגייס/ מנהל)</FormHelperText>
                            </FormControl>
                        </Grid>

                        {!candidate && <Grid item xs={12}>
                            <FormControl fullWidth size="small">
                                <TextField
                                    label="ספר/י לנו קצת על המועמד/ת"
                                    name="aboutYourself"
                                    value={formik.values.aboutYourself}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    maxRows={15}
                                    variant="outlined"
                                />
                                <FormHelperText>קצת מידע על המועמד/ת (בשביל המגייס/ מנהל) - לאחר יצירת המועמד ערך זה לא
                                    יהיה זמין לעריכה.</FormHelperText>
                            </FormControl>
                        </Grid>}

                        <Grid item xs={12}>
                            <BorderedContainer sx={{minHeight: "8rem", maxHeight: "fit-content"}}>
                                <Typography sx={{
                                    color: theme.palette.custom.form.text,
                                    paddingBottom: "0.3em"
                                }}>קובץ קורות חיים</Typography>

                                <FormControl fullWidth size="small">
                                    <Box display="flex" justifyContent="center">
                                        <Button
                                            disableRipple
                                            variant="contained"
                                            color="info"
                                            component="label"
                                            startIcon={<CloudUploadIcon/>}
                                        >
                                            {formik.values.cv && !formik.errors.cv ? 'קובץ נבחר' : 'צירוף קורות חיים'}
                                            <input
                                                type="file"
                                                hidden
                                                accept=".pdf"
                                                onChange={(event) => {
                                                    const file = event.target.files?.[0];
                                                    if (file) {
                                                        formik.setFieldValue('cv', file);
                                                    }
                                                }}
                                            />
                                        </Button>
                                    </Box>

                                    <FormHelperText
                                        sx={{color: Boolean(formik.errors.cv) ? "#d32f2f" : undefined}}>
                                        {formik.values.cv && !formik.errors.cv
                                            ? `קובץ נבחר: ${(formik.values.cv as File).name}`
                                            : (Boolean(formik.errors.cv)
                                                ? formik.errors.cv
                                                : (candidate
                                                    ? "הקובץ בענן - במידה ובחרתם קובץ חדש הקובץ הקודם יימחק"
                                                    : "לא נבחר קובץ"))}
                                    </FormHelperText>
                                </FormControl>

                            </BorderedContainer>
                        </Grid>

                        <Grid item xs={12} md={candidate && role === UserRole.admin ? 10 : 12}>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                loading={formik.isSubmitting}
                                disabled={candidate &&
                                    formik.values.firstName === candidate.firstname &&
                                    formik.values.lastName === candidate.lastname &&
                                    formik.values.email === candidate.email &&
                                    formik.values.comments === candidate.note &&
                                    formik.values.cv === null}
                                fullWidth
                            >
                                {candidate ? "עדכן מועמד" : "צור מועמד חדש"}
                            </LoadingButton>
                        </Grid>

                        {candidate && role === UserRole.admin &&
                            <Grid item xs={12} md={2}>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => removeCandidate(candidate)}
                                    fullWidth
                                >
                                    הסר מועמד
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </CustomCard>
        </Box>
    );
}
