import {Box, Grid} from "@mui/material";
import AssessmentIcon from '@mui/icons-material/Assessment';
import ReportsByCity from "./Components/ReportsByCity";
import ReportsBySectors from "./Components/ReportsBySectors";
import ReportsByCandidates from "./Components/ReportsByCandidates";
import ReportsByJobs from "./Components/ReportsByJobs";
import React from "react";
import TitleLabel from "../../Components/TitleLabel";
import {JobsDataProvider, useJobsData} from "../../Contexts/DataContext/JobsDataContext";
import {useAuth} from "../../Contexts/AuthContext/AuthContext";
import {SectorsDataProvider, useSectorsData} from "../../Contexts/DataContext/SectorsDataContext";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import {RolesDataProvider} from "../../Contexts/DataContext/RolesDataContext";
import {CandidatesDataProvider, useCandidatesData} from "../../Contexts/DataContext/CandidatesDataContext";

function ReportsPageContent() {
    const {loadingJobs} = useJobsData();
    const {loadingSectors} = useSectorsData();
    const {loadingCandidates} = useCandidatesData();

    if (loadingJobs || loadingSectors || loadingCandidates) {
        return <LoadingSpinner/>;
    }

    return (
        <Box width="100%">
            <TitleLabel title="דוחות" icon={AssessmentIcon}/>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <ReportsBySectors/>
                </Grid>

                <Grid item xs={12} md={6}>
                    <ReportsByCity/>
                </Grid>

                <Grid item xs={12}>
                    <ReportsByCandidates/>
                </Grid>

                <Grid item xs={12}>
                    <ReportsByJobs/>
                </Grid>
            </Grid>
        </Box>
    )
}

export default function ReportsPage() {
    const {userDetails, role, loading} = useAuth();

    if (loading) {
        return <LoadingSpinner/>;
    }

    return (
        <JobsDataProvider>
            <SectorsDataProvider>
                <RolesDataProvider>
                    <CandidatesDataProvider>
                        <ReportsPageContent/>
                    </CandidatesDataProvider>
                </RolesDataProvider>
            </SectorsDataProvider>
        </JobsDataProvider>
    )
}
