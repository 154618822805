import {TableColumns} from "../../../../Components/Table/Table.types";
import BetterTable from "../../../../Components/Table/Table";
import React from "react";
import {TableSearch} from "../../../../Components/Table/TableSearch";
import {Box, Chip, Stack, Typography} from "@mui/material";
import CandidateSelfInfo from "./CandidateSelfInfo";
import CustomCard, {CustomCardHeader} from "../../../../Components/Cards/Card";
import {useOutletContext} from "react-router-dom";
import {Candidate} from "../../../../../Firebase/FirebaseFunctions/CandidateService";
import {ApplicationCandidatesStatuses, statusesToMoveToBottom} from "../../../../Utilities/Statuses";
import ChipColorPicker from "../../../../Utilities/ChipColorPicker";
import {RatingRTL} from "../../../../Styled/Rating";
import dayjs from "dayjs";

export default function CandidateView() {
    const {candidateApplications, candidateJobs} = useOutletContext<{
        candidate: Candidate;
        candidateApplications: any[];
        candidateJobs: any[];
    }>();

    const columnsData: TableColumns = {
        columns: [
            {name: "מס' משרה", field: "candidateJobNumber", width: "5%", sortable: true},
            {name: "איזור", field: "candidateJobArea", width: "10%", sortable: true},
            {name: "תפקיד", field: "candidateJobRole", width: "10%", sortable: true},
            {name: "סטטוס", field: "candidateJobStatus", width: "10%", sortable: true},
            {name: "התאמה", field: "candidateJobFit", width: "10%", sortable: true},
            {name: "ספר לנו עליך", field: "candidateSelfInfo", width: "10%", sortable: true},
            {name: "תאריך הגשת מועמדות", field: "candidateApplyDate", width: "10%", sortable: true},
            {name: "איך הגעת אלינו", field: "candidateReach", sortable: true}
        ]
    };

    const mapApplicationsToData = () => {
        return candidateApplications.map(app => {
            const job = candidateJobs.find(job => job.jobNumber === app.jobNumber);
            if (!job) {
                return null;
            }

            return {
                candidateJobNumber: job.jobNumber,
                candidateJobArea: job.region,
                candidateJobRole: job.role,
                candidateJobStatus: {
                    label: app.status,
                    component: <Chip label={app.status}
                        // @ts-ignore
                                     color={ChipColorPicker(ApplicationCandidatesStatuses.indexOf(app.status))}/>
                },
                candidateJobFit: {
                    label: app.matchingRate,
                    component: <RatingRTL value={app.matchingRate} readOnly/>
                },
                candidateApplyDate: {
                    label: app?.applyDate,
                    component: <>
                        <Typography variant="body2">
                            {dayjs(app.applyDate).isValid() ? dayjs(app.applyDate).format("DD/MM/YY") : "תאריך לא תקין"}
                        </Typography>
                    </>
                },
                candidateSelfInfo: {label: app.about, component: <CandidateSelfInfo about={app.about}/>},
                candidateReach: app.arrivalWay
            }
        }).filter(app => app != null)
            .sort((a, b) => {
                const aStatus = statusesToMoveToBottom.has(a?.candidateJobStatus.label);
                const bStatus = statusesToMoveToBottom.has(b?.candidateJobStatus.label);
                return aStatus === bStatus ? 0 : aStatus ? 1 : -1;
            });
    };

    const [data] = React.useState<any[]>(mapApplicationsToData());
    const [filteredData, setFilteredData] = React.useState<any[]>(data);

    return (
        <CustomCard noPadding={true}>
            <CustomCardHeader>
                <Stack flexDirection="row" justifyContent="space-between" gap={2}>
                    <Typography
                        variant="h5"
                        alignSelf="center"
                        sx={{
                            fontFamily: 'Rubik',
                            fontWeight: 600,
                            opacity: 0.8,
                        }}
                    >
                        משרות
                    </Typography>

                    <Box sx={{
                        width: {xs: "100%", sm: "50%"}
                    }}>
                        <TableSearch data={data} setFilteredData={setFilteredData} columns={columnsData}/>
                    </Box>
                </Stack>

            </CustomCardHeader>

            <BetterTable
                columnsData={columnsData}
                data={filteredData}
                // paginator={{label: "משרות", rowsPerPage: 5}}
                loading={false}
            />
        </CustomCard>
    );
}