import CustomCard from "../../../../Components/Cards/Card";
import React, {useState} from "react";
import {Box, Button, FormControl, InputLabel, Stack, Step, StepLabel, Stepper, Typography,} from "@mui/material";
import SelectJob from "../../../../Components/Selects/SelectJob";
import InviteForInterview from "./Components/InviteForInterview";
import InterviewSummary from "./Components/InterviewSummary";
import ShowConfetti from "../../../../Components/Animations/Confetti";
import {UseIsScreenXS} from "../../../../../Utilities/ScreenSize";
import InterviewStatus from "./Components/InterviewStatus";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {useFormik} from "formik";
import {useOutletContext} from "react-router-dom";
import {Candidate, candidateService} from "../../../../../Firebase/FirebaseFunctions/CandidateService";
import {Application} from "../../../../../Firebase/FirebaseFunctions/ApplicationService";
import {useSnackbar} from "../../../../../Utilities/Context/Snackbar";
import 'firebase/compat/firestore';
import RejectButton from "./Components/Buttons/RejectButton";
import WhatsAppButton from "./Components/Buttons/WhatsAppButton";
import AcceptButton from "./Components/Buttons/AcceptButton";
import BackButton from "./Components/Buttons/BackButton";
import NextButton from "./Components/Buttons/NextButton";
import MoreOptionsButton from "./Components/Buttons/MoreOptionsButton";
import {useCandidateUpdateApplication} from "../../../../Functions/Candidate";
import {useJobsData} from "../../../../Contexts/DataContext/JobsDataContext";
import dayjs from "dayjs";

export default function CandidateInterviewPage() {
    const {candidate, candidateApplications} = useOutletContext<{
        candidate: Candidate;
        candidateApplications: any[];
    }>();

    const {jobs} = useJobsData();
    const updateApplication = useCandidateUpdateApplication();
    const {showSnackbar} = useSnackbar();
    const [activeStep, setActiveStep] = useState(0);
    const [showConfetti, setShowConfetti] = useState(false);
    const [currentApplication, setCurrentApplication] = useState<Application | undefined>(undefined);
    const [interviewDate, setInterviewDate] = useState<any>(undefined);

    const formik = useFormik({
        initialValues: {
            job: "",
            firstWaMessage: "",
            firstSummary: "",
            secondWaMessage: "",
            secondSummary: "",
            rating: null,
            lastUpdate: null,
        },
        onSubmit: (values) => {
        }
    });

    // ----------------------------------------------------------------
    // Steps
    const steps = [
        "בחירת משרה",
        "זימון ראיון ראשון",
        "ראיון ראשון",
        "זימון לראיון שני",
        "ראיון שני",
        "קבלה"
    ];

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <Stack gap={1}>
                    <FormControl fullWidth variant="standard" size="small" sx={{marginTop: "3px"}} required>
                        <InputLabel>בחר/י משרה</InputLabel>
                        <SelectJob
                            value={formik.values.job}
                            onChange={(event) => onJobChoose(event)}
                            filterJobs={candidateApplications.map(app => app.jobNumber)}
                            type={undefined}
                        />
                    </FormControl>

                    {dayjs(formik.values.lastUpdate).isValid() && (
                        <Typography variant="body2" color="text.secondary">
                            {`הסטטוס שונה לאחרונה בתאריך: ${dayjs(formik.values.lastUpdate).format("DD/MM/YY HH:mm")}`}
                        </Typography>
                    )}
                </Stack>
            case 1:
            case 3:
                return <>
                    <InviteForInterview candidate={candidate} application={currentApplication!}
                                        setInterviewDate={setInterviewDate} formik={formik}
                                        step={step}/>
                </>
            case 2:
            case 4:
                return <InterviewSummary formik={formik} step={step}/>;
            case 5:
                return <>
                    <Stack alignItems="center">
                        <InterviewStatus status={currentApplication?.status as any}/>
                    </Stack>

                    {currentApplication?.status === "נדחה" && (
                        <Typography
                            variant="h6"
                            sx={{
                                fontFamily: 'Rubik, sans-serif',
                                '& .label': {
                                    color: 'error.main',
                                    fontWeight: 600
                                },
                                '& .value': {
                                    color: 'text.primary'
                                }
                            }}
                        >
                            <span className="label">סיבת דחייה:</span>{' '}
                            <span className="value">
                                {currentApplication?.rejectCause.trim() === '' ? "לא צויין" : currentApplication?.rejectCause}
                            </span>
                        </Typography>

                    )}
                </>;
        }
    };

    const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

    // ----------------------------------------------------------------
    // Next and Back
    const goBack = () => {
        if (activeStep === 5 && currentApplication) {
            if (currentApplication.interviewsSummery[1] === "") {
                setActiveStep(2);
                return;
            }
        }

        formik.setErrors({});
        handleBack();
    }

    const goNext = () => {
        if (activeStep === 0) {
            if (currentApplication?.status === "הוגשה מועמדות" || currentApplication?.status.includes("ראיון")) {
                handleNext();
                return;
            } else {
                setActiveStep(5);
                if (currentApplication?.status === "התקבל") {
                    setShowConfetti(true);
                }
                return;
            }
        }

        if ((activeStep === 2 || activeStep === 4) &&
            !(currentApplication!.interviewsSummery[0] === formik.values.firstSummary &&
                currentApplication!.interviewsSummery[1] === formik.values.secondSummary &&
                currentApplication!.matchingRate === formik.values.rating)) {
            saveMainData();
        }

        handleNext();
    }

    // ----------------------------------------------------------------
    // Options logic
    const onJobChoose = (jobNumber) => {
        const application = candidateApplications.find(app => app.jobNumber.toString() === jobNumber.toString());
        setCurrentApplication(application);

        if (application !== undefined) {
            formik.setFieldValue('job', jobNumber);
            formik.setFieldValue('firstSummary', application.interviewsSummery[0]);
            formik.setFieldValue('secondSummary', application.interviewsSummery[1]);
            formik.setFieldValue('rating', application.matchingRate);

            if (dayjs(application.applyDate).isValid() && dayjs(application.lastUpdate).isValid()) {
                if (application.applyDate !== application.lastUpdate) {
                    formik.setFieldValue('lastUpdate', application.lastUpdate);
                }
            }
        } else {
            showSnackbar({message: "אירעה שגיאה. תנסו שוב. במידה ולא עובד יש לפנות לתמיכה."});
        }
    }

    const handleApplicationUpdate = async ({
                                               status = currentApplication!.status,
                                               rejectCause = currentApplication!.rejectCause,
                                               interviewDate = currentApplication!.interviewDate,
                                               applyDate = currentApplication!.applyDate,
                                               matchingRate = currentApplication!.matchingRate,
                                               interviewSummary = currentApplication!.interviewsSummery
                                           }) => {
        try {
            const baseObj = {...currentApplication!};
            baseObj.lastUpdate = new Date();
            baseObj.status = status;
            baseObj.rejectCause = rejectCause;
            baseObj.applyDate = applyDate;
            baseObj.interviewDate = interviewDate;
            baseObj.matchingRate = matchingRate;
            baseObj.interviewsSummery = interviewSummary;

            await updateApplication(candidate, currentApplication!, baseObj);

            currentApplication!.lastUpdate = baseObj.lastUpdate;
            currentApplication!.status = baseObj.status;
            currentApplication!.rejectCause = baseObj.rejectCause;
            currentApplication!.applyDate = baseObj.applyDate;
            currentApplication!.interviewDate = baseObj.interviewDate;
            currentApplication!.matchingRate = baseObj.matchingRate;
            currentApplication!.interviewsSummery = baseObj.interviewsSummery;
            formik.setFieldValue('lastUpdate', currentApplication!.lastUpdate);
        } catch (e) {
            showSnackbar({message: `עדכון המועמד נכשל: ${(e as any).message as string}`});
        }
    }

    const WhatsAppClick = async () => {
        const [day, month, year] = interviewDate.date.split('/');
        const [hours, minutes] = interviewDate.time.split(":");

        await handleApplicationUpdate({
            interviewDate: new Date(
                parseInt(year),
                parseInt(month) - 1,
                parseInt(day),
                parseInt(hours),
                parseInt(minutes)
            ),
            status: activeStep === 1 ? "זומן לראיון ראשון" : "זומן לראיון שני"
        });
    }

    const rejectCandidate = (rejectReason, setLoading) => {
        setLoading(true);
        handleApplicationUpdate({
            status: "נדחה",
            rejectCause: rejectReason,
            interviewSummary: [
                formik.values.firstSummary,
                formik.values.secondSummary
            ],
            matchingRate: formik.values.rating as unknown as number
        })
            .then(() => setActiveStep(5))
            .finally(() => setLoading(false));
    }

    const acceptCandidate = () => {
        handleApplicationUpdate({
            status: "התקבל",
            matchingRate: formik.values.rating as unknown as number,
            interviewSummary: [
                formik.values.firstSummary,
                formik.values.secondSummary
            ]
        })
            .then(() => {
                setActiveStep(5);
                setShowConfetti(true);
            });
    }

    const notInterestedCandidate = () => {
        handleApplicationUpdate({
            status: "אינו מעוניין במשרה",
            matchingRate: formik.values.rating as unknown as number,
            interviewSummary: [
                formik.values.firstSummary,
                formik.values.secondSummary
            ]
        }).then(() => setActiveStep(5));
    }

    const passedInterviewCandidate = (type = "ראשון") => {
        handleApplicationUpdate({
            status: type === "ראשון" ? "עבר ראיון ראשון" : "עבר ראיון שני",
            matchingRate: formik.values.rating as unknown as number,
            interviewSummary: [
                formik.values.firstSummary,
                formik.values.secondSummary
            ]
        });
    }

    const moveJobCandidate = (jobId, setLoading) => {
        setLoading(true);

        if (candidateApplications.some(app => app.jobNumber === jobId)) {
            showSnackbar({message: "המועמד/ת כבר הגישה למשרה הזאת."});
            setLoading(false);
            return;
        }

        handleApplicationUpdate({
            status: "הועבר למשרה אחרת",
            matchingRate: formik.values.rating as unknown as number,
            interviewSummary: [
                formik.values.firstSummary,
                formik.values.secondSummary
            ]
        })
            .then(async () => {
                const job = jobs.find(j => j.jobNumber === jobId);
                await candidateService.addApplication({
                    jobNumber: job!.jobNumber,
                    status: "הוגשה מועמדות",
                    arrivalWay: currentApplication!.arrivalWay,
                    sector: job!.sector,
                    candidateId: candidate.id
                });

                setActiveStep(5);
            })
            .finally(() => setLoading(false));
    }

    const sentCVToManagerCandidate = () => {
        handleApplicationUpdate({
            status: 'קו"ח הועברו למנהלי התוכנית',
            matchingRate: formik.values.rating as unknown as number,
            interviewSummary: [
                formik.values.firstSummary,
                formik.values.secondSummary
            ]
        }).then(() => setActiveStep(5));
    }

    const appliedCandidate = () => {
        handleApplicationUpdate({
            status: 'הוגשה מועמדות',
            matchingRate: formik.values.rating as unknown as number,
            interviewSummary: [
                formik.values.firstSummary,
                formik.values.secondSummary
            ]
        });
    }

    const saveMainData = () => {
        handleApplicationUpdate({
            matchingRate: formik.values.rating as unknown as number,
            interviewSummary: [
                formik.values.firstSummary,
                formik.values.secondSummary
            ]
        });
    }

    // ----------------------------------------------------------------
    const screenXS = UseIsScreenXS();

    return <>
        <CustomCard title="ראיונות">
            <Stepper
                activeStep={activeStep}
                orientation={screenXS ? 'vertical' : 'horizontal'}
                sx={{justifySelf: {xs: "center", sm: undefined}, width: {xs: undefined, sm: "100%"}}}
            >
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    const isLastStep = index === steps.length - 1;

                    return (
                        <Step key={index} {...stepProps}>
                            <StepLabel
                                {...labelProps}
                                StepIconComponent={isLastStep && activeStep === 5 ? LastStepIcon : undefined}
                            >
                                {label}
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            <form onSubmit={formik.handleSubmit}>
                <Box sx={{mt: 3, width: "100%"}}>
                    {getStepContent(activeStep)}
                </Box>
            </form>

            <Stack gap={1}
                   sx={{
                       mt: 2,
                       flexDirection: {xs: "column", md: "row"},
                       justifyContent: "space-between",
                       width: "100%"
                   }}
            >
                {/* Right Side */}
                {(() => {
                    switch (activeStep) {
                        case 0:
                            return <Box/>;
                        default:
                            return <BackButton onClick={goBack}/>
                    }
                })()}

                {/* Left Side */}
                <Stack gap={1} sx={{flexDirection: {xs: "column", md: "row"}}}>
                    {(() => {
                        switch (activeStep) {
                            case 0:
                                return <>
                                    {currentApplication && <MoreOptionsButton
                                        application={currentApplication}
                                        validate={() => true}
                                        onAccepted={acceptCandidate}
                                        onNotInterested={notInterestedCandidate}
                                        onMoveJob={moveJobCandidate}
                                        onSentCVToManager={sentCVToManagerCandidate}
                                        onDeclined={rejectCandidate}
                                        onPassFirstInterview={() => passedInterviewCandidate("ראשון")}
                                        onPassSecondInterview={() => passedInterviewCandidate("שני")}
                                        onInviteFirstInterview={currentApplication!.status.includes("זומן לראיון ראשון") ? () => {
                                        } : undefined}
                                        onInviteSecondInterview={currentApplication!.status.includes("זומן לראיון שני") ? () => {
                                        } : undefined}
                                        onAppliedInterview={appliedCandidate}
                                    />}
                                    <NextButton onClick={goNext} disabled={!currentApplication}/>
                                </>

                            case 1:
                            case 3:
                                return <>
                                    <WhatsAppButton
                                        message={activeStep === 1 ? formik.values.firstWaMessage : formik.values.secondWaMessage}
                                        phoneNumber={candidate.id}
                                        interviewType={activeStep === 1 ? "ראשון" : "שני"}
                                        clicked={WhatsAppClick}/>
                                    <NextButton onClick={goNext} disabled={!currentApplication}/>
                                </>

                            case 2:
                                return <>
                                    <MoreOptionsButton
                                        application={currentApplication!}
                                        onAccepted={acceptCandidate}
                                        onNotInterested={notInterestedCandidate}
                                        onMoveJob={moveJobCandidate}
                                        onSentCVToManager={sentCVToManagerCandidate}
                                        onPassFirstInterview={() => passedInterviewCandidate("ראשון")}
                                        onPassSecondInterview={() => passedInterviewCandidate("שני")}
                                        onInviteFirstInterview={currentApplication!.status.includes("זומן לראיון ראשון") ? () => {
                                        } : undefined}
                                        onInviteSecondInterview={currentApplication!.status.includes("זומן לראיון שני") ? () => {
                                        } : undefined}
                                    />
                                    <RejectButton status={currentApplication?.status} onClick={rejectCandidate}/>
                                    <NextButton onClick={goNext} disabled={!currentApplication}
                                                asSaveAndNext={formik.values.rating !== currentApplication?.matchingRate || formik.values.firstSummary !== currentApplication?.interviewsSummery[0]}/>
                                </>

                            case 4:
                                return <>
                                    <MoreOptionsButton
                                        application={currentApplication!}
                                        onNotInterested={notInterestedCandidate}
                                        onMoveJob={moveJobCandidate}
                                        onSentCVToManager={sentCVToManagerCandidate}
                                        onPassFirstInterview={() => passedInterviewCandidate("ראשון")}
                                        onPassSecondInterview={() => passedInterviewCandidate("שני")}
                                        onInviteFirstInterview={currentApplication!.status.includes("זומן לראיון ראשון") ? () => {
                                        } : undefined}
                                        onInviteSecondInterview={currentApplication!.status.includes("זומן לראיון שני") ? () => {
                                        } : undefined}
                                    />
                                    <RejectButton status={currentApplication?.status}
                                                  onClick={rejectCandidate}/>
                                    <AcceptButton status={currentApplication?.status} onClick={acceptCandidate}/>

                                    {currentApplication?.status !== "הוגשה מועמדות" && !currentApplication?.status.includes("ראיון") ?
                                        <NextButton onClick={goNext} disabled={!currentApplication}
                                                    asSaveAndNext={formik.values.rating !== currentApplication?.matchingRate || formik.values.secondSummary !== currentApplication?.interviewsSummery[1]}/> :
                                        <Button onClick={saveMainData} variant="contained" color="primary"
                                                disabled={formik.values.rating === currentApplication?.matchingRate && formik.values.secondSummary === currentApplication?.interviewsSummery[1]}>שמור</Button>}
                                </>
                        }
                    })()}
                </Stack>
            </Stack>
        </CustomCard>

        <ShowConfetti show={showConfetti} setShow={setShowConfetti}/>
    </>
}


function LastStepIcon(props) {
    const {active, completed} = props;
    return <DoneAllIcon color={active || completed ? "success" : "disabled"}/>
}
