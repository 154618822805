import React from 'react';
import {AppBar, Box, Toolbar, useTheme} from '@mui/material';
import {Sidebar} from "../Sidebar/Sidebar";

export function MainNavbar({startItems, centerItems, endItems}) {
    const theme = useTheme();

    return (
        <AppBar position="relative" sx={{
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            boxShadow: "none",
            borderBottom: "none",
            height: '100%',
        }}>
            <Toolbar sx={{paddingRight: "16px !important"}}>
                <Box sx={{display: "flex", justifyContent: "space-between", width: '100%', height: '100%'}}>
                    {/* Start Items */}
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Box sx={{display: {xs: "flex", md: "none"}}}>
                            <Sidebar/>
                        </Box>
                        {startItems}
                    </Box>

                    {/* Center Items */}
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexGrow: 0,  // Changed from 1 to 0
                        flexBasis: 'auto',  // Added this line
                        margin: '0 auto'  // Added this line
                    }}>
                        {centerItems}
                    </Box>

                    {/* End Items */}
                    <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                        {endItems}
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>
    );
}