import {useNavigate, useParams} from "react-router-dom";
import React from "react";
import GvanimProgress from "../../Components/GvanimProgress/GvanimProgress";
import JobInformation from "./Components/JobInfo/JobInfo";
import {Box} from "@mui/material";
import ApplicationForm from "./Components/ApplicationForm/ApplicationForm";
import {page} from "./JobPageStyles";
import {Job} from "../../../Firebase/FirebaseFunctions/JobsService";
import {useDrushimJobsData} from "../../Contexts/JobsDrushimContext";
import Background from "./Components/Background";

export default function JobPage() {
    const {id} = useParams();
    const {jobs, loadingJobs} = useDrushimJobsData();
    const [job, setJob] = React.useState<Job | undefined>(undefined);
    const [loading, setLoading] = React.useState(true);
    const navigate = useNavigate();

    const jobApplication = React.useRef<HTMLParagraphElement>(null);

    React.useEffect(() => {
        if (!loadingJobs) {
            const temp = jobs.find((job) => job.jobNumber.toString() === id);
            if (temp === undefined) {
                navigate("/career/not-found")
            } else {
                setJob(temp);
            }
            setLoading(false);
        }
    }, [jobs, loadingJobs])

    if (loading) {
        return <GvanimProgress />;
    }

    return (<>
        <Background/>

        {/* Entire Page */}
        <Box sx={page()}>
            <JobInformation job={job!} jobApplicationElement={jobApplication}/>
            <ApplicationForm jobApplicationElement={jobApplication} job={job!}/>
        </Box>
    </>);
}
