import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import {Stack, Typography} from "@mui/material";

interface PasswordStrengthIndicatorProps {
    password: string;
    setStrongPassword: (value: boolean) => void;
}

const PasswordStrengthIndicator: React.FC<PasswordStrengthIndicatorProps> = ({password, setStrongPassword}) => {
    // Function to calculate password strength
    const calculatePasswordStrength = (password: string): number => {
        let score = 0;
        if (password.length >= 6) score += 1; // Minimum length
        if (/[A-Z]/.test(password)) score += 1; // Uppercase letters
        if (/[0-9]/.test(password)) score += 1; // Numbers
        if (/[^A-Za-z0-9]/.test(password)) score += 1; // Special characters
        return (score / 4) * 100; // Convert to percentage
    };

    const strength = calculatePasswordStrength(password);
    setStrongPassword(strength == 100);

    // Function to generate a gradient color from red to green based on strength
    const getGradientColor = (strength: number): string => {
        const red = Math.max(255 - Math.round((strength / 100) * 255), 0); // Decrease red as strength increases
        const green = Math.min(Math.round((strength / 100) * 180), 180); // Increase green as strength increases
        return `rgb(${red}, ${green}, 0)`;
    };

    // Determine the label based on the strength percentage
    const getStrengthLabel = (strength: number): string => {
        if (strength === 100) return "הסיסמה מאוד חזקה";
        if (strength >= 75) return "הסיסמה חזקה";
        if (strength >= 50) return "הסיסמה בינונית";
        if (strength >= 25) return "הסיסמה חלשה";
        return "הסיסמה מאוד חלשה";
    };

    return (
        <Stack flexDirection="column" gap="8px">
            {/* Linear Progress Bar */}
            <LinearProgress
                variant="determinate"
                value={strength}
                sx={{
                    height: 8,
                    borderRadius: 4,
                    backgroundColor: "#e0e0e0",
                    "& .MuiLinearProgress-bar": {
                        backgroundColor: getGradientColor(strength),
                        transition: "transform 1s ease-out, background-color 1s ease-out",
                    },
                }}
            />
            {/* Label for Strength */}
            <Typography
                variant="body2"
                align="center"
                fontWeight="bold"
                sx={{
                    color: getGradientColor(strength),
                }}
            >
                {getStrengthLabel(strength)}
            </Typography>
        </Stack>
    );
};

export default PasswordStrengthIndicator;
