import {styled} from "@mui/material/styles";
import {Box, Container, Link, Stack, Typography} from "@mui/material";
import React from "react";

const StyledLink = styled(Link)(({theme}) => ({
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline'
    }
}));

interface AlertPageProps {
    title: React.ReactNode;
    color?: string;
    goBackToJobs?: boolean;
}

export default function AlertPage({title, goBackToJobs = true, color = "primary.drushimTitle"}: AlertPageProps) {
    return (
        <Container
            maxWidth="md"
            sx={{
                padding: 2,
                flex: 1,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Stack
                    spacing={3}
                    sx={{
                        py: 4,
                        width: '100%',
                        color: 'primary.myBoxShadow'
                    }}
                >
                    <Typography
                        variant="h2"
                        sx={{
                            textAlign: 'center',
                            color: color,
                            lineHeight: 1.8
                        }}
                    >
                        {title}
                    </Typography>

                    {goBackToJobs &&
                        <Typography
                            variant="body2"
                            sx={{
                                textAlign: 'center',
                                color: 'secondary.drushimTitle'
                            }}
                        >
                            לחזרה לדף המשרות{' '}
                            <StyledLink href="/career/jobs">
                                לחץ כאן
                            </StyledLink>
                        </Typography>
                    }
                </Stack>
            </Box>
        </Container>
    );
}