import React from 'react';
import {Select, MenuItem} from '@mui/material';
import {useSectorsData} from "../../Contexts/DataContext/SectorsDataContext";
import {useAuth} from "../../Contexts/AuthContext/AuthContext";

const SelectSector = ({value, onChange, labelId, allSectors = false}) => {
    const {sectors} = useSectorsData();
    let filteredSectors = sectors.filter((sector) => sector.open);

    const {userDetails} = useAuth();
    filteredSectors = filteredSectors.filter((sector) => userDetails?.sectors?.includes(sector.id));

    return (
        <Select
            value={value || ''}
            label="אשכול"
            onChange={(event) => onChange(event.target.value)}
            labelId={labelId}
        >
            {allSectors && (
                <MenuItem value="כל האשכולות">
                    כל האשכולות
                </MenuItem>
            )}

            {filteredSectors?.map((sector) => (
                <MenuItem key={sector.id} value={sector.name}>
                    {sector.name}
                </MenuItem>
            ))}
        </Select>
    );
};

export default SelectSector;
