import React from 'react';
import {Select, MenuItem} from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import {green, red} from "@mui/material/colors";

const SelectOpenClosed = ({value, onChange, name}) => {
    const statuses = [
        {value: "פתוח", icon: <LockOpenIcon/>, color: green[700]},
        {value: "סגור", icon: <LockIcon/>, color: red[500]}
    ];

    return (
        <Select
            value={value}
            onChange={onChange}
            name={name}
            sx={{
                '& .MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    color: statuses.find(s => s.value === value)?.color,
                },
            }}
        >
            {statuses.map((status) => (
                <MenuItem
                    key={status.value}
                    value={status.value}
                    sx={{
                        display: 'flex',
                        gap: 1,
                        color: status.color,
                        '& .MuiSvgIcon-root': {
                            color: status.color,
                        },
                    }}
                >
                    {status.icon}
                    {status.value}
                </MenuItem>
            ))}
        </Select>
    );
};

export default SelectOpenClosed;
