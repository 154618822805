import {createContext, useContext, useEffect, useState, ReactNode} from 'react';
import {collection, getDocs} from 'firebase/firestore';
import {db} from '../../../Firebase/FirebaseConfig/firebase';
import {getLocalStorage, setLocalStorage} from "../../../Firebase/FirebaseFunctions/Helpers/LocalStorage";
import {SectorsLocalStorage} from "../../../Firebase/FirebaseFunctions/SectorService";

interface DataContextType {
    sectors: Array<any>;
    loadingSectors: boolean;
    refreshSectors: () => Promise<void>;
}

const DataContext = createContext<DataContextType | undefined>(undefined);

export function SectorsDataProvider({children}: { children: ReactNode }) {
    const [sectors, setSectors] = useState<Array<any>>(getLocalStorage(SectorsLocalStorage));
    const [loadingSectors, setLoadingSectors] = useState<boolean>(true);

    const fetchSectors = async () => {
        setLoadingSectors(true);
        try {
            const snapshot = await getDocs(collection(db, 'sectors'));
            const newData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            console.log('[SECTORS] Fetched sectors (from server) length:', newData.length);

            setSectors(newData);
            setLocalStorage(SectorsLocalStorage, newData);
            localStorage.setItem('sectorsLastFetchTime', new Date().getTime().toString());
        } catch (error) {
            console.error("[SECTORS] Error fetching sectors: ", error);
            const cachedData = getLocalStorage(SectorsLocalStorage);
            setSectors(cachedData);
        } finally {
            setLoadingSectors(false);
        }
    };

    const refreshSectors = async () => {
        setLoadingSectors(true);
        console.log("[SECTORS] data refreshed (from local).");
        setSectors(getLocalStorage(SectorsLocalStorage));
        setLoadingSectors(false);
    };

    useEffect(() => {
        const lastFetch = localStorage.getItem('sectorsLastFetchTime');
        const currentTime = new Date().getTime();
        const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes cache

        if (lastFetch && currentTime - parseInt(lastFetch) < CACHE_DURATION) {
            const cachedData = getLocalStorage(SectorsLocalStorage);
            console.log('[SECTORS] Fetched sectors (from local) length:', cachedData.length);
            setSectors(cachedData);
            setLoadingSectors(false);
            return;
        }

        fetchSectors();
    }, []);

    return (
        <DataContext.Provider value={{sectors, loadingSectors, refreshSectors}}>
            {children}
        </DataContext.Provider>
    );
}


export function useSectorsData(): DataContextType {
    const context = useContext(DataContext);
    if (!context) {
        throw new Error("useData must be used within a DataProvider");
    }
    return context;
}
