import {createContext, useContext, useState} from 'react';

const ThemeContext = createContext({
    toggleTheme: () => {
    },
    isDark: false,
});

export const useThemeContext = () => useContext(ThemeContext);

export const ThemeContextProvider = ({children}) => {
    const [isDark, setIsDark] = useState(() => {
        const savedTheme = localStorage.getItem('isDark');
        return savedTheme ? JSON.parse(savedTheme) : false;
    });

    const toggleTheme = () => {
        setIsDark((prev) => {
            const newValue = !prev;
            localStorage.setItem('isDark', JSON.stringify(newValue));
            return newValue;
        });
    };

    return (
        <ThemeContext.Provider value={{toggleTheme, isDark}}>
            {children}
        </ThemeContext.Provider>
    );
};
