import FullScreenDialog, {FullScreenDialogHeader} from "../../../Components/Dialog/FullScreenDialog/FullScreenDialog";
import JobCandidatesHeader from "./Components/JobCandidatesHeader";
import JobCandidatesTable from "./Components/JobCandidatesTable";
import React from "react";
import CustomCard from "../../../Components/Cards/Card";
import {Box} from "@mui/material";
import JobDetails from "./Components/JobDetails";

export default function JobCandidates({show, close, job}) {
    return (
        <FullScreenDialog show={show} close={close} centerContent={true}>
            <FullScreenDialogHeader>
                <JobCandidatesHeader/>
            </FullScreenDialogHeader>

            <Box
                sx={{
                    position: "relative",
                    display: "flex",
                    justifySelf: "center",
                    width: {md: "85%", sm: "90%", xs: "100%"},
                }}
            >
                <CustomCard noPadding={true}>
                    <Box display="flex" justifyContent="center" marginTop={{xs: 0, sm: "1rem"}} paddingTop="16px"
                         marginBottom={{xs: "1rem", sm: "4rem"}}>
                        <JobDetails job={job}/>
                    </Box>
                    <JobCandidatesTable job={job}/>
                </CustomCard>
            </Box>
        </FullScreenDialog>
    );
}