import {SentimentDissatisfied} from "@mui/icons-material";
import {Button, Stack, TextField} from "@mui/material";
import React from "react";
import SimpleDialog, {SimpleDialogFooter} from "../../../../../../Components/Dialog/SimpleDialog";
import {useFormik} from "formik";
import * as Yup from 'yup';
import {LoadingButton} from "@mui/lab";
import RejectDialog from "../RejectDialog";

export default function RejectButton({status, validate = () => true, onClick}) {
    const [open, setOpen] = React.useState(false);

    const onOpen = () => {
        if (!validate()) {
            return;
        }
        setOpen(true);
    }

    return <>
        <Button
            startIcon={<SentimentDissatisfied/>}
            variant="contained"
            color="error"
            disabled={status === 'נדחה'}
            onClick={onOpen}
        >
            נדחה
        </Button>

        <RejectDialog setOpen={setOpen} open={open} onClick={onClick}/>
    </>
}
