import React from 'react';
import {Autocomplete, TextField} from '@mui/material';
import {useJobsData} from "../../Contexts/DataContext/JobsDataContext";

const SelectArea = ({value, formik, onChange, required = false}) => {
    const {jobs, loadingJobs} = useJobsData();
    const [locations] = React.useState<Set<string>>(new Set<string>());
    const [text, setText] = React.useState("");

    React.useEffect(() => {
        if (!loadingJobs) {
            jobs.map(job => locations.add(job.region));
        }
    }, [jobs, loadingJobs])

    const handleChange = (event, newValue) => {
        if (newValue) {
            if (!locations.has(newValue)) {
                setText(newValue);
            }
            onChange(newValue);
        } else {
            // Handle empty value
            onChange("");
        }
        // Touch the field to trigger validation
        formik.setFieldTouched('area', true, false);
    };

    const handleBlur = (event) => {
        if (text.trim() !== "") {
            locations.add(text.trim());
        }
        // Trigger validation on blur
        formik.setFieldTouched('area', true, true);
    };

    return (
        <Autocomplete
            freeSolo
            options={Array.from(locations.values())}
            value={value}
            openOnFocus
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="איזור"
                    variant="standard"
                    required={required}
                    error={formik.touched.area && Boolean(formik.errors.area)}
                    helperText={formik.touched.area && formik.errors.area}
                    onBlur={handleBlur}
                />
            )}
            onInputChange={handleChange}
        />
    );
};


export default SelectArea;

