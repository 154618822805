import React from "react";
import {Box, Button, FormHelperText, Grid, Icon, Stack, Step, StepLabel, Stepper, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import * as Yup from "yup";
import {useFormik} from "formik";
import {useNavigate} from "react-router-dom";
import {UseScreenSelectors} from "../../../../../Utilities/ScreenSize";
import {ReactComponent as CloudSVG} from "../../../../Assets/Cloud.svg";
import {StyledTextField} from "./ApplicationFormStyled";
import OTP from "../../../../../Utilities/Components/OTP";
import {ConfirmationResult, RecaptchaVerifier, signInWithPhoneNumber} from "firebase/auth";
import {auth} from "../../../../../Firebase/FirebaseConfig/firebase";
import {useSnackbar} from "../../../../../Utilities/Context/Snackbar";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {candidateService, setCandidateCustomClaims} from "../../../../../Firebase/FirebaseFunctions/CandidateService";
import {Application} from "../../../../../Firebase/FirebaseFunctions/ApplicationService";
import Alert from "@mui/material/Alert";

const ABOUT_MAX_LENGTH = 1000;
const ARRIVAL_MAX_LENGTH = 50;
const MINUTES15 = 15 * 60 * 1000;

const steps = [
    'פרטים אישיים',
    'פרטי מועמדות',
    'אימות'
];

const validationSchema = Yup.object({
    candidateFirstName: Yup.string()
        .trim()
        .min(2, "שם פרטי חייב להיות לפחות 2 תווים.")
        .required("שדה זה הינו שדה חובה."),
    candidateLastName: Yup.string()
        .trim()
        .min(2, "שם משפחה חייב להיות לפחות 2 תווים.")
        .required("שדה זה הינו שדה חובה."),
    candidatePhone: Yup.string()
        .matches(
            /^(0)?[0-9]{9}$/,
            "מספר פלאפון שגוי."
        )
        .required("שדה זה הינו שדה חובה."),
    candidateEmail: Yup.string()
        .email("האימייל שגוי.")
        .required("שדה זה הינו שדה חובה."),
    arrivalWay: Yup.string()
        .trim()
        .max(ARRIVAL_MAX_LENGTH, `מקסימום ${ARRIVAL_MAX_LENGTH} תווים.`)
        .required("שדה זה הינו שדה חובה."),
    about: Yup.string()
        .max(ABOUT_MAX_LENGTH, `מקסימום ${ABOUT_MAX_LENGTH} תווים.`),
    cv: Yup.mixed<File>()
        .required("שדה זה הינו שדה חובה.")
        .test("fileFormat", "יש לבחור קובץ PDF בלבד.", (value) => {
            if (!value) return false;
            return value instanceof File && value.type === "application/pdf";
        })
        .test("fileSize", "גודל הקובץ חייב להיות קטן מ-5MB.", (value) => {
            if (!value) return false;
            return value instanceof File && value.size <= 5 * 1024 * 1024;
        }),
    otp: Yup.string()
        .length(6, "אימות דו שלבי חייב להיות בעל 6 ספרות.")
        .required("שדה זה הינו שדה חובה.")
});

export default function ApplicationForm({jobApplicationElement, job}) {
    const {showSnackbar} = useSnackbar();
    const [activeStep, setActiveStep] = React.useState(0);
    const navigate = useNavigate();
    const screenSize = UseScreenSelectors();

    const [nextLoading, setNextLoading] = React.useState(false);
    const [phoneCodeResult, setPhoneCodeResult] = React.useState<ConfirmationResult | null>(null);
    const [reCapVerifier, setReCapVerifier] = React.useState<any>(null);
    const [codeError, setCodeError] = React.useState(false);
    const [codeTimestamp, setCodeTimestamp] = React.useState<number | null>(null);

    function hasAtLeastTwoActiveApplications(applications: Application[]): boolean {
        const activeApplications = applications.filter(
            (app) => app.status !== "התקבל" && app.status !== "נדחה" && app.status !== "הועבר למשרה אחרת"
        );

        return activeApplications.length >= 2;
    }

    function hasApplicationForJob(applications: Application[], jobNumber: number): boolean {
        return applications.some(app => app.jobNumber === jobNumber);
    }

    const pushCandidateToDB = async () => {
        try {
            const id: string = `${formik.values.candidatePhone}`.toString();
            const candidate = await candidateService.getCandidate(id);

            if (!candidate) {
                await candidateService.saveCandidate({
                    id: id,
                    firstname: formik.values.candidateFirstName,
                    lastname: formik.values.candidateLastName,
                    email: formik.values.candidateEmail,
                    sectors: "",
                });
            } else {
                const applications: Application[] = await candidateService.getApplications(formik.values.candidatePhone);
                console.log(applications);

                if (hasApplicationForJob(applications, job.jobNumber)) {
                    showSnackbar({message: "כבר הוגשה בעבר מועמדות למשרה זו"});
                    return;
                }

                if (hasAtLeastTwoActiveApplications(applications)) {
                    navigate("/career/already-applied");
                    return;
                }
            }

            await Promise.all([
                candidateService.addApplication({
                    about: formik.values.about,
                    arrivalWay: formik.values.arrivalWay,
                    jobNumber: job.jobNumber,
                    candidateId: id,
                    sector: job.sector,
                }),
                candidateService.uploadCandidateCV(id, formik.values.cv!),
            ]);

            console.log("המועמדות הוגשה בהצלחה");
        } catch (error) {
            console.error("An error occurred while pushing candidate to the database:", error);
            showSnackbar({message: "אירעה שגיאה בעת שמירת המועמדות. נסה שנית מאוחר יותר."});
        }
    };

    const formik = useFormik({
        initialValues: {
            candidateFirstName: "",
            candidateLastName: "",
            candidatePhone: "",
            candidateEmail: "",
            about: "",
            arrivalWay: "",
            cv: null,
            otp: ""
        },
        validationSchema,
        validate: () => {
            if (activeStep === 2 && formik.errors.otp !== undefined) {
                setCodeError(true);
            }
        },
        onSubmit: async (values, {setSubmitting}) => {
            setSubmitting(true);
            console.log("Form submitted:", values);

            try {
                if (auth.currentUser) {
                    await auth.signOut();
                }

                await phoneCodeResult?.confirm(values.otp);
                console.log("Current User after phone verification:", auth.currentUser);

                if (!auth.currentUser) {
                    console.error("User not found after phone verification.");
                    showSnackbar({message: "שגיאה באימות המשתמש. אנא נסו שנית."});
                    return;
                }

                try {
                    // Call the Cloud Function to assign candidate role
                    await setCandidateCustomClaims();

                    // Refresh the token to ensure it includes the latest claims
                    await auth?.currentUser?.getIdToken(true);

                    await pushCandidateToDB();
                } catch (error: any) {
                    console.error("Error calling assignCandidateRole or refreshing token:", error.message);
                    showSnackbar({message: "שגיאה בהגדרת תפקיד המועמד. אנא נסו שנית."});
                    return;
                }

                // Navigate to the thank you page
                navigate("/career/thanks");
            } catch (error: any) {
                switch (error.code) {
                    case "auth/invalid-verification-code":
                        formik.setFieldError("otp", "קוד האימות שגוי, אנא נסו שנית");
                        break;
                    case "auth/code-expired":
                        handleBack();
                        showSnackbar({message: "קוד האימות פג תוקף, אנא בקשו קוד חדש."});
                        setCodeTimestamp(null);
                        break;
                    default:
                        formik.setFieldError("otp", "שגיאה באימות, אנא נסה שנית");
                }
                setCodeError(true);
            } finally {
                // Clean up: Delete user if they exist
                if (auth.currentUser) {
                    try {
                        await auth.currentUser.delete();
                        // console.log("Temporary user deleted successfully.");
                    } catch (error) {
                        console.error("Failed to delete user:", error);
                    }
                }

                // End form submission
                setSubmitting(false);
            }

        },

    });

    const setupRecaptcha = async () => {
        try {
            if (!reCapVerifier) {
                const appVerifier = new RecaptchaVerifier(
                    "recaptcha-container",
                    {size: "invisible"},
                    auth
                );

                await appVerifier.render();
                setReCapVerifier(appVerifier);
            }
        } catch (error: any) {
            switch (error.code) {
                case 'auth/network-request-failed':
                    showSnackbar({
                        message: "בעיית תקשורת, אנא בדוק את החיבור לאינטרנט",
                    });
                    break;
                default:
                    showSnackbar({
                        message: "שגיאה באתחול מערכת האימות, אנא רענן את הדף",
                    });
            }
        }
    };

    React.useEffect(() => {
        setupRecaptcha();
    }, []);

    const sendCode = async () => {
        try {
            if (isCodeExpired()) {
                const result = await signInWithPhoneNumber(auth, `+972${formik.values.candidatePhone}`, reCapVerifier);
                setPhoneCodeResult(result);
                setCodeTimestamp(Date.now());
            }
            return true;
        } catch (error: any) {
            switch (error.code) {
                case 'auth/invalid-phone-number':
                    formik.setFieldError('candidatePhone', "מספר פלאפון אינו תקין.");
                    handleBack();
                    break;
                case 'auth/too-many-requests':
                    showSnackbar({
                        message: "נסיונות רבים מדי, אנא נסה שוב מאוחר יותר",
                    });
                    break;
                default:
                    showSnackbar({
                        message: "שגיאה בשליחת קוד האימות, אנא נסה שנית",
                    });
            }

            return false;
        }
    }

    const isCodeExpired = () => {
        if (!codeTimestamp) return true;
        const timeElapsed = (Date.now() - codeTimestamp) / 1000 / 60;
        return timeElapsed >= 15;
    };

    React.useEffect(() => {
        if (!codeTimestamp) return;

        const timer = setTimeout(() => {
            if (isCodeExpired()) {
                handleBack();
                showSnackbar({
                    message: "קוד האימות פג תוקף, אנא בקשו קוד חדש."
                });
            }
        }, MINUTES15);

        return () => clearTimeout(timer);
    }, [codeTimestamp]);

    const handleNext = async (e) => {
        e.preventDefault();

        let fieldsToCheck: string[] = [];

        if (activeStep === 0) {
            fieldsToCheck = ['candidateFirstName', 'candidateLastName', 'candidatePhone', 'candidateEmail'];
        } else if (activeStep === 1) {
            fieldsToCheck = ['about', 'arrivalWay', 'cv'];
        }

        // Touch all fields in current step
        fieldsToCheck.forEach(field => {
            formik.setFieldTouched(field, true);
        });

        // Validate only the fields in the current step
        const stepErrors = await formik.validateForm();
        const hasStepErrors = fieldsToCheck.some(field => stepErrors[field]);

        // Only proceed if current step fields are valid
        if (!hasStepErrors) {
            if (activeStep === 1) {
                setNextLoading(true);
                const result = await sendCode();
                setNextLoading(false);

                if (!result) {
                    return;
                }
            }

            setActiveStep((prevStep) => prevStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <StyledTextField
                                fullWidth
                                label="שם פרטי"
                                {...formik.getFieldProps('candidateFirstName')}
                                error={formik.touched.candidateFirstName && Boolean(formik.errors.candidateFirstName)}
                                helperText={formik.touched.candidateFirstName && formik.errors.candidateFirstName}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <StyledTextField
                                fullWidth
                                label="שם משפחה"
                                {...formik.getFieldProps('candidateLastName')}
                                error={formik.touched.candidateLastName && Boolean(formik.errors.candidateLastName)}
                                helperText={formik.touched.candidateLastName && formik.errors.candidateLastName}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <StyledTextField
                                fullWidth
                                label="מספר פלאפון"
                                type="text"
                                {...formik.getFieldProps('candidatePhone')}
                                placeholder="05X-XXX-XXXX"
                                error={formik.touched.candidatePhone && Boolean(formik.errors.candidatePhone)}
                                helperText={formik.touched.candidatePhone && formik.errors.candidatePhone}
                                inputProps={{
                                    inputMode: 'numeric',
                                    maxLength: 10,
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Tab') return;
                                    if (!/^[0-9]$/.test(e.key) && !["Backspace", "Delete", "ArrowLeft", "ArrowRight"].includes(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(e) => {
                                    let sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                    if (!sanitizedValue.startsWith('0') && sanitizedValue.length > 0) {
                                        sanitizedValue = '0' + sanitizedValue.replace(/^0+/, '');
                                    }
                                    if (sanitizedValue.length > 10) {
                                        sanitizedValue = sanitizedValue.slice(0, 10);
                                    }
                                    formik.setFieldValue('candidatePhone', sanitizedValue);
                                }}
                                onPaste={(e) => {
                                    const pasteData = e.clipboardData.getData('text').replace(/[^0-9]/g, '');
                                    if (!pasteData.startsWith('0') || pasteData.length !== 10) {
                                        e.preventDefault();
                                    }
                                }}
                                sx={{
                                    '& input[type=text]': {
                                        mozAppearance: 'textfield',
                                    },
                                    '& input[type=text]::-webkit-outer-spin-button': {
                                        WebkitAppearance: 'none',
                                        margin: 0,
                                    },
                                    '& input[type=text]::-webkit-inner-spin-button': {
                                        WebkitAppearance: 'none',
                                        margin: 0,
                                    },
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <StyledTextField
                                fullWidth
                                label="אימייל"
                                {...formik.getFieldProps('candidateEmail')}
                                error={formik.touched.candidateEmail && Boolean(formik.errors.candidateEmail)}
                                helperText={formik.touched.candidateEmail && formik.errors.candidateEmail}
                            />
                        </Grid>
                    </Grid>
                );
            case 1:
                return (
                    <Stack spacing={2}>
                        <Box>
                            <StyledTextField
                                fullWidth
                                multiline
                                rows={4}
                                label="ספרו לנו קצת עליכם"
                                {...formik.getFieldProps('about')}
                                error={formik.touched.about && Boolean(formik.errors.about)}
                                placeholder={"כתבו כאן..."}
                            />

                            <FormHelperText error={formik.touched.about && Boolean(formik.errors.about)}>
                                <Box component="span"
                                     sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                    <Typography component="span" variant="body2">
                                        {formik.touched.about && formik.errors.about}
                                    </Typography>
                                    <Typography component="span" variant="body2">
                                        {`${formik.values.about?.length || 0}/${ABOUT_MAX_LENGTH}`}
                                    </Typography>
                                </Box>
                            </FormHelperText>
                        </Box>

                        <Box>
                            <StyledTextField
                                fullWidth
                                label="איך הגעת אלינו?"
                                {...formik.getFieldProps('arrivalWay')}
                                error={formik.touched.arrivalWay && Boolean(formik.errors.arrivalWay)}
                            />

                            <FormHelperText error={formik.touched.arrivalWay && Boolean(formik.errors.arrivalWay)}>
                                <Box component="span"
                                     sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                    <Typography component="span" variant="body2">
                                        {formik.touched.arrivalWay && formik.errors.arrivalWay}
                                    </Typography>
                                    <Typography component="span" variant="body2">
                                        {`${formik.values.arrivalWay?.length || 0}/${ARRIVAL_MAX_LENGTH}`}
                                    </Typography>
                                </Box>
                            </FormHelperText>
                        </Box>

                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Button
                                disableRipple
                                variant="contained"
                                color="info"
                                component="label"
                                startIcon={<CloudUploadIcon/>}
                                sx={{
                                    backgroundColor: formik.touched.cv && formik.errors.cv
                                        ? "error.main"
                                        : "background.cvButton",
                                    borderRadius: "36px",
                                    color: "primary.textBright",
                                    "&:hover": {
                                        backgroundColor: "background.cvButtonHover"
                                    },
                                    paddingY: "14px",
                                    paddingLeft: "65px",
                                    paddingRight: "58px",
                                    fontSize: "20px"
                                }}
                            >
                                {formik.values.cv && !formik.errors.cv ? 'קובץ נבחר' : 'צירוף קורות חיים'}
                                <input
                                    type="file"
                                    hidden
                                    accept=".pdf"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const files = event.currentTarget.files;
                                        if (files && files.length > 0) {
                                            const file = files[0];
                                            formik.setFieldValue('cv', file);
                                        }
                                    }}
                                />
                            </Button>
                            {formik.touched.cv && formik.errors.cv && (
                                <Typography color="error" variant="caption" textAlign="center" sx={{marginTop: 1}}>
                                    {formik.errors.cv}
                                </Typography>
                            )}
                            {formik.values.cv && !formik.errors.cv && (
                                <Typography variant="caption" textAlign="center" sx={{marginTop: 1}}
                                            color="secondary.descAndReqText">
                                    {(formik.values.cv as File).name}
                                </Typography>
                            )}
                        </Box>
                    </Stack>
                );

            case 2:
                return <Box padding="10px" justifyItems="center">
                    <Typography
                        variant="body1"
                        textAlign="center"
                        fontWeight={700}
                        color="secondary.descAndReqText"
                    >
                        ברגעים אלו נשלח אליכם קוד אימות לפלאפון
                    </Typography>

                    <Typography variant="h5" textAlign="center" marginBottom="20px" color="secondary.descAndReqText">
                        נשלח למספר {formik.values.candidatePhone}
                    </Typography>

                    <OTP
                        value={formik.values.otp}
                        length={6}
                        onlyNumbers
                        onChange={(newValue) => {
                            if (typeof newValue !== 'function') {
                                return;
                            }
                            const currentOtp = String(formik.values.otp || '');
                            const updatedValue = newValue(currentOtp);
                            formik.setFieldValue('otp', updatedValue);
                            setCodeError(false);
                        }}
                        separator=""
                        sx={{
                            input: {
                                width: '50px',
                                height: '50px',
                                borderColor: codeError ? "red" : "primary.descAndReqTitle",
                            }
                        }}
                    />

                    {formik.touched.otp && formik.errors.otp && (
                        <Typography color="error" variant="caption" display="block">
                            {formik.errors.otp}
                        </Typography>
                    )}

                    <Alert
                        severity="info"
                        sx={{
                            mt: 4,
                            alignItems: "center"
                        }}
                    >
                        הקוד תקף ל-15 דקות בלבד. במידה ולא קיבלתם את הקוד, אנא בדקו בתיבת הספאם.
                    </Alert>
                </Box>
            default:
                return null;
        }
    };

    return (
        <Box sx={{width: '100%', marginTop: "179px", marginBottom: "50px"}} ref={jobApplicationElement}>
            {/*recaptcha verifier*/}
            <Box id="recaptcha-container" sx={{zIndex: 10}}></Box>
            <Box
                sx={{
                    alignSelf: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Icon
                    sx={{width: {xs: "118px", md: "208px"}, height: {xs: "65px", md: "115px"}}}
                    component={CloudSVG}
                ></Icon>
                <Typography variant={screenSize === "xs" ? "h6" : "h1"}
                            sx={{
                                marginTop: "19px",
                                marginBottom: {xs: "38px", md: "76px"},
                                color: "primary.jobTitle",
                            }}
                >
                    השאירו פרטים כאן:
                </Typography>
            </Box>

            <Stepper activeStep={activeStep}>
                {steps.map((label) => (
                    <Step
                        key={label}
                        sx={{
                            '& .MuiStepLabel-root .Mui-completed': {
                                color: 'secondary.main', // Color for completed steps
                            },
                            '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
                                color: 'secondary.main', // Text color for completed step labels
                            },
                            '& .MuiStepLabel-root .Mui-active': {
                                color: 'secondary.main', // Color for active step
                            },
                            '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
                                color: 'grey.400', // Text color for active step labels
                            },
                            '& .MuiStepLabel-root .Mui-disabled': {
                                color: 'grey.400', // Color for disabled steps
                            },
                            '& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel': {
                                color: 'grey.400', // Text color for disabled step labels
                            }
                        }}
                    >
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Box sx={{mt: 4}}>
                <form onSubmit={formik.handleSubmit}>
                    {renderStepContent(activeStep)}

                    <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 3}}>
                        {activeStep !== 0
                            ? <Button
                                type="button"
                                variant="outlined"
                                onClick={handleBack}
                                sx={{
                                    "&:hover": {
                                        backgroundColor: "background.cvButtonHover",
                                        color: "white",
                                        borderColor: "transparent"
                                    },
                                }}
                            >
                                אחורה
                            </Button>
                            : <Box></Box>
                        }

                        {activeStep === steps.length - 1 ? (
                            <LoadingButton
                                variant="contained"
                                type="submit"
                                loading={formik.isSubmitting}
                                sx={{
                                    color: "white",
                                    borderRadius: "36px",
                                    paddingX: "21px",
                                    paddingY: "10px",
                                    backgroundColor: "background.cvButton",
                                    "&:hover": {
                                        backgroundColor: "background.cvButtonHover"
                                    },
                                }}
                            >
                                הגש/י מועמדות!
                            </LoadingButton>
                        ) : (
                            <LoadingButton
                                type="button"
                                variant="contained"
                                onClick={handleNext}
                                sx={{
                                    color: "white",
                                    paddingX: "21px",
                                    paddingY: "10px",
                                    backgroundColor: "background.cvButton",
                                    "&:hover": {
                                        backgroundColor: "background.cvButtonHover"
                                    },
                                }}
                                loading={nextLoading}
                            >
                                הבא
                            </LoadingButton>
                        )}
                    </Box>
                </form>
            </Box>
        </Box>
    );
}
