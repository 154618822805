import React, {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {Job, jobConverter} from "../../Firebase/FirebaseFunctions/JobsService";
import {collection, getDocs, orderBy, query, where} from 'firebase/firestore';
import {db} from "../../Firebase/FirebaseConfig/firebase";

interface DataContextType {
    jobs: Array<Job>;
    loadingJobs: boolean;
}

const DataContext = createContext<DataContextType | undefined>(undefined);

export function DrushimJobsDataProvider({children}: { children: ReactNode }) {
    const [jobs, setJobs] = useState<Array<Job>>([]);
    const [loadingJobs, setLoadingJobs] = useState<boolean>(true);

    const fetchJobs = async () => {
        setLoadingJobs(true);
        try {
            const baseQuery = query(
                collection(db, "jobs").withConverter(jobConverter),
                where("open", "==", true),
                orderBy("highPriority", "desc")
            );
            const snapshot = await getDocs(baseQuery);
            const newData = snapshot.docs.map((doc) => doc.data() as Job);
            setJobs(newData);
        } catch (error) {
            console.error("Error fetching jobs: ", error);
        } finally {
            setLoadingJobs(false);
        }
    };

    useEffect(() => {
        fetchJobs();
    }, []);

    return (
        <DataContext.Provider value={{jobs, loadingJobs}}>
            {children}
        </DataContext.Provider>
    );
}


export function useDrushimJobsData(): DataContextType {
    const context = useContext(DataContext);
    if (!context) {
        throw new Error('useDrushimJobsData must be used within a DrushimJobsDataProvider');
    }
    return context;
}
