import {useDialog} from "../Contexts/Dialog";
import {useSnackbar} from "../../Utilities/Context/Snackbar";
import {useRolesData} from "../Contexts/DataContext/RolesDataContext";
import {roleService} from "../../Firebase/FirebaseFunctions/RoleService";

export function useRoleCreate(closeHandler?: () => void) {
    const {showDialog} = useDialog();
    const {showSnackbar} = useSnackbar();
    const {refreshRoles} = useRolesData();

    return (roleName: string, roleStatus: boolean) => {
        return new Promise((resolve) => {
            showDialog({
                title: "יצירת תפקיד",
                description: `האם להוסיף את התפקיד: "${roleName}"?`,
                onAccept: async () => {
                    try {
                        const role = {
                            name: roleName,
                            open: roleStatus
                        }

                        await roleService.saveRole(role);
                        await refreshRoles();

                        showSnackbar({
                            message: `התפקיד נוסף בהצלחה.`,
                            severity: "success"
                        });

                        if (closeHandler) {
                            closeHandler();
                        }
                        resolve(true);
                    } catch (error) {
                        const errorMessage = (error as any).toString();
                        console.log(errorMessage);
                        if (errorMessage.includes("already exists")) {
                            showSnackbar({message: "הוספת התפקיד נכשלה בגלל שכבר קיים תפקיד כזה."});
                        } else {
                            showSnackbar({message: ` הוספת התפקיד נכשלה. שגיאה: ${error}`})
                        }
                        resolve(false);
                    }
                },
                onCancel: () => resolve(false)
            })
        })
    }
}

export function useRoleDelete(closeHandler = () => {
}) {
    const {showDialog} = useDialog();
    const {showSnackbar} = useSnackbar();
    const {refreshRoles} = useRolesData();

    return (role) => {
        showDialog({
            title: "מחיקת תפקיד",
            description: `האם למחוק את התפקיד: "${role.name}"?`,
            onAccept: async () => {
                try {
                    await roleService.deleteRole(role.id);
                    await refreshRoles();

                    showSnackbar({
                        message: `התפקיד נמחק בהצלחה.`,
                        severity: "success"
                    });

                    if (closeHandler) {
                        closeHandler();
                    }
                } catch (error) {
                    showSnackbar({message: ` מחיקת התפקיד נכשלה. שגיאה: ${error}`})
                }
            }
        })
    }
}

export function useRoleChangeOpenClosed() {
    const {showDialog} = useDialog();
    const {showSnackbar} = useSnackbar();
    const {refreshRoles} = useRolesData();

    return (role) => {
        return new Promise((resolve) => {
            showDialog({
                title: "שינוי סטטוס",
                description: `האם לשנות ל'${role.open ? 'סגור' : 'פתוח'}'?`,
                onAccept: async () => {
                    try {
                        await roleService.updateRoleStatus(role.id, !role.open);
                        await refreshRoles();

                        showSnackbar({message: `הסטטוס השתנה!`, severity: "success"});
                        resolve(true);
                    } catch (error) {
                        showSnackbar({message: `שינוי סטטוס התפקיד נכשל. שגיאה: ${error}`})
                        resolve(false);
                    }
                },
                onCancel: async () => {
                    resolve(false);
                }
            });
        });
    };
}

export function useRoleUpdate(closeHandler) {
    const {showDialog} = useDialog();
    const {showSnackbar} = useSnackbar();
    const {refreshRoles} = useRolesData();

    return (role, name: string | null, status: boolean | null) => {
        return new Promise((resolve) => {
            showDialog({
                title: "עריכת תפקיד",
                description: `האם לערוך את התפקיד: "${role.name}"?`,
                onAccept: async () => {
                    try {
                        if (status !== null) {
                            await roleService.updateRoleStatus(role.id, status);
                        }

                        if (name !== null) {
                            await roleService.updateRoleName(role.id, name);
                        }

                        await refreshRoles();

                        showSnackbar({
                            message: `התפקיד נערך בהצלחה.`,
                            severity: "success"
                        });

                        if (closeHandler) {
                            closeHandler();
                        }
                        resolve(true);
                    } catch (error) {
                        const errorMessage = (error as any).toString();
                        console.log(errorMessage);
                        if (errorMessage.includes("already exists")) {
                            showSnackbar({message: "עריכת התפקיד נכשלה בגלל שכבר קיים תפקיד כזה."});
                        } else {
                            showSnackbar({message: ` עריכת התפקיד נכשלה. שגיאה: ${error}`})
                        }
                        resolve(false);
                    }
                },
                onCancel: () => resolve(false)
            })
        })
    }
}