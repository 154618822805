import {onAuthStateChanged, User} from "firebase/auth";
import React, {createContext, useCallback, useContext, useEffect, useMemo, useState,} from "react";
import {Recruiter, recruiterService} from "../../../Firebase/FirebaseFunctions/RecruiterService";
import {auth} from "../../../Firebase/FirebaseConfig/firebase";
import {UserRole} from "../../../Firebase/enums/UserRole";
import {useIdleTimer} from "react-idle-timer";
import {getLocalStorage, setLocalStorage} from "../../../Firebase/FirebaseFunctions/Helpers/LocalStorage";
import {useLocation} from "react-router-dom";

export const AuthContext = createContext<any>(null);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [userDetails, setUserDetails] = useState<Recruiter | null>(() => {
        const cachedDetails = localStorage.getItem("userDetails");
        return cachedDetails ? JSON.parse(cachedDetails) : null;
    });
    const [role, setRole] = useState<UserRole | null>(null);
    const [loading, setLoading] = useState(true);

    const location = useLocation();

    useEffect(() => {
        const path = location.pathname;
        if (path.includes("management") && !path.endsWith("management") && !path.includes("dashboard")) {
            localStorage.setItem("lastPath", path);
        }
    }, [location.pathname]);

    const handleLogout = useCallback(async (idle = false) => {
        if (auth.currentUser) {
            await recruiterService.setRecruiterActivity(false);
        }

        await auth.signOut();
        setCurrentUser(null);
        setRole(null);
        setUserDetails(null);

        const sidebarLocked = localStorage.getItem("sidebarLocked");
        const isDark = localStorage.getItem("isDark");
        const lastPath = localStorage.getItem("lastPath");
        localStorage.clear();
        if (sidebarLocked) {
            localStorage.setItem("sidebarLocked", sidebarLocked);
        }

        if (isDark) {
            localStorage.setItem("isDark", isDark);
        }

        if (idle && lastPath) {
            localStorage.setItem("lastPath", lastPath);
        }
    }, []);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            setCurrentUser(user);
            if (user) {
                setLoading(true);
                try {
                    const tokenResult = await user.getIdTokenResult();
                    const userRole = tokenResult.claims.role as UserRole;
                    setRole(userRole || null);

                    if (userRole && userRole !== UserRole.candidate) {
                        await recruiterService.setRecruiterActivity(true);
                        const recruiterDetails = await recruiterService.getRecruiterByUID(user.uid);
                        setUserDetails(recruiterDetails);
                        localStorage.setItem("userDetails", JSON.stringify(recruiterDetails));
                    }
                } catch (error) {
                    console.error("Error fetching user details:", error);
                } finally {
                    setLoading(false);
                }
            } else {
                setRole(null);
                setUserDetails(null);
                localStorage.removeItem("userDetails");
                setLoading(false);
            }
        });

        return () => {
            unsubscribe();
        };
    }, []);

    const DEBOUNCE = 500;

    useIdleTimer({
        timeout: 1000 * 60 * 10,
        onIdle: () => handleLogout(true),
        debounce: DEBOUNCE,
        crossTab: true,
        syncTimers: 200
    });

    React.useEffect(() => {
        const id = setInterval(() => {
            setLocalStorage("lastDebounce", Date.now());
        }, DEBOUNCE);

        return () => clearInterval(id);
    }, []);

    React.useEffect(() => {
        const lastActive = getLocalStorage("lastDebounce");
        if (lastActive && Date.now() - Number(lastActive) > 100 * 60 * 2) {
            handleLogout(true);
        }
    }, [handleLogout]);

    const value = useMemo(
        () => ({
            currentUser,
            role,
            userDetails,
            loading,
            handleLogout
        }),
        [currentUser, role, userDetails, loading, handleLogout]
    );

    return (
        <AuthContext.Provider value={value}>
            {children}
            {loading && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "transparent",
                        zIndex: 9998,
                    }}
                />
            )}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
