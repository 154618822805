import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {collection, getDocs, orderBy, query} from 'firebase/firestore';
import {Job, jobConverter, JobsLocalStorage} from '../../../Firebase/FirebaseFunctions/JobsService';
import {db} from '../../../Firebase/FirebaseConfig/firebase';
import {getLocalStorage, setLocalStorage} from "../../../Firebase/FirebaseFunctions/Helpers/LocalStorage";
import {UserRole} from "../../../Firebase/enums/UserRole";
import {useAuth} from "../AuthContext/AuthContext";

interface DataContextType {
    jobs: Array<Job>;
    fullJobs: Array<Job>;
    loadingJobs: boolean;
    refreshJobs: () => Promise<void>;
}

const DataContext = createContext<DataContextType | undefined>(undefined);

export function JobsDataProvider({
                                     children,
                                 }: {
    children: ReactNode;
}) {
    const {role, userDetails} = useAuth();
    const FullJobsLocalStorage = `full${JobsLocalStorage[0].toUpperCase()}${JobsLocalStorage.slice(1)}`
    const [jobs, setJobs] = useState<Array<Job>>(getLocalStorage(JobsLocalStorage));
    const [fullJobs, setFullJobs] = useState<Job[]>(getLocalStorage(FullJobsLocalStorage));
    const [loadingJobs, setLoadingJobs] = useState<boolean>(true);

    const fetchJobs = async () => {
        setLoadingJobs(true);
        try {
            if (userDetails?.sectors.length <= 0) {
                setJobs([]);
                return;
            }

            let jobsQuery = query(
                collection(db, 'jobs').withConverter(jobConverter),
                orderBy('creationDate', 'desc')
            );

            // if (role !== UserRole.admin) {
            //     jobsQuery = query(jobsQuery, where('sector', 'in', userDetails?.sectors));
            // }

            const snapshot = await getDocs(jobsQuery);
            const fetchedJobs = snapshot.docs.map((doc) => doc.data() as Job);
            console.log('[JOBS] Fetched jobs (from server) length:', fetchedJobs.length);

            const fetchedFilteredJobs = role !== UserRole.admin ? fetchedJobs.filter(job => userDetails?.sectors?.includes(job.sector)) : fetchedJobs;
            setFullJobs(fetchedJobs);
            setJobs(fetchedFilteredJobs);

            setLocalStorage(JobsLocalStorage, fetchedFilteredJobs);
            setLocalStorage(FullJobsLocalStorage, fetchedJobs);

            localStorage.setItem('jobsLastFetchTime', new Date().getTime().toString());
        } catch (error) {
            console.error("[JOBS] Error fetching jobs: ", error);
            const cachedData = getLocalStorage(JobsLocalStorage);
            setJobs(cachedData);
        } finally {
            setLoadingJobs(false);
        }
    };

    const refreshJobs = async () => {
        setLoadingJobs(true);
        console.log("[JOBS] data refreshed (from local).");
        setJobs(getLocalStorage(JobsLocalStorage));
        setFullJobs(getLocalStorage(FullJobsLocalStorage));
        setLoadingJobs(false);
    };

    useEffect(() => {
        const lastFetch = localStorage.getItem('jobsLastFetchTime');
        const currentTime = new Date().getTime();
        const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes cache

        if (lastFetch && currentTime - parseInt(lastFetch) < CACHE_DURATION) {


            const cachedData = getLocalStorage(JobsLocalStorage);
            setJobs(cachedData);
            console.log('[JOBS] Found cached (from local) jobs:', cachedData.length);

            const fullCachedData = getLocalStorage(FullJobsLocalStorage);
            setFullJobs(fullCachedData);
            console.log('[JOBS] Found cached (from local) jobs:', fullCachedData.length);

            setLoadingJobs(false);
            return;
        }

        fetchJobs();
    }, [userDetails?.sectors, role]);

    return (
        <DataContext.Provider value={{jobs, fullJobs, loadingJobs, refreshJobs}}>
            {children}
        </DataContext.Provider>
    );
}

export function useJobsData(): DataContextType {
    const context = useContext(DataContext);
    if (!context) {
        throw new Error('useJobsData must be used within a JobsDataProvider');
    }
    return context;
}
