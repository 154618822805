import React from "react";
import {Box, Stack, Typography} from "@mui/material";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

type InterviewStatusProps = {
    status: "התקבל" | "נדחה" | "אינו מעוניין במשרה" | "העבר למשרה אחרת" | 'קו\\"ח הועברו למנהלי התוכנית';
};

export default function InterviewStatus({status}: InterviewStatusProps) {
    const statusConfig: Record<string, { icon: React.ElementType; color: string; text: string }> = {
        "התקבל": {icon: SentimentSatisfiedAltIcon, color: "green", text: "המועמד התקבל!"},
        "נדחה": {icon: SentimentVeryDissatisfiedIcon, color: "red", text: "נדחה"},
        "אינו מעוניין במשרה": {icon: SentimentVeryDissatisfiedIcon, color: "red", text: "אינו מעוניין במשרה"},
        "העבר למשרה אחרת": {icon: SentimentSatisfiedIcon, color: "blue", text: "הועבר למשרה אחרת"},
        "קו\"ח הועברו למנהלי התוכנית": {
            icon: SentimentSatisfiedIcon,
            color: "blue",
            text: "קו\"ח הועברו למנהלי התוכנית",
        },
    };

    const config = statusConfig[status];

    const StatusContent = ({
                               icon: Icon,
                               color,
                               text,
                           }: {
        icon: React.ElementType;
        color: string;
        text: string;
    }) => (
        <>
            <Icon sx={{fontSize: 120, color}}/>
            <Box>
                <Typography variant="h4" fontWeight="bold" sx={{color}}>
                    {text}
                </Typography>
            </Box>
        </>
    );

    return (
        <Stack direction={{xs: "column", sm: "row"}} alignItems="center" spacing={2}>
            {config ? <StatusContent icon={config.icon} color={config.color} text={config.text}/> : null}
        </Stack>
    );
}
