import {Card, CardContent, CardHeader, Typography} from "@mui/material";
import React from "react";

interface CustomCardProps {
    children: React.ReactNode;
    title?: string;
    noPadding?: boolean;
    sx?: any;
}

export default function CustomCard({ children, noPadding, title, sx = {} }: CustomCardProps) {
    const childrenArray = React.Children.toArray(children);
    const header = childrenArray.find(child =>
        React.isValidElement(child) && child.type === CustomCardHeader
    );

    const otherChildren = childrenArray.filter(child =>
        React.isValidElement(child) && child.type !== CustomCardHeader
    );

    return (
        <Card variant="outlined" sx={{ borderRadius: "15px", width: "100%", display: "flex", flexDirection: "column", ...sx }}>
            {(title || header) && (
                <CardHeader
                    title={
                        header ? header : ( // Change this line
                            title ? (
                                <Typography
                                    variant="h5"
                                    alignSelf="center"
                                    sx={{
                                        fontFamily: 'Rubik',
                                        fontWeight: 600,
                                        opacity: 0.8,
                                    }}
                                >
                                    {title}
                                </Typography>
                            ) : undefined
                        )
                    }
                />
            )}
            <CardContent
                sx={{
                    padding: noPadding ? 0 : "16px",
                    "&:last-child": {
                        paddingBottom: noPadding ? 0 : "24px",
                    },
                    width: "100%",
                    ...sx
                }}
            >
                {otherChildren}
            </CardContent>
        </Card>
    );
}


export function CustomCardHeader({children}) {
    return (<>{children}</>)
}