import {Grid, TextField, Typography} from "@mui/material";
import React from "react";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, {Dayjs} from "dayjs";
import {DatePickerRTL} from "../../../../../Styled/DatePicker";
import {TimePickerRTL} from "../../../../../Styled/TimePicker";
import {useAuth} from "../../../../../Contexts/AuthContext/AuthContext";
import { UserRole } from "../../../../../../Firebase/enums/UserRole";

export default function InviteForInterview({candidate, application, setInterviewDate, formik, step}) {
    const {role} = useAuth();
    const [date, setDate] = React.useState<Dayjs | null>(dayjs());
    const [time, setTime] = React.useState<Dayjs | null>(dayjs());
    const messageField = step === 1 ? 'firstWaMessage' : 'secondWaMessage';

    const formatDate = (date: Dayjs | null) => date ? date.format('DD/MM/YYYY') : '';
    const formatTime = (time: Dayjs | null) => time ? time.format('HH:mm') : '';

    const updateFormik = () => {
        const obj = {
            date: formatDate(date),
            time: formatTime(time),
        };
        setInterviewDate(obj);

        const initialMessage = `שלום רב ${candidate.firstname}, בהמשך לשיחתנו, זומנת לראיון בתאריך ${obj.date} בשעה ${obj.time}.`;
        formik.setFieldValue(messageField, initialMessage, false);
    }

    React.useEffect(() => {
        updateFormik();
    }, [date, time]);

    const interviewDate = dayjs(application.interviewDate).format("DD/MM/YYYY");
    const interviewTime = dayjs(application.interviewDate).format("HH:mm");
    const currentDateAndTime = new Date();

    return (<>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="he">
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <DatePickerRTL
                        label="בחירת תאריך"
                        value={date}
                        onChange={(newDate) => setDate(newDate as Dayjs)}
                        views={['year', 'month', 'day']}
                        sx={{width: '100%'}}
                        minDate={role === UserRole.admin ? undefined : dayjs()}
                        defaultValue={dayjs()}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TimePickerRTL
                        label="בחירת שעה"
                        value={time}
                        onChange={(newTime) => setTime(newTime as Dayjs)}
                        sx={{width: '100%'}}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        required
                        label="הודעה בווצאפ"
                        fullWidth
                        multiline
                        rows={2}
                        variant="outlined"
                        {...formik.getFieldProps(messageField)}
                        error={formik.touched[messageField] && Boolean(formik.errors[messageField])}
                        helperText={formik.touched[messageField] && formik.errors[messageField]}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
            </Grid>
        </LocalizationProvider>

        {application.interviewDate && new Date(application.interviewDate).getFullYear() >= 2000 && (
            <Typography variant="body2" color="text.secondary">
                {application.interviewDate < currentDateAndTime ? `בוצע לאחרונה ראיון בתאריך ${interviewDate}` : `זומן לראיון לתאריך ${interviewDate}`}
                {interviewTime && ` בשעה ${interviewTime}`}
            </Typography>
        )}
    </>)
}
