import {TableColumns} from "../../../Components/Table/Table.types";
import React from "react";
import {useCandidatesData} from "../../../Contexts/DataContext/CandidatesDataContext";
import {useApplicationsData} from "../../../Contexts/DataContext/ApplicationsDataContext";
import {Alert, Chip} from "@mui/material";
import ChipColorPicker from "../../../Utilities/ChipColorPicker";
import {ApplicationCandidatesStatuses} from "../../../Utilities/Statuses";
import {RatingRTL} from "../../../Styled/Rating";
import BetterTable from "../../../Components/Table/Table";

export default function JobCandidatesTable({job}) {
    const columnsData: TableColumns = {
        columns: [
            {name: "שם המועמד", field: "candidateName", width: "50%", sortable: false},
            {name: "התאמה", field: "candidateFit", width: "30%", sortable: false},
            {name: "סטטוס", field: "candidateStatus", width: "20%", sortable: false}
        ],
    };

    const [loading, setLoading] = React.useState(true);
    const {candidates, loadingCandidates} = useCandidatesData();
    const {fetchApplicationsByJobNumber} = useApplicationsData();

    const [data, setData] = React.useState<any[]>([]);

    const formatCandidateName = (candidate) => {
        if (!candidate?.firstname && !candidate?.lastname) {
            return "שגיאה";
        }

        return [
            candidate.firstname,
            candidate.lastname
        ].filter(Boolean).join(" ");
    };

    const mapApplicationsToData = (results) => {
        return results
            .filter(app => {
                const candidate = candidates.find(cand => cand.id === app.candidateId);
                return candidate && app.status === "הוגשה מועמדות";
            })
            .map(app => {
                const candidate = candidates.find(cand => cand.id === app.candidateId);
                const fullName = formatCandidateName(candidate);

                return {
                    candidateName: {
                        label: fullName,
                        component: <a href={`/management/candidate/${candidate?.id}`} target="_blank"
                                      rel={"noreferrer"}>{fullName}</a>
                    },
                    candidateStatus: {
                        label: app.status,
                        component: <Chip
                            label={app.status}
                            color={ChipColorPicker(ApplicationCandidatesStatuses.indexOf(app.status))}
                        />
                    },
                    candidateFit: {
                        label: app.matchingRate,
                        component: <RatingRTL
                            value={app.matchingRate}
                            readOnly
                        />
                    },
                    candidate
                };
            });
    };

    React.useEffect(() => {
        if (!loadingCandidates) {
            fetchApplicationsByJobNumber(job.jobNumber).then(data => {
                setData(mapApplicationsToData(data));
            }).finally(() => setLoading(false));
        }
    }, [job, loadingCandidates]);

    if (!loading && data.length <= 0) {
        return <>
            <Alert
                severity="info"
                variant="outlined"
                sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    padding: 2,
                    borderRadius: 1
                }}
            >
                לא נמצאו מועמדים עם מועמדות מסוג "הוגשה מועמדות"
            </Alert>
        </>
    }

    return <>
        <BetterTable columnsData={columnsData}
                     data={data}
                     density={'small'}
                     plain={true}
                     alignText="start"
                     sxHeaderCell={{zIndex: 5}}
                     loading={loading}
        />
    </>
}
