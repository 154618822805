import React, {useCallback, useEffect, useState} from 'react';
import {Box, Button, Grid, Stack} from '@mui/material';
import GvanimProgress from "../../Components/GvanimProgress/GvanimProgress";
import Header from "./Components/Header/Header";
import ScrollTop from "./Components/ScrollTop/ScrollTop";
import FilterPanel from "./Components/FilterPanel/FilterPanel";
import NoItems from "./Components/NoItems/NoItems";
import JobItem from "./Components/JobItem/JobItem";
import {UseScreenSelectors} from "../../../Utilities/ScreenSize";
import {OptionType} from "../../Components/CustomMultiSelect/Option.type";
import {containerStyles, dividerStyles, gridStyles, loadingStyles, loadingStylesContainer} from "./MainPageStyles";
import {useDrushimJobsData} from "../../Contexts/JobsDrushimContext";
import {Job} from "../../../Firebase/FirebaseFunctions/JobsService";

export default function MainPage() {
    const {jobs, loadingJobs} = useDrushimJobsData();
    const [visible, setVisible] = useState(0);
    const [locationFilter, setLocationFilter] = useState<OptionType[] | null>();
    const [roleFilter, setRoleFilter] = useState<OptionType[] | null>();
    const [search, setSearch] = useState('');
    const [scope, setScope] = useState<number | null>(null);

    const screenSize = UseScreenSelectors();

    const checkItemsVisibility = useCallback(() => {
        setVisible((val) => screenSize === 'xs' || screenSize === 'sm' ? val + 4 : val + 6);
    }, [screenSize]);

    const showMoreItems = () => {
        checkItemsVisibility();
    }

    const filterJobs = () => {
        return jobs?.filter((job: Job) => {
            const roleMatch = !roleFilter?.length || roleFilter.some(role => role.label === job.role);
            const locationMatch = !locationFilter?.length || locationFilter.some(location => job.region.trim().includes(location.label.trim()));
            const scopeMatch = scope ? (scope === 100 ? job.scope.includes(100) : job.scope[0] <= scope) : true;
            const searchTerms = search.toLowerCase();
            const searchMatch = job.region.toLowerCase().includes(searchTerms) ||
                job.role.toLowerCase().includes(searchTerms) ||
                job.requirements.toLowerCase().includes(searchTerms);
            return roleMatch && locationMatch && scopeMatch && searchMatch;
        });
    };

    useEffect(() => {
        setVisible(screenSize === 'xs' || screenSize === 'sm' ? 4 : 6);
    }, [screenSize]);

    const renderFilterPanel = (display) => (
        <FilterPanel
            display={display}
            setSearch={setSearch}
            setLocationFilter={setLocationFilter}
            setRoleFilter={setRoleFilter}
            setScope={setScope}
        />
    );

    const renderJobItems = () => {
        const filteredJobs = filterJobs();
        if (filteredJobs.length <= 0) return <NoItems/>;

        return filteredJobs
            .sort((job) => job?.highPriority ? -1 : 1)
            .slice(0, visible)
            .map((job: Job, index: number) => (
                <Grid item xs={12} sm={8} md={4} lg={4} xl={4} key={index} sx={{mb: {xs: 3, md: 0}}}>
                    <JobItem job={job}/>
                </Grid>
            ));
    };

    if (loadingJobs) {
        return <GvanimProgress/>;
    }

    const filteredJobs = filterJobs();

    return (
        <Box>
            <Header/>
            <Stack
                direction='row'
                justifyContent={{sm: 'center', lg: 'space-between'}}
                sx={containerStyles()}
            >
                <ScrollTop/>
                {renderFilterPanel(screenSize !== 'xs' && screenSize !== 'sm' ? 'desktop' : 'none')}
                <Grid
                    alignContent='start'
                    justifyContent='start'
                    container
                    maxWidth={{xs: '100%', lg: '74%'}}
                    rowSpacing={{xs: 0, sm: 2, md: 4, lg: 3, xl: 4}}
                    columnSpacing={{xs: 0, sm: 0, md: 4, lg: 4, xl: 4}}
                    columns={{xs: 12, sm: 8, md: 8, lg: 8, xl: 8}}
                    sx={gridStyles()}
                >
                    {renderFilterPanel(screenSize === 'xs' || screenSize === 'sm' ? 'mobile' : 'none')}
                    <Box display={{xs: 'none', lg: 'flex'}} sx={dividerStyles()}/>

                    {renderJobItems()}
                    {filteredJobs?.length > visible && (
                        <Box sx={loadingStylesContainer()}>
                            <Button
                                onClick={showMoreItems}
                                sx={loadingStyles()}
                            >
                                פתח עוד..
                            </Button>
                        </Box>
                    )}
                </Grid>
            </Stack>
        </Box>
    );
}
