import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import CandidateInfo from "./Components/CandidateInfo";
import {useAuth} from "../../../Contexts/AuthContext/AuthContext";
import LoadingSpinner from "../../../Components/LoadingSpinner/LoadingSpinner";
import {JobsDataProvider, useJobsData} from "../../../Contexts/DataContext/JobsDataContext";
import {CandidatesDataProvider, useCandidatesData} from "../../../Contexts/DataContext/CandidatesDataContext";
import {ApplicationsDataProvider, useApplicationsData} from "../../../Contexts/DataContext/ApplicationsDataContext";
import {Candidate} from "../../../../Firebase/FirebaseFunctions/CandidateService";
import {Application} from "../../../../Firebase/FirebaseFunctions/ApplicationService";

function CandidatePageContent() {
    const {id} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const isResumeUrl = location.pathname.endsWith('resume');

    const {candidates, loadingCandidates, reloadCandidates} = useCandidatesData();
    const {jobs, loadingJobs} = useJobsData();
    const {loadingApplications, fetchApplicationsByCandidateId} = useApplicationsData();

    const [candidateApplications, setCandidateApplications] = useState<Application[] | undefined>(undefined);
    const [candidate, setCandidate] = useState<Candidate | undefined>(undefined);
    const [candidateJobs, setCandidateJobs] = useState<any[] | undefined>(undefined);

    // Updating candidate information
    useEffect(() => {
        if (!loadingCandidates && !candidate) {
            const foundCandidate = candidates.find(c => c.id === id);
            if (foundCandidate) {
                setCandidate(foundCandidate);
            } else {
                // Reload candidates from the database for an up-to-date list
                reloadCandidates().then(() => {
                    const refreshedCandidate = candidates.find(c => c.id === id);
                    if (refreshedCandidate) {
                        setCandidate(refreshedCandidate);
                    } else {
                        navigate("/management/not-found");
                    }
                });
            }
        }
    }, [candidates, loadingCandidates, id, candidate, navigate, reloadCandidates]);

    // Updating candidate applications
    useEffect(() => {
        if (!loadingApplications && !candidateApplications) {
            fetchApplicationsByCandidateId(id!)
                .then(results => setCandidateApplications(results));
        }
    }, [loadingApplications, id, candidateApplications, fetchApplicationsByCandidateId]);

    // Updating candidate jobs
    useEffect(() => {
        if (!loadingApplications && !loadingJobs && !candidateJobs && candidateApplications) {
            const filteredJobs = jobs.filter(job =>
                candidateApplications.some(app => app.jobNumber === job.jobNumber)
            );
            setCandidateJobs(filteredJobs);
        }
    }, [loadingApplications, loadingJobs, jobs, candidateApplications, candidateJobs]);

    if (!candidate || !candidateApplications || !candidateJobs || loadingCandidates || loadingJobs || loadingApplications) {
        return <LoadingSpinner/>;
    }

    const outletContext = {
        candidate,
        candidateApplications,
        candidateJobs,
    };

    return (
        <>
            {!isResumeUrl && <CandidateInfo candidate={candidate}/>}
            <Outlet context={outletContext}/>
        </>
    );
}

export default function CandidatePage() {
    const {loading} = useAuth();

    if (loading) {
        return <LoadingSpinner/>;
    }

    return (
        <JobsDataProvider>
            <CandidatesDataProvider>
                <ApplicationsDataProvider>
                    <CandidatePageContent/>
                </ApplicationsDataProvider>
            </CandidatesDataProvider>
        </JobsDataProvider>
    );
}