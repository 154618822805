import {Box} from "@mui/material";
import React from "react";
import FullScreenDialog, {FullScreenDialogHeader} from "../../../Components/Dialog/FullScreenDialog/FullScreenDialog";
import ProfileForm from "./Components/ProfileForm";
import ModifyNewRecruiterHeader from "./ModifyNewRecruiterHeader";

export default function ModifyNewRecruiter({recruiter, show, close}) {
    return <>
        <FullScreenDialog show={show} close={close}>
            <FullScreenDialogHeader>
                <ModifyNewRecruiterHeader recruiter={recruiter}/>
            </FullScreenDialogHeader>

            <Box
                sx={{
                    position: "relative",
                    display: "flex",
                    justifySelf: "center",
                    width: {md: "85%", sm: "90%", xs: "100%"},
                    paddingTop: 5,
                    alignSelf: "center"
                }}
            >
                <ProfileForm recruiter={recruiter} state={recruiter ? 'modify' : 'new'} close={close}/>
            </Box>
        </FullScreenDialog>
    </>
}
